import { createContext, ReactNode, useState } from "react";
import asModal from "view/LAYOUT/asModal";

export const ModalContext = createContext<
  (
    Component: (close: () => void) => ReactNode,
    options?: NonNullable<Parameters<typeof asModal>[1]>
  ) => void
>(() => {
  return;
});

export default function ModalProvider(props: { children: ReactNode }) {
  const [modal, setModal] = useState<ReactNode>();

  function close() {
    setModal(undefined);
  }

  return (
    <ModalContext.Provider
      value={(Component, options) =>
        setModal(
          asModal(Component(close), {
            ...options,
            onClose: (...params) => {
              options?.onClose?.(...params);
              close();
            },
          })
        )
      }
    >
      {modal}
      {props.children}
    </ModalContext.Provider>
  );
}
