import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import type {
  CodeDeliveryDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { Dispatch, SetStateAction, useState } from "react";
import { setUserRoles } from "./authorize";
import { setLocationPermissions } from "./locate";
import { log } from "./log";
import { useAsync } from "./render";
import { storeClear, storeConfigure } from "./store";

export type Email = `${string}@${string}.${string}`;

/**
 * Enum for sign in function errors.
 */
export enum SignInMessages {
  UsernameIsWrong,
  UserNotConfirmed,
  PasswordIsWrong,
  PasswordAttemptsExceeded,
  UserMustBeInitialized,
  UserMustResetPassword,
  UserSessionCouldNotBeLoaded,
}

export const DEFAULT_SIGN_UP_PASSWORD = "123456789";

let currentUser: CognitoUser | null | undefined; //? null means no user, undefined means need to evaluate
let currentUserSetter: Dispatch<SetStateAction<typeof currentUser>>;

/**
 * Hook used for auth initialization.
 * @returns Undefined if auth to initialize, else true if authenticated
 */
export function useAuth() {
  const [user, setUser] = useState<typeof currentUser>();

  currentUser = user;
  currentUserSetter = setUser;

  let subscription: (() => void) | undefined;

  useAsync(
    {
      action: () =>
        Hub.listen("auth", async ({ payload: { event } }) => {
          if (event === "signOut") {
            await setCurrentUser(null);

            if (process.env.NODE_ENV === "development") await storeClear();
          }
        }),
      cleanup: () => subscription?.(),
    },
    (listener) => (subscription = listener)
  );

  return user === undefined ? undefined : isAuthenticated();
}

/**
 * Signs the user in.
 * @param username Username of the user
 * @param password Password of the user
 */
export async function signIn(username: string, password: string) {
  let mustClearData = process.env.NODE_ENV === "development";

  const trimmedUsername = username.trim();

  if (!mustClearData && trimmedUsername && password) {
    const lastUserKey = "lastUser";

    mustClearData = localStorage.getItem(lastUserKey) !== trimmedUsername;

    if (mustClearData) localStorage.setItem(lastUserKey, trimmedUsername);
  }

  //! Here there could be data loss
  if (mustClearData) await storeClear();

  let user: CognitoUser;
  let message: SignInMessages | undefined;

  try {
    user = await Auth.signIn(trimmedUsername, password);
  } catch (error) {
    const typedError = error as {
      code:
        | "UserNotFoundException"
        | "NotAuthorizedException"
        | "UserNotConfirmedException"
        | "PasswordResetRequiredException";
      message: string;
    };

    let enumMessage = NaN;

    switch (typedError.code) {
      case "UserNotFoundException":
        enumMessage = SignInMessages.UsernameIsWrong;
        break;

      case "NotAuthorizedException":
        enumMessage =
          typedError.message !== "Password attempts exceeded"
            ? SignInMessages.PasswordIsWrong
            : SignInMessages.PasswordAttemptsExceeded;
        break;

      case "UserNotConfirmedException":
        enumMessage = SignInMessages.UserNotConfirmed;
        break;

      case "PasswordResetRequiredException":
        enumMessage = SignInMessages.UserMustResetPassword;
        break;
    }

    return { error, message: enumMessage };
  }

  if (isUserInitialized(user)) await setCurrentUser(user);
  else message = SignInMessages.UserMustBeInitialized;

  return { user, message };
}

/**
 * Signs the user out.
 */
export async function signOut() {
  try {
    return (await Auth.signOut()) as void;
  } catch (error) {
    return { error };
  }
}

/**
 * Creates a new user with the provided username and email.
 * @param username The username to sign up with
 * @param email The email to contact the user
 * @returns The signed up user or error
 */
export async function signUp(username: string, email: Email) {
  try {
    return await Auth.signUp({
      username,
      password: DEFAULT_SIGN_UP_PASSWORD,
      attributes: {
        email,
      },
    });
  } catch (error) {
    return { error };
  }
}

/**
 * Initializes the user to allow authenticated sign in.
 * @param user User to initialize
 * @param newPassword Password used to initialize the user
 * @returns The current user session or error
 */
export async function initializeUser(user: CognitoUser, newPassword: string) {
  return new Promise<{ session?: CognitoUserSession; error?: unknown }>(
    (resolve) =>
      user.completeNewPasswordChallenge(newPassword, null, {
        onSuccess: async (session) => {
          await setCurrentUser(user);

          resolve({ session });
        },
        onFailure: (error) => resolve({ error }),
      })
  );
}

/**
 * Changes the password of the authenticated user (otherwise use initializeUser).
 * @param user User to initialize
 * @param currentPassword Current password
 * @param newPassword New password
 */
export async function changePassword(
  user: CognitoUser,
  currentPassword: string,
  newPassword: string
) {
  return new Promise<{ error?: unknown }>((resolve) =>
    user.changePassword(currentPassword, newPassword, (error) =>
      resolve({ error })
    )
  );
}

/**
 * Initiates a new forgot password procedure; sends a verification code, that needs to be confirmed with the new password.
 * @param username The username that forgot the password
 * @returns The error or the object used for confirmation
 */
export async function forgotPassword(username: string) {
  let deliveryDetails: CodeDeliveryDetails;

  try {
    deliveryDetails = (await Auth.forgotPassword(username)).CodeDeliveryDetails;
  } catch (error) {
    return { error };
  }

  return {
    deliveryDetails: {
      medium: deliveryDetails.DeliveryMedium as "EMAIL" | "SMS",
      destination: deliveryDetails.Destination,
      attribute: deliveryDetails.AttributeName,
    },
    confirm: async (verificationCode: string, newPassword: string) => {
      try {
        await Auth.forgotPasswordSubmit(
          username,
          verificationCode,
          newPassword
        );
      } catch (error) {
        return { error };
      }

      return signIn(username, newPassword);
    },
  };
}

/**
 * Checks if the current user session is valid.
 */
export function isAuthenticated() {
  return !!currentUser ?? false;
}

/**
 * Retrieves the username of the current user.
 * @returns The username as string or undefined
 */
export function getLoggedUser() {
  return currentUser?.getUsername();
}

/**
 * Tries to restore login from a past session.
 * @returns The signed in user
 */
export async function restoreSignIn() {
  let authenticatedUser: typeof currentUser = null;

  try {
    authenticatedUser = await Auth.currentAuthenticatedUser();
  } catch (error) {
    if (error !== "The user is not authenticated") log(error);
  }

  await setCurrentUser(authenticatedUser);

  return authenticatedUser;
}

/**
 * Retrieves the authenticated JWT token for the current user.
 * @returns The active JWT token
 */
export function getAuthToken() {
  return getAccessToken()?.getJwtToken();
}

/**
 * Sets the current user.
 * @param user The user to set
 */
async function setCurrentUser(user: typeof currentUser) {
  currentUserSetter(user);
  // Current session could be null
  if (!user) {
    setUserRoles([]);
  } else {
    setUserRoles(getAccessToken(user)?.payload["cognito:groups"] ?? []);
    setLocationPermissions(await Auth.currentCredentials());

    storeConfigure();
  }
}

/**
 * Retrieves the access token of the user passed as param or the current user.
 * @param user The user for which to retrieve the access token
 * @returns The access token of the user
 */
function getAccessToken(user?: CognitoUser | null) {
  return (user ?? currentUser)?.getSignInUserSession()?.getAccessToken();
}

/**
 * Checks if the specified AWS Cognito user is initalized or needs to be initialized.
 * @param user The Cognito user to check.
 * @returns True if the user is initialized, false if the user needs to be initalized.
 */
function isUserInitialized(user: CognitoUser) {
  return !(user as unknown as { challengeName: string }).challengeName;
}
