import { Amplify } from "@aws-amplify/core"; // Amplify services
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material"; // Material ui theme
import awsexports from "aws-exports"; // Amplify config file
import { LOCAL_STORAGE_KEY_PREFIX } from "constants/localization";
import { PALETTE } from "constants/ui";
import { lazy, Suspense } from "react";
import "utils/localize"; // react-i18next translations
import Progress from "view/symbols/Progress";

//! Initialize app settings here

if (process.env.NODE_ENV !== "production") {
  //? Uncomment the following line to reset local storage after each refresh
  // localStorage.clear();

  // Clears browser locales, cached in local storage, which are memorized using LocalStorageBackend (configured in i18n)
  // This serves to render real locales instead of cached ones during development and testing
  for (const key in localStorage) {
    if (key.startsWith("i18next") || key.startsWith(LOCAL_STORAGE_KEY_PREFIX))
      localStorage.removeItem(key);
  }
}

//! Initialize third-party modules here

Amplify.configure(awsexports);

//? This is used to display a loading screen as soon as possible
const Localizer = lazy(() => import("Localizer"));

export default function App() {
  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          ...PALETTE,
          background: { default: "#FFFFFF" },
        },
      })}
    >
      <CssBaseline />
      <Suspense fallback={<Progress />}>
        <Localizer />
      </Suspense>
    </ThemeProvider>
  );
}
