import { ComponentProps, FC, ReactNode } from "react";
import asBordered from "../LAYOUT/asBordered";
import asGrid from "../LAYOUT/asGrid";
import Controller from "./Controller";
import { FormProps } from "./Form";

export default function Section(
  props: {
    id?: string | number;
    defaultValue?: Record<any, any>;
  } & ComponentProps<typeof SectionBase> &
    FormProps
) {
  const { form, defaultValue, required, children, ...sectionProps } = props;

  return (
    <Controller
      form={form}
      defaultValue={defaultValue}
      defaultFallback={null}
      {...sectionProps}
    >
      {(field) => (
        <SectionBase
          {...sectionProps}
          required={required ?? form.required}
          {...field}
        >
          {required && !form.required ? `${children} *` : children}
        </SectionBase>
      )}
    </Controller>
  );
}

function SectionBase(props: {
  children?: ReactNode;
  value?: Record<any, any>;
  required?: boolean;
  inNewLine?: number[];
  insert?: { after: number; Component: FC }[];
  Components?: (props: {
    values: Record<any, any>;
    inputProps: Record<any, any>;
  }) => ReactNode[];
  exclude?: number[];
}) {
  const { children, value, required, insert, Components, inNewLine, exclude } =
    props;

  const rawComponents = Components?.({
    values: value ?? {},
    inputProps: { required },
  });

  const components = exclude
    ? rawComponents?.filter((_, index) => !exclude?.includes(index))
    : rawComponents;

  insert?.forEach(({ after, Component }) =>
    components?.splice(after + 1, 0, <Component />)
  );

  const list = [] as ReactNode[];

  if (components) {
    const sections = [] as Parameters<typeof asGrid>[0];

    if (inNewLine) {
      let splitter = 0;

      inNewLine.forEach((value) => {
        sections.push({
          section: components.slice(splitter, value),
          options: {
            fullWidth: true,
          },
        });

        splitter = value;
      });

      sections.push({
        section: components.slice(splitter),
        options: {
          fullWidth: true,
        },
      });
    } else {
      sections.push({
        section: components,
      });
    }

    list.push(asGrid(sections));
  }

  return asBordered(
    asGrid(
      [
        {
          section: list,
        },
      ],
      {
        maxColumns: 2,
      }
    ),
    { title: children }
  );
}
