import { ReactNode } from "react";
import { getLargeScreen } from "utils/show";
import asBordered from "../LAYOUT/asBordered";
import asGrid from "../LAYOUT/asGrid";
import asSpace from "../LAYOUT/asSpace";

export default function Context(props: {
  children?: ReactNode;
  values?: ReactNode[];
}) {
  const { children, values } = props;

  const largeScreen = getLargeScreen();

  return values
    ? asBordered(
        asSpace(
          asGrid([{ section: values }]),
          largeScreen ? { pt: 1 } : undefined
        ),
        {
          title: children,
        }
      )
    : null;
}
