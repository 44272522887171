import { ModalContext } from "helpers/contexts/ModalContext";
import { OptionsObject as SnackbarOptions, useSnackbar } from "notistack";
import { ReactNode, useContext } from "react";

let largeScreen: boolean | undefined;

export function setLargeScreen(largeScreenValue: boolean) {
  largeScreen = largeScreenValue;
}

/**
 * Executes a media query to check whether the app is displayed on a large screen.
 * @returns True if using a large screen, false otherwise
 */
export function getLargeScreen() {
  return largeScreen;
}

const toastQueue = [] as {
  message: ReactNode;
  variant?: SnackbarOptions["variant"];
  options?: Omit<SnackbarOptions, "variant">;
}[];

const viewedKeys = [] as NonNullable<SnackbarOptions["key"]>[];

let currentTimeout: NodeJS.Timeout;

/**
 * Creates a toast function, for UI notifications.
 * @returns Toast function
 */
export function useToast() {
  const { enqueueSnackbar } = useSnackbar();

  function toastFromQueue() {
    const { message, variant, options } = toastQueue[0];

    currentTimeout = setTimeout(() => {
      toastQueue.shift();
      if (toastQueue.length) toastFromQueue();
    }, options?.autoHideDuration ?? 7000);

    enqueueSnackbar(message, {
      ...options,
      variant,
    });
  }

  return (
    message: ReactNode,
    variant?: SnackbarOptions["variant"],
    options?: { queue?: boolean } & Omit<SnackbarOptions, "variant">
  ) => {
    if (options?.key) {
      if (viewedKeys.includes(options.key)) return;
      else viewedKeys.push(options.key);
    }

    let assignedSnackbarOptions: typeof options;

    if (options) {
      const { queue, ...snackbarOptions } = options;

      assignedSnackbarOptions = snackbarOptions;
    }

    const immediate = !options?.queue;

    //? Default behavior
    if (immediate) {
      //? Remove current toast
      clearTimeout(currentTimeout);
      toastQueue.shift();

      //? If immediate, move to front of queue and show immediately
      toastQueue.unshift({
        message,
        variant,
        options: assignedSnackbarOptions,
      });

      toastFromQueue();
    } else {
      //? If queued, wait for current toast to end its execution
      toastQueue.push({
        message,
        variant,
        options: assignedSnackbarOptions,
      });

      if (toastQueue.length === 1) toastFromQueue();
    }
  };
}

/**
 * Opens the specified modal, and sets it as global.
 * @returns The function to open a modal
 */
export function useModal() {
  return useContext(ModalContext);
}
