import App from "App";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "reportWebVitals";
import { register } from "serviceWorkerRegistration";
import { recordPerformance } from "utils/log";
import { setServiceWorkerStatus } from "utils/update";

//! Only the base React structure is initialized here, all initialization settings and third-party modules are initialized in App.tsx

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);

register({
  onSuccess: (registration) => setServiceWorkerStatus?.(registration),
  onUpdate: (registration) => setServiceWorkerStatus?.(registration),
});

reportWebVitals(recordPerformance);
