import { Grid as MuiGrid, GridProps as MuiGridProps } from "@mui/material";
import { ReactNode } from "react";
import { getLargeScreen } from "utils/show";
import Title from "../outputs/Title";
import asSpace from "./asSpace";

export default function asRow(
  components: ReactNode[],
  options?: {
    disableResponsive?: boolean;
    rightShifted?: MuiGridProps["xs"];
  } & { spacing?: number } & Omit<MuiGridProps, "container" | "spacing">
) {
  let props = undefined;

  if (options) {
    const { disableResponsive, rightShifted, ...gridProps } = options;

    props = gridProps;
  }

  const spacing = options?.spacing ?? 2;

  const largeScreen = getLargeScreen();

  return (
    <>
      {options?.title &&
        asSpace(<Title section>{options.title}</Title>, {
          mb: spacing / 2,
        })}
      <MuiGrid
        {...props}
        spacing={spacing}
        alignItems={options?.alignItems ?? (largeScreen ? "center" : undefined)}
        container
        direction={
          options?.disableResponsive || largeScreen
            ? options?.direction ?? "row"
            : largeScreen === false
            ? "column"
            : options?.direction ?? "row"
        }
      >
        {components.map((component, index) => {
          if (component) {
            const isLastElement = index === components.length - 1;

            return (
              <MuiGrid
                key={`${index}-${components.length}`}
                item
                xs={
                  options?.rightShifted
                    ? isLastElement
                      ? options.rightShifted
                      : typeof options.rightShifted === "number"
                      ? Math.round(
                          ((12 - options.rightShifted) /
                            (components.length - 1)) *
                            10
                        ) / 10
                      : !options.rightShifted
                    : !options?.disableResponsive
                }
                textAlign={
                  options?.rightShifted && isLastElement ? "end" : undefined
                }
              >
                {component}
              </MuiGrid>
            );
          }

          return undefined;
        })}
      </MuiGrid>
    </>
  );
}
