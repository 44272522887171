import styled from "@emotion/styled";
import {
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
  Theme,
} from "@mui/material";
import { ReactNode } from "react";
import { getLargeScreen } from "utils/show";
import asCard from "../LAYOUT/asCard";
import asModal from "../LAYOUT/asModal";
import asSpace from "../LAYOUT/asSpace";

const StyledPopper = styled(MuiPopper)(({ theme }) => ({
  zIndex: (theme as Theme).zIndex.modal + 1,
}));

export default function Popper(
  props: {
    attachTo?: MuiPopperProps["anchorEl"];
    onClose?: () => void;
    children?: ReactNode;
    noMargin?: boolean;
    mobileProps?: Parameters<typeof asModal>[1];
  } & Omit<NonNullable<Parameters<typeof asCard>[1]>, "noMargin">
) {
  const { onClose, attachTo, noMargin, children, mobileProps, ...cardProps } =
    props;

  const largeScreen = getLargeScreen();

  const open = !!attachTo;

  return largeScreen ? (
    <StyledPopper open={open} anchorEl={attachTo}>
      <ClickAwayListener onClickAway={onClose ?? (() => {})}>
        {asCard(noMargin ? children : asSpace(children, { m: 2 }), {
          ...cardProps,
          noMargin: true,
        })}
      </ClickAwayListener>
    </StyledPopper>
  ) : open ? (
    asModal(children, { onClose, ...mobileProps })
  ) : null;
}
