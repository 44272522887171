import {
  DateTimePicker as MuiDateTimePicker,
  DateTimePickerProps as MuiDateTimePickerProps,
} from "@mui/lab";
import { DateTime } from "luxon";
import { ComponentProps } from "react";
import { getScheduler } from "utils/query";
import { useLoader } from "utils/render";
import { toDateTime, toDuration } from "utils/time";
import Controller from "./Controller";
import { FormProps } from "./Form";
import Input from "./Input";

type BaseInputValue = DateTime | string | null | undefined;

type BaseOutputValue = DateTime | null | undefined;

export type DateTimeOutputValue = string | undefined;

export type DateTimeInputValue = BaseInputValue | DateTimeOutputValue;

//? This data structure is used only to allow partial completion of the input.
//? The default behavior resets the value if the user closes the modal.
const currentAndBefore = [] as (string | undefined)[];

export default function DateAndTime<F>(
  props: {
    id?: string | number;
    defaultValue?: DateTimeInputValue;
    formatValue?: (value: F) => DateTimeInputValue;
    formatOnChange?: (value: DateTimeOutputValue) => F;
    onChange?: (value: unknown extends F ? DateTimeOutputValue : F) => void;
  } & FormProps &
    Pick<ComponentProps<typeof Input>, "required"> &
    Omit<
      MuiDateTimePickerProps,
      "id" | "label" | "inputFormat" | "renderInput" | "value" | "onChange"
    >
) {
  const {
    form,
    id,
    defaultValue,
    children,
    required,
    disabled,
    formatValue,
    minDate,
    maxDate,
    formatOnChange,
    onChange: propsOnChange,
    minutesStep,
    ...dateTimePickerProps
  } = props;

  const scheduler = useLoader(async () =>
    minutesStep ? undefined : (await getScheduler())?.scheduler
  );

  function transformInput(input: DateTimeInputValue): BaseInputValue {
    return (
      input && (typeof input === "string" ? input : input?.toString() ?? null)
    );
  }

  function transformOutput(output: BaseOutputValue): DateTimeOutputValue {
    const dateTimeObject =
      typeof output === "string" ? toDateTime(output) : output;

    return dateTimeObject?.toISO();
  }

  return (
    <Controller
      form={form}
      id={id}
      defaultValue={transformInput(defaultValue)}
      defaultFallback={null}
      {...dateTimePickerProps}
    >
      {({ onChange, value, ...field }) => (
        <MuiDateTimePicker
          {...dateTimePickerProps}
          disabled={disabled ?? form.disabled}
          renderInput={(inputProps) => (
            <Input form={form} required={required} {...inputProps} />
          )}
          inputFormat="dd/MM/yyyy HH:mm"
          label={children}
          minutesStep={
            minutesStep ??
            (scheduler
              ? toDuration(scheduler.timeInterval).as("minutes")
              : undefined)
          }
          value={transformInput(formatValue ? formatValue(value) : value)}
          onChange={(dateTime) => {
            const transformedOutput = transformOutput(
              dateTime as BaseOutputValue
            );

            const formattedOutput = formatOnChange
              ? formatOnChange(transformedOutput)
              : transformedOutput;

            onChange(formattedOutput);
            propsOnChange?.(formattedOutput as any);

            if (currentAndBefore.push(formattedOutput as any) > 2)
              currentAndBefore.shift();
          }}
          onClose={() => {
            //? If only seconds change, do not do nothing. Else, change value
            if (
              !currentAndBefore[1]?.startsWith(
                currentAndBefore[0]!.slice(0, 14)
              )
            ) {
              onChange(currentAndBefore[0]);
              propsOnChange?.(currentAndBefore[0] as any);
            }
          }}
          minDate={typeof minDate === "string" ? toDateTime(minDate) : minDate}
          maxDate={typeof maxDate === "string" ? toDateTime(maxDate) : minDate}
          {...field}
        />
      )}
    </Controller>
  );
}
