import {
  ModelInit,
  PersistentModelConstructor,
  ProducerModelPredicate,
} from "@aws-amplify/datastore";
import {
  Activity,
  Audit,
  Configuration,
  Identity,
  Notification,
  OutcomeMotivationCodename,
  OutcomeName,
  Payment,
  Task,
  User,
  UserRole,
} from "models";
import schedule, { getCandidateAssignees } from "utils/schedule";
import { Email, getLoggedUser, signUp } from "./authenticate";
import {
  calculateDistance,
  getSurroundingTasks,
  getTravelInfo,
  getTravelInfoParams,
} from "./locate";
import { audit } from "./log";
import {
  addToRole,
  confirmUser,
  disableUser,
  enableUser,
  removeFromRole,
} from "./manage";
import {
  ActivityModel,
  AuditModel,
  ConfigurationModel,
  getActiveTaskChildren,
  getActivities,
  getActivity,
  getRandomUserInRole,
  getTasks,
  getUser,
  getUsers,
  IdentityModel,
  NotificationModel,
  PaymentModel,
  TaskModel,
  UserModel,
} from "./query";
import {
  Entity,
  MotivationEntity,
  storeCreate,
  storeDelete,
  storeDeleteMultiple,
  storeUpdate,
  UpdateEntity,
  UpdateOptions as StoreUpdateOptions,
} from "./store";
import { toDateTime, toDuration, toInterval } from "./time";

type CreateMutation<T extends Entity> = ModelInit<T>;
type CreateOptions<T extends Entity> = {
  condition?: ProducerModelPredicate<T>;
  audit?: Parameters<typeof audit>[4];
};

type UpdateMutation<T extends Entity> =
  | UpdateEntity<T>
  | ((entity: T) => UpdateEntity<T> | void | Promise<UpdateEntity<T> | void>);
type UpdateObject<T extends Entity> = T | T[];
type UpdateOptions<T extends Entity> = {
  motivation?: MotivationEntity<T>;
} & StoreUpdateOptions<T>;
type UpdateReturn<U extends UpdateObject<T>, T extends Entity> = Promise<
  U extends any[] ? (T | undefined)[] : T | undefined
>;

type DeleteObject<T extends Entity> = T | Parameters<T[]["filter"]>[0];
type DeleteReturn<D extends DeleteObject<T>, T extends Entity> = Promise<
  (D extends Function ? T[] : T) | undefined
>;

//! Tasks

async function createTaskChildren(task: TaskModel) {
  const activity = await getActivity(task);

  if (activity?.children?.length) {
    return Promise.all(
      activity.children.map(async ({ childrenId, minDurationBeforeParent }) => {
        const childActivity = await getActivity(childrenId);

        if (childActivity) {
          const parentResponsibilityChange =
            activity.responsibilityChangeDuration
              ? toDuration(activity.responsibilityChangeDuration)
              : undefined;

          return schedule(
            {
              activityTasksId: childrenId,
              identityTasksId: task.identityTasksId,
              taskChildrenId: task.id,
            },
            childActivity.role,
            {
              planning: {
                strategy: "alap",
              },
              time: {
                relative: {
                  minTimeBefore:
                    parentResponsibilityChange
                      ?.plus(toDuration(minDurationBeforeParent))
                      .toISO() ?? minDurationBeforeParent,
                  referenceDateTime: task.startDateTime,
                },
              },
            }
          );
        }
      })
    );
  }
}

async function updateTaskChildren(
  originalTask: TaskModel,
  task?: TaskModel | undefined,
  options?: { noChildren?: boolean }
) {
  if (!task) return;

  let children: TaskModel[] | undefined;

  if (originalTask.activityTasksId !== task.activityTasksId) {
    //? If activity changed, delete children without outcome and create new children
    await deleteTask(
      ({ outcome, taskChildrenId }) =>
        !outcome && taskChildrenId === originalTask.id
    );

    if (!options?.noChildren) await createTaskChildren(task);
  } else if (originalTask.startDateTime !== task.startDateTime) {
    //? If start date time changed, move children without outcome
    if (!children) children = await getActiveTaskChildren(originalTask);

    if (children?.length) {
      const activity = await getActivity(task);

      if (activity) {
        await Promise.all(
          children.map(async (child) => {
            const assignee = (await getUser(["username", child.assignee]))?.id;

            if (!assignee) return;

            return schedule(child, assignee, {
              planning: {
                strategy: "alap",
              },
              time: {
                relative: {
                  minTimeBefore: toDateTime(originalTask.startDateTime)
                    .diff(toDateTime(child.startDateTime))
                    .toISO(),
                  referenceDateTime: task.startDateTime,
                },
              },
            });
          })
        );
      }
    }
  }

  if (!originalTask.outcome && task.outcome) {
    //? If outcome procedure, outcome also children
    if (!children) children = await getActiveTaskChildren(originalTask);

    if (children?.length) {
      await updateTask(
        {
          outcome: OutcomeName.NOTEXECUTED,
          outcomeMotivation: OutcomeMotivationCodename.WRONGPERSONALMANAGEMENT,
        },
        children
      );
    }
  }
}

async function updateSurroundingTasks(
  { firstTaskBefore, firstTaskAfter }: ReturnType<typeof getSurroundingTasks>,
  travelInfoParams: Omit<
    NonNullable<Parameters<typeof getTravelInfo>[1]>,
    "surroundingTasks"
  >,
  insertedTask?: TaskModel
) {
  //? Trigger travel info recalculation
  if (firstTaskBefore) {
    let headquartersTravel =
      firstTaskBefore.assignee === insertedTask?.assignee
        ? travelInfoParams.headquartersTravel
        : undefined;

    if (!headquartersTravel) {
      const assigneeUser = await getUser([
        "username",
        firstTaskBefore.assignee,
      ]);

      if (!assigneeUser) return;

      const preciseHeadquartersTravel = await calculateDistance(
        assigneeUser.headquarters,
        firstTaskBefore.placeInfo,
        travelInfoParams.schedulerData
      );

      if (!preciseHeadquartersTravel) return;

      headquartersTravel = preciseHeadquartersTravel.as("milliseconds")
        ? preciseHeadquartersTravel.toISO()
        : undefined;
    }

    await updateTask(
      await getTravelInfo(firstTaskBefore, {
        ...travelInfoParams,
        surroundingTasks: {
          firstTaskAfter: insertedTask ?? firstTaskAfter,
          firstTaskBefore: null,
        },
        headquartersTravel,
      }),
      firstTaskBefore,
      { doNotCalculateTravel: true }
    );
  }

  //? Trigger travel info recalculation
  if (firstTaskAfter) {
    let headquartersTravel =
      firstTaskAfter.assignee === insertedTask?.assignee
        ? travelInfoParams.headquartersTravel
        : undefined;

    if (!headquartersTravel) {
      const assigneeUser = await getUser(["username", firstTaskAfter.assignee]);

      if (!assigneeUser) return;

      const preciseHeadquartersTravel = await calculateDistance(
        assigneeUser.headquarters,
        firstTaskAfter.placeInfo,
        travelInfoParams.schedulerData
      );

      if (!preciseHeadquartersTravel) return;

      headquartersTravel = preciseHeadquartersTravel.as("milliseconds")
        ? preciseHeadquartersTravel.toISO()
        : undefined;
    }

    await updateTask(
      await getTravelInfo(firstTaskAfter, {
        ...travelInfoParams,
        surroundingTasks: {
          firstTaskBefore: insertedTask ?? firstTaskBefore,
          firstTaskAfter: null,
        },
        headquartersTravel,
      }),
      firstTaskAfter,
      { doNotCalculateTravel: true }
    );
  }
}

export async function createTask(
  mutation: CreateMutation<TaskModel>,
  options?: CreateOptions<TaskModel> & {
    noChildren?: boolean;
    travelInfoParams?: Parameters<typeof getTravelInfoParams>[2];
  }
) {
  const travelInfoParams = await getTravelInfoParams(
    {
      ...mutation,
      interval: toInterval(mutation.startDateTime, mutation.endDateTime),
    },
    undefined,
    options?.travelInfoParams
  );

  const createdTask = await storeCreate<TaskModel>(
    new Task(
      travelInfoParams
        ? {
            ...(await getTravelInfo(mutation, {
              ...travelInfoParams,
              surroundingTasks: travelInfoParams.updatedSurroundingTasks,
              headquartersTravel: travelInfoParams.updatedHeadquartersTravel,
            })),
            ...mutation,
          }
        : mutation
    ),
    options?.condition,
    options
  );

  if (!createdTask) return;

  if (!options?.noChildren) await createTaskChildren(createdTask);

  if (travelInfoParams?.updatedSurroundingTasks) {
    await updateSurroundingTasks(
      travelInfoParams.updatedSurroundingTasks,
      {
        ...travelInfoParams,
        headquartersTravel: travelInfoParams.updatedHeadquartersTravel,
      },
      createdTask
    );
  }

  return createdTask;
}

async function updateSingleTask(
  original: TaskModel,
  updated: TaskModel,
  options: {
    travelInfoParamsMap: Record<
      TaskModel["id"],
      Awaited<ReturnType<typeof getTravelInfoParams>>
    >;
    noChildren?: boolean;
    doNotCalculateTravel?: boolean;
  }
) {
  await updateTaskChildren(original, updated, options);

  if (options.doNotCalculateTravel) return;

  const travelInfoParams = options.travelInfoParamsMap[updated.id];

  if (!travelInfoParams) return;

  if (travelInfoParams.updatedSurroundingTasks) {
    await updateSurroundingTasks(
      travelInfoParams.updatedSurroundingTasks,
      {
        ...travelInfoParams,
        headquartersTravel: travelInfoParams.updatedHeadquartersTravel,
      },
      updated
    );
  }

  if (
    travelInfoParams.originalSurroundingTasks &&
    travelInfoParams.originalSurroundingTasks.firstTaskBefore?.id !==
      travelInfoParams.updatedSurroundingTasks?.firstTaskBefore?.id &&
    travelInfoParams.originalSurroundingTasks.firstTaskAfter?.id !==
      travelInfoParams.updatedSurroundingTasks?.firstTaskAfter?.id
  ) {
    await updateSurroundingTasks(travelInfoParams.originalSurroundingTasks, {
      ...travelInfoParams,
      headquartersTravel: travelInfoParams.originalHeadquartersTravel,
    });
  }
}

export async function updateTask<U extends UpdateObject<TaskModel>>(
  mutation: UpdateMutation<TaskModel>,
  entity: U,
  options?: UpdateOptions<TaskModel> &
    Omit<Parameters<typeof updateSingleTask>[2], "travelInfoParamsMap"> & {
      travelInfoParams?: Parameters<typeof getTravelInfoParams>[2];
    }
) {
  const travelInfoParamsMap: NonNullable<
    Parameters<typeof updateSingleTask>[2]
  >["travelInfoParamsMap"] = {};

  const updatedTasks = await updateEntity(Task, entity, mutation, {
    changeMutation: options?.doNotCalculateTravel
      ? undefined
      : async (original, baseMutation) => {
          const mutated = { ...original, ...baseMutation };

          travelInfoParamsMap[mutated.id] = await getTravelInfoParams(
            {
              ...mutated,
              interval: toInterval(mutated.startDateTime, mutated.endDateTime),
            },
            {
              ...original,
              interval: toInterval(
                original.startDateTime,
                original.endDateTime
              ),
            },
            options?.travelInfoParams
          );

          const travelInfoParams = travelInfoParamsMap[mutated.id];

          return travelInfoParams
            ? {
                ...(await getTravelInfo(mutated, {
                  ...travelInfoParams,
                  surroundingTasks: travelInfoParams.updatedSurroundingTasks,
                  headquartersTravel:
                    travelInfoParams.updatedHeadquartersTravel,
                })),
                ...baseMutation,
              }
            : baseMutation;
        },
    ...options,
  });

  if (!updatedTasks) return;

  const singleTaskOptions = {
    travelInfoParamsMap,
    ...options,
  };

  if (Array.isArray(updatedTasks)) {
    await Promise.all(
      updatedTasks.map(
        async (task, index) =>
          task &&
          updateSingleTask(
            (entity as Extract<typeof entity, any[]>)[index],
            task,
            singleTaskOptions
          )
      )
    );
  } else {
    await updateSingleTask(
      entity as Exclude<typeof entity, any[]>,
      updatedTasks,
      singleTaskOptions
    );
  }

  return updatedTasks;
}

async function deleteSingleTask(
  deleted: TaskModel,
  options?: { travelInfoParams?: Parameters<typeof getTravelInfoParams>[2] }
) {
  const travelInfoParams = await getTravelInfoParams(
    {
      ...deleted,
      interval: toInterval(deleted.startDateTime, deleted.endDateTime),
    },
    undefined,
    options?.travelInfoParams
  );

  if (!travelInfoParams?.updatedSurroundingTasks) return;

  await updateSurroundingTasks(travelInfoParams.updatedSurroundingTasks, {
    ...travelInfoParams,
    headquartersTravel: travelInfoParams.updatedHeadquartersTravel,
  });
}

export async function deleteTask<D extends DeleteObject<TaskModel>>(
  entity: D,
  options?: { travelInfoParams?: Parameters<typeof getTravelInfoParams>[2] }
) {
  const deletedTask = await deleteEntity(Task, entity);

  if (!deletedTask) return;

  const deletedTasks: TaskModel[] = Array.isArray(deletedTask)
    ? deletedTask
    : [deletedTask];

  if (!deletedTasks.length) return deletedTask;

  await deleteTask(
    ({ outcome, taskChildrenId }) =>
      !outcome && deletedTasks.some(({ id }) => id === taskChildrenId)
  );

  await Promise.all(
    deletedTasks.map(async (task) => deleteSingleTask(task, options))
  );

  return deletedTask;
}

//! Identities

export async function createIdentity(
  mutation: CreateMutation<IdentityModel>,
  options?: CreateOptions<IdentityModel>
) {
  return storeCreate<IdentityModel>(
    new Identity(mutation),
    options?.condition,
    options
  );
}

export async function updateIdentity<U extends UpdateObject<IdentityModel>>(
  mutation: UpdateMutation<IdentityModel>,
  entity: U,
  options?: UpdateOptions<IdentityModel>
) {
  return updateEntity(Identity, entity, mutation, options);
}

export async function deleteIdentity<D extends DeleteObject<IdentityModel>>(
  entity: D
) {
  const deletedIdentity = await deleteEntity(Identity, entity);

  if (!deletedIdentity) return;

  const deletedIdentities: IdentityModel[] = Array.isArray(deletedIdentity)
    ? deletedIdentity
    : [deletedIdentity];

  await deleteTask(({ identityTasksId }) =>
    deletedIdentities.some(({ id }) => id === identityTasksId)
  );

  return deletedIdentity;
}

//! Activities

export async function createActivity(
  mutation: CreateMutation<ActivityModel>,
  options?: CreateOptions<ActivityModel>
) {
  return storeCreate<ActivityModel>(
    new Activity(mutation),
    options?.condition,
    options
  );
}

export async function updateActivity<U extends UpdateObject<ActivityModel>>(
  mutation: UpdateMutation<ActivityModel>,
  entity: U,
  options?: UpdateOptions<ActivityModel>
) {
  return updateEntity(Activity, entity, mutation, options);
}

export async function deleteActivity<D extends DeleteObject<ActivityModel>>(
  entity: D
) {
  return deleteEntity(Activity, entity);
}

//! Payments

export async function createPayment(
  mutation: CreateMutation<PaymentModel>,
  options?: CreateOptions<PaymentModel>
) {
  return storeCreate<PaymentModel>(
    new Payment(mutation),
    options?.condition,
    options
  );
}

export async function updatePayment<U extends UpdateObject<PaymentModel>>(
  mutation: UpdateMutation<PaymentModel>,
  entity: U,
  options?: UpdateOptions<PaymentModel>
) {
  return updateEntity(Payment, entity, mutation, options);
}

export async function deletePayment<D extends DeleteObject<PaymentModel>>(
  entity: D
) {
  return deleteEntity(Payment, entity);
}

//! Users

async function updateCognitoRoles(
  originalRoles: UserModel["roles"],
  roles: UserModel["roles"],
  username: UserModel["username"]
) {
  //? Added roles
  await Promise.all(
    (roles as UserRole[])
      .filter((role) => !originalRoles.includes(role))
      .map(async (role) => addToRole(username, role))
  );

  //? Removed roles
  await Promise.all(
    (originalRoles as UserRole[])
      .filter((role) => !roles.includes(role))
      .map(async (role) => removeFromRole(username, role))
  );
}

export async function createUser(
  mutation: CreateMutation<UserModel>,
  options?: CreateOptions<UserModel>
) {
  const existingUser = await getUser(["username", mutation.username], {
    includeDeleted: true,
  });

  let mutatedUser: typeof existingUser | undefined;

  if (existingUser) {
    const wasDeleted = !!existingUser.deletedAt;

    mutatedUser = await updateUser(
      wasDeleted ? { ...mutation, deletedAt: null as any } : mutation,
      existingUser
    );

    if (!mutatedUser) return;

    if (wasDeleted) await enableUser(existingUser.username);
  } else {
    mutatedUser = await storeCreate<UserModel>(
      new User(mutation),
      options?.condition,
      options
    );

    if (!mutatedUser) return;

    await signUp(mutatedUser.username, mutatedUser.email as Email);

    await confirmUser(mutatedUser.username);

    await updateCognitoRoles([], mutatedUser.roles, mutatedUser.username);
  }

  return mutatedUser;
}

export async function updateUser<U extends UpdateObject<UserModel>>(
  mutation: UpdateMutation<UserModel>,
  entity: U,
  options?: UpdateOptions<UserModel>
) {
  const updatedUser = await updateEntity(User, entity, mutation, options);

  if (!updatedUser) return;

  await (Array.isArray(updatedUser)
    ? Promise.all(
        updatedUser.map(
          async (user, index) =>
            user &&
            updateCognitoRoles(
              (entity as Extract<U, UserModel[]>)[index].roles,
              user.roles,
              user.username
            )
        )
      )
    : updateCognitoRoles(
        (entity as Extract<U, UserModel>).roles,
        updatedUser.roles,
        updatedUser.username
      ));

  return updatedUser;
}

async function disableUsers(deletedUsers: UserModel | UserModel[]) {
  const deletedUsernames = Array.isArray(deletedUsers)
    ? deletedUsers.map(({ username }) => username)
    : [deletedUsers.username];

  await Promise.all(deletedUsernames.map(disableUser));

  const tasks = (await getTasks())?.filter(
    ({ assignee, outcome }) => !outcome && deletedUsernames.includes(assignee)
  );

  if (tasks) {
    const taskActivities = (await getActivities()).filter(({ id }) =>
      tasks.some(({ activityTasksId }) => activityTasksId === id)
    );

    const activeUsers = await getUsers();

    const identityCandidates: Partial<
      Record<TaskModel["identityTasksId"], TaskModel["assignee"]>
    > = {};

    await updateTask(async ({ activityTasksId, identityTasksId }) => {
      const role = taskActivities?.find(
        ({ id }) => id === activityTasksId
      )?.role;

      if (!identityCandidates[identityTasksId]) {
        identityCandidates[identityTasksId] =
          (role &&
            (await getRandomUserInRole(
              role as UserRole,
              await getCandidateAssignees(
                role,
                identityTasksId,
                undefined,
                activeUsers
              )
            ))) ||
          getLoggedUser();
      }

      return {
        assignee: identityCandidates[identityTasksId],
      };
    }, tasks);
  }
}

export async function deleteUser<D extends DeleteObject<UserModel>>(entity: D) {
  const deletedUser = await deleteEntity(User, entity);

  if (!deletedUser) return;

  await disableUsers(deletedUser);

  return deletedUser;
}

//! Audits

export async function createAudit(
  mutation: CreateMutation<AuditModel>,
  options?: CreateOptions<AuditModel>
) {
  return storeCreate<AuditModel>(
    new Audit(mutation),
    options?.condition,
    options
  );
}

export async function updateAudit<U extends UpdateObject<AuditModel>>(
  mutation: UpdateMutation<AuditModel>,
  entity: U,
  options?: UpdateOptions<AuditModel>
) {
  return updateEntity(Audit, entity, mutation, options);
}

export async function deleteAudit<D extends DeleteObject<AuditModel>>(
  entity: D
) {
  return deleteEntity(Audit, entity);
}

//! Notifications

export async function createNotification(
  mutation: CreateMutation<NotificationModel>,
  options?: CreateOptions<NotificationModel>
) {
  return storeCreate<NotificationModel>(
    new Notification(mutation),
    options?.condition,
    options
  );
}

export async function updateNotification<
  U extends UpdateObject<NotificationModel>
>(
  mutation: UpdateMutation<NotificationModel>,
  entity: U,
  options?: UpdateOptions<NotificationModel>
) {
  return updateEntity(Notification, entity, mutation, options);
}

export async function deleteNotification<
  D extends DeleteObject<NotificationModel>
>(entity: D) {
  return deleteEntity(Notification, entity);
}

//! Configurations

export async function createConfiguration(
  mutation: CreateMutation<ConfigurationModel>,
  options?: CreateOptions<ConfigurationModel>
) {
  return storeCreate<ConfigurationModel>(
    new Configuration(mutation),
    options?.condition,
    options
  );
}

export async function updateConfiguration<
  U extends UpdateObject<ConfigurationModel>
>(
  mutation: UpdateMutation<ConfigurationModel>,
  entity: U,
  options?: UpdateOptions<ConfigurationModel>
) {
  return updateEntity(Configuration, entity, mutation, options);
}

export async function deleteConfiguration<
  D extends DeleteObject<ConfigurationModel>
>(entity: D) {
  return deleteEntity(Configuration, entity);
}

//? Utils

async function updateEntity<T extends Entity, U extends UpdateObject<T>>(
  model: PersistentModelConstructor<T>,
  entities: U,
  mutation: UpdateMutation<T>,
  options?: UpdateOptions<T>
) {
  const resolveMutation = typeof mutation === "function";

  async function updateSingleEntity(entity: T) {
    const resolvedMutation = resolveMutation
      ? await mutation(entity)
      : mutation;

    if (!resolvedMutation) return entity;

    return storeUpdate(
      model,
      entity,
      resolvedMutation,
      options?.motivation,
      options
    );
  }

  return (
    Array.isArray(entities)
      ? Promise.all(entities.map(updateSingleEntity))
      : updateSingleEntity(entities as T)
  ) as UpdateReturn<U, T>;
}

async function deleteEntity<T extends Entity, D extends DeleteObject<T>>(
  model: PersistentModelConstructor<T>,
  entity: D
) {
  return (
    typeof entity === "function"
      ? storeDeleteMultiple(model, entity)
      : storeDelete(model, entity as T)
  ) as DeleteReturn<D, T>;
}
