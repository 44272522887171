import {
  DataStore,
  MutableModel,
  PersistentModelConstructor,
  Predicates,
  ProducerModelPredicate,
  syncExpression,
} from "@aws-amplify/datastore";
import { diff } from "deep-object-diff";
import { getModelName } from "helpers/display";
import { Permissions } from "helpers/rbac";
import { Audit, ModelType, MutationAction } from "models";
import { canUser } from "./authorize";
import { deepRemoveUndefined } from "./format";
import { audit, log } from "./log";
import { notify } from "./notify";
import { join } from "./query";
import { getNow } from "./time";

/**
 * Represents an instance of a datastore/local store model.
 */
export type Entity = Readonly<{ id: string } & Record<string, any>>;

/**
 * Represents the type of a mutation entity.
 */
export type UpdateEntity<T extends Record<string, any>> = {
  [K in keyof MutableModel<T>]?: MutableModel<T>[K];
};

/**
 * Represents the type of a motivation entity, used to motivate an update in its audit.
 */
export type MotivationEntity<T extends Entity> =
  | Partial<Record<keyof MutableModel<T>, string>>
  | string;

export type UpdateOptions<T extends Entity> = {
  audit?: Parameters<typeof audit>[4];
  onAuditCreated?: (audit: Audit) => void;
  changeMutation?: (
    original: T,
    mutation: UpdateEntity<T>
  ) => UpdateEntity<T> | Promise<UpdateEntity<T>>;
};

/**
 * Configures the local store to sync only used entities from backend.
 */
export function storeConfigure() {
  const syncExpressions: NonNullable<
    NonNullable<Parameters<typeof DataStore["configure"]>[0]>["syncExpressions"]
  > = [];

  if (!canUser(Permissions.HandleDatabase)) {
    syncExpressions.push(
      syncExpression(Audit, () =>
        join([ModelType.TASK, ModelType.IDENTITY], "modelType")
      )
    );
  }

  DataStore.configure({
    syncExpressions,
    maxRecordsToSync: 15000,
  });
}

/**
 * Caches the entities to improve performance.
 */
let entityCache: Partial<
  Record<
    string,
    {
      alive: Map<Entity["id"], Entity>;
      deleted: Map<Entity["id"], Entity>;
      subscriptions: { id: any; callback: () => void }[];
    }
  >
> = {};

/**
 * Initializes a model cache to improve performance.
 * @param model The model to initialize
 * @param modelName The model name to optimize performance
 * @returns The promise to await for initialization
 */
async function initializeCache<T extends Entity>(
  model: PersistentModelConstructor<T>,
  modelName?: string
) {
  const processedModelName = modelName ?? getModelName(model);

  entityCache[processedModelName] = {
    alive: new Map(),
    deleted: new Map(),
    subscriptions: [],
  };

  return new Promise<void>((resolve) => {
    DataStore.observeQuery(model).subscribe(({ items, isSynced }) => {
      entityCache[processedModelName]!.alive.clear();
      entityCache[processedModelName]!.deleted.clear();

      items.forEach((item) =>
        entityCache[processedModelName]![
          item.deletedAt ? "deleted" : "alive"
        ].set(item.id, item)
      );

      entityCache[processedModelName]!.subscriptions.forEach(({ callback }) =>
        callback()
      );

      if (isSynced) resolve();
    });
  });
}

/**
 * Creates an entity in the local store.
 * @param entity The entity to create, instantiated through the model constructor
 * @param condition The condition to apply to the creation of the model
 * @param options The options of the store creation operation
 * @returns The created entity
 */
export async function storeCreate<T extends Entity>(
  entity: T,
  condition?: ProducerModelPredicate<T> | undefined,
  options?: {
    audit?: Parameters<typeof audit>[4];
  }
) {
  let created: T | undefined;

  try {
    created = await DataStore.save(entity, condition);
  } catch (error) {
    log(error);
  }

  if (created) {
    audit(
      MutationAction.CREATE,
      created,
      undefined,
      undefined,
      options?.audit
    ).then((createdAudit) => {
      if (createdAudit) notify(created!, createdAudit);
    });
  }

  return created;
}

/**
 * Updates an entity in the local store.
 * @param model The model of the entity
 * @param entity The entity to update
 * @param update The update mutation object
 * @param motivation The motivation for the update
 * @param options The options of the update operation
 * @returns The updated entity
 */
export async function storeUpdate<T extends Entity>(
  model: PersistentModelConstructor<T>,
  entity: T,
  update: UpdateEntity<T>,
  motivation?: MotivationEntity<T>,
  options?: UpdateOptions<T>
) {
  let mutation = cleanMutation(entity, update);

  let updated: T | undefined;

  if (options?.changeMutation)
    mutation = cleanMutation(
      entity,
      await options.changeMutation(entity, mutation)
    );

  //? Nothing to mutate
  if (!Object.keys(mutation).length) return entity;

  const modelName = getModelName(model);

  if (!entityCache[modelName]) await initializeCache(model, modelName);

  let cachedEntity = entityCache[modelName]!.deleted.get(entity.id) as
    | T
    | undefined;

  if (cachedEntity) {
    //? Is deleted
    const newEntity = {
      ...cachedEntity,
      ...(mutation as any),
    };

    entityCache[modelName]!.deleted.set(entity.id, newEntity);

    updated = newEntity;
  } else {
    cachedEntity = entityCache[modelName]!.alive.get(entity.id) as
      | T
      | undefined;

    const newEntity = {
      ...cachedEntity,
      ...(mutation as any),
    };

    entityCache[modelName]!.alive.set(entity.id, newEntity);

    updated = newEntity;
  }

  if (!cachedEntity) return;

  entityCache[modelName]!.subscriptions.forEach(({ callback }) => callback());

  DataStore.save(
    model.copyOf(cachedEntity, (mutable) => {
      for (const key in mutation)
        (mutable as Record<any, any>)[key] = mutation[key];
    })
  ).catch(log);

  if (updated) {
    audit(
      MutationAction.UPDATE,
      updated,
      motivation,
      mutation,
      options?.audit
    ).then((createdAudit) => {
      if (createdAudit) {
        options?.onAuditCreated?.(createdAudit);
        notify(updated!, createdAudit);
      }
    });
  }

  return updated;
}

/**
 * Deletes an entity in the local store.
 * @param model The model of the entity
 * @param entity The entity to delete
 * @returns The deleted entity
 */
export async function storeDelete<T extends Entity>(
  model: PersistentModelConstructor<T>,
  entity: T
) {
  const deleted = await deleteObject(model, entity);

  if (deleted) {
    audit(MutationAction.DELETE, deleted).then((createdAudit) => {
      if (createdAudit) notify(deleted, createdAudit);
    });
  }

  return deleted;
}

/**
 * Deletes multiple entities in the local store that satisfy a condition.
 * @param model The model of the entity
 * @param condition The condition to apply to the entities for deletion
 * @returns The deleted entities
 */
export async function storeDeleteMultiple<T extends Entity>(
  model: PersistentModelConstructor<T>,
  condition?: Parameters<T[]["filter"]>[0]
) {
  const objects = await storeQuery(model);

  const filteredObjects = condition ? objects.filter(condition) : objects;

  return (
    await Promise.all(
      filteredObjects.map(async (object) => storeDelete(model, object))
    )
  ).filter((deleted) => !!deleted) as T[];
}

/**
 * Retrieves a single entity from the local store.
 * @param model The model of the entity
 * @param id The entity ID
 * @param includeDeleted True if deleted entities should be queried as well
 * @returns The requested entity
 */
export async function storeGet<T extends Entity>(
  model: PersistentModelConstructor<T>,
  id: Entity["id"],
  includeDeleted?: boolean
) {
  const modelName = getModelName(model);

  if (!entityCache[modelName]) await initializeCache(model, modelName);

  const deletedEntity = includeDeleted
    ? (entityCache[modelName]!.deleted.get(id) as T)
    : undefined;

  if (deletedEntity) return deletedEntity;

  return entityCache[modelName]!.alive.get(id) as T | undefined;
}

let subscriptionCount = 0;

/**
 * Queries the local store and observes changes based on the provided query.
 * @param model The model of the entity
 * @param onResult Callback that gets executed every time items are received
 * @param includeDeleted True if deleted entities should be queried as well
 * @returns The cancelable subscription for the query
 */
export function storeList<T extends Entity>(
  model: PersistentModelConstructor<T>,
  onResult: (entities: T[]) => void,
  includeDeleted?: boolean
) {
  const modelName = getModelName(model);

  if (!entityCache[modelName]) initializeCache(model, modelName);

  const id = subscriptionCount++;

  entityCache[modelName]!.subscriptions.push({
    id,
    callback: () => {
      onResult([
        ...(includeDeleted
          ? Array.from(entityCache[modelName]!.deleted.values())
          : []),
        ...Array.from(entityCache[modelName]!.alive.values()),
      ] as T[]);
    },
  });

  onResult([
    ...(includeDeleted
      ? Array.from(entityCache[modelName]!.deleted.values())
      : []),
    ...Array.from(entityCache[modelName]!.alive.values()),
  ] as T[]);

  return {
    unsubscribe: () =>
      entityCache[modelName]!.subscriptions.splice(
        entityCache[modelName]!.subscriptions.findIndex(
          ({ id: unsubscribeId }) => unsubscribeId === id
        ),
        1
      ),
  };
}

/**
 * Queries entities of the local store.
 * @param model The model of the entities
 * @param includeDeleted True if deleted entities should be queried as well
 * @returns The queried entities
 */
export async function storeQuery<T extends Entity>(
  model: PersistentModelConstructor<T>,
  includeDeleted?: boolean
) {
  const modelName = getModelName(model);

  if (!entityCache[modelName]) await initializeCache(model, modelName);

  return [
    ...(includeDeleted
      ? Array.from(entityCache[modelName]!.deleted.values())
      : []),
    ...Array.from(entityCache[modelName]!.alive.values()),
  ] as T[];
}

/**
 * Observes a model filtered through a condition.
 * @param model The model to observe
 * @param condition The condition to apply as a filter
 * @returns The cancelable subscription for the query
 */
export function storeObserve<T extends Entity>(
  model: PersistentModelConstructor<T>,
  condition?: string | ProducerModelPredicate<T>
) {
  return DataStore.observe(model, condition);
}

/**
 * Completely wipes out the local store. This process could deleted not synced data.
 */
export async function storeClear() {
  entityCache = {};

  try {
    return await DataStore.clear();
  } catch (error) {
    log(error);
  }
}

/**
 * USE WITH EXTREME CAUTION. Permanently deletes all instances of a model.
 * @param model The model to permanently delete
 * @returns The deleted entities
 */
export async function storePermanentlyDeleteType<T extends Entity>(
  model: PersistentModelConstructor<T>
) {
  try {
    return await DataStore.delete(model, Predicates.ALL);
  } catch (error) {
    log(error);
  }
}

/**
 * Deletes an entity from the local store.
 * @param model The model of the entity
 * @param entity The entity to delete
 * @returns The deleted entity
 */
async function deleteObject<T extends Entity>(
  model: PersistentModelConstructor<T>,
  entity: T
) {
  if (entity.hasOwnProperty("deletedAt")) {
    try {
      return await DataStore.save(
        model.copyOf(entity, (mutable) => {
          (mutable as { deletedAt?: string }).deletedAt = getNow().toISO();
        })
      );
    } catch (error) {
      log(error);
    }
  } else {
    try {
      return await DataStore.delete(entity);
    } catch (error) {
      log(error);
    }
  }
}

function cleanMutation<T extends Entity>(original: T, update: UpdateEntity<T>) {
  const mutation = {} as UpdateEntity<T>;

  for (const key in update) {
    const value = update[key];
    const originalValue = original[key];

    if (
      value !== undefined &&
      (((value === null || typeof value !== "object") &&
        value !== originalValue) ||
        Object.keys(deepRemoveUndefined(diff(originalValue, value))).length)
    ) {
      (mutation as Record<any, any>)[key] = value;
    }
  }

  return mutation;
}
