import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/lab";
import { DateTime } from "luxon";
import { ComponentProps } from "react";
import Controller from "./Controller";
import { FormProps } from "./Form";
import Input from "./Input";

export default function Date(
  props: {
    id?: string | number;
    defaultValue?: DateTime | string | null;
    onChange?: (value: DateTime) => void;
  } & FormProps &
    Pick<ComponentProps<typeof Input>, "required"> &
    Omit<
      MuiDatePickerProps,
      "id" | "label" | "inputFormat" | "renderInput" | "value" | "onChange"
    >
) {
  const {
    form,
    id,
    defaultValue,
    children,
    required,
    disabled,
    onChange: propsOnChange,
    ...datePickerProps
  } = props;

  function formatDate(dateTime: DateTime) {
    return dateTime ? dateTime.toISODate() : null;
  }

  return (
    <Controller
      form={form}
      id={id}
      defaultValue={
        defaultValue &&
        (typeof defaultValue === "string"
          ? defaultValue
          : formatDate(defaultValue))
      }
      defaultFallback={null}
      {...datePickerProps}
    >
      {({ onChange, ...field }) => (
        <MuiDatePicker
          {...datePickerProps}
          disabled={disabled ?? form.disabled}
          renderInput={(inputProps) => (
            <Input form={form} required={required} {...inputProps} />
          )}
          inputFormat="dd/MM/yyyy"
          label={children}
          onChange={(dateTime) => {
            onChange(formatDate(dateTime));
            propsOnChange?.(dateTime);
          }}
          {...field}
        />
      )}
    </Controller>
  );
}
