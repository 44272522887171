import define from "helpers/define";
import { Permissions } from "helpers/rbac";
import {
  Activity,
  ActivityChild,
  Identity,
  LocationName,
  ModalityName,
  OutcomeMotivationCodename,
  OutcomeName,
  Review,
  Task,
  User,
} from "models";
import { ComponentProps } from "react";
import { canUser } from "utils/authorize";
import { displayPerson } from "utils/format";
import { useTranslation } from "utils/localize";
import { getActivities, getIdentities, getUsers } from "utils/query";
import Choice from "view/inputs/Choice";
import TimeDuration from "view/inputs/TimeDuration";

export default define<Omit<Task, "assigner" | "moved">>(() => {
  const { t } = useTranslation("tasks");

  return {
    startDateTime: {
      view: "datetime",
      label: t("tasks:startDateTime"),
    },
    endDateTime: { view: "datetime", label: t("tasks:endDateTime") },
    travelDuration: {
      view: "duration",
      label: t("tasks:travelDuration"),
      props: { unit: "minutes" } as ComponentProps<typeof TimeDuration>,
    },
    returnDuration: {
      view: "duration",
      label: t("tasks:returnDuration"),
      props: { unit: "minutes" } as ComponentProps<typeof TimeDuration>,
    },
    assignee: {
      view: "choice",
      label: t("tasks:assignee"),
      props: {
        options: async () =>
          (await getUsers({
            includeDeleted: canUser(Permissions.HandleDatabase),
          })) as unknown,
        getLabel: (user) => displayPerson(user as User),
        identifier: "username" as keyof User as any,
      } as ComponentProps<typeof Choice>,
    },
    identityTasksId: {
      view: "choice",
      label: t("tasks:identity"),
      props: {
        options: async () => (await getIdentities()) as unknown,
        getLabel: (identity) => displayPerson(identity as Identity),
      } as ComponentProps<typeof Choice>,
    },
    activityTasksId: {
      view: "choice",
      label: t("tasks:activity"),
      props: {
        options: async () => (await getActivities()) as unknown,
        change: canUser(Permissions.HandleDatabase)
          ? undefined
          : (activities: Activity[]) => {
              const activityChildren = [] as ActivityChild["childrenId"][];

              activities.forEach(
                ({ children }) =>
                  children?.length &&
                  activityChildren.push(
                    ...children.map(({ childrenId }) => childrenId)
                  )
              );

              return activities.filter(
                ({ id }) => !activityChildren.includes(id)
              );
            },
        getLabel: (activity: Activity) => activity?.name,
      } as ComponentProps<typeof Choice>,
    },
    modality: {
      view: "choice",
      label: t("tasks:modality"),
      props: {
        options: ModalityName,
      } as ComponentProps<typeof Choice>,
    },
    location: {
      view: "choice",
      label: t("tasks:location"),
      props: {
        options: LocationName,
      } as ComponentProps<typeof Choice>,
    },
    place: {
      view: "exclude",
    },
    placeInfo: {
      view: "place",
      label: t("tasks:place"),
    },
    outcome: {
      view: "choice",
      label: t("tasks:outcome"),
      props: {
        options: OutcomeName,
      } as ComponentProps<typeof Choice>,
    },
    outcomeMotivation: {
      view: "choice",
      label: t("tasks:outcomeMotivation"),
      props: {
        options: OutcomeMotivationCodename,
      } as ComponentProps<typeof Choice>,
    },
    review: {
      view: "choice",
      label: t("tasks:review"),
      props: {
        options: Review,
      } as ComponentProps<typeof Choice>,
    },
    suggestedOutcomeMotivation: {
      view: "free",
      label: t("tasks:suggestedOutcomeMotivation"),
    },
    taskChildrenId: {
      view: "exclude",
    },
    children: {
      view: "exclude",
    },
    audits: {
      view: "exclude",
    },
  };
});
