import { namespaces } from "constants/localization";
import { ComponentProps, ReactNode, useState } from "react";
import { getNamespaceForEnumOptions, useTranslation } from "utils/localize";
import { useAsync } from "utils/render";
import { getLargeScreen } from "utils/show";
import { Entity } from "utils/store";
import { PercentageUnit } from "view/inputs/Percentage";
import Choice, { EnumOptions } from "../inputs/Choice";
import { MoneyUnit } from "../inputs/Money";
import TimeDuration from "../inputs/TimeDuration";
import asSpace from "../LAYOUT/asSpace";
import Text from "./Text";

export default function Title(props: {
  children?: ReactNode;
  noMargin?: boolean;
  noTitle?: boolean;
  color?: ComponentProps<typeof Text>["color"];
  subtitle?: ReactNode | string[] | (() => Promise<ReactNode>);
  unit?:
    | ComponentProps<typeof TimeDuration>["unit"]
    | MoneyUnit
    | PercentageUnit;
  resolver?:
    | typeof namespaces[number]
    | ComponentProps<typeof Choice>["options"];
  identifier?: string;
  getLabel?: (value: any) => ReactNode;
  details?: ReactNode;
  section?: boolean;
  field?: boolean;
  plain?: boolean;
  small?: boolean;
  chart?: boolean;
  typographyProps?: {
    title?: ComponentProps<typeof Text>;
    subtitle?: ComponentProps<typeof Text>;
    details?: ComponentProps<typeof Text>;
  };
}) {
  const {
    children,
    color,
    subtitle,
    resolver,
    identifier,
    getLabel,
    details,
    unit,
    section,
    field,
    plain,
    small,
    chart,
    noMargin,
    noTitle,
    typographyProps,
  } = props;

  const namespace =
    resolver &&
    (typeof resolver === "string"
      ? resolver
      : typeof resolver === "function"
      ? undefined
      : getNamespaceForEnumOptions(resolver as EnumOptions));

  const {
    t,
    i18n: { language },
  } = useTranslation(namespace);

  function resolveWithNamespace(valueToResolve: ReactNode) {
    if (valueToResolve && namespace) {
      const translationKey: `${NonNullable<
        typeof namespace
      >}:${string}` = `${namespace}:${valueToResolve}`;
      const resolvedValue = t(translationKey);

      if (resolvedValue !== translationKey) return resolvedValue;
    }

    return valueToResolve;
  }

  const mustResolveSubtitle = typeof subtitle === "function";

  const [values, setValues] = useState<ReactNode[] | undefined>(
    !mustResolveSubtitle && namespace
      ? Array.isArray(subtitle)
        ? subtitle.map((value) =>
            typeof value === "string" ? resolveWithNamespace(value) : value
          )
        : [resolveWithNamespace(subtitle)]
      : undefined
  );

  useAsync(
    {
      condition: !values && !!(mustResolveSubtitle || resolver),
      action: (mustResolveSubtitle ? subtitle : resolver) as () => Promise<
        ReactNode | Entity[]
      >,
    },
    (loadedValues) => {
      if (Array.isArray(loadedValues)) {
        let processedSubtitle = subtitle;

        if (!Array.isArray(subtitle)) processedSubtitle = [subtitle as string];

        setValues(
          (processedSubtitle as string[]).map((value) =>
            getLabel?.(
              loadedValues.find(
                (searchValue) => searchValue[identifier ?? "id"] === value
              )
            )
          )
        );
      } else {
        setValues([loadedValues]);
      }
    }
  );

  const largeScreen = getLargeScreen();

  const margin = 3;

  function processNumber(value: unknown) {
    return typeof value === "number" ? value.toLocaleString(language) : value;
  }

  const isResolvedSubtitle = subtitle && (!mustResolveSubtitle || values);

  const renderedTitle = (
    <>
      {children && !noTitle && (
        <Text
          type={
            section
              ? "section"
              : field
              ? "fieldTitle"
              : plain
              ? "common"
              : subtitle
              ? "description"
              : chart
              ? "chartTitle"
              : "title"
          }
          color={color}
          style={
            small
              ? {
                  fontSize: 8,
                }
              : undefined
          }
          {...typographyProps?.title}
        >
          {children}
        </Text>
      )}
      {(isResolvedSubtitle || field) && (
        <Text
          type={
            section
              ? "sectionSubtitle"
              : field
              ? "fieldBody"
              : plain
              ? "description"
              : "title"
          }
          style={
            small
              ? {
                  fontSize: 12,
                }
              : undefined
          }
          {...typographyProps?.subtitle}
        >
          {field && !subtitle ? undefined : (
            <>
              {values?.map(processNumber)?.join(", ") ??
                (isResolvedSubtitle && processNumber(subtitle))}
              {unit && ` ${t(`view:${unit}Unit`)}`}
            </>
          )}
        </Text>
      )}
      {details && (
        <Text
          type="subtitle"
          style={
            small
              ? {
                  fontSize: 12,
                }
              : undefined
          }
          {...typographyProps?.details}
        >
          {details}
        </Text>
      )}
    </>
  );

  return children || isResolvedSubtitle || values
    ? noMargin
      ? renderedTitle
      : asSpace(renderedTitle, {
          mb: margin - (largeScreen ? 0 : 1),
          mt: largeScreen ? -1 : undefined,
        })
    : null;
}
