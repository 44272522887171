import { FormProps } from "view/inputs/Form";
import Input, { FormElementProps } from "view/inputs/Input";
import Controller from "./Controller";

export default function Free(props: FormProps & FormElementProps) {
  const { defaultValue, ...inputProps } = props;

  return (
    <Controller defaultValue={defaultValue} {...inputProps}>
      {({ innerRef, value, ...field }) => (
        <Input
          inputRef={innerRef}
          value={value ?? ""}
          {...inputProps}
          {...field}
        />
      )}
    </Controller>
  );
}
