import { ReactNode, useState } from "react";
import asGrid from "../LAYOUT/asGrid";

export default function MainAndOptional(props: {
  main?: number;
  showOptionalButtonText?: ReactNode;
  inNewLine?: number[];
  components?: ReactNode[];
}) {
  const { main, components, inNewLine } = props;

  const [showOptional] = useState(false);

  const sections = [] as Parameters<typeof asGrid>[0];

  if (components && main !== undefined) {
    const mainComponent = components[main];

    sections.push({
      section: [mainComponent],
      options: {
        fullWidth: true,
      },
    });

    if (showOptional) {
      const optionalComponents = [...components];

      optionalComponents.splice(main, 1);

      if (inNewLine) {
        let splitter = 0;

        inNewLine.forEach((value) => {
          sections.push({
            section: optionalComponents.slice(splitter, value),
            options: {
              fullWidth: true,
            },
          });

          splitter = value;
        });

        sections.push({
          section: optionalComponents.slice(splitter),
          options: {
            fullWidth: true,
          },
        });
      } else {
        sections.push({
          section: optionalComponents,
        });
      }
    } else {
      // sections.push({
      //   section: [
      //     <Button
      //       asBack
      //       onClick={(complete) => {
      //         complete();
      //         setShowOptional(true);
      //       }}
      //     >
      //       {showOptionalButtonText}
      //     </Button>,
      //   ],
      //   options: {
      //     fullWidth: true,
      //   },
      // });
    }
  }

  return asGrid(sections, {
    maxColumns: 2,
  });
}
