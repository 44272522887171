import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "utils/localize";
import { useAsync } from "utils/render";
import { useToast } from "utils/show";
import Progress from "../symbols/Progress";

export type Complete = (
  ...args: Partial<Parameters<ReturnType<typeof useToast>>>
) => void;

type CompleteCallback = (complete: Complete) => void;

export function useClickable(simulateClick?: CompleteCallback) {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [clickFunction, setClickFunction] = useState<
    typeof simulateClick | null
  >(() => simulateClick);

  useAsync({
    condition: clickFunction !== null && !!(clickFunction || simulateClick),
    action: (isMounted) => {
      if (!loading) {
        setLoading(true);
      } else {
        (clickFunction ? clickFunction : simulateClick!)((...toastParams) => {
          if (isMounted()) {
            if (clickFunction) setClickFunction(null);
            setLoading(false);
          }

          if (toastParams[0]) toast(...toastParams);
        });
      }
    },
  });

  return [loading, setClickFunction] as [
    loading: typeof loading,
    setClickFunction: typeof setClickFunction
  ];
}

export default function Button(
  props: {
    back?: boolean;
    link?: boolean;
    delete?: boolean;
    upload?: boolean;
    create?: boolean;
    reset?: boolean;
    lowEmphasisUpdate?: boolean;
    lowEmphasisDelete?: boolean;
    onClick?: CompleteCallback;
    simulateClick?: CompleteCallback;
  } & Omit<MuiButtonProps, "variant" | "color" | "onClick" | "form">
) {
  const {
    type,
    back,
    delete: asDelete,
    create,
    reset,
    lowEmphasisUpdate,
    lowEmphasisDelete,
    link,
    upload,
    children,
    disabled,
    onClick,
    simulateClick,
    ...buttonProps
  } = props;

  const { t } = useTranslation("view");

  const [loading, setClickFunction] = useClickable(simulateClick);

  return (
    <MuiButton
      disableElevation
      variant={
        back || lowEmphasisDelete || lowEmphasisUpdate || upload
          ? undefined
          : link
          ? "outlined"
          : "contained"
      }
      color={
        asDelete || lowEmphasisDelete
          ? "secondary"
          : link
          ? undefined
          : "primary"
      }
      type={type ?? "button"}
      disabled={disabled ?? loading}
      onClick={onClick && (() => setClickFunction(() => onClick))}
      {...buttonProps}
    >
      <Progress
        key={loading.toString()}
        spinner
        disabled={!loading}
        size={24.5}
      >
        {children ??
          (back
            ? t("view:back")
            : asDelete
            ? t("view:delete")
            : link
            ? t("view:go")
            : upload
            ? t("view:upload")
            : create
            ? t("view:create")
            : reset
            ? t("view:reset")
            : t("view:ok"))}
      </Progress>
    </MuiButton>
  );
}
