//? Roles can not be parsable numbers

import { UserRole } from "models";

//! Must be equivalent to UserRole db enum, but TitleCase
export enum CognitoRoles {
  ADMIN = "Admin",
  MANAGEMENT = "Management",
  CREDITBROKER = "CreditBroker",
  CALLCENTER = "CallCenter",
  HOUSEHUNTER = "HouseHunter",
  ACCOUNTING = "Accounting",
  HUMANRESOURCES = "HumanResources",
  AGREEMENTS = "Agreements",
  REALESTATE = "RealEstate",
  BANKREALESTATE = "BankRealEstate",
  MARKETING = "Marketing",
  COMMUNICATION = "Communication",
  CONTRACTSANDPOLICIES = "ContractsAndPolicies",
  AUDIT = "Audit",
  APPRAISAL = "Appraisal",
  FILE = "File",
  CREDIT = "Credit",
  INCOME = "Income",
  BUSINESSINTELLIGENCE = "BusinessIntelligence",
  LEGAL = "Legal",
  FUND = "Fund",
  ARCHIVE = "Archive",
  CUSTOMERSERVICE = "CustomerService",
  PHONESELLER = "PhoneSeller",
  DOCUMENTREQUESTER = "DocumentRequester",
}

export enum Permissions {
  HandleTasks,
  HandleMeetings,
  HandleIdentities,
  HandleUsers,
  HandleProcess,
  HandleDatabase,
  HandleOwnIdentities,
  HandleSuggestions,
  ViewTasks,
  ViewCollaboratorTasks,
  ViewIdentities,
  ViewProcess,
  ImportLeadFile,
  HasFocusMode,
  ValidateMeetings,
  PayMeetings,
  CheckPartners,
}

const rbac: Record<CognitoRoles, Permissions[]> = {
  [CognitoRoles.ADMIN]: [
    Permissions.HandleDatabase,
    Permissions.HandleIdentities,
    Permissions.HandleProcess,
    Permissions.HandleTasks,
    Permissions.HandleUsers,
    Permissions.HandleSuggestions,
    Permissions.CheckPartners,
    Permissions.ImportLeadFile,
    Permissions.ValidateMeetings,
  ],
  [CognitoRoles.MANAGEMENT]: [
    Permissions.HandleIdentities,
    Permissions.HandleProcess,
    Permissions.HandleTasks,
    Permissions.HandleUsers,
    Permissions.HandleSuggestions,
    Permissions.CheckPartners,
    Permissions.ValidateMeetings,
  ],
  [CognitoRoles.CREDITBROKER]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.CALLCENTER]: [
    Permissions.HandleMeetings,
    Permissions.ViewProcess,
  ],
  [CognitoRoles.HOUSEHUNTER]: [],
  [CognitoRoles.ACCOUNTING]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.PayMeetings,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.HUMANRESOURCES]: [
    Permissions.HandleUsers,
    Permissions.ViewProcess,
  ],
  [CognitoRoles.AGREEMENTS]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.REALESTATE]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.BANKREALESTATE]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.MARKETING]: [
    Permissions.HandleSuggestions,
    Permissions.HandleIdentities,
    Permissions.ViewProcess,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.COMMUNICATION]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.CONTRACTSANDPOLICIES]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.AUDIT]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.APPRAISAL]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.FILE]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.CREDIT]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.INCOME]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.BUSINESSINTELLIGENCE]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.LEGAL]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.FUND]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.ARCHIVE]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.CUSTOMERSERVICE]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.PHONESELLER]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
    Permissions.HasFocusMode,
  ],
  [CognitoRoles.DOCUMENTREQUESTER]: [
    Permissions.ViewIdentities,
    Permissions.ViewProcess,
    Permissions.ViewCollaboratorTasks,
  ],
};

export default rbac;

export const canViewTasks: Partial<Record<UserRole, UserRole[]>> = {
  [UserRole.CALLCENTER]: [UserRole.CREDITBROKER],
  [UserRole.CREDITBROKER]: [
    UserRole.FILE,
    UserRole.AGREEMENTS,
    UserRole.APPRAISAL,
    UserRole.CONTRACTSANDPOLICIES,
    UserRole.AUDIT,
  ],
  [UserRole.FILE]: [
    UserRole.AGREEMENTS,
    UserRole.APPRAISAL,
    UserRole.CONTRACTSANDPOLICIES,
    UserRole.AUDIT,
    UserRole.CREDITBROKER,
  ],
  [UserRole.AGREEMENTS]: [
    UserRole.FILE,
    UserRole.APPRAISAL,
    UserRole.CONTRACTSANDPOLICIES,
    UserRole.AUDIT,
    UserRole.CREDITBROKER,
  ],
  [UserRole.APPRAISAL]: [
    UserRole.FILE,
    UserRole.AGREEMENTS,
    UserRole.CONTRACTSANDPOLICIES,
    UserRole.AUDIT,
    UserRole.CREDITBROKER,
  ],
  [UserRole.CONTRACTSANDPOLICIES]: [
    UserRole.FILE,
    UserRole.AGREEMENTS,
    UserRole.APPRAISAL,
    UserRole.AUDIT,
    UserRole.CREDITBROKER,
  ],
  [UserRole.AUDIT]: [
    UserRole.FILE,
    UserRole.AGREEMENTS,
    UserRole.APPRAISAL,
    UserRole.CONTRACTSANDPOLICIES,
    UserRole.CREDITBROKER,
  ],
  [UserRole.ACCOUNTING]: [UserRole.CREDITBROKER, UserRole.LEGAL],
  [UserRole.MARKETING]: [UserRole.CALLCENTER],
  [UserRole.BANKREALESTATE]: [
    UserRole.CREDITBROKER,
    UserRole.HOUSEHUNTER,
    UserRole.REALESTATE,
    UserRole.AGREEMENTS,
  ],
};
