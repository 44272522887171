import {
  Controller as FormController,
  ControllerProps as FormControllerProps,
  ControllerRenderProps as FormControllerRenderProps,
} from "react-hook-form";
import { FormProps } from "view/inputs/Form";

export default function Controller(
  props: {
    children: (
      field: { innerRef: FormControllerRenderProps["ref"] } & Omit<
        FormControllerRenderProps,
        "ref"
      >
    ) => ReturnType<FormControllerProps["render"]>;
    id?: string | number;
    defaultFallback?: unknown;
  } & FormProps &
    Omit<FormControllerProps, "name" | "render" | "control">
) {
  return (
    <FormController
      control={props.form.control}
      name={props.id?.toString() ?? " "}
      defaultValue={
        props.defaultValue === undefined
          ? props.defaultFallback === undefined
            ? ""
            : props.defaultFallback
          : props.defaultValue
      }
      rules={props.rules}
      render={({ field: { ref, ...field } }) =>
        props.children({
          innerRef: ref,
          ...field,
        })
      }
    />
  );
}
