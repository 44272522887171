import { darken as darkenColor } from "color2k";
import { OutcomeName } from "models";
import { TaskModel } from "./query";

/**
 * Combines multiple enum values into a single enum value. Single enum values can be checked.
 * @param enumValues Enum values to combine
 */
export function combineEnumValues(enumValues: number[]) {
  // Uses bitwise OR to create compound/multiple enum values
  return enumValues.length
    ? enumValues.reduce(
        (combinedEnumValue, enumValue) => enumValue | combinedEnumValue
      )
    : NaN;
}

/**
 * Parses an integer string as an integer.
 * @param number The number string to interpret
 * @returns The integer number
 */
export function parseInteger(number: string) {
  const parsedNumber = parseFloat(number.split(".").join(""));
  return isNaN(parsedNumber) ? undefined : parsedNumber;
}

/**
 * Parses a float string as a float.
 * @param number The number string to interpret
 * @returns The float number
 */
export function parseFloating(number: string) {
  const parsedNumber = parseFloat(number);
  return isNaN(parsedNumber) ? undefined : parsedNumber;
}

/**
 * Transforms a hex color string in its corresponding rgb string.
 * @param hex Hex color
 * @returns The color as rgb string
 */
export function hexToRGB(hex: string) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (_, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16))
    .join();
}

/**
 * Darkens or brightens a color.
 * @param color The hex color to change
 * @param amount The amount of darkness to apply
 * @returns The changed color
 */
export function darken(color: string, amount: number) {
  return darkenColor(color, amount);
}

/**
 * Converts a string to Title Case.
 * @param text The string to convert
 * @returns The string converted to Title Case
 */
export function toTitleCase(text: string) {
  return text.replace(/\w\S*/g, toLabelCase);
}

/**
 * Converts a string to Label case.
 * @param text The string to convert
 * @returns The string converted to Label case
 */
export function toLabelCase<T extends string>(text: T) {
  return (
    text &&
    (`${text[0].toUpperCase()}${text.slice(1).toLowerCase()}` as Capitalize<T>)
  );
}

/**
 * Converts a string for use inside a sentence.
 * @param text The string to convert
 * @returns The string converted for use inside a sentence
 */
export function toInsideSentence<T extends string>(text: T) {
  return (
    text && (`${text[0].toLowerCase()}${text.slice(1)}` as Uncapitalize<T>)
  );
}

/**
 * Cleans a text for use in the software.
 * @param text The text to clean
 * @param textCase Uses Title Case instead of Label case
 * @returns The cleaned text
 */
export function cleanUserInput(
  text?: string | null,
  textCase: "none" | "titleCase" | "labelCase" | "lowerCase" = "labelCase"
) {
  const trimmedString = text?.trim().replace(/\s\s/g, " ");

  return trimmedString
    ? textCase === "none"
      ? trimmedString
      : textCase === "titleCase"
      ? toTitleCase(trimmedString)
      : textCase === "lowerCase"
      ? trimmedString.toLowerCase()
      : toLabelCase(trimmedString)
    : "";
}

/**
 * Formats a number amount to its money representation.
 * @param amount The amount of money to format
 * @returns The formatted amount
 */
export function toMoney(amount?: number) {
  return amount !== undefined ? `${amount} €` : "";
}

/**
 * Formats a number to its percentage representation.
 * @param amount The percentage to format
 * @returns The formatted percentage
 */
export function toPercentage(amount?: number | null) {
  return amount || amount === 0 ? `${amount} %` : "";
}

/**
 * Displays a person through its surname and name.
 * @param person The person to display
 * @returns The name string
 */
export function displayPerson(person?: { surname: string; name: string }) {
  return person ? `${person.surname} ${person.name}` : "";
}

/**
 * Evaluates an acronym from a string, using its words.
 * @param name The name to transform to acronym
 * @param uniqueArray An optional array to check uniqueness of the acronym
 * @param configuration The configuration used to process the acronym
 * @returns The evaluated acronym
 */
export function evaluateAcronym(
  name: string,
  uniqueArray?: string[],
  configuration: [words: number, lowercaseLetters: number] = [1, 0]
): string {
  const wordsToProcess = configuration[0] + 1;

  const lowercaseLettersPerWord = Math.floor(configuration[1] / wordsToProcess);

  const lowercaseLettersToAddToFirstWord = configuration[1] % wordsToProcess;

  const nameWords = toTitleCase(name).split(" ");

  const acronym = nameWords
    .slice(0, wordsToProcess)
    .map((word, index) =>
      word.slice(
        0,
        1 +
          (index
            ? lowercaseLettersPerWord
            : lowercaseLettersPerWord + lowercaseLettersToAddToFirstWord)
      )
    )
    .join("");

  if (acronym !== nameWords.join("") && uniqueArray?.includes(acronym)) {
    const shouldAddWords = wordsToProcess < nameWords.length;

    return evaluateAcronym(name, uniqueArray, [
      shouldAddWords ? configuration[0] + 1 : configuration[0],
      shouldAddWords ? configuration[1] : configuration[1] + 1,
    ]);
  } else {
    return acronym;
  }
}

/**
 * Very simple implementation of transforming a string from singular to plural.
 * @param text The singular text to pluralize
 * @returns The text in its plural form
 */
export function pluralize(text: string) {
  return text.endsWith("y") ? `${text.slice(0, -1)}ies` : `${text}s`;
}

/**
 * Removes all undefined values from an object.
 * @param dirtyObject The object to remove undefined in
 * @returns The object without undefined values
 */
export function deepRemoveUndefined(dirtyObject: Record<any, any>) {
  const cleanObject = {} as typeof dirtyObject;

  for (const key in dirtyObject) {
    const value = dirtyObject[key];

    //? Number check ensures array removals (example { 3: undefined }) are valid
    if (value === undefined && isNaN(Number(key))) continue;

    if (value !== null && typeof value === "object") {
      const cleanSubobject = deepRemoveUndefined(value);

      if (Object.keys(cleanSubobject).length) cleanObject[key] = cleanSubobject;
    } else {
      cleanObject[key] = value;
    }
  }

  return cleanObject;
}

/**
 * Transforms an outcome to its corresponding palette color.
 * @param outcome The outcome name to transform
 * @returns The corresponding palette color
 */
export function toColorFromOutcome(outcome?: TaskModel["outcome"]) {
  switch (outcome) {
    case OutcomeName.POSITIVE:
      return "success";

    case OutcomeName.WEAK:
      return "warning";

    case OutcomeName.NEGATIVE:
      return "error";

    case OutcomeName.NOTEXECUTED:
      return "gray";
  }
}
