import define from "helpers/define";
import { LastWorkDay, RealEstate, UserRole } from "models";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import { UserModel } from "utils/query";
import Choice from "view/inputs/Choice";
import Money from "view/inputs/Money";
import Percentage from "view/inputs/Percentage";

export default define<UserModel>(() => {
  const { t } = useTranslation([
    "users",
    "enumRealEstateIndexes",
    "enumRealEstatePrices",
    "enumSavings",
    "enumLoanToValues",
  ]);

  return {
    username: {
      view: "id",
      label: t("users:username"),
    },
    email: {
      view: "mail",
      label: t("users:mail"),
    },
    surname: {
      view: "name",
      label: t("users:surname"),
    },
    name: {
      view: "name",
      label: t("users:name"),
    },
    workTimeStart: {
      view: "time",
      label: t("users:workTimeStart"),
    },
    lunchBreakStart: {
      view: "time",
      label: t("users:lunchBreakStart"),
    },
    lunchBreakEnd: {
      view: "time",
      label: t("users:lunchBreakEnd"),
    },
    workTimeEnd: {
      view: "time",
      label: t("users:workTimeEnd"),
    },
    roles: {
      view: "multichoice",
      label: t("users:roles"),
      props: {
        options: UserRole,
      } as ComponentProps<typeof Choice>,
    },
    lastWorkDay: {
      view: "choice",
      label: t("users:lastWorkDay"),
      props: {
        options: LastWorkDay,
      } as ComponentProps<typeof Choice>,
    },
    headquarters: {
      view: "place",
      label: t("users:headquarters"),
    },
    preferredBuyerPersona: {
      view: "section",
      label: t("users:preferredBuyerPersona"),
      subtype: {
        realEstate: {
          view: "choice",
          label: t("users:realEstate"),
          props: {
            options: RealEstate,
            getHelperText: (option) =>
              option && t(`enumRealEstateIndexes:${option}`),
          } as ComponentProps<typeof Choice>,
        },
        realEstatePrice: {
          view: "money",
          label: t("users:realEstatePrice"),
          props: {
            getHelperText: (value) =>
              (value || value === 0) &&
              (value > 200000
                ? t("enumRealEstatePrices:MERIT")
                : value > 150000
                ? t("enumRealEstatePrices:ELEGANT")
                : value > 125000
                ? t("enumRealEstatePrices:BOURGEOIS")
                : value > 100000
                ? t("enumRealEstatePrices:ECONOMIC")
                : t("enumRealEstatePrices:POPULAR")),
          } as ComponentProps<typeof Money>,
        },
        savings: {
          view: "money",
          label: t("users:savings"),
          props: {
            getHelperText: (savings) =>
              (savings || savings === 0) &&
              (savings > 40000
                ? t("enumSavings:GREAT")
                : savings > 30000
                ? t("enumSavings:GOOD")
                : savings > 20000
                ? t("enumSavings:DISCRETE")
                : savings > 10000
                ? t("enumSavings:SUFFICIENT")
                : savings > 5000
                ? t("enumSavings:BAD")
                : t("enumSavings:ZERO")),
          } as ComponentProps<typeof Money>,
        },
        loanToValue: {
          view: "percentage",
          label: t("users:loanToValue"),
          props: {
            min: 0,
            max: 200,
            getHelperText: (loanToValue) =>
              (loanToValue || loanToValue === 0) &&
              (loanToValue > 100
                ? t("enumLoanToValues:GREATERTHANHUNDRED")
                : loanToValue > 95
                ? t("enumLoanToValues:NINETYSIXTOHUNDRED")
                : loanToValue > 94
                ? t("enumLoanToValues:NINETYFIVE")
                : loanToValue > 80
                ? t("enumLoanToValues:EIGHTYONETONINETYFOUR")
                : loanToValue > 79
                ? t("enumLoanToValues:EIGHTY")
                : t("enumLoanToValues:UNDEREIGHTY")),
          } as ComponentProps<typeof Percentage>,
        },
      },
    },
    audits: {
      view: "exclude",
    },
  };
});
