import define from "helpers/define";
import {
  Activity,
  ActivityCodename,
  ActivityPhase,
  ConditionFilter,
  LocationName,
  ModalityName,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
  Task,
  UserRole,
} from "models";
import { ComponentProps } from "react";
import { areTheHumanSame, useTranslation } from "utils/localize";
import { ActivityModel, getActivities } from "utils/query";
import Choice from "view/inputs/Choice";
import MainAndOptional from "view/inputs/MainAndOptional";
import Quicklist from "view/inputs/Quicklist";
import TimeDuration from "view/inputs/TimeDuration";

export default define<ActivityModel>(() => {
  const { t } = useTranslation("activities");

  return {
    name: {
      view: "id",
      label: t("activities:name"),
    },
    role: {
      view: "choice",
      label: t("activities:role"),
      props: {
        options: UserRole,
      } as ComponentProps<typeof Choice>,
    },
    expectedDuration: {
      view: "duration",
      label: t("activities:duration"),
      props: {
        unit: "minutes",
      } as ComponentProps<typeof TimeDuration>,
    },
    phase: {
      view: "choice",
      label: t("activities:phase"),
      props: {
        options: ActivityPhase,
      } as ComponentProps<typeof Choice>,
    },
    codename: {
      view: "choice",
      label: t("activities:codename"),
      props: {
        options: ActivityCodename,
      } as ComponentProps<typeof Choice>,
    },
    modalities: {
      view: "quicklist",
      label: t("activities:modalities"),
      subtype: {
        name: {
          view: "choice",
          label: t("activities:name"),
          props: {
            options: ModalityName,
          } as ComponentProps<typeof Choice>,
        },
        locations: {
          view: "mainAndOptional",
          label: t("activities:locations"),
          props: {
            main: 0,
          } as ComponentProps<typeof MainAndOptional>,
          subtype: {
            name: {
              view: "multichoice",
              label: t("activities:name"),
              props: {
                options: LocationName,
              } as ComponentProps<typeof Choice>,
            },
            defaultPlace: {
              view: "exclude",
            },
            placeInfo: {
              view: "place",
              label: t("activities:address"),
            },
          },
        },
      },
    },
    outcomes: {
      view: "quicklist",
      label: t("activities:outcomes"),
      subtype: {
        name: {
          view: "choice",
          label: t("activities:name"),
          props: {
            options: OutcomeName,
          } as ComponentProps<typeof Choice>,
        },
        outcomeMotivations: {
          view: "quicklist",
          label: t("activities:motivations"),
          identifier: "name",
          props: {
            inNewLine: [4],
          } as ComponentProps<typeof Quicklist>,
          subtype: {
            name: {
              view: "id",
              label: t("activities:name"),
            },
            codename: {
              view: "choice",
              label: t("activities:codename"),
              props: {
                options: OutcomeMotivationCodename,
              } as ComponentProps<typeof Choice>,
            },
            path: {
              view: "id",
              label: t("activities:path"),
            },
            toNotPay: {
              view: "toggle",
              label: t("activities:toNotPay"),
            },
            nextActivityIds: {
              view: "multichoice",
              label: t("activities:nextActivities"),
              props: {
                options: async () => (await getActivities()) as unknown,
                getLabel: (activity: Activity) => activity?.name,
              } as ComponentProps<typeof Choice>,
            },
            condition: {
              view: "section",
              label: t("activities:condition"),
              subtype: {
                oneOfPaths: {
                  view: "multichoice",
                  label: t("activities:ifOneOf"),
                  props: {
                    options: async () => {
                      const activities = await getActivities();

                      const paths = [] as NonNullable<
                        OutcomeMotivation["path"]
                      >[];

                      activities?.forEach(({ outcomes }) =>
                        outcomes.forEach(({ outcomeMotivations }) =>
                          outcomeMotivations.forEach(
                            ({ path }) =>
                              path &&
                              !paths.some((pathToCheck) =>
                                areTheHumanSame(pathToCheck, path)
                              ) &&
                              paths.push(path)
                          )
                        )
                      );

                      return paths as unknown[];
                    },
                  } as ComponentProps<typeof Choice>,
                },
                invertCondition: {
                  view: "toggle",
                  label: t("activities:noneOf"),
                },
                filter: {
                  view: "choice",
                  label: t("activities:filter"),
                  props: {
                    options: ConditionFilter,
                  } as ComponentProps<typeof Choice>,
                },
                invertFilterIfFalse: {
                  view: "toggle",
                  label: t("activities:invertFilterIfFalse"),
                },
              },
            },
          },
        },
      },
    },
    manualScheduling: {
      view: "toggle",
      label: t("activities:manualScheduling"),
    },
    responsibilityChangeDuration: {
      view: "duration",
      label: t("activities:responsibilityDuration"),
      props: {
        unit: "minutes",
        helperText: t(
          "activities:timeBeforePlannedBeforeWhichTheAssignerIsResponsible"
        ),
      } as ComponentProps<typeof TimeDuration>,
    },
    minDurationBeforeExecution: {
      view: "duration",
      label: t("activities:minDurationBeforeScheduling"),
      props: {
        unit: "days",
        helperText: t("activities:minimumTimeBetweenPlanningAndExecution"),
      } as ComponentProps<typeof TimeDuration>,
    },
    marginDuration: {
      view: "duration",
      label: t("activities:marginDuration"),
      props: {
        unit: "minutes",
        helperText: t(
          "activities:timeAfterPlannedWithoutBeingForcedForOutcome"
        ),
      } as ComponentProps<typeof TimeDuration>,
    },
    favoriteStartTime: {
      view: "time",
      label: t("activities:favoriteStartTime"),
    },
    favoriteEndTime: {
      view: "time",
      label: t("activities:favoriteEndTime"),
    },
    children: {
      view: "quicklist",
      label: t("activities:generates"),
      subtype: {
        childrenId: {
          view: "choice",
          label: t("activities:activity"),
          props: {
            options: async () => (await getActivities()) as unknown,
            getLabel: (activity) => (activity as Activity).name,
          } as ComponentProps<typeof Choice>,
        },
        minDurationBeforeParent: {
          view: "duration",
          label: t("activities:minDurationBeforeParent"),
          props: {
            unit: "hours",
          } as ComponentProps<typeof TimeDuration>,
        },
        isConfirmation: {
          view: "toggle",
          label: t("activities:isConfirmation"),
        },
      },
    },
    tasks: {
      view: "quicklist",
      label: t("activities:tasks"),
      relationship: Task,
    },
    audits: {
      view: "exclude",
    },
  };
});
