import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from "@mui/material";
import { Place as PlaceModel } from "models";
import { ComponentProps, lazy, ReactNode, useState } from "react";
import { RefCallBack } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdOutlineMap } from "react-icons/md";
import {
  encodeGeoPlace,
  GeoPlace,
  getPlaces,
  placeToGeoPlace,
} from "utils/locate";
import { useAsync } from "utils/render";
import asSpace from "view/LAYOUT/asSpace";
import Popper from "view/outputs/Popper";
import Text from "view/outputs/Text";
import Icon from "view/symbols/Icon";
import Progress from "view/symbols/Progress";
import Controller from "./Controller";
import { FormProps } from "./Form";
import Input from "./Input";

type PlaceAutocompleteProps = MuiAutocompleteProps<
  PlaceModel,
  false,
  false,
  true
>;

//? Text or coordinates
type SearchValue = string;

type PlaceValue = PlaceModel | null;

type GeoHashPlaceMap = {
  forSearchValue: SearchValue;
} & Record<string, SearchValue | PlaceValue>;

const Map = lazy(() => import("view/inputs/Map"));

export default function Place(
  props: {
    id?: string | number;
    onChange?: (data: PlaceValue) => void;
    children?: ReactNode;
    required?: boolean;
    helperText?: ReactNode;
    getHelperText?: (
      option: PlaceAutocompleteProps["options"][number]
    ) => ReactNode;
    defaultValue?: PlaceValue;
  } & FormProps &
    Pick<PlaceAutocompleteProps, "defaultValue"> &
    ComponentProps<typeof PlaceBase>
) {
  const {
    id,
    form,
    defaultValue,
    onChange: propsOnChange,
    required,
    children,
    disabled,
    helperText,
    getHelperText,
    ...placeBaseProps
  } = props;

  const { t } = useTranslation("view");

  const [popperElement, setPopperElement] = useState<HTMLElement>();

  const [searchValue, setSearchValue] = useState<SearchValue>("");
  const geohashToPlaceInitialValue = {
    forSearchValue: searchValue,
  };

  const [geohashToPlace, setGeohashToPlace] = useState<GeoHashPlaceMap>(
    geohashToPlaceInitialValue
  );
  const [throttle, setThrottle] = useState(false);

  function geoPlaceToPlace(place: GeoPlace) {
    if (
      !Object.values(geohashToPlace).some(
        (value) =>
          value && typeof value !== "string" && value.label === place.label
      )
    ) {
      const geohash = encodeGeoPlace(place);

      if (geohash) {
        const { geometry, ...placeInput } = place;

        return {
          geohash,
          ...placeInput,
        };
      }
    }
  }

  let timeout: NodeJS.Timeout | undefined;

  useAsync<
    [
      geohashMap?: typeof geohashToPlace,
      throttle?: typeof throttle | typeof timeout
    ]
  >(
    {
      condition: !!throttle || searchValue !== geohashToPlace.forSearchValue,
      action: async () => {
        if (throttle)
          return [undefined, setTimeout(() => setThrottle(false), 1000)];

        const geohashMap = {
          forSearchValue: searchValue,
        } as typeof geohashToPlace;

        if (searchValue.length < 2) return [geohashMap];

        (await getPlaces(searchValue)).forEach((geoPlace) => {
          const place = geoPlaceToPlace(geoPlace);

          if (place) geohashMap[place.geohash] = place;
        });

        return [geohashMap, true];
      },
      cleanup: () => timeout && clearTimeout(timeout),
    },
    ([geohashMap, wait]) => {
      if (wait) {
        if (typeof wait === "boolean") setThrottle(wait);
        else timeout = wait;
      }

      if (geohashMap) setGeohashToPlace(geohashMap);
    }
  );

  const { forSearchValue: removed, ...rawMap } = geohashToPlace;

  const geohashPlaceMap = rawMap as Record<string, PlaceValue>;

  const processedID = id?.toString();

  // TODO FIX PHONE SIZE OF THIS MODAL
  return (
    <>
      <Controller
        form={form}
        id={id}
        defaultValue={defaultValue || null}
        {...placeBaseProps}
      >
        {({ onChange, value, ...field }) => {
          const typedValue = value as PlaceValue;

          const geoPlaceValue = typedValue && placeToGeoPlace(typedValue);

          return (
            <>
              <Popper
                attachTo={popperElement}
                onClose={() => setPopperElement(undefined)}
                mobileProps={{
                  fullScreen: false,
                  fullWidth: true,
                }}
              >
                <Map
                  id={processedID}
                  value={geoPlaceValue}
                  onChange={(geoPlace) => {
                    let placeValue: PlaceValue = null;

                    if (geoPlace) {
                      const place = geoPlaceToPlace(geoPlace);

                      if (place) {
                        placeValue = place;

                        setGeohashToPlace({
                          ...geohashToPlaceInitialValue,
                          [place.geohash]: place,
                        });
                      }
                    }

                    onChange(placeValue);
                    propsOnChange?.(placeValue);
                  }}
                />
                {geoPlaceValue &&
                  asSpace(
                    <Text toMap={geoPlaceValue.geometry.point}>
                      {t("view:openNavigator")}
                    </Text>,
                    {
                      mt: -2,
                      mb: -1,
                      textAlign: "center",
                    }
                  )}
              </Popper>
              <PlaceBase
                {...placeBaseProps}
                id={processedID}
                loading={!!throttle}
                value={typedValue?.geohash ?? null}
                geohashPlaceMap={geohashPlaceMap}
                disabled={disabled ?? form.disabled}
                onChange={(_, data) => {
                  const place = data ? geohashPlaceMap[data] : null;

                  onChange(place);
                  propsOnChange?.(place);
                }}
                options={geohashPlaceMap ? Object.keys(geohashPlaceMap) : []}
                onGapValue={(gapValue) => {
                  const forSearchText = typedValue?.label ?? "";

                  setGeohashToPlace({
                    ...geohashPlaceMap,
                    forSearchValue: forSearchText,
                    [gapValue]: typedValue,
                  });

                  setSearchValue(forSearchText);
                }}
                renderInput={(inputProps) => (
                  <Input
                    {...inputProps}
                    value={searchValue}
                    onChange={({
                      target: { value: data },
                    }: {
                      target: { value: string };
                    }) => setSearchValue(data)}
                    InputProps={{
                      ...inputProps.InputProps,
                      endAdornment: (
                        <>
                          {throttle && <Progress spinner size={18} />}
                          <Icon
                            size="small"
                            aria-label="open map viewer"
                            sx={{
                              position: "absolute",
                              top: "calc(50% - 14px)",
                              right: 36,
                            }}
                            onClick={(complete, { currentTarget }) => {
                              complete();
                              setPopperElement(
                                popperElement ? undefined : currentTarget
                              );
                            }}
                          >
                            {MdOutlineMap}
                          </Icon>
                          {inputProps.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    InputLabelProps={{
                      required: required && !form.required,
                    }}
                    required={required ?? form.required}
                    form={form}
                  >
                    {children}
                  </Input>
                )}
                {...field}
              />
            </>
          );
        }}
      </Controller>
    </>
  );
}

function PlaceBase(
  props: {
    geohashPlaceMap: Record<string, PlaceModel | null>;
    innerRef?: RefCallBack;
    inputProps?: ComponentProps<typeof Input>;
    onGapValue?: (value: string) => void;
  } & Omit<
    MuiAutocompleteProps<string, false, false, true>,
    "variant" | "label" | "onOpen" | "onClose"
  >
) {
  const {
    id,
    innerRef,
    value,
    geohashPlaceMap,
    onGapValue,
    ...autocompleteProps
  } = props;

  const isValid = !value || geohashPlaceMap[value] !== undefined;

  useAsync(
    {
      condition: !isValid,
      action: async () => value as string,
    },
    onGapValue
  );

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      id={id}
      popupIcon={null}
      ref={innerRef}
      value={isValid ? value : null}
      getOptionLabel={(place) => geohashPlaceMap[place]?.label ?? ""}
      filterOptions={(option) => option}
      autoComplete
    />
  );
}
