import { ComponentProps, ReactNode } from "react";
import { useTranslation } from "utils/localize";
import Button from "../inputs/Button";
import asRow from "../LAYOUT/asRow";
import Title from "./Title";

export default function Confirm(props: {
  title?: ReactNode;
  subtitle?: ReactNode;
  details?: ReactNode;
  confirm?: ReactNode;
  cancel?: ReactNode;
  confirmButtonProps?: ComponentProps<typeof Button>;
  onConfirm?: ComponentProps<typeof Button>["onClick"];
  onCancel?: () => void;
}) {
  const { t } = useTranslation("view");

  return (
    <>
      <Title subtitle={props.subtitle} details={props.details}>
        {props.title}
      </Title>
      {asRow(
        [
          <Button
            back
            onClick={(complete) => {
              complete();
              props.onCancel?.();
            }}
          >
            {props.cancel}
          </Button>,
          <Button
            delete
            onClick={props.onConfirm}
            {...props.confirmButtonProps}
          >
            {props.confirm ?? t("view:confirm")}
          </Button>,
        ],
        {
          disableResponsive: true,
        }
      )}
    </>
  );
}
