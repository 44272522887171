import Axios from "axios";
import {
  enumNamespaces,
  LOCAL_STORAGE_KEY_PREFIX,
  namespaces,
} from "constants/localization";
import type { LocalizedEnum } from "helpers/enumLocalization";
import i18n, { StringMap, TOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { DateTime } from "luxon";
import {
  ActivityCodename,
  ActivityPhase,
  Bank,
  ConditionFilter,
  FinancingType,
  Gender,
  HouseHunter,
  LastWorkDay,
  LocationName,
  ModalityName,
  ModelType,
  Nationality,
  OutcomeMotivationCodename,
  OutcomeName,
  PaymentService,
  PaymentState,
  PaymentType,
  PlaceType,
  RealEstate,
  Review,
  Source,
  Supplier,
  UserRole,
} from "models";
import {
  initReactI18next,
  useTranslation as useUntypedTranslation,
  UseTranslationOptions,
} from "react-i18next";
import { EnumOptions } from "view/inputs/Choice";
import { cleanUserInput } from "./format";
import { toDateTime } from "./time";

export type TranslationFunction<T extends TranslationNamespaces> = (
  key: TranslationString<T> | TranslationString<T>[],
  options?: TOptions<StringMap> | string
) => string;

type Enum = number;

type LocalEnum = string;

export default i18n
  .use(LanguageDetector) // Detects user language automatically
  .use(ChainedBackend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "it",
    supportedLngs: ["it"], //! Mirrors Locales and local folder, add locale here when folders or local enums are updated
    ns: [],
    defaultNS: "view",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      backends: [
        LocalStorageBackend, // primary (cache)
        HttpBackend, // fallback
      ],
      backendOptions: [
        { defaultVersion: process.env.REACT_APP_VERSION }, //! Update this version when locales change
      ],
    },
  });

/**
 * Encodes enum objects from their code representation to their DB representation.
 * @param enumObject The enum object
 */
export function encodeLocalizedEnumValue<E extends LocalizedEnum>(
  enumObject: E
): (E["language"] extends string ? LocalEnum : Enum) | undefined {
  if (enumObject.enumValue !== undefined) {
    return (
      enumObject.language
        ? `${enumObject.language}|${enumObject.enumValue}`
        : enumObject.enumValue
    ) as any;
  }
}

/**
 * Decodes formatted local enum values from their DB representation to a usable object.
 * @param formattedEnumValue The enum value as it is represented in the DB
 * @param namespace The namespace associated with the enum value
 */
export function decodeLocalizedEnumValue<E extends Enum | LocalEnum>(
  formattedEnumValue: E | undefined,
  namespace: LocalizedEnum["namespace"]
) {
  let [enumValue, language] = [
    formattedEnumValue as LocalizedEnum["enumValue"],
    undefined as LocalizedEnum["language"],
  ];

  if (typeof formattedEnumValue === "string") {
    //? Local enum
    const [locale, value] = formattedEnumValue.split("|");

    enumValue = parseInt(value);
    language = locale;
  }

  return {
    enumValue: enumValue,
    namespace: namespace,
    language: language,
  } as LocalizedEnum;
}

/**
 * Resolves the specified formatted enum to its localized enum string value/translation.
 * @param formattedLocalizedEnum the enum to resolve
 */
export async function resolveLocalizedEnumToString(
  formattedLocalizedEnum: LocalizedEnum
) {
  return formattedLocalizedEnum.enumValue !== undefined
    ? (
        await fetchNamespace(
          formattedLocalizedEnum.namespace,
          formattedLocalizedEnum.language
        )
      )[formattedLocalizedEnum.enumValue]
    : undefined;
}

/**
 * Fetches the values of a namespace.json. Namespaces are cached in local storage.
 * @param namespace i18next namespace to fetch; it's the name of the json file
 * @param language i18next language to use to fetch the namespace; it's the name of the locale folder. If not specified, localized namespace is loaded
 * @returns the promise containing the namespace as a string array
 */
export async function fetchNamespace(
  namespace: LocalizedEnum["namespace"],
  language?: LocalizedEnum["language"]
) {
  const languageToLoad = language ?? i18n.language;
  const formattedNamespace = language ? `enums/${namespace}` : namespace;
  const localStorageKey = `${LOCAL_STORAGE_KEY_PREFIX}enums_${languageToLoad}-${formattedNamespace}`;

  if (!localStorage[localStorageKey])
    localStorage.setItem(
      localStorageKey,
      JSON.stringify(
        (
          await Axios.get(
            `/locales/${languageToLoad}/${formattedNamespace}.json`
          )
        ).data
      )
    );

  return JSON.parse(localStorage[localStorageKey]) as Record<string, string>;
}

/**
 * Converts an ISO date to the appropriate localized representation.
 * @param isoDate The date in ISO format or an empty string if the date is invalid
 */
export function toLocalizedDate(
  isoDate?: string | null,
  options?: Intl.DateTimeFormatOptions
) {
  let date: DateTime | undefined;

  if (isoDate) date = toDateTime(isoDate);

  return date?.isValid
    ? date.setLocale(i18n.language).toLocaleString(options)
    : "";
}

/**
 * Converts an ISO date to the appropriate localized parts that compose it.
 * @param isoDate The date in ISO format or an empty string if the date is invalid
 */
export function toLocalizedParts(
  isoDate?: string | null,
  options?: Intl.DateTimeFormatOptions
) {
  let date: DateTime | undefined;

  if (isoDate) date = toDateTime(isoDate);

  return date?.isValid
    ? date.setLocale(i18n.language).toLocaleParts(options)
    : [];
}

/**
 * Converts an ISO date/time to the appropriate localized representation.
 * @param isoDateTime The date/time in ISO format or an empty string if the date/time is invalid
 */
export function toLocalizedDateTime(isoDateTime?: string | null) {
  let dateTime: DateTime | undefined;

  if (isoDateTime) dateTime = toDateTime(isoDateTime);

  return dateTime?.isValid
    ? dateTime.setLocale(i18n.language).toLocaleString({
        dateStyle: "short",
        timeStyle: "short",
      })
    : "";
}

/**
 * Converts an ISO date/time to the appropriate localized time representation.
 * @param isoDateTime The date/time in ISO format or an empty string if the date/time is invalid
 */
export function toLocalizedTime(isoDateTime?: string | null) {
  let dateTime: DateTime | undefined;

  if (isoDateTime) dateTime = toDateTime(isoDateTime);

  return dateTime?.isValid
    ? dateTime.toJSDate().toLocaleTimeString(i18n.language, {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";
}

/**
 * Localizes a date time to its month year representation.
 * @param dateTime The date time to convert
 * @returns The localized date time in month year format
 */
export function toLocalizedMonthYear(dateTime?: DateTime) {
  return toLocalizedParts(dateTime?.toISO()).reduce(
    (previous, { type, value }, index, parts) =>
      type === "day" || parts[index - 1].type === "day"
        ? previous
        : `${previous}${value}`,
    ""
  );
}

/**
 * Localizes a date time to its day month representation.
 * @param dateTime The date time to convert
 * @returns The localized date time in day month format
 */
export function toLocalizedDayMonth(dateTime?: DateTime) {
  return toLocalizedParts(dateTime?.toISO()).reduce(
    (previous, { type, value }, index, parts) =>
      type === "year" || parts[index + 1].type === "year"
        ? previous
        : `${previous}${value}`,
    ""
  );
}

/**
 * Changes the time of a dateTime object, considering timezones.
 * @param dateTime The date time to change
 * @param time The time to set on the date time
 * @returns The date time with the time set correctly
 */
export function changeTime(dateTime: DateTime, time: DateTime) {
  return toDateTime(
    `${dateTime.toISODate()}${time.toISOTime({ includePrefix: true })}`
  );
}

/**
 * Compares two string in a case insensitive and fuzzy manner.
 * @param firstString The first string to compare
 * @param secondString The second string to compare
 * @returns True if the strings are equal
 */
export function areTheHumanSame(
  firstString?: string | null,
  secondString?: string | null
) {
  return firstString && secondString
    ? !cleanUserInput(firstString, "none").localeCompare(
        cleanUserInput(secondString, "none"),
        "it"
      )
    : firstString === secondString;
}

/**
 * Gets the enum namespace corresponding to an enum options object.
 * @param enumOptions The enum options to transform
 * @returns The corresponding namespace
 */
export function getNamespaceForEnumOptions(
  enumOptions: EnumOptions
): typeof enumNamespaces[number] | undefined {
  switch (enumOptions) {
    case ActivityCodename:
      return "enumActivities";
    case OutcomeMotivationCodename:
      return "enumMotivations";
    case Source:
      return "enumSources";
    case RealEstate:
      return "enumRealEstates";
    case FinancingType:
      return "enumFinancingTypes";
    case ModalityName:
      return "enumModalities";
    case OutcomeName:
      return "enumOutcomes";
    case LocationName:
      return "enumLocations";
    case UserRole:
      return "enumRoles";
    case Gender:
      return "enumGenders";
    case Review:
      return "enumReviews";
    case PlaceType:
      return "enumPlaces";
    case Nationality:
      return "enumNationalities";
    case LastWorkDay:
      return "enumLastWorkDays";
    case HouseHunter:
      return "enumHouseHunters";
    case PaymentState:
      return "enumPaymentStates";
    case Supplier:
      return "enumSuppliers";
    case Bank:
      return "enumBanks";
    case PaymentType:
      return "enumPayments";
    case PaymentService:
      return "enumPaymentServices";
    case ModelType:
      return "enumModels";
    case ConditionFilter:
      return "enumConditionFilters";
    case ActivityPhase:
      return "enumPhases";
  }
}

type TranslationNamespaces =
  | typeof namespaces[number]
  | typeof enumNamespaces[number];

type TranslationString<T extends TranslationNamespaces> = `${T}:${string}`;

/**
 * Creates a translation object used to translate strings.
 * @param ns The namespaces to load
 * @param translationOptions The options to pass to the native useTranslation
 * @returns The translation object used for translating, changing languages, ecc
 */
export function useTranslation<T extends TranslationNamespaces>(
  ns?: T | T[],
  translationOptions?: UseTranslationOptions
) {
  const { t: translate, ...rest } = useUntypedTranslation<T>(
    ns as any,
    translationOptions
  );

  return {
    t: ((key, options) => {
      const translation = translate(key as any, options);

      return translation === key
        ? translation.split(":").slice(1).join("")
        : translation;
    }) as TranslationFunction<T>,
    ...rest,
  };
}

/**
 * Sorts dates for javascript sort.
 * @param before The task that should be before
 * @param after The task that should be after
 * @param sortOn The sort key
 * @returns before - after
 */
export function sortDates<T>(before?: T, after?: T, sortOn?: keyof T) {
  const key =
    sortOn ?? ("endDateTime" as unknown as NonNullable<typeof sortOn>);

  const beforeValue = before?.[key] as any;
  const afterValue = after?.[key] as any;

  return (
    (beforeValue ? new Date(beforeValue).getTime() : 0) -
    (afterValue ? new Date(afterValue).getTime() : 0)
  );
}

/**
 * Transforms the language from i18next format to MUI import name format.
 * @param language The language to transform
 * @returns The language in MUI import name format
 */
export function toMuiLocaleFromi18Next(language: string) {
  return language.includes("-")
    ? language.replace(/-/g, "")
    : `${language}${language.toUpperCase()}`;
}
