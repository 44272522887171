import { getModelName } from "helpers/display";
import { Audit, ModelType, Task } from "models";
import { Entity } from "utils/store";
import { getLoggedUser } from "./authenticate";
import { log } from "./log";
import { createNotification } from "./mutate";

export async function notify<T extends Entity>(entity: T, audit: Audit) {
  switch (resolveEnabledEntity(entity)) {
    case ModelType.TASK:
      const task = entity as unknown as Task;

      return generateNotification(
        entity,
        audit,
        task.assignee === audit.user ? task.assigner : task.assignee
      );
  }
}

async function generateNotification<T extends Entity>(
  entity: T,
  audit: Audit,
  reader?: string | null
) {
  if (!reader || reader === getLoggedUser()) return;

  const constructor: Parameters<typeof createNotification>[0] = {
    audit,
    reader,
  };

  const notification = await createNotification(constructor);

  if (!notification) {
    log({
      message: "Notification creation error",
      constructor,
      entity,
      audit,
    });
  }

  return notification;
}

function resolveEnabledEntity(entity: Entity): ModelType | undefined {
  //? Notifications are disabled by default

  switch (entity.constructor.name) {
    case getModelName(Task):
      return ModelType.TASK;
  }
}
