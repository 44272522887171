import { PersistentModelConstructor } from "@aws-amplify/datastore";
import { NavigationEvent } from "constants/code";
import { ViewableEntity } from "helpers/define";
import display, { getModelName } from "helpers/display";
import { Permissions } from "helpers/rbac";
import { ComponentProps, ReactNode } from "react";
import { canUser } from "utils/authorize";
import { Entity as EntityType } from "utils/store";
import FormEntity, { FormEntityProps } from "./inputs/FormEntity";
import ViewEntity, { ViewEntityProps } from "./outputs/ViewEntity";

export type InternalEntityProps<T extends EntityType> = {
  model?: PersistentModelConstructor<T>;
  propertyProps?: PropertyProps<T>;
  operation?: "create" | "update" | "view";
  exclude?: Exclude<T>;
  defaultValues?: { [K in keyof Omit<T, "id">]?: T[K] };
  onOperationExecuted?: NavigationEvent;
  formProps?: Omit<
    NonNullable<FormEntityProps<T>["formProps"]>,
    "title" | "subtitle" | "details"
  >;
};

export type EntityProps<T extends EntityType> = InternalEntityProps<T> &
  Omit<FormEntityProps<T>, "formProps"> &
  ViewEntityProps<T>;

type PropertyProps<T extends EntityType> = {
  [K in keyof ViewableEntity<T>]?: NonNullable<T[K]> extends any[]
    ? PropertyProps<NonNullable<NonNullable<T[K]>[number]>> | any
    : PropertyProps<NonNullable<T[K]>> | any;
};

//! Limitation: does not check for required
export type Exclude<T extends EntityType> = {
  [K in keyof ViewableEntity<T>]?:
    | boolean
    | (NonNullable<T[K]> extends any[]
        ? Exclude<NonNullable<NonNullable<T[K]>[number]>>
        : Exclude<NonNullable<T[K]>>);
};

export default function Entity<T extends EntityType>(props: EntityProps<T>) {
  const {
    model,
    operation,
    exclude,
    defaultValues,
    propertyProps,
    titleProps,
    onOperationExecuted,
    ...visualizerProps
  } = props;

  function evaluateExcluded(
    type: ViewableEntity<T>,
    excludeToEvaluate?: Exclude<T>
  ) {
    if (excludeToEvaluate) {
      const included = {} as ViewableEntity<T>;

      for (const property in type) {
        if (excludeToEvaluate[property] !== true) {
          // if (type[property].subtype) {
          // TODO ADD EXCLUDED TO SUBTYPE PROPERTY PROPS AND PASS DOWN
          // propertyProps
          // }

          (included as any)[property] = {
            ...type[property],
            subtype:
              type[property].subtype &&
              evaluateExcluded(
                type[property].subtype as ViewableEntity<T>,
                excludeToEvaluate[property]
              ),
          };
        }
      }

      return Object.keys(included).length ? included : undefined;
    } else {
      return type;
    }
  }

  function evaluateOperation() {
    if (model) {
      const propertyInfo = display[
        getModelName(model)
      ]() as unknown as ViewableEntity<T>;

      // TODO THIS WORKS BUT IS NOT CHECKED, SECTIONS AND QUICKLIST SHOULD PROPAGATE EXCLUDED
      const includedPropertyInfo =
        evaluateExcluded(propertyInfo, exclude) ?? ({} as ViewableEntity<T>);

      const formattedDefaultValues = {} as Record<any, any>;

      //? Checks in viewable entity to allow manual addition of default values
      for (const key in propertyInfo) {
        const entity = includedPropertyInfo[key as any];

        if (
          operation === "update" ||
          defaultValues?.[key] ||
          entity?.view === "section"
        ) {
          formattedDefaultValues[key] = entity?.toView
            ? entity.toView(defaultValues?.[key], defaultValues)
            : defaultValues?.[key];
        }
      }

      const sharedProps = {
        defaultValues: formattedDefaultValues,
        propertyProps,
        propertyInfo: includedPropertyInfo,
      } as ComponentProps<typeof FormEntity> &
        ComponentProps<typeof ViewEntity>;

      const processedTitle =
        titleProps?.children && canUser(Permissions.HandleDatabase)
          ? `${titleProps.children}${
              defaultValues?.id ? ` ${defaultValues.id}` : ""
            }`
          : titleProps?.children;

      if (operation === "create" || operation === "update") {
        const { formProps, ...formEntityProps } =
          visualizerProps as FormEntityProps<T> & ViewEntityProps<T>;

        return (
          <FormEntity
            model={model}
            instance={defaultValues as T}
            formProps={{
              update: operation === "update",
              title: processedTitle,
              subtitle: titleProps?.subtitle as ReactNode,
              details: titleProps?.details,
              ...formProps,
            }}
            onEntityDeleted={onOperationExecuted}
            onEntitySubmitted={onOperationExecuted}
            {...(sharedProps as FormEntityProps<T>)}
            {...formEntityProps}
          />
        );
      } else {
        return (
          <ViewEntity
            titleProps={{ ...titleProps, children: processedTitle }}
            {...(sharedProps as ViewEntityProps<T>)}
            {...visualizerProps}
          />
        );
      }
    }

    return null;
  }

  return <>{evaluateOperation()}</>;
}
