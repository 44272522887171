import { Geo, Place as AmplifyPlace } from "@aws-amplify/geo";
import {
  CalculateRouteCommand,
  CalculateRouteCommandInput,
  LocationClient,
  LocationClientConfig,
} from "@aws-sdk/client-location";
import awsconfig from "aws-exports";
import haversine from "haversine";
import Geohash from "latlon-geohash";
import { Duration, Interval } from "luxon";
import { LocationName, ModalityName, OutcomeName, Place } from "models";
import { sortDates, TranslationFunction } from "./localize";
import { log } from "./log";
import { getScheduler, getTasks, getUser, TaskModel, UserModel } from "./query";
import { toDateTime, toDuration, toInterval } from "./time";

export type GeoPlace = {
  geometry: NonNullable<AmplifyPlace["geometry"]>;
} & Omit<AmplifyPlace, "geometry">;

export type Coordinates = [longitude: number, latitude: number];

type SchedulerData = {
  interval: Duration;
  intervalMinutes: number;
};

/**
 * Place cache.
 */
const places = {} as Record<string, GeoPlace | null>;

/**
 * Distance cache.
 */
const distances = {} as Record<string, Duration | null>;

let routeCalculatorClient: LocationClient | undefined;

const routeCalculatorName = `routecalculatorb4fd9608-${awsconfig.aws_cloud_logic_custom[0].endpoint
  .split("/")
  .pop()}`;

/**
 * Retrieves the current place of the user in lat and lon format.
 * @returns The place of the current user in [lon, lat] format
 */
export async function getCurrentPlace() {
  if (!navigator.geolocation) return;

  return new Promise<Coordinates | void>((resolve) =>
    navigator.geolocation.getCurrentPosition(
      ({ coords: { longitude, latitude } }) => resolve([longitude, latitude]),
      (error) => {
        log(error);
        resolve();
      },
      {
        maximumAge: 600000,
        timeout: 5000,
      }
    )
  );
}

/**
 * Constructs a label from the provided place.
 * @param place The place to process
 * @param options The options to pass to the label
 * @returns The processed label for the place
 */
export function labelFromPlace(
  place?: Omit<Place, "geohash"> | null,
  options?: { short?: boolean; aggregator?: keyof Omit<Place, "geohash"> }
) {
  if (place) {
    const { label, addressNumber, street, municipality, postalCode, country } =
      place;

    const aggregator = place[options?.aggregator ?? "subRegion" ?? "region"];

    if (options?.short) {
      return [
        municipality,
        aggregator === municipality ? undefined : aggregator,
      ]
        .filter((value) => !!value)
        .join(", ");
    } else {
      return (
        label ??
        [
          [addressNumber, street].filter((value) => !!value).join(" "),
          municipality,
          aggregator === municipality ? undefined : aggregator,
          postalCode,
          country,
        ]
          .filter((value) => !!value)
          .join(", ")
      );
    }
  } else {
    return "";
  }
}

/**
 * Decodes a geohash place to its address.
 * @param place The geohash place to decode
 * @param options The options to pass to the label
 * @returns The address for the place
 */
export async function getAddress(
  place?: string,
  options?: Parameters<typeof labelFromPlace>[1]
) {
  return labelFromPlace(await getGeoPlace(place), options);
}

/**
 * Decodes a geohash place or coordinates to its geo place.
 * @param place The geohash place or coordinates to decode
 * @returns The geo place for the place, or null if is loading.
 */
export async function getGeoPlace(place?: Coordinates | string) {
  if (!place) return;

  const isGeohash = typeof place === "string";

  let coordinates = isGeohash ? undefined : place;

  if (isGeohash) {
    if (places[place] !== undefined) return places[place];

    places[place] = null;

    coordinates = decodeGeohash(place);
  }

  if (!coordinates) return;

  const amplifyPlace = await Geo.searchByCoordinates(coordinates, {
    maxResults: 1,
  });

  if (!amplifyPlace.geometry) {
    amplifyPlace.geometry = {
      point: coordinates,
    };
  }

  const geoPlace = amplifyPlaceToGeoPlace(amplifyPlace)!;

  if (isGeohash) places[place] = geoPlace;

  return geoPlace;
}

/**
 * Decodes a geohash place to its cached geo place.
 * @param place The geohash place to decode
 * @returns The cached geo place for the place
 */
export function getGeoPlaceFromCache(place?: string) {
  return place ? places[place] : undefined;
}

/**
 * Encodes a human readable address to its geoplace object.
 * @param address The address in human readable form to encode
 * @returns The geoplace
 */
export async function getPlaces(
  address: string,
  options?: Parameters<typeof Geo["searchByText"]>[1]
) {
  const location = await getCurrentPlace();

  return (
    await Geo.searchByText(address, {
      maxResults: 5,
      biasPosition: location ?? undefined,
      countries: ["ITA"],
      ...options,
    })
  )
    .filter(({ geometry }) => !!geometry)
    .map(amplifyPlaceToGeoPlace) as GeoPlace[];
}

/**
 * Encodes a geoplace object to its geohash place.
 * @param place The geoplace object to encode
 * @returns The geohash
 */
export function encodeGeoPlace(place: GeoPlace | Coordinates) {
  const [longitude, latitude] = Array.isArray(place)
    ? place
    : place.geometry.point;

  return Geohash.encode(latitude, longitude);
}

/**
 * Decodes a geohash string to its corresponding longitude and latitude.
 * @param geohash The geohash to decode
 * @returns The decoded longitude and latitude
 */
export function decodeGeohash(geohash?: string) {
  if (!geohash) return;

  let longitude: number | undefined;
  let latitude: number | undefined;

  try {
    const { lon, lat } = Geohash.decode(geohash);
    longitude = lon;
    latitude = lat;
  } catch (error) {
    log(error);
    return;
  }

  return [longitude, latitude] as Coordinates;
}

/**
 * Reverts a place to its geoplace form.
 * @param place The place object
 * @returns The base geo place
 */
export function placeToGeoPlace(place: Place) {
  const { geohash, ...info } = place;

  const point = decodeGeohash(geohash);

  if (!point) return;

  return {
    ...info,
    geometry: { point },
  } as GeoPlace;
}

/**
 * Calculates the distance between two coordinates.
 * @param start The start coordinates
 * @param end The end coordinates
 * @returns The calculated distance object
 */
export async function calculateDistance(
  start?: Place | null,
  end?: Place | null,
  options?: {
    interval?: Duration;
    intervalMinutes?: number;
  } & Partial<CalculateRouteCommandInput>
) {
  if (!routeCalculatorClient) return;

  if (!start || !end || start.geohash === end.geohash) return toDuration(0);

  const normalPath = `${start.geohash}-${end.geohash}`;
  if (distances[normalPath] !== undefined)
    return distances[normalPath] ?? undefined;

  const invertedPath = `${end.geohash}-${start.geohash}`;
  if (distances[invertedPath] !== undefined)
    return distances[invertedPath] ?? undefined;

  let calculatorOptions: Partial<CalculateRouteCommandInput> | undefined;

  if (options) {
    const { interval, intervalMinutes, ...realOptions } = options;

    calculatorOptions = realOptions;
  }

  return new Promise<Duration | undefined>(async (resolve) =>
    routeCalculatorClient!.send(
      new CalculateRouteCommand({
        CalculatorName: routeCalculatorName,
        DeparturePosition: decodeGeohash(start.geohash),
        DestinationPosition: decodeGeohash(end.geohash),
        ...calculatorOptions,
      }),
      (error, data) => {
        const seconds = data?.Summary?.DurationSeconds;

        if (error) log(error);

        if (seconds === undefined) {
          resolve(seconds);
        } else {
          const preciseDuration = toDuration({
            minutes: Math.floor(seconds / 60),
          }).plus(options?.interval ?? 0);

          const intervalMinutes =
            options?.intervalMinutes ??
            (options?.interval ? options.interval.as("minutes") : undefined);

          const duration = intervalMinutes
            ? preciseDuration.minus({
                minutes:
                  preciseDuration.as("minutes") % intervalMinutes ||
                  options?.intervalMinutes,
              })
            : preciseDuration;

          distances[normalPath] = duration;

          resolve(duration);
        }
      }
    )
  );
}

/**
 * Initializes the location service with the provided credentials.
 * @param credentials The credentials used for the permissions
 */
export function setLocationPermissions(
  credentials?: LocationClientConfig["credentials"]
) {
  routeCalculatorClient = new LocationClient({
    credentials,
    region: awsconfig.aws_project_region,
  });
}

const modalitiesWithoutLocation = [ModalityName.MAIL, ModalityName.VOCAL];

/**
 * Checks if a task should have a location.
 * @param modality The modality of the task
 * @returns True if the task shouldn't have a location
 */
export function isTaskWithoutLocation(modality: TaskModel["modality"]) {
  return modalitiesWithoutLocation.includes(
    modality as typeof modalitiesWithoutLocation[number]
  );
}

/**
 * Retrieves the parameters used to determine travel info of a task.
 * @param updated The updated task
 * @param original The original task
 * @param options The options used to optimize the process
 * @returns The params used to calculate travel info
 */
export async function getTravelInfoParams(
  updated: { interval: Interval } & Pick<
    TaskModel,
    "assignee" | "placeInfo" | "modality" | "outcome"
  >,
  original?: { interval: Interval } & Pick<
    TaskModel,
    "assignee" | "placeInfo" | "modality" | "id" | "outcome"
  >,
  options?: {
    schedulerData: SchedulerData;
    updatedTasksToCheck?: TaskModel[];
    originalTasksToCheck?: TaskModel[];
    updatedHeadquartersTravel?: Duration;
    originalHeadquartersTravel?: Duration;
  }
) {
  const isWithoutLocation =
    updated.outcome === OutcomeName.NOTEXECUTED ||
    isTaskWithoutLocation(updated.modality);
  const wasWithoutLocation =
    original &&
    (original.outcome === OutcomeName.NOTEXECUTED ||
      isTaskWithoutLocation(original.modality));

  if (isWithoutLocation && wasWithoutLocation) return;

  //? Params
  let processedSchedulerData = options?.schedulerData;

  if (!processedSchedulerData) {
    const scheduler = await getScheduler();

    if (!scheduler) return;

    const timeInterval = toDuration(scheduler.scheduler!.timeInterval);

    processedSchedulerData = {
      interval: timeInterval,
      intervalMinutes: timeInterval.as("minutes"),
    };
  }

  const travelInfoParams: {
    schedulerData: SchedulerData;
    updatedSurroundingTasks?: ReturnType<typeof getSurroundingTasks>;
    updatedHeadquartersTravel?: TaskModel["travelDuration"];
    originalSurroundingTasks?: ReturnType<typeof getSurroundingTasks>;
    originalHeadquartersTravel?: TaskModel["travelDuration"];
  } = { schedulerData: processedSchedulerData };

  const modalitiesWithLocation = Object.keys(ModalityName).filter(
    (modality) => !isTaskWithoutLocation(modality as ModalityName)
  );

  //? Mutation
  if (!isWithoutLocation) {
    let assigneeHeadquartersTravel = options?.updatedHeadquartersTravel;

    if (!assigneeHeadquartersTravel) {
      const assigneeUser = await getUser(["username", updated.assignee]);

      if (!assigneeUser) return;

      assigneeHeadquartersTravel = await calculateDistance(
        assigneeUser.headquarters,
        updated.placeInfo,
        processedSchedulerData
      );

      if (!assigneeHeadquartersTravel) return;
    }

    const updatedDayInterval = toInterval(
      updated.interval.start.startOf("day"),
      updated.interval.end.endOf("day")
    );

    const mutationTasksToCheck =
      options?.updatedTasksToCheck ??
      (await getTasks())?.filter(
        ({ assignee, modality, outcome, startDateTime, endDateTime }) =>
          assignee === updated.assignee &&
          outcome !== OutcomeName.NOTEXECUTED &&
          modalitiesWithLocation.includes(modality) &&
          updatedDayInterval.overlaps(toInterval(startDateTime, endDateTime))
      );

    if (!mutationTasksToCheck) return;

    const mutationSurroundingTasks = getSurroundingTasks(
      updated,
      mutationTasksToCheck
    );

    if (!mutationSurroundingTasks) return;

    travelInfoParams.updatedSurroundingTasks = mutationSurroundingTasks;
    travelInfoParams.updatedHeadquartersTravel = assigneeHeadquartersTravel.as(
      "milliseconds"
    )
      ? assigneeHeadquartersTravel.toISO()
      : undefined;
  }

  //? Original
  if (original && !wasWithoutLocation) {
    let originalHeadquartersTravel =
      original.assignee === updated.assignee
        ? travelInfoParams.updatedHeadquartersTravel
          ? toDuration(travelInfoParams.updatedHeadquartersTravel)
          : undefined
        : options?.originalHeadquartersTravel;

    if (!originalHeadquartersTravel) {
      const assigneeUser = await getUser(["username", original.assignee]);

      if (!assigneeUser) return;

      originalHeadquartersTravel = await calculateDistance(
        assigneeUser.headquarters,
        original.placeInfo,
        processedSchedulerData
      );

      if (!originalHeadquartersTravel) return;
    }

    const originalDayInterval = toInterval(
      original.interval.start.startOf("day"),
      original.interval.end.endOf("day")
    );

    const originalTasksToCheck =
      options?.originalTasksToCheck ??
      (await getTasks())?.filter(
        ({ assignee, modality, outcome, startDateTime, endDateTime }) =>
          assignee === original.assignee &&
          outcome !== OutcomeName.NOTEXECUTED &&
          modalitiesWithLocation.includes(modality) &&
          originalDayInterval.overlaps(toInterval(startDateTime, endDateTime))
      );

    if (!originalTasksToCheck) return;

    const originalSurroundingTasks = getSurroundingTasks(
      original,
      originalTasksToCheck
    );

    if (!originalSurroundingTasks) return;

    travelInfoParams.originalSurroundingTasks = originalSurroundingTasks;
    travelInfoParams.originalHeadquartersTravel = originalHeadquartersTravel.as(
      "milliseconds"
    )
      ? originalHeadquartersTravel.toISO()
      : undefined;
  }

  return travelInfoParams;
}

/**
 * Calculates the travel to and from of a task.
 * @param task The task for which to get the travel info
 * @param param1 The data used for the travel info
 * @returns The travel and return data of the task
 */
export async function getTravelInfo(
  { placeInfo, outcome }: Pick<TaskModel, "placeInfo" | "outcome">,
  {
    surroundingTasks,
    schedulerData,
    headquartersTravel,
  }: {
    surroundingTasks?: {
      [K in keyof ReturnType<typeof getSurroundingTasks>]:
        | ReturnType<typeof getSurroundingTasks>[K]
        | null;
    };
    schedulerData: SchedulerData;
    headquartersTravel: TaskModel["travelDuration"];
  }
) {
  const travelInfo: {
    -readonly [K in keyof Pick<
      TaskModel,
      "travelDuration" | "returnDuration"
    >]: TaskModel[K];
  } = {
    travelDuration:
      surroundingTasks?.firstTaskBefore === null ? undefined : null,
    returnDuration:
      surroundingTasks?.firstTaskAfter === null ? undefined : null,
  };

  if (outcome === OutcomeName.NOTEXECUTED || !surroundingTasks)
    return travelInfo;

  const { firstTaskBefore, firstTaskAfter } = surroundingTasks;

  if (firstTaskBefore) {
    const travel = await calculateDistance(
      placeInfo,
      firstTaskBefore.placeInfo,
      schedulerData
    );

    if (travel?.as("milliseconds")) travelInfo.travelDuration = travel.toISO();
  } else if (firstTaskBefore === undefined && headquartersTravel) {
    travelInfo.travelDuration = headquartersTravel;
  }

  if (firstTaskAfter === undefined && headquartersTravel)
    travelInfo.returnDuration = headquartersTravel;

  return travelInfo;
}

/**
 * Retrieves the surrounding tasks of a reference task.
 * @param param0 The reference task
 * @param tasksToCheck The tasks to check; usually the non-not executed tasks (with location) of an assignee in the same day of start and end of the reference task
 * @returns The first task before and the first task after of the reference task
 */
export function getSurroundingTasks(
  {
    id,
    interval: { start, end },
  }: { interval: Interval } & Partial<Pick<TaskModel, "id">>,
  tasksToCheck: TaskModel[]
) {
  const interval = toInterval(
    start.plus({ milliseconds: 1 }),
    end.minus({ milliseconds: 1 })
  );

  tasksToCheck.sort((aTask, bTask) => sortDates(bTask, aTask));

  const firstTaskBefore = tasksToCheck.find(
    ({ id: taskID, endDateTime }) =>
      taskID !== id && interval.isAfter(toDateTime(endDateTime))
  );

  tasksToCheck.sort((aTask, bTask) => sortDates(aTask, bTask, "startDateTime"));

  const firstTaskAfter = tasksToCheck.find(
    ({ id: taskID, startDateTime }) =>
      taskID !== id &&
      interval.isBefore(toDateTime(startDateTime).plus({ milliseconds: 1 }))
  );

  return {
    firstTaskBefore,
    firstTaskAfter,
  };
}

/**
 * Transforms a task to a task place label.
 * @param task The task to transform to a place label
 * @param options The options used for the transformation
 * @returns The transformed place label
 */
export function toTaskPlace(
  task: TaskModel,
  options: {
    t: TranslationFunction<"tasks" | "enumModalities" | "enumLocations">;
    isMeetingViewer: boolean;
    resource?: UserModel;
  }
) {
  return isTaskWithoutLocation(task.modality)
    ? options.t(`enumModalities:${task.modality}`)
    : task.placeInfo
    ? options.isMeetingViewer ||
      task.placeInfo.geohash !== options.resource?.headquarters?.geohash
      ? labelFromPlace(task.placeInfo, {
          short: true,
          aggregator: options.isMeetingViewer ? "region" : undefined,
        })
      : options.t(`enumLocations:${LocationName.HEADQUARTERS}`)
    : options.t("tasks:addAddress");
}

/**
 * Calculates the distance "as the crow flies" between two geohashes.
 * @param firstGeohash The start geohash
 * @param secondGeohash The end geohash
 * @returns The distance in KM
 */
export function geohashDistance(firstGeohash: string, secondGeohash: string) {
  return haversine(
    decodeGeohash(firstGeohash)!,
    decodeGeohash(secondGeohash)!,
    {
      format: "[lon,lat]",
    }
  );
}

/**
 * Transforms an AWS Amplify place to its corresponding geo place.
 * @param amplifyPlace The AWS Amplify place to transform
 * @returns The geo place corresponding to the Amplify place
 */
function amplifyPlaceToGeoPlace(
  amplifyPlace: AmplifyPlace
): GeoPlace | undefined {
  const {
    geometry,
    addressNumber,
    country,
    label,
    municipality,
    neighborhood,
    postalCode,
    region,
    street,
    subRegion,
  } = amplifyPlace;

  return geometry
    ? {
        geometry,
        addressNumber,
        country,
        label,
        municipality,
        neighborhood,
        postalCode,
        region,
        street,
        subRegion,
      }
    : undefined;
}
