import { RestAPI as API } from "@aws-amplify/api-rest";
import { getAuthToken } from "./authenticate";
import { log } from "./log";

type Body = Record<string, any>;
type Headers = Record<string, any>;
type Response = Record<any, any>;

/**
 * Executes a post operation on a REST API.
 * @param api The REST API name
 * @param path The path to post on, without the first slash
 * @param body The body of the request
 * @param header Headers for the request. Content type and authorization are handled automatically
 * @returns The response to the post operation
 */
export async function post(
  api: string,
  path: string,
  body: Body,
  header?: Headers
) {
  const headers = processHeaders(header);

  if (!headers) return;

  try {
    return (await API.post(api, `/${path}`, {
      body,
      headers,
    })) as Response;
  } catch (error) {
    log(error);
  }
}

/**
 * Composes additional headers with base headers.
 * @param headers Additional headers for the request
 * @returns The header object with base headers
 */
function processHeaders(headers?: Headers) {
  const Authorization = getAuthToken();

  if (!Authorization) return;

  return {
    "Content-Type": "application/json",
    Authorization,
    ...headers,
  };
}
