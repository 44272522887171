import { DateTime, Duration, DurationObjectUnits, Interval } from "luxon";

/**
 * Gets now as a date time object.
 * @returns Now as date time object
 */
export function getNow() {
  return DateTime.now();
}

/**
 * Transforms a date time to a date time object.
 * @param dateTime The date time
 * @returns The date time object
 */
export function toDateTime<
  T extends
    | string
    | Date
    | Parameters<typeof DateTime["fromObject"]>[0]
    | undefined
>(dateTime?: T) {
  return (
    dateTime
      ? isDate(dateTime)
        ? DateTime.fromJSDate(dateTime)
        : typeof dateTime === "string"
        ? DateTime.fromISO(dateTime)
        : DateTime.fromObject(dateTime)
      : undefined
  ) as undefined extends T ? DateTime | undefined : DateTime;
}

/**
 * Creates a duration object from a duration.
 * @param duration The duration
 * @returns The duration object
 */
export function toDuration<
  T extends
    | string
    | number
    | Parameters<typeof Duration["fromObject"]>[0]
    | undefined
>(duration?: T) {
  return (
    duration || duration === 0
      ? typeof duration === "string"
        ? Duration.fromISO(duration)
        : typeof duration === "number"
        ? Duration.fromMillis(duration)
        : Duration.fromObject(duration)
      : undefined
  ) as undefined extends T ? Duration | undefined : Duration;
}

/**
 * Creates an interval from a start and an end.
 * @param start The start date
 * @param end The end date
 * @returns The created interval
 */
export function toInterval<
  T extends ReturnType<typeof toDateTime> | Parameters<typeof toDateTime>[0]
>(start?: T, end?: T) {
  return (
    start && end
      ? Interval.fromDateTimes(
          isDateTime(start) ? start : toDateTime(start!),
          isDateTime(end) ? end : toDateTime(end!)
        )
      : undefined
  ) as undefined extends T ? Interval | undefined : Interval;
}

/**
 * Transforms a generic duration object into its storeable ISO representation.
 * @param object Duration object
 * @returns The appropriate duration string to store
 */
export function toISODurationFromObject(object: DurationObjectUnits) {
  return toDuration(object)?.toISO();
}

/**
 * Transforms a timespan into its storeable ISO representation.
 * @param timespan Timespan
 * @returns The appropriate duration string to store
 */
export function toISODurationFromTimespan(timespan: string) {
  return toDurationFromTimespan(timespan).toISO();
}

/**
 * Evaluates start and end time into a duration useful for timespan components.
 * @param startDateTime Start ISO dateTime
 * @param endDateTime End ISO dateTime
 * @returns The appropriate duration string for the timespan component
 */
export function toTimespanFromStartEnd(
  startDateTime: string,
  endDateTime: string
) {
  return toTimespanFromDuration(
    toDurationFromStartEnd(startDateTime, endDateTime)
  );
}

/**
 * Subtracts a timespan from an end dateTime.
 * @param endDateTime End ISO dateTime
 * @param timespan Timespan string from component
 * @returns The storeable ISO dateTime string
 */
export function toStartFromEndTimespan(endDateTime: string, timespan: string) {
  return toDateTime(endDateTime)
    .minus(toDurationFromTimespan(timespan))
    .toISO();
}

/**
 * Adds a timespan to a start dateTime.
 * @param startDateTime Start ISO dateTime
 * @param timespan Timespan string from component
 * @returns The storeable ISO dateTime string
 */
export function toEndFromStartTimespan(
  startDateTime: string,
  timespan: string
) {
  return toDateTime(startDateTime)
    .plus(toDurationFromTimespan(timespan))
    .toISO();
}

/**
 * Transforms a component timespan into a duration object useful for processing.
 * @param timespan The timespan as received from a timespan component
 * @returns The corresponding duration object
 */
export function toDurationFromTimespan(timespan: string | Date) {
  return Duration.fromISOTime(
    toDateTime(timespan)
      .set({ second: 0, millisecond: 0 })
      .toISOTime({ includeOffset: false })
  );
}

/**
 * Transforms an ISO duration into a time useful for visualization.
 * @param isoDuration The ISO duration as received from the database
 * @returns The corresponding time
 */
export function toTimeFromISODuration(isoDuration: string) {
  return toDuration(isoDuration).toISOTime({ suppressSeconds: true });
}

/**
 * Transforms a component timespan into a time useful for visualization.
 * @param timespan The timespan as received from a timespan component
 * @returns The corresponding time
 */
export function toTimeFromTimespan(timespan: string) {
  return toDurationFromTimespan(timespan).toISOTime({ suppressSeconds: true });
}

/**
 * Transforms an ISO duration into its viewable timespan representation.
 * @param isoDuration ISO duration
 * @returns The appropriate timespan string to view
 */
export function toTimespanFromISODuration(isoDuration: string) {
  return toTimespanFromDuration(toDuration(isoDuration));
}

/**
 * Transforms a duration object into a timespan useful for components.
 * @param duration The duration as received from processing
 * @returns The corresponding timespan string
 */
export function toTimespanFromDuration(duration: Duration) {
  return toDateTime(
    `${getNow().toISODate()}${duration.toISOTime({
      includePrefix: true,
    })}`
  )
    .setZone(getNow().zone)
    .toISO();
}

/**
 * Evaluates start and end time into a duration useful for processing.
 * @param startDateTime Start ISO dateTime
 * @param endDateTime End ISO dateTime
 * @returns The appropriate duration object for processing
 */
function toDurationFromStartEnd(startDateTime: string, endDateTime: string) {
  return toDateTime(endDateTime).diff(toDateTime(startDateTime));
}

/**
 * Checks if a value is a JS date object.
 * @param dateTime The date time value to check
 * @returns True if is a JS date object
 */
function isDate(dateTime: unknown): dateTime is Date {
  return typeof (dateTime as Date).getMonth === "function";
}

/**
 * Checks if a value is a date time object.
 * @param dateTime The date time value to check
 * @returns True if is a date time object
 */
function isDateTime(
  dateTime: unknown
): dateTime is ReturnType<typeof toDateTime> {
  return typeof (dateTime as DateTime).resolvedLocaleOptions === "function";
}
