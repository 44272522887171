import define from "helpers/define";
import {
  FinancingType,
  Gender,
  HouseHunter,
  Identity,
  Nationality,
  PlaceType,
  RealEstate,
  Source,
  Task,
} from "models";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import { getPayments } from "utils/query";
import { toDuration } from "utils/time";
import Choice from "view/inputs/Choice";
import Money from "view/inputs/Money";
import Percentage from "view/inputs/Percentage";
import TimeDuration from "view/inputs/TimeDuration";

export default define<Identity>(() => {
  const { t } = useTranslation([
    "identities",
    "enumRealEstateIndexes",
    "enumRealEstatePrices",
    "enumSavings",
    "enumLoanToValues",
  ]);

  return {
    callCenterId: { view: "id", label: t("identities:contactID") },
    houseHunter: {
      view: "choice",
      label: t("identities:houseHunter"),
      props: {
        options: HouseHunter,
      } as ComponentProps<typeof Choice>,
    },
    source: {
      view: "choice",
      label: t("identities:source"),
      props: {
        options: Source,
      } as ComponentProps<typeof Choice>,
    },
    surname: {
      view: "name",
      label: t("identities:surname"),
    },
    name: {
      view: "name",
      label: t("identities:name"),
    },
    nationality: {
      view: "choice",
      label: t("identities:nationality"),
      props: {
        options: Nationality,
      } as ComponentProps<typeof Choice>,
    },
    gender: {
      view: "choice",
      label: t("identities:gender"),
      props: {
        options: Gender,
      } as ComponentProps<typeof Choice>,
    },
    mobilePhone: {
      view: "phone",
      label: t("identities:mobilePhone"),
    },
    email: {
      view: "mail",
      label: t("identities:email"),
    },
    placeType: {
      view: "choice",
      label: t("identities:placeType"),
      props: {
        options: PlaceType,
      } as ComponentProps<typeof Choice>,
    },
    place: {
      view: "exclude",
    },
    placeInfo: {
      view: "place",
      label: t("identities:place"),
    },
    financingType: {
      view: "choice",
      label: t("identities:financingType"),
      props: {
        options: FinancingType,
      } as ComponentProps<typeof Choice>,
    },
    financing: {
      view: "money",
      label: t("identities:financing"),
    },
    financingDuration: {
      view: "duration",
      label: t("identities:financingDuration"),
      props: {
        unit: "months",
        min: toDuration({ months: 36 }),
        max: toDuration({ months: 360 }),
      } as ComponentProps<typeof TimeDuration>,
    },
    buyerPersona: {
      view: "section",
      label: t("identities:buyerPersona"),
      subtype: {
        realEstate: {
          view: "choice",
          label: t("identities:realEstate"),
          props: {
            options: RealEstate,
            getHelperText: (option) =>
              option && t(`enumRealEstateIndexes:${option}`),
          } as ComponentProps<typeof Choice>,
        },
        realEstatePrice: {
          view: "money",
          label: t("identities:realEstatePrice"),
          props: {
            getHelperText: (value) =>
              (value || value === 0) &&
              (value > 200000
                ? t("enumRealEstatePrices:MERIT")
                : value > 150000
                ? t("enumRealEstatePrices:ELEGANT")
                : value > 125000
                ? t("enumRealEstatePrices:BOURGEOIS")
                : value > 100000
                ? t("enumRealEstatePrices:ECONOMIC")
                : t("enumRealEstatePrices:POPULAR")),
          } as ComponentProps<typeof Money>,
        },
        savings: {
          view: "money",
          label: t("identities:savings"),
          props: {
            getHelperText: (savings) =>
              (savings || savings === 0) &&
              (savings > 40000
                ? t("enumSavings:GREAT")
                : savings > 30000
                ? t("enumSavings:GOOD")
                : savings > 20000
                ? t("enumSavings:DISCRETE")
                : savings > 10000
                ? t("enumSavings:SUFFICIENT")
                : savings > 5000
                ? t("enumSavings:BAD")
                : t("enumSavings:ZERO")),
          } as ComponentProps<typeof Money>,
        },
        loanToValue: {
          view: "percentage",
          label: t("identities:loanToValue"),
          props: {
            min: 0,
            max: 200,
            getHelperText: (loanToValue) =>
              (loanToValue || loanToValue === 0) &&
              (loanToValue > 100
                ? t("enumLoanToValues:GREATERTHANHUNDRED")
                : loanToValue > 95
                ? t("enumLoanToValues:NINETYSIXTOHUNDRED")
                : loanToValue > 94
                ? t("enumLoanToValues:NINETYFIVE")
                : loanToValue > 80
                ? t("enumLoanToValues:EIGHTYONETONINETYFOUR")
                : loanToValue > 79
                ? t("enumLoanToValues:EIGHTY")
                : t("enumLoanToValues:UNDEREIGHTY")),
          } as ComponentProps<typeof Percentage>,
        },
      },
    },
    path: {
      view: "exclude",
    },
    price: {
      view: "money",
      label: t("identities:price"),
    },
    toPay: {
      view: "toggle",
      label: t("identities:toPay"),
    },
    paymentIdentitiesId: {
      view: "choice",
      label: t("identities:payment"),
      props: {
        options: async () => (await getPayments()) as unknown,
      } as ComponentProps<typeof Choice>,
    },
    tasks: {
      view: "quicklist",
      label: t("identities:tasks"),
      relationship: Task,
    },
    audits: {
      view: "exclude",
    },
  };
});
