import {
  MobileStepper as MuiMobileStepper,
  MobileStepperProps as MuiMobileStepperProps,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  Stepper as MuiStepper,
  StepperProps as MuiStepperProps,
} from "@mui/material";
import { ComponentProps, ReactNode, useState } from "react";
import { useTranslation } from "utils/localize";
import { getLargeScreen } from "utils/show";
import Button from "../inputs/Button";
import asRow from "../LAYOUT/asRow";
import asSpace from "../LAYOUT/asSpace";
import Title from "./Title";

export type ContentProps = {
  Buttons: React.FC<ButtonsProps>;
  next: (data?: StepData, avoidCompletion?: boolean) => void;
  complete: (data?: StepData) => void;
  data: Partial<StepData>;
  stepperData: StepperData;
};

export type Steps<I extends string = string> = {
  id: I;
  label?: ReactNode;
  Content?: React.FC<ContentProps>;
}[];

type ButtonsProps = {
  next?: ComponentProps<typeof Button>;
  back?: ComponentProps<typeof Button>;
};

type StepData = Record<string | number, unknown>;

type StepperData = Record<Steps[number]["id"], StepData>;

export default function Stepper(
  props: {
    title?: ReactNode;
    subtitle?: ReactNode;
    steps?: Steps;
    mobileVariant?: MuiMobileStepperProps["variant"];
    onComplete?: (data: any) => void | Promise<void>;
  } & Omit<
    MuiStepperProps & MuiMobileStepperProps,
    | "children"
    | "steps"
    | "nextButton"
    | "backButton"
    | "activeStep"
    | "title"
    | "variant"
  >
) {
  const { steps, title, subtitle, mobileVariant, onComplete, ...stepperProps } =
    props;

  const { t } = useTranslation("view");

  const [stepperState, setStepperState] = useState({
    activeStep: 0,
    data: {} as StepperData,
  });

  const largeScreen = getLargeScreen();

  async function goToNext(...args: Parameters<ContentProps["next"]>) {
    const newData = {
      ...stepperState.data,
    };

    if (steps) {
      if (args[0]) newData[steps[stepperState.activeStep].id] = args[0];

      if (stepperState.activeStep !== steps.length - 1 || args[1]) {
        setStepperState({
          activeStep: stepperState.activeStep + 1,
          data: newData,
        });
      } else {
        await onComplete?.(newData);
      }
    } else {
      await onComplete?.(newData);
    }
  }

  const BackButton = (backProps: NonNullable<ButtonsProps["back"]>) => (
    <Button
      back
      disabled={stepperState.activeStep === 0}
      onClick={(complete) => {
        complete();
        setStepperState({
          activeStep: stepperState.activeStep - 1,
          data: stepperState.data,
        });
      }}
      {...backProps}
    />
  );

  const NextButton = (nextProps: NonNullable<ButtonsProps["next"]>) => (
    <Button
      {...nextProps}
      onClick={
        nextProps.onClick ??
        (nextProps.type === "submit"
          ? undefined
          : (complete) => {
              complete();
              goToNext();
            })
      }
    >
      {nextProps.children ??
        (steps && stepperState.activeStep !== steps.length - 1
          ? t("view:next")
          : t("view:complete"))}
    </Button>
  );

  const RowButtons = (rowProps: ButtonsProps) =>
    asRow(
      [<BackButton {...rowProps.back} />, <NextButton {...rowProps.next} />],
      {
        disableResponsive: true,
      }
    );

  const ActiveContent = (contentProps: { Buttons: React.FC<ButtonsProps> }) => {
    const activePage = steps?.[stepperState.activeStep];

    return activePage?.Content ? (
      <activePage.Content
        Buttons={contentProps.Buttons}
        next={goToNext}
        complete={async (data) => {
          const newData = {
            ...stepperState.data,
          };

          if (steps && data) newData[steps[stepperState.activeStep].id] = data;

          await onComplete?.(newData);
        }}
        data={
          (steps && stepperState.data[steps[stepperState.activeStep].id]) ?? {}
        }
        stepperData={stepperState.data}
      />
    ) : (
      <contentProps.Buttons />
    );
  };

  return (
    <>
      <Title
        subtitle={subtitle}
        details={!largeScreen && steps?.[stepperState.activeStep].label}
      >
        {title}
      </Title>
      {largeScreen ? (
        <>
          <MuiStepper {...stepperProps} activeStep={stepperState.activeStep}>
            {steps?.map(({ label }, index) => (
              <MuiStep key={index}>
                <MuiStepLabel>{label}</MuiStepLabel>
              </MuiStep>
            ))}
          </MuiStepper>
          {asSpace(
            <ActiveContent
              Buttons={(buttonsProps) => <RowButtons {...buttonsProps} />}
            />,
            {
              mt: 3,
            }
          )}
        </>
      ) : (
        <ActiveContent
          Buttons={({ back, next }) => (
            <MuiMobileStepper
              {...stepperProps}
              variant={mobileVariant}
              style={{ position: "relative", width: "100%" }}
              steps={steps?.length ?? 0}
              activeStep={stepperState.activeStep}
              backButton={<BackButton {...back} />}
              nextButton={<NextButton {...next} />}
            />
          )}
        />
      )}
    </>
  );
}
