import {
  Activity,
  Identity,
  LocationName,
  ModalityName,
  OutcomeMotivation,
  OutcomeName,
  Place,
  Task,
} from "models";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import { getNow, toDateTime } from "utils/time";
import Form from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import TaskEffective from "../elements/TaskEffective";
import { OutcomeProcedureFormIds } from "../OutcomeProcedure";
import { isPlaceVisible } from "../TaskDetails";

export default function NextTask(props: {
  data: Record<OutcomeProcedureFormIds, any>;
  formProps?: ComponentProps<typeof Form>;
  task?: Task;
  identity?: Identity;
  activity?: Activity;
  nextActivity?: Activity;
  outcome?: OutcomeName;
  outcomeMotivation?: OutcomeMotivation["name"];
}) {
  const { t } = useTranslation("tasks");

  if (props.nextActivity) {
    const showPlanningInfo = props.nextActivity.manualScheduling;

    const isSingleModality =
      Object.keys(props.nextActivity.modalities).length === 1;

    return (
      <Form
        title={props.nextActivity.name}
        defaultValues={{
          [OutcomeProcedureFormIds.NextTaskModality]:
            (props.data[
              OutcomeProcedureFormIds.NextTaskModality
            ] as ModalityName) ??
            (isSingleModality ? props.nextActivity.modalities[0].name : null),
          [OutcomeProcedureFormIds.NextTaskLocation]:
            (props.data[OutcomeProcedureFormIds.NextTaskLocation] as string) ??
            null,
          [OutcomeProcedureFormIds.NextTaskStartDateTime]: props.data[
            OutcomeProcedureFormIds.NextTaskStartDateTime
          ] as string,
          [OutcomeProcedureFormIds.NextTaskEndDateTime]: props.data[
            OutcomeProcedureFormIds.NextTaskEndDateTime
          ] as string,
          [OutcomeProcedureFormIds.NextTaskPlaceValue]: props.data[
            OutcomeProcedureFormIds.NextTaskPlaceValue
          ] as string,
        }}
        {...props.formProps}
        onSubmit={(data, complete, reset) => {
          if (
            showPlanningInfo &&
            toDateTime(
              data[OutcomeProcedureFormIds.NextTaskStartDateTime] as string
            ) >
              toDateTime(
                data[OutcomeProcedureFormIds.NextTaskEndDateTime] as string
              )
          ) {
            complete(t("tasks:endDateTimeIsBeforeStartTime"), "error");
          } else {
            props.formProps?.onSubmit?.(data, complete, reset as any);
          }
        }}
      >
        {(form, { getValueRender, setValue }) => {
          const modality = getValueRender<ModalityName>(
            OutcomeProcedureFormIds.NextTaskModality
          );

          const location = getValueRender<LocationName>(
            OutcomeProcedureFormIds.NextTaskLocation
          );

          return asColumn(
            TaskEffective(
              {
                NextTaskModality: isSingleModality
                  ? undefined
                  : OutcomeProcedureFormIds.NextTaskModality,
                NextTaskLocation: modality
                  ? OutcomeProcedureFormIds.NextTaskLocation
                  : undefined,
                NextStartDateTime: showPlanningInfo
                  ? OutcomeProcedureFormIds.NextTaskStartDateTime
                  : undefined,
                NextEndDateTime: showPlanningInfo
                  ? OutcomeProcedureFormIds.NextTaskEndDateTime
                  : undefined,
                NextTaskPlace: isPlaceVisible(location)
                  ? OutcomeProcedureFormIds.NextTaskPlaceValue
                  : undefined,
              },
              form,
              {
                NextTaskModality: {
                  change: (options) => {
                    const modalities = {} as Record<ModalityName, ModalityName>;

                    props.nextActivity?.modalities.forEach(
                      ({ name }) =>
                        (modalities[name] =
                          options[name as keyof typeof options])
                    );

                    return modalities;
                  },
                },
                NextTaskLocation: {
                  key: modality,
                  change: (options) => {
                    const locations = {} as Record<LocationName, LocationName>;

                    props.nextActivity?.modalities
                      .find(({ name }) => name === modality)!
                      .locations.forEach(
                        ({ name }) =>
                          (locations[name] =
                            options[name as keyof typeof options])
                      );

                    const locationKeys = Object.keys(locations);

                    if (locationKeys.length === 1) {
                      setValue(
                        OutcomeProcedureFormIds.NextTaskLocation,
                        locationKeys[0]
                      );

                      return {};
                    } else {
                      return locations;
                    }
                  },
                  onChange:
                    props.identity &&
                    ((value) => {
                      if (value === LocationName.IDENTITYHOME) {
                        setValue(OutcomeProcedureFormIds.NextTaskPlaceValue, {
                          ...props.identity!.placeInfo,
                        } as Place);
                      }
                    }),
                },
                NextStartDateTime: {
                  minDateTime: getNow(),
                },
                NextEndDateTime: {
                  minDateTime: getNow(),
                },
              }
            )
          );
        }}
      </Form>
    );
  } else {
    return null;
  }
}
