import {
  Activity,
  ModalityName,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
  Task,
} from "models";
import { ComponentProps, useState } from "react";
import { toInsideSentence } from "utils/format";
import { useTranslation } from "utils/localize";
import { getActivity } from "utils/query";
import { toDateTime } from "utils/time";
import Form from "view/inputs/Form";
import Period from "view/inputs/Period";
import asColumn from "view/LAYOUT/asColumn";
import TaskEffective from "../elements/TaskEffective";
import { OutcomeProcedureFormIds } from "../OutcomeProcedure";

export default function TaskModality(props: {
  data: Record<OutcomeProcedureFormIds, any>;
  formProps?: ComponentProps<typeof Form>;
  task?: Task;
  activity?: Activity;
  onTaskPostponed?: () => void;
}) {
  const { t } = useTranslation("tasks");

  const [nextActivity, setNextActivity] = useState<Activity>();

  if (props.activity) {
    const activityModalities = props.activity.modalities;

    const showPlanningInfo = nextActivity?.manualScheduling;

    const notExecutedMotivations = props.activity.outcomes?.find(
      ({ name }) => name === OutcomeName.NOTEXECUTED
    )?.outcomeMotivations;

    return (
      <Form
        {...props.formProps}
        defaultValues={{
          [OutcomeProcedureFormIds.Modality]:
            (props.data[OutcomeProcedureFormIds.Modality] as string) ?? null,
          [OutcomeProcedureFormIds.OutcomeMotivationValue]:
            (props.data[
              OutcomeProcedureFormIds.OutcomeMotivationValue
            ] as OutcomeMotivation["name"]) ?? null,
          [OutcomeProcedureFormIds.Subject]:
            (props.data[OutcomeProcedureFormIds.Subject] as string) ?? null,
          [OutcomeProcedureFormIds.ModalityChangeMotivation]: props.data[
            OutcomeProcedureFormIds.ModalityChangeMotivation
          ] as string,
          [OutcomeProcedureFormIds.NextTaskStartDateTime]: props.data[
            OutcomeProcedureFormIds.NextTaskStartDateTime
          ] as string,
          [OutcomeProcedureFormIds.NextTaskEndDateTime]: props.data[
            OutcomeProcedureFormIds.NextTaskEndDateTime
          ] as string,
        }}
        onSubmit={(data, complete, reset) => {
          if (
            (data[OutcomeProcedureFormIds.Modality] as OutcomeName) ===
              OutcomeName.NOTEXECUTED &&
            showPlanningInfo &&
            toDateTime(data[OutcomeProcedureFormIds.NextTaskStartDateTime]) >
              toDateTime(data[OutcomeProcedureFormIds.NextTaskEndDateTime])
          ) {
            complete(t("tasks:endDateTimeIsBeforeStartTime"), "error");
          } else {
            props.formProps?.onSubmit?.(data, complete, reset as any);
          }
        }}
      >
        {(form, { getValueRender }) => {
          const modality = getValueRender<
            ModalityName | OutcomeName.NOTEXECUTED
          >(OutcomeProcedureFormIds.Modality);

          const outcomeMotivation = getValueRender<OutcomeMotivation["name"]>(
            OutcomeProcedureFormIds.OutcomeMotivationValue
          );

          const showMotivation = modality && modality !== props.task?.modality;

          const isNotExecuted = modality === OutcomeName.NOTEXECUTED;

          const showSubject =
            isNotExecuted &&
            (outcomeMotivation ===
              OutcomeMotivationCodename.LACKOFREPLYFROMCOLLEAGUE ||
              outcomeMotivation ===
                OutcomeMotivationCodename.LACKOFREPLYFROMEXTERNAL ||
              outcomeMotivation === OutcomeMotivationCodename.LACKOFOUTCOME);

          return asColumn([
            ...TaskEffective(
              {
                Modality: OutcomeProcedureFormIds.Modality,
                OutcomeMotivation: isNotExecuted
                  ? OutcomeProcedureFormIds.OutcomeMotivationValue
                  : undefined,
                Motivation: showMotivation
                  ? OutcomeProcedureFormIds.ModalityChangeMotivation
                  : undefined,
                Resource: showSubject
                  ? OutcomeProcedureFormIds.Subject
                  : undefined,
              },
              form,
              {
                Modality: {
                  change: (options) => {
                    const modalities = {} as Record<ModalityName, ModalityName>;

                    activityModalities.forEach(
                      ({ name }) =>
                        (modalities[name] =
                          options[name as keyof typeof options])
                    );

                    return modalities;
                  },
                  getLabel: (option: any) =>
                    activityModalities.length === 1
                      ? t("tasks:executed")
                      : option,
                },
                OutcomeMotivation: {
                  change: () => {
                    const types = {} as Record<
                      OutcomeMotivation["name"],
                      OutcomeMotivation["name"]
                    >;

                    notExecutedMotivations?.forEach(
                      ({ codename, name }) => (types[codename ?? name] = name)
                    );

                    return {
                      ...types,
                      [OutcomeMotivationCodename.OTHER]:
                        OutcomeMotivationCodename.OTHER,
                    };
                  },
                  onChange: async (motivation) =>
                    setNextActivity(
                      await getActivity(
                        notExecutedMotivations?.find(
                          ({ codename, name }) =>
                            codename === motivation || name === motivation
                        )?.nextActivityIds[0]
                      )
                    ),
                },
                Motivation: isNotExecuted
                  ? undefined
                  : {
                      children: t("tasks:modalityChangeMotivation"),
                    },
                Resource: {
                  children:
                    outcomeMotivation ===
                    OutcomeMotivationCodename.LACKOFREPLYFROMCOLLEAGUE
                      ? t("tasks:colleague")
                      : outcomeMotivation ===
                        OutcomeMotivationCodename.LACKOFREPLYFROMEXTERNAL
                      ? t("tasks:external")
                      : outcomeMotivation ===
                        OutcomeMotivationCodename.LACKOFOUTCOME
                      ? t("tasks:taskOutcome")
                      : undefined,
                },
              }
            ),
            isNotExecuted && (
              <Period
                form={form}
                timePoints={
                  showPlanningInfo
                    ? [
                        {
                          type: "dateAndTime",
                          id: OutcomeProcedureFormIds.NextTaskStartDateTime,
                          children: t("tasks:startDateTime"),
                        },
                        {
                          type: "dateAndTime",
                          id: OutcomeProcedureFormIds.NextTaskEndDateTime,
                          children: t("tasks:endDateTime"),
                        },
                      ]
                    : undefined
                }
              >
                {nextActivity?.id === props.activity?.id
                  ? t("tasks:reschedule")
                  : `${t("tasks:schedule")}${
                      nextActivity?.name
                        ? ` ${toInsideSentence(nextActivity.name)}`
                        : ""
                    }`}
              </Period>
            ),
            // isNotExecuted && nextActivity && props.task && (
            //   <PlanningCalendar
            //     taskData={{
            //       id: props.task.id,
            //       activityTasksId: props.activity!.id,
            //       identityTasksId: props.task.identityTasksId,
            //       modality: props.task.modality,
            //       location: props.task.location,
            //       placeInfo: props.task.placeInfo,
            //       taskChildrenId: props.task.taskChildrenId,
            //     }}
            //     activity={props.activity}
            //     assignee={props.activity!.role}
            //   />
            // ),
          ]);
        }}
      </Form>
    );
  } else {
    return null;
  }
}
