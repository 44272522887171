import {
  ActivityCodename,
  LocationName,
  ModalityName,
  OutcomeMotivationCodename,
  OutcomeName,
  Review,
} from "models";
import { ComponentProps } from "react";
import { displayPerson } from "utils/format";
import { useTranslation } from "utils/localize";
import { getUsers } from "utils/query";
import Choice from "view/inputs/Choice";
import DateAndTime from "view/inputs/DateAndTime";
import { createFormElement } from "view/inputs/Form";
import Free from "view/inputs/Free";
import Place from "view/inputs/Place";
import Time from "view/inputs/Time";
import Toggle from "view/inputs/Toggle";

export default createFormElement(() => {
  const { t } = useTranslation(["tasks", "enumOutcomes"]);

  return {
    StartTime: (props: typeof Time) => (
      <Time {...(props as any)}>{t("tasks:startTime")}</Time>
    ),
    StartDateTime: (props: typeof DateAndTime) => (
      <DateAndTime {...(props as any)}>{t("tasks:startDateTime")}</DateAndTime>
    ),
    EndTime: (props: typeof Time) => (
      <Time {...(props as any)}>{t("tasks:endTime")}</Time>
    ),
    EndDateTime: (props: typeof DateAndTime) => (
      <DateAndTime {...(props as any)}>{t("tasks:endDateTime")}</DateAndTime>
    ),
    Modality: (props: typeof Choice) => {
      return (
        <Choice
          options={ModalityName}
          {...(props as any)}
          change={(options: typeof ModalityName) => {
            const processedOptions =
              (
                props as unknown as ComponentProps<typeof props> | undefined
              )?.change?.(options) ?? options;

            const { NOTEXECUTED } = OutcomeName;

            return {
              ...processedOptions,
              NOTEXECUTED,
            };
          }}
          getLabel={(option: any) =>
            option === OutcomeName.NOTEXECUTED
              ? t(`enumOutcomes:${option}`)
              : (
                  props as unknown as ComponentProps<typeof props> | undefined
                )?.getLabel?.(option) ?? option
          }
        >
          {t("tasks:modality")}
        </Choice>
      );
    },
    Location: (props: typeof Choice) => (
      <Choice options={LocationName} {...(props as any)}>
        {t("tasks:location")}
      </Choice>
    ),
    IsSameLocation: (props: typeof Toggle) => (
      <Toggle
        boundToAbove
        children={t("tasks:asPlanned")}
        {...(props as any)}
      />
    ),
    Review: (props: typeof Choice) => (
      <Choice options={Review} {...(props as any)}>
        {t("tasks:review")}
      </Choice>
    ),
    ReviewMotivation: (props: typeof Free) => (
      <Free multiline {...(props as any)}>
        {t("tasks:motivation")}
      </Free>
    ),
    Outcome: (props: typeof Choice) => (
      <Choice options={OutcomeName} {...(props as any)}>
        {t("tasks:outcome")}
      </Choice>
    ),
    OutcomeMotivation: (props: typeof Choice) => (
      <Choice options={OutcomeMotivationCodename} {...(props as any)}>
        {t("tasks:outcomeMotivation")}
      </Choice>
    ),
    FreeMotivation: (props: typeof Free) => (
      <Free multiline {...(props as any)}>
        {t("tasks:description")}
      </Free>
    ),
    Resource: (props: typeof Free) => (
      <Free {...(props as any)}>{t("tasks:subject")}</Free>
    ),
    Motivation: (props: typeof Free) => (
      <Free multiline {...(props as any)}>
        {t("tasks:motivation")}
      </Free>
    ),
    NextStartDateTime: (props: typeof DateAndTime) => (
      <DateAndTime defaultValue={null} {...(props as any)}>
        {t("tasks:startDateTime")}
      </DateAndTime>
    ),
    NextEndDateTime: (props: typeof DateAndTime) => (
      <DateAndTime defaultValue={null} {...(props as any)}>
        {t("tasks:endDateTime")}
      </DateAndTime>
    ),
    Assignee: (props: typeof Choice) => (
      <Choice
        options={async () => getUsers()}
        getLabel={displayPerson}
        identifier="username"
        {...(props as any)}
      >
        {t("tasks:assignee")}
      </Choice>
    ),
    Activity: (props: typeof Choice) => (
      <Choice options={ActivityCodename} {...(props as any)}>
        {t("tasks:nextActivity")}
      </Choice>
    ),
    NextTaskModality: (props: typeof Choice) => (
      <Choice options={ModalityName} {...(props as any)}>
        {t("tasks:modality")}
      </Choice>
    ),
    NextTaskLocation: (props: typeof Choice) => (
      <Choice options={LocationName} {...(props as any)}>
        {t("tasks:location")}
      </Choice>
    ),
    Place: (props: typeof Place) => (
      <Place {...(props as any)}>{t("tasks:address")}</Place>
    ),
    NextTaskPlace: (props: typeof Place) => (
      <Place {...(props as any)}>{t("tasks:address")}</Place>
    ),
  };
});
