import { CognitoRoles } from "helpers/rbac";
import { UserRole } from "models";
import { post } from "./call";

const apiName = "AdminQueries";

type AdminQueriesResponse = Promise<{ message: string } | undefined>;

/**
 * Adds a user to a role/AWS Cognito group.
 * @param username The username of the user
 * @param role The role to add
 * @returns The new user
 */
export async function addToRole(username: string, role: UserRole) {
  return post(apiName, "addUserToGroup", {
    username,
    groupname: CognitoRoles[role],
  }) as AdminQueriesResponse;
}

/**
 * Removes a role/AWS Cognito group from a user.
 * @param username The username of the user
 * @param role The role to remove
 * @returns The new user
 */
export async function removeFromRole(username: string, role: UserRole) {
  return post(apiName, "removeUserFromGroup", {
    username,
    groupname: CognitoRoles[role],
  }) as AdminQueriesResponse;
}

/**
 * Enables a user.
 * @param username The username of the user
 * @returns The enabled user
 */
export async function enableUser(username: string) {
  return post(apiName, "enableUser", {
    username,
  }) as AdminQueriesResponse;
}

/**
 * Disables a user.
 * @param username The username of the user
 * @returns The disabled user
 */
export async function disableUser(username: string) {
  return post(apiName, "disableUser", {
    username,
  }) as AdminQueriesResponse;
}

/**
 * Confirms the sign up of a user.
 * @param username The username of the user
 * @returns The confirmed user
 */
export async function confirmUser(username: string) {
  return post(apiName, "confirmUserSignUp", {
    username,
  }) as AdminQueriesResponse;
}
