import { Analytics } from "@aws-amplify/analytics";
import { Amplify, AWSCloudWatchProvider, Logger } from "@aws-amplify/core";
import { ModelInit, MutableModel } from "@aws-amplify/datastore";
import { getModelName } from "helpers/display";
import {
  Activity,
  Audit,
  Configuration,
  Identity,
  ModelType,
  MutationAction,
  Payment,
  Task,
  User,
} from "models";
import { Entity, UpdateEntity } from "utils/store";
import { Metric } from "web-vitals";
import { getLoggedUser } from "./authenticate";
import { createAudit } from "./mutate";

const logger = new Logger("INsync logger");
Amplify.register(logger);
logger.addPluggable(
  new AWSCloudWatchProvider({
    logGroupName: `insync-${process.env.REACT_APP_ENV ?? "local"}`,
    logStreamName: `insync-${process.env.NODE_ENV}`,
    region: "eu-central-1",
  })
);

/**
 * Logs an error to the console.
 * @param error The error object
 */
export function log(error: unknown) {
  logger.error(
    JSON.stringify({
      user: getLoggedUser(),
      error,
      stack: (error as Error | undefined)?.stack ?? new Error().stack,
    })
  );
}

/**
 * Records a performance metric from web vitals.
 * @param metric The performance metric
 */
export async function recordPerformance({ name, value, id, entries }: Metric) {
  const attributeEntries = {} as Record<keyof typeof entries[number], string>;
  const metricsEntries = {} as Record<keyof typeof entries[number], number>;

  entries.forEach((entry) => {
    for (const key in entry) {
      const entryKey = key as keyof typeof entry;
      const entryValue = entry[entryKey];

      if (typeof entryValue === "string")
        attributeEntries[entryKey] = entryValue;
      else if (typeof entryValue === "number")
        metricsEntries[entryKey] = entryValue;
    }
  });

  return record({
    category: "Web Vitals",
    name,
    attributes: {
      ...attributeEntries,
      id,
    },
    metrics: {
      ...metricsEntries,
      value: value && Math.round(name === "CLS" ? value * 1000 : value),
    },
  });
}

/**
 * Records an event to AWS Pinpoint analytics.
 * @param args Record parameters
 * @returns result of the record operation
 */
export async function record(
  event: {
    name: string;
    category: string;
    attributes?: Record<any, string>;
    metrics?: Record<any, number>;
  },
  ...args: [provider?: string]
) {
  const { name, category, attributes, metrics } = event;

  try {
    return await Analytics.record(
      {
        name,
        attributes: {
          ...attributes,
          category,
        },
        metrics,
      },
      ...args
    );
  } catch (error) {
    log(error);
  }
}

/**
 * Stores a mutation as an audit entity.
 * @param action Mutation name
 * @param entity Mutated entity
 * @param motivation Object with descriptions of the mutated fields
 * @param mutation Mutation object
 * @param options Audit options
 * @returns The created audit
 */
export async function audit<T extends Entity>(
  action: MutationAction,
  entity: T,
  motivation?: Partial<Record<keyof MutableModel<T>, string>> | string,
  mutation?: UpdateEntity<T>,
  options?: { override?: Partial<Audit> }
) {
  const auditModel = Object.keys(modelResolver).find(
    (key) =>
      modelResolver[key as keyof typeof modelResolver] ===
      entity.constructor.name
  ) as keyof typeof modelResolver | undefined;

  if (!auditModel) return;

  const { id, ...create } = entity;

  const constructor: ModelInit<Audit> = {
    modelAuditsId: (entity as Entity).id,
    modelType: auditModel,
    action,
    mutation:
      action === "DELETE" ? undefined : JSON.stringify(mutation ?? create),
    detailedMotivation:
      typeof motivation === "object" ? JSON.stringify(motivation) : undefined,
    motivation: typeof motivation === "string" ? motivation : undefined,
    ...options?.override,
  };

  const createdAudit = (await createAudit(constructor)) as Audit | undefined;

  if (!createdAudit) {
    log({
      message: "Audit creation error",
      constructor,
      entity,
      action,
      motivation,
      mutation,
    });
  }

  return createdAudit;
}

/**
 * Resolves model names to corresponding entity names.
 */
const modelResolver: Record<
  Exclude<ModelType, ModelType.NOTIFICATION>,
  string
> = {
  [ModelType.TASK]: getModelName(Task),
  [ModelType.IDENTITY]: getModelName(Identity),
  [ModelType.USER]: getModelName(User),
  [ModelType.ACTIVITY]: getModelName(Activity),
  [ModelType.PAYMENT]: getModelName(Payment),
  [ModelType.CONFIGURATION]: getModelName(Configuration),
};
