import { filterAsync, someAsync } from "constants/code";
import { DateTime, Duration, Interval } from "luxon";
import {
  Activity,
  ActivityPhase,
  BuyerPersona,
  LastWorkDay,
  LocationName,
  ModalityName,
  OutcomeName,
  RealEstate,
  Scheduler,
  User,
  UserRole,
} from "models";
import { getLoggedUser } from "./authenticate";
import { getUserRoles } from "./authorize";
import { changeTime, sortDates } from "./localize";
import {
  calculateDistance,
  geohashDistance,
  isTaskWithoutLocation,
} from "./locate";
import { log } from "./log";
import { createTask, updateTask } from "./mutate";
import {
  ActivityModel,
  getActivities,
  getIdentity,
  getScheduler,
  getTasks,
  getUser,
  getUsers,
  IdentityModel,
  TaskModel,
  UserModel,
} from "./query";
import { getNow, toDateTime, toDuration, toInterval } from "./time";

type RequiredTaskData = "identityTasksId" | "activityTasksId";

/**
 * Used to construct a task.
 */
type TaskData = Pick<TaskModel, RequiredTaskData> &
  Partial<Omit<TaskModel, RequiredTaskData>>;

/**
 * wrong scheduling schedules as soon as possible today in work hours, otherwise tomorrow as soon as possible ignoring work hours
 * asap schedules as soon as possible
 * alap schedules as late as possible
 * default schedules in best time according to parameters
 */
type PlanningStrategy = "alap" | "asap" | "wrongScheduling";

type TaskInfo = {
  activity: ActivityModel;
  activities: ActivityModel[];
  identity: IdentityModel;
  identityBuyerPersona: Record<keyof typeof buyerPersonas, number>;
  interval: Duration;
  intervalMinutes: number;
  favoriteStartTime?: DateTime;
  favoriteEndTime?: DateTime;
  strategy?: PlanningStrategy;
};

type AssigneeInfo = {
  mutationData: Omit<
    TaskModel,
    "id" | "assignee" | "startDateTime" | "endDateTime"
  >;
  assignee: UserModel;
  tasks: TaskModel[];
  availableDateTime: Interval;
  headquartersTravel: Duration;
};

type PlanningInfo = {
  task: Interval;
  tasksToCheck: TaskModel[];
  tasksToMove: TaskModel[];
};

/**
 * Params of resolvers.
 */
type ResolverCandidate = {
  planningInfo: PlanningInfo;
  taskInfo: TaskInfo;
  assigneeInfo: AssigneeInfo;
};

/**
 * Object that resolves parameters to evaluators;
 * evaluators can be task-time (default, called for each task) or assignee-time (tuple, called for each assignee).
 */
type ResolverObject<P extends keyof Scheduler> = Record<
  keyof Scheduler[P],
  P extends keyof Pick<Scheduler, "planningParameters">
    ? (candidate: ResolverCandidate) => number | Promise<number>
    : (
        candidate: Omit<ResolverCandidate, "planningInfo" | "assigneeInfo">
      ) => number | Promise<number>
>;

/**
 * Planning ranking info.
 */
type Planning = [
  rating: { rating: number } & Scheduler["planningParameters"],
  assigneeInfo: AssigneeInfo,
  planningInfo: PlanningInfo
];

//? This value represents how much time the scheduler should try to schedule after the last already scheduled task.
//? In this specific case, the value is 1 day because the task could be placed in its favorite time range instead of as soon as possible.
//? Having more than 1 day would give no benefit to the scheduling, since no parameters are dependent on specific days (instead, they are dependent on specific time ranges inside a day).
//? Hardcoded since it can't be inferred easily.
const ADDED_SCHEDULE_DURATION = toDuration({
  days: 1,
});

//? This value transforms 0.5 priority into the specified days
const NORMAL_PRIORITY_TO_DAYS = 10;

const MAXIMUM_PLANNING_DAYS = 30;

/**
 * Schedules a task.
 * @param taskData The data to create the task or the task to move
 * @param assignee The role or id of the user to whom the task must be assigned
 */
export default async function schedule(
  taskData: Parameters<typeof createPlanningRanking>[0],
  assignee: UserModel["id"] | UserRole,
  options?: Parameters<typeof createPlanningRanking>[2]
): Promise<TaskModel | undefined> {
  const identity =
    options?.optimizations?.identity ?? (await getIdentity(taskData));

  if (!identity) return;

  const activities =
    options?.optimizations?.activities ??
    (await getActivities(undefined, identity));

  if (!activities?.length) return;

  const scheduler =
    options?.optimizations?.scheduler ?? (await getScheduler())?.scheduler;

  if (!scheduler) return;

  const interval =
    options?.optimizations?.interval ?? toDuration(scheduler.timeInterval);
  const intervalMinutes =
    options?.optimizations?.intervalMinutes ?? interval.as("minutes");

  const planningRanking = await createPlanningRanking(taskData, assignee, {
    ...options,
    optimizations: {
      identity,
      activities,
      scheduler,
      interval,
      intervalMinutes,
    },
  });

  if (!planningRanking) return;

  let task: TaskModel | undefined;

  for (const bestPlanning of planningRanking) {
    const [
      ,
      {
        assignee: taskAssignee,
        mutationData,
        headquartersTravel,
        tasks: assigneeTasks,
      },
      { task: taskInterval, tasksToCheck, tasksToMove },
    ] = bestPlanning;

    const baseMutation = {
      assignee: taskAssignee.username,
      startDateTime: taskInterval.start.toISO(),
      endDateTime: taskInterval.end.toISO(),
    };

    const mutationOptions = {
      travelInfoParams: {
        schedulerData: { interval, intervalMinutes },
        mutationTasksToCheck: tasksToCheck.filter(
          ({ modality: taskModality }) => !isTaskWithoutLocation(taskModality)
        ),
        mutationHeadquartersTravel: headquartersTravel,
      },
    };

    if (taskData.id) {
      const typedTaskData = taskData as TaskModel;

      const intervalToCheck = toInterval(
        toDateTime(typedTaskData.startDateTime).startOf("day"),
        toDateTime(typedTaskData.endDateTime).endOf("day")
      );

      task = await updateTask(
        {
          ...mutationData,
          ...baseMutation,
          moved: mutationData.moved ?? 0 + 1,
        },
        typedTaskData,
        {
          ...mutationOptions,
          travelInfoParams: {
            ...mutationOptions.travelInfoParams,
            originalTasksToCheck: assigneeTasks.filter(
              ({ modality: taskModality, startDateTime, endDateTime }) =>
                !isTaskWithoutLocation(taskModality) &&
                toInterval(startDateTime, endDateTime).overlaps(intervalToCheck)
            ),
            originalHeadquartersTravel: headquartersTravel, //? Same assignee, same travel
          },
        }
      );
    } else {
      task = await createTask(
        {
          ...mutationData,
          ...baseMutation,
        },
        mutationOptions
      );
    }

    if (!task) continue;

    //? Move the task with biggest intersection with current task
    tasksToMove.sort(
      (
        { startDateTime: aStart, endDateTime: aEnd },
        { startDateTime: bStart, endDateTime: bEnd }
      ) =>
        taskInterval
          .intersection(toInterval(aStart, aEnd))!
          .toDuration()
          .toMillis() -
        taskInterval
          .intersection(toInterval(bStart, bEnd))!
          .toDuration()
          .toMillis()
    );

    const taskToMove = tasksToMove[0];

    if (!taskToMove) return task;

    const movedTask = await schedule(taskToMove, taskAssignee.id, {
      planning: {
        doNotMove: [...(options?.planning?.doNotMove ?? []), task.id],
      },
    });

    if (!movedTask) {
      log({
        message: "Task move error",
        task: taskToMove,
        assignee: taskAssignee,
      });

      return task;
    }

    //? When a task is moved should reschedule the current task to check if something has changed
    return schedule(task, assignee, options);
  }

  //? If task has not been created by plannings, plan asap
  planningRanking.sort(([, , { task: aTask }], [, , { task: bTask }]) =>
    sortDates(bTask, aTask, "start")
  );

  return schedule(taskData, assignee, {
    ...options,
    time: {
      ...options?.time,
      start: planningRanking[0]?.[2].task.start.toISO(),
    },
    planning: {
      ...options?.planning,
      strategy:
        options?.planning?.strategy === "wrongScheduling"
          ? "wrongScheduling"
          : "asap",
    },
  });
}

/**
 * Generates a planning ranking for a specified task.
 * @param taskData The task for which to plan
 * @param assignee The assignee to whom the task is assigned
 * @param options The scheduling options
 * @returns The generated planning ranking
 */
export async function createPlanningRanking(
  taskData: TaskData,
  assignee: UserModel["id"] | UserRole,
  options?: {
    time?: {
      start?: TaskModel["startDateTime"];
      relative?: {
        referenceDateTime: NonNullable<TaskModel["startDateTime"]>;
        minTimeBefore: NonNullable<
          ActivityModel["children"]
        >[number]["minDurationBeforeParent"];
      };
    };
    assignees?: {
      exclude?: UserModel["id"][];
      outcomeUsername?: UserModel["username"];
    };
    planning?: {
      strategy?: PlanningStrategy;
      doNotMove?: TaskModel["id"][];
    };
    optimizations?: {
      identity?: IdentityModel;
      activities?: ActivityModel[];
      scheduler?: Scheduler;
      interval?: Duration;
      intervalMinutes?: number;
    };
  }
) {
  //? This function is placed in the frontend with offline in mind

  const identity =
    options?.optimizations?.identity ?? (await getIdentity(taskData));

  if (!identity) return;

  const activities =
    options?.optimizations?.activities ??
    (await getActivities(undefined, identity));

  if (!activities?.length) return;

  const activity = activities.find(({ id }) => id === taskData.activityTasksId);

  if (!activity) return;

  const modality =
    taskData.modality ??
    activity.modalities.find(({ name }) => name === ModalityName.PHYSICAL)
      ?.name ??
    activity.modalities[0].name;

  const modalityObject = activity.modalities.find(
    ({ name }) => name === modality
  );

  const location =
    taskData.location ??
    modalityObject?.locations.find(
      ({ name }) => name === LocationName.HEADQUARTERS
    )?.name ??
    modalityObject?.locations[0]?.name;

  const baseMutationData = {
    ...taskData,
    modality,
    location,
    placeInfo:
      location === LocationName.IDENTITYHOME
        ? identity.placeInfo
        : taskData.placeInfo,
  };

  const favoriteStartTime = activity.favoriteStartTime
    ? toDateTime(activity.favoriteStartTime)
    : undefined;

  const favoriteEndTime = activity.favoriteEndTime
    ? toDateTime(activity.favoriteEndTime)
    : undefined;

  //? Both could be invalid, which means infinite time
  let minStart: DateTime | undefined;
  let maxEnd: DateTime | undefined;

  if (options?.time?.relative) {
    maxEnd = toDateTime(options.time.relative.referenceDateTime).minus(
      toDuration(options.time.relative.minTimeBefore)
    );
  }

  const scheduler =
    options?.optimizations?.scheduler ?? (await getScheduler())?.scheduler;

  if (!scheduler) return;

  const interval =
    options?.optimizations?.interval ?? toDuration(scheduler.timeInterval);
  const intervalMinutes =
    options?.optimizations?.intervalMinutes ?? interval.as("minutes");

  const strategy = options?.planning?.strategy;

  const activityCandidate: Omit<
    ResolverCandidate,
    "planningInfo" | "assigneeInfo"
  > = {
    taskInfo: {
      activity,
      activities,
      identity,
      identityBuyerPersona: {
        realEstate: buyerPersonas.realEstate[identity.buyerPersona.realEstate],
        realEstatePrice: transformRealEstatePrice(
          identity.buyerPersona.realEstatePrice
        ),
        savings: transformSavings(identity.buyerPersona.savings),
        loanToValue: transformLoanToValue(identity.buyerPersona.loanToValue),
      },
      favoriteStartTime,
      favoriteEndTime,
      strategy,
      interval,
      intervalMinutes,
    },
  };

  const isWrongScheduling = strategy === "wrongScheduling";

  const preciseMinStart = maxEnd
    ? maxEnd.minus(
        await getMaximumTimerange(
          scheduler.prioritizationParameters,
          activityCandidate
        )
      )
    : (options?.time?.start
        ? toDateTime(options.time.start)
        : activity.minDurationBeforeExecution &&
          (!options || (strategy !== "asap" && !isWrongScheduling))
        ? getNow().plus(toDuration(activity.minDurationBeforeExecution))
        : getNow()
      ).plus(interval);

  minStart = preciseMinStart.minus({
    minutes:
      Math.floor(preciseMinStart.minute) % intervalMinutes || intervalMinutes,
  });

  if (!maxEnd) {
    maxEnd = minStart.plus(
      await getMaximumTimerange(
        scheduler.prioritizationParameters,
        activityCandidate
      )
    );
  }

  // The possible users that can execute the task
  const assignees = await getCandidateAssignees(
    assignee,
    taskData.identityTasksId,
    options?.assignees?.exclude,
    undefined,
    options?.assignees?.outcomeUsername
  );

  if (!assignees?.length) return;

  const tasks = (await getTasks())
    ?.filter(
      ({ assignee: searchAssignee, outcome, endDateTime, startDateTime, id }) =>
        !outcome &&
        assignees.some(({ username }) => username === searchAssignee) &&
        (!minStart!.isValid || toDateTime(endDateTime) > minStart!) &&
        (!maxEnd!.isValid || toDateTime(startDateTime) < maxEnd!) &&
        (!taskData.id || id !== taskData.id)
    )
    .sort((aTask, bTask) => sortDates(bTask, aTask));

  if (!tasks) return;

  const duration = toDuration(activity.expectedDuration);

  const candidate = activityCandidate as Omit<ResolverCandidate, "start">;

  //? This section should find the best available planning info
  const planningRanking = [] as Planning[];

  const doNotMove = options?.planning?.doNotMove;

  //? Check every potential assignees' calendar
  for (const userAssignee of assignees) {
    const mutationData = {
      ...baseMutationData,
      placeInfo:
        baseMutationData.location === LocationName.HEADQUARTERS
          ? userAssignee.headquarters
          : baseMutationData.placeInfo,
    };

    const workStart = toDateTime(userAssignee.workTimeStart);
    const workEnd = toDateTime(userAssignee.workTimeEnd);

    const lastWorkDay = userAssignee.lastWorkDay === LastWorkDay.FRIDAY ? 5 : 6;

    const lunchStart = toDateTime(userAssignee.lunchBreakStart);
    const lunchEnd = toDateTime(userAssignee.lunchBreakEnd);

    const fitScheduleConfig: Parameters<typeof fitInSchedule>[1] = {
      workStart,
      workEnd,
      lastWorkDay,
      lunchStart,
      lunchEnd,
    };

    const fitStart = fitInSchedule(minStart, fitScheduleConfig);

    const minStartDate = minStart.toISODate();

    let start =
      isWrongScheduling && fitStart.toISODate() !== minStartDate
        ? fitStart.startOf("day")
        : fitStart;

    const assigneeTasks = tasks.filter(
      ({ assignee: searchAssignee }) => searchAssignee === userAssignee.username
    );

    const latestTask = assigneeTasks[0];

    let notLimitedEnd = (
      latestTask ? toDateTime(latestTask.endDateTime) : start
    ).plus(ADDED_SCHEDULE_DURATION);

    if (notLimitedEnd.weekday > lastWorkDay)
      notLimitedEnd = notLimitedEnd.plus({ weeks: 1 }).set({ weekday: 1 });

    //? Schedule end should never exceed maximum
    const end =
      options?.time?.relative || (maxEnd.isValid && notLimitedEnd > maxEnd)
        ? maxEnd
        : notLimitedEnd;

    if (start > end) start = minStart;

    const getTaskEndConfig: Parameters<typeof getCurrentEnd>[1] = {
      duration,
      lunchStart,
    };

    const headquartersTravel = await calculateDistance(
      userAssignee.headquarters,
      mutationData.placeInfo,
      { interval, intervalMinutes }
    );

    if (!headquartersTravel) continue;

    candidate.assigneeInfo = {
      mutationData,
      headquartersTravel,
      assignee: userAssignee,
      tasks: assigneeTasks,
      availableDateTime: toInterval(start, end),
    };

    const strategyPlanningParameters =
      strategy === "asap" || isWrongScheduling
        ? {
            ...scheduler.planningParameters,
            favoriteTimeRange: 0,
            similarActivities: 0,
          }
        : scheduler.planningParameters;

    const planningParameters = mutationData.taskChildrenId
      ? { ...strategyPlanningParameters, similarActivities: 0 }
      : strategyPlanningParameters;

    let current = start;

    let tasksToCheck: {
      currentInterval: string;
      tasks: PlanningInfo["tasksToCheck"];
    } = {
      currentInterval: "",
      tasks: [],
    };

    //? Check calendar of current assignee
    do {
      const taskEnd = getCurrentEnd(current, getTaskEndConfig);

      if (taskEnd) {
        const intervalToCheck = toInterval(
          current.startOf("day"),
          taskEnd.endOf("day")
        );

        const currentInterval = intervalToCheck.toISO();

        if (currentInterval !== tasksToCheck.currentInterval) {
          tasksToCheck = {
            currentInterval,
            tasks: candidate.assigneeInfo.tasks.filter(
              ({ outcome, startDateTime, endDateTime }) =>
                outcome !== OutcomeName.NOTEXECUTED &&
                toInterval(startDateTime, endDateTime).overlaps(intervalToCheck)
            ),
          };
        }

        const task = toInterval(current, taskEnd);

        const tasksToMove = await filterAsync(
          tasksToCheck.tasks,
          async (taskToCheck) =>
            isTaskToMove(taskToCheck, task, mutationData.placeInfo, {
              interval,
              intervalMinutes,
            })
        );

        //? If some tasks cannot be moved, the planning is impossible and the next best planning should be taken into consideration
        if (
          !tasksToMove.some(
            ({ id, moved, activityTasksId }) =>
              doNotMove?.includes(id) ||
              (moved ?? 0) >= scheduler.maxAllowedMoves ||
              activities.find(
                ({ id: activityID }) => activityID === activityTasksId
              )?.manualScheduling
          )
        ) {
          candidate.planningInfo = {
            task,
            tasksToCheck: tasksToCheck.tasks,
            tasksToMove,
          };

          planningRanking.push([
            await normalizedWeightedAverage(
              planningResolvers,
              planningParameters,
              candidate
            ),
            candidate.assigneeInfo,
            candidate.planningInfo,
          ]);
        }
      }

      const baseNext = current.plus(interval);
      const next = fitInSchedule(baseNext, fitScheduleConfig);

      if (isWrongScheduling && next.toISODate() !== minStartDate) {
        //? Set to first available spot, ignoring schedule
        current =
          current.toISODate() === minStartDate ? next.startOf("day") : baseNext;
      } else {
        current = next;
      }
    } while (current < end);
  }

  return planningRanking.sort(
    ([{ rating: aRating }], [{ rating: bRating }]) => bRating - aRating
  );
}

/**
 * Resolves a role or id of assignee to a candidate array.
 * @param assignee Role or id of the assignee
 * @param identityID The id of the identity for which to check the assignee
 * @param exclude The assignee ids to exclude
 * @returns The resolved candidate array
 */
export async function getCandidateAssignees(
  assignee: Parameters<typeof schedule>[1],
  identityID: TaskData["identityTasksId"],
  exclude?: NonNullable<
    NonNullable<Parameters<typeof schedule>[2]>["assignees"]
  >["exclude"],
  users?: UserModel[],
  outcomeUsername?: TaskModel["assignee"]
) {
  function isRole(assigneeOrRole: string): assigneeOrRole is UserRole {
    return !!UserRole[assigneeOrRole as UserRole];
  }

  let candidateAssignee: UserModel | undefined;

  if (isRole(assignee)) {
    const outcomeUser = outcomeUsername
      ? await getUser(["username", outcomeUsername])
      : undefined;

    if (outcomeUser?.roles.includes(assignee)) {
      candidateAssignee = outcomeUser;
    } else if (getUserRoles().includes(assignee)) {
      const loggedUser = getLoggedUser()!;

      candidateAssignee =
        users?.find(({ username }) => username === loggedUser) ??
        (await getUser(["username", loggedUser]));
    } else {
      const candidates = (users ?? (await getUsers()))?.filter(({ roles }) =>
        roles.includes(assignee)
      );

      candidateAssignee = await getLastUserInRole(
        assignee,
        identityID,
        candidates
      );

      if (!candidateAssignee) {
        return exclude
          ? candidates?.filter(({ id }) => !exclude?.includes(id))
          : candidates;
      }
    }
  }

  if (!candidateAssignee) {
    candidateAssignee =
      users?.find(({ id }) => id === assignee) ?? (await getUser(assignee));
  }

  if (candidateAssignee && !exclude?.includes(candidateAssignee.id))
    return [candidateAssignee];
}

async function isTaskToMove(
  {
    startDateTime,
    endDateTime,
    placeInfo,
    modality,
    outcome,
  }: Pick<
    TaskModel,
    "startDateTime" | "endDateTime" | "placeInfo" | "modality" | "outcome"
  >,
  candidateInterval: Interval,
  candidatePlace: TaskModel["placeInfo"],
  schedulerData: { interval: Duration; intervalMinutes: number }
) {
  const intervalToCheck = toInterval(startDateTime, endDateTime);

  if (outcome) return false;

  //? Direct intersection or incompatible travel distance
  if (candidateInterval.overlaps(intervalToCheck)) {
    return true;
  } else if (!isTaskWithoutLocation(modality)) {
    const travel = await calculateDistance(
      candidatePlace,
      placeInfo,
      schedulerData
    );

    const humanInterval = toInterval(
      candidateInterval.start.plus({ milliseconds: 1 }),
      candidateInterval.end.minus({ milliseconds: 1 })
    );

    if (
      travel &&
      ((intervalToCheck.isBefore(humanInterval.start) &&
        candidateInterval
          .set({ start: candidateInterval.start.minus(travel) })
          .overlaps(intervalToCheck)) ||
        (intervalToCheck.isAfter(humanInterval.end) &&
          intervalToCheck
            .set({ start: intervalToCheck.start.minus(travel) })
            .overlaps(candidateInterval)))
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Retrieves the last user in role to operate on that identity.
 * @param role The role of the user
 * @param identityID The identity to check
 * @param users The users to check
 * @returns The last user in role that operated on that identity
 */
async function getLastUserInRole(
  role: UserRole,
  identityID: TaskModel["identityTasksId"],
  users?: User[]
) {
  const usersInRole =
    users ?? (await getUsers())?.filter(({ roles }) => roles.includes(role));

  if (!usersInRole) return;

  const lastAssigneeInRole = (await getTasks())
    ?.filter(
      ({ identityTasksId, assignee }) =>
        identityTasksId === identityID &&
        usersInRole.some(({ username }) => username === assignee)
    )
    .sort((aTask, bTask) => sortDates(bTask, aTask))[0]?.assignee;

  if (lastAssigneeInRole)
    return usersInRole.find(({ username }) => username === lastAssigneeInRole);
}

/**
 * Evaluates the maximum duration available for a task to be scheduled.
 * @param prioritizationParameters The parameters to weigh prioritization resolvers
 * @returns The duration object representing the maximum timerange for the task derived from the parameters
 */
async function getMaximumTimerange(
  prioritizationParameters: Scheduler["prioritizationParameters"],
  candidate: Omit<ResolverCandidate, "planningInfo" | "assigneeInfo">
) {
  const priority =
    (
      await normalizedWeightedAverage(
        prioritizationResolvers,
        prioritizationParameters,
        candidate
      )
    ).rating * 0.7; //? Scales the priority to not exaggerate (higher values should normally be reserved only for manual settings)

  //? expm1 is used because it gives more natural results than a linear function, since the returned time at 0 must be infinite
  const millis = 5e7 * NORMAL_PRIORITY_TO_DAYS * Math.expm1(1 / priority - 1);

  const maximumMillis = 8.64e7 * MAXIMUM_PLANNING_DAYS;

  return toDuration(millis > maximumMillis ? maximumMillis : millis);
}

/**
 * Analyzes resolvers and their weights, then returns a number from 0 to 1 composed of weighted sum of resolvers divided by the sum of weighs.
 * @param resolvers The resolvers to evaluate
 * @param weights The weighs to apply
 * @returns Returns the normalized sum(weigh * resolved) / sum(weighs), which is 0 if resolved are minimum and 1 if resolved are maximum
 */
async function normalizedWeightedAverage<P extends keyof Scheduler>(
  resolvers: ResolverObject<P>,
  weights: Record<keyof typeof resolvers, number>,
  candidate: Parameters<typeof resolvers[keyof typeof resolvers]>[0]
) {
  let weightedSum = 0;
  let weightSum = 0;
  const details = {} as typeof weights;

  for (const parameter in resolvers) {
    // Single evaluated parameter will always be smaller than its weight, since the unweighted parameter is 1 at its maximum
    const parameterValue =
      weights[parameter as keyof typeof resolvers] *
      (await resolvers[parameter as keyof typeof resolvers](candidate as any));

    details[parameter] = parameterValue;
    weightedSum += parameterValue;

    weightSum += weights[parameter as keyof typeof resolvers];
  }

  return { ...details, rating: weightedSum / weightSum };
}

function fitInSchedule(
  dateTime: DateTime,
  config: {
    workStart: DateTime;
    workEnd: DateTime;
    lunchStart: DateTime;
    lunchEnd: DateTime;
    lastWorkDay: number;
  }
): DateTime {
  const { lastWorkDay, workStart, workEnd, lunchStart, lunchEnd } = config;

  if (dateTime.weekday > lastWorkDay) {
    return changeTime(
      dateTime.plus({ weeks: 1 }).set({
        weekday: 1,
      }),
      workStart
    );
  }

  const timeToCheck = toDateTime(dateTime.toISOTime());

  if (timeToCheck < workStart) return changeTime(dateTime, workStart);

  if (timeToCheck >= workEnd) {
    return fitInSchedule(
      changeTime(dateTime.plus({ days: 1 }), workStart),
      config
    );
  }

  if (timeToCheck >= lunchStart && timeToCheck < lunchEnd)
    return changeTime(dateTime, lunchEnd);

  return dateTime;
}

function getCurrentEnd(
  startDateTime: DateTime,
  config: {
    duration: Duration;
    lunchStart: DateTime;
  }
) {
  const { duration, lunchStart } = config;

  const endCandidate = startDateTime.plus(duration);

  const currentLunchStart = changeTime(startDateTime, lunchStart);

  if (endCandidate > currentLunchStart && startDateTime < currentLunchStart)
    return;

  return endCandidate;
}

//? Higher values are better for identity quality
const buyerPersonas: {
  [K in keyof BuyerPersona]-?: Record<NonNullable<BuyerPersona[K]>, number>;
} = {
  realEstate: {
    [RealEstate.OWNREALESTATE]: 0,
    [RealEstate.SELLINGOWNHOUSE]: 1,
    [RealEstate.AVAILABLETOSIGNHHP]: 2,
    [RealEstate.PROACTIVERESEARCH]: 3,
    [RealEstate.IDENTIFIED]: 4,
    [RealEstate.NEGOTIATIONSTARTED]: 5,
    [RealEstate.PURCHASEPROPOSALSIGNED]: 6,
  },
  realEstatePrice: {
    0: 0,
    100: 1,
    125: 2,
    150: 3,
    200: 4,
  },
  savings: {
    0: 0,
    5: 1,
    10: 2,
    20: 3,
    30: 4,
    40: 5,
  },
  loanToValue: {
    0: 0,
    80: 1,
    81: 2,
    95: 3,
    96: 4,
    101: 5,
  },
};

//? Higher values are better for process quality
const phases: Record<NonNullable<Activity["phase"]>, number> = {
  [ActivityPhase.LEGALDISPUTE]: 0,
  [ActivityPhase.NOTINTERESTED]: 1,
  [ActivityPhase.ARCHIVE]: 2,
  [ActivityPhase.CUSTOMER]: 3,
  [ActivityPhase.CUSTOMERCONVERSIONMANAGEMENT]: 4,
  [ActivityPhase.CUSTOMERREALESTATESEARCH]: 5,
  [ActivityPhase.CUSTOMERNEGOTIATION]: 6,
  [ActivityPhase.CUSTOMERPROPOSAL]: 7,
  [ActivityPhase.CUSTOMERBANKOUTCOME]: 8,
  [ActivityPhase.CUSTOMERREVENUESEVENTYFIVEDAYS]: 9,
  [ActivityPhase.CUSTOMERREVENUESIXTYDAYS]: 10,
  [ActivityPhase.CUSTOMERREVENUEFORTYFIVEDAYS]: 11,
  [ActivityPhase.CUSTOMERREVENUETHIRTYFIVEDAYS]: 12,
  [ActivityPhase.CUSTOMERREVENUETWENTYFIVEDAYS]: 13,
  [ActivityPhase.CUSTOMERREVENUETWENTYDAYS]: 14,
  [ActivityPhase.CUSTOMERREVENUEFIFTEENDAYS]: 15,
  [ActivityPhase.CUSTOMERREVENUETENDAYS]: 16,
  [ActivityPhase.CUSTOMERREVENUETWODAYS]: 17,
  [ActivityPhase.CUSTOMERREVENUE]: 18,
  [ActivityPhase.CONTACTTOSCHEDULE]: 19,
  [ActivityPhase.CONTACTTOMEET]: 20,
  [ActivityPhase.LEADMET]: 21,
  [ActivityPhase.LEADWEAK]: 22,
  [ActivityPhase.LEADLOW]: 23,
  [ActivityPhase.LEADMEDIUM]: 24,
  [ActivityPhase.LEADHIGH]: 25,
  [ActivityPhase.PROSPECTMEDIUM]: 26,
  [ActivityPhase.PROSPECTHIGH]: 27,
};

const realEstatePrice = buyerPersonas.realEstatePrice;
function transformRealEstatePrice(value: BuyerPersona["realEstatePrice"]) {
  return value > 200000
    ? realEstatePrice[200]
    : value > 150000
    ? realEstatePrice[150]
    : value > 125000
    ? realEstatePrice[125]
    : value > 100000
    ? realEstatePrice[100]
    : realEstatePrice[0];
}

const savings = buyerPersonas.savings;
function transformSavings(value: BuyerPersona["savings"]) {
  return value > 40000
    ? savings[40]
    : value > 30000
    ? savings[30]
    : value > 20000
    ? savings[20]
    : value > 10000
    ? savings[10]
    : value > 5000
    ? savings[5]
    : savings[0];
}

const loanToValue = buyerPersonas.loanToValue;
const loanToValueMean = Object.keys(loanToValue).length / 2;
function transformLoanToValue(value: BuyerPersona["loanToValue"]) {
  return value
    ? value > 100
      ? loanToValue[101]
      : value > 95
      ? loanToValue[96]
      : value > 94
      ? loanToValue[95]
      : value > 80
      ? loanToValue[81]
      : value > 79
      ? loanToValue[80]
      : loanToValue[0]
    : loanToValueMean;
}

//? This value specifies what it means for an activity to be "near", at its worst case
const NEAR_ACTIVITY_RADIUS = toDuration({ hours: 2 });

//? Used to normalize distance function, higher values enhance exponential decay
const ACCEPTABLE_DISTANCE = 0.01;

const planningResolvers: ResolverObject<"planningParameters"> = {
  // Better that the task placement does not move other tasks
  async doNotMove({
    planningInfo: { task, tasksToCheck },
    assigneeInfo: {
      mutationData: { placeInfo },
    },
    taskInfo: { interval, intervalMinutes },
  }) {
    //? If some tasks would be moved, 0 else 1
    return (await someAsync(tasksToCheck, async (taskToCheck) =>
      isTaskToMove(taskToCheck, task, placeInfo, { interval, intervalMinutes })
    ))
      ? 0
      : 1;
  },
  // Better that the task is done in the "best" (definition varies for each activity) time range
  favoriteTimeRange({
    planningInfo: { task },
    taskInfo: { favoriteStartTime, favoriteEndTime },
  }) {
    //? Should be 1 if task is engulfed by favorite or favorite is engulfed by task
    //? Otherwise, should return % of favorite occupied by task
    const favoriteInterval = toInterval(
      favoriteStartTime
        ? changeTime(task.start, favoriteStartTime)
        : task.start.startOf("day"),
      favoriteEndTime
        ? changeTime(task.start, favoriteEndTime)
        : task.start.endOf("day")
    );

    return (
      (favoriteInterval.intersection(task)?.toDuration().toMillis() ?? 0) /
      task.toDuration().toMillis()
    );
  },
  // Better that the identity likes the assignee
  compatibility({
    assigneeInfo: {
      assignee: { preferredBuyerPersona },
    },
    taskInfo: { identityBuyerPersona },
  }) {
    // TODO ASSIGNEE-TIME
    //? Should be 1 when buyer personas are the same, 0 when they are completely different; something in the middle when similar
    const assigneeBuyerPersona: Record<
      keyof typeof preferredBuyerPersona,
      number
    > = {
      realEstate: buyerPersonas.realEstate[preferredBuyerPersona.realEstate],
      realEstatePrice: transformRealEstatePrice(
        preferredBuyerPersona.realEstatePrice
      ),
      savings: transformSavings(preferredBuyerPersona.savings),
      loanToValue: transformLoanToValue(preferredBuyerPersona.loanToValue),
    };

    let compatibility = 0;

    for (const key in identityBuyerPersona) {
      compatibility +=
        1 -
        Math.abs(
          assigneeBuyerPersona[key as keyof typeof assigneeBuyerPersona] -
            identityBuyerPersona[key as keyof typeof identityBuyerPersona]
        ) /
          (Object.keys(buyerPersonas[key as keyof typeof buyerPersonas])
            .length -
            1);
    }

    return compatibility / Object.keys(identityBuyerPersona).length;
  },
  // Better that the task is done in locations near surrounding tasks' locations
  nearLocations({
    planningInfo: {
      task: { start, end },
      tasksToCheck,
    },
    assigneeInfo: {
      mutationData: { modality, placeInfo },
      assignee: { headquarters },
    },
  }) {
    //? 1 when places are all in the same location, 0 when places do not have anything in common
    if (isTaskWithoutLocation(modality) || !placeInfo) return 0;

    const nearInterval = toInterval(
      start.minus(NEAR_ACTIVITY_RADIUS),
      end.plus(NEAR_ACTIVITY_RADIUS)
    );

    let nearestTaskSimilarity = Math.exp(
      -ACCEPTABLE_DISTANCE *
        geohashDistance(placeInfo.geohash, headquarters.geohash)
    );

    for (const {
      modality: taskModality,
      placeInfo: taskPlaceInfo,
      startDateTime,
      endDateTime,
    } of tasksToCheck) {
      if (
        !nearInterval.overlaps(toInterval(startDateTime, endDateTime)) ||
        isTaskWithoutLocation(taskModality) ||
        !taskPlaceInfo
      ) {
        continue;
      }

      const similarity = Math.exp(
        -ACCEPTABLE_DISTANCE *
          geohashDistance(placeInfo.geohash, taskPlaceInfo.geohash)
      );

      if (similarity > nearestTaskSimilarity)
        nearestTaskSimilarity = similarity;
    }

    return nearestTaskSimilarity;
  },
  // Better to do a task sooner than later
  asSoonAsPossible({
    planningInfo: {
      task: { start },
    },
    assigneeInfo: { availableDateTime },
    taskInfo: { strategy },
  }) {
    //? 1 when nearest to available start, 0 when nearest to available end
    //? If max is best, 0 when nearest to available end, 1 when nearest to available start
    return (
      (strategy === "alap"
        ? start.diff(availableDateTime.start)
        : availableDateTime.end.diff(start)
      ).toMillis() / availableDateTime.toDuration().toMillis()
    );
  },
  // Better that the task is done near tasks with same activity
  similarActivities({
    planningInfo: { task, tasksToCheck },
    taskInfo: {
      activity: { id },
    },
  }) {
    //? 1 when nearest or intersecting tasks with same activity, otherwise evaluates distance, which is 0 if out of a predefined range
    const nearInterval = toInterval(
      task.start.minus(NEAR_ACTIVITY_RADIUS),
      task.end.plus(NEAR_ACTIVITY_RADIUS)
    );

    let nearestTaskDistance = NEAR_ACTIVITY_RADIUS;

    for (const {
      startDateTime,
      endDateTime,
      activityTasksId,
    } of tasksToCheck) {
      if (activityTasksId === id) {
        const currentTask = toInterval(startDateTime, endDateTime);

        //? If a task overlaps, it's the best situation
        if (currentTask.overlaps(task)) return 1;

        const distanceFromTask = nearInterval
          .intersection(currentTask)
          ?.toDuration();

        if (distanceFromTask && distanceFromTask < nearestTaskDistance)
          nearestTaskDistance = distanceFromTask;
      }
    }

    return 1 - nearestTaskDistance.toMillis() / NEAR_ACTIVITY_RADIUS.toMillis();
  },
};

const prioritizationResolvers: ResolverObject<"prioritizationParameters"> = {
  // It's important that the task's activity is before an activity that converts the identity from contact/lead/prospect to customer (e.g. signing a contract)
  conversionToCustomer({
    taskInfo: {
      activity: { phase },
    },
  }) {
    //? 1 if the identity is about to become customer, 0 if the identity is not interested
    return phase ? phases[phase] / (Object.keys(phases).length - 1) : 0.5;
  },
  // It's important that the type of identity is valuable to the business
  identityValue({ taskInfo: { identityBuyerPersona } }) {
    //? 1 if the identity is the most valuable, 0 if the identity is not valuable at all
    let rating = 0;

    for (const key in identityBuyerPersona) {
      rating +=
        identityBuyerPersona[key as keyof typeof identityBuyerPersona] /
        (Object.keys(buyerPersonas[key as keyof typeof buyerPersonas]).length -
          1);
    }

    return rating / Object.keys(identityBuyerPersona).length;
  },
};
