import define from "helpers/define";
import {
  Bank,
  Identity,
  Payment,
  PaymentService,
  PaymentState,
  PaymentType,
  Supplier,
} from "models";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import Choice from "view/inputs/Choice";
import Free from "view/inputs/Free";

export default define<Omit<Payment, "createdOn">>(() => {
  const { t } = useTranslation("payments");

  return {
    state: {
      view: "choice",
      label: t("payments:state"),
      props: {
        options: PaymentState,
      } as ComponentProps<typeof Choice>,
    },
    type: {
      view: "choice",
      label: t("payments:paymentType"),
      props: {
        options: PaymentType,
      } as ComponentProps<typeof Choice>,
    },
    billDate: {
      view: "date",
      label: t("payments:billDate"),
    },
    dueDate: {
      view: "date",
      label: t("payments:dueDate"),
    },
    date: {
      view: "date",
      label: t("payments:paymentDate"),
    },
    supplier: {
      view: "choice",
      label: t("payments:supplier"),
      props: {
        options: Supplier,
      } as ComponentProps<typeof Choice>,
    },
    service: {
      view: "choice",
      label: t("payments:service"),
      props: {
        options: PaymentService,
      } as ComponentProps<typeof Choice>,
    },
    amount: {
      view: "money",
      label: t("payments:amount"),
    },
    billNumber: {
      view: "id",
      label: t("payments:billNumber"),
    },
    reason: {
      view: "free",
      label: t("payments:reason"),
      props: {
        multiline: true,
      } as ComponentProps<typeof Free>,
    },
    bank: {
      view: "choice",
      label: t("payments:bank"),
      props: {
        options: Bank,
      } as ComponentProps<typeof Choice>,
    },
    identities: {
      view: "quicklist",
      label: t("payments:identities"),
      relationship: Identity,
    },
    audits: {
      view: "exclude",
    },
  };
});
