import { Duration, DurationUnit } from "luxon";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import Number from "./Number";
import Timespan from "./Timespan";

export default function TimeDuration<U extends "time" | DurationUnit>(
  props: {
    unit?: U;
    min?: Duration;
    max?: Duration;
  } & (U extends "time"
    ? ComponentProps<typeof Timespan>
    : Omit<ComponentProps<typeof Number>, "unit" | "min" | "max">)
) {
  const { unit, min, max, ...durationProps } = props;

  const { t } = useTranslation("view");

  return (
    !unit || unit === "time" ? (
      <Timespan {...(durationProps as any)} />
    ) : (
      <Number
        precise
        unit={t(`view:${unit}Unit`)}
        min={min?.get(unit)}
        max={max?.get(unit)}
        {...(durationProps as any)}
      />
    )
  ) as any;
}
