import { DateTime, Duration } from "luxon";
import { ComponentProps, ReactNode, useState } from "react";
import { getNow, toDateTime, toDuration } from "utils/time";
import asBordered from "../LAYOUT/asBordered";
import asGrid from "../LAYOUT/asGrid";
import DateAndTime from "./DateAndTime";
import { FormProps } from "./Form";
import TimeDuration from "./TimeDuration";
import { TimespanInputValue, TimespanOutputValue } from "./Timespan";

type BaseInputValue = TimespanInputValue;

type BaseOutputValue = TimespanOutputValue;

export type PeriodOutputValue = string | undefined;

export type PeriodInputValue = BaseInputValue | PeriodOutputValue;

// TODO ADD DATE TIMEPOINT, AND TIME TIMEPOINT
type DateAndTimeTimePoint = {
  type: "dateAndTime";
} & Required<Pick<ComponentProps<typeof DateAndTime>, "id">> &
  Omit<ComponentProps<typeof DateAndTime>, keyof FormProps | "id">;

type TimeDurationTimePoint = {
  type: "duration";
} & Required<Pick<ComponentProps<typeof TimeDuration>, "id">> &
  Omit<ComponentProps<typeof TimeDuration>, keyof FormProps | "id">;

type TimePoint = DateAndTimeTimePoint | TimeDurationTimePoint;

/**
 * This component works by calculating duration time points based on dateAndTime time points.
 */
export default function Period<S extends TimePoint>(
  props: {
    timePoints?: S[];
    children?: ReactNode;
    insert?: [
      {
        after: string | number;
        Component: React.FC;
      }
    ];
  } & FormProps
): //? Should have at least one dateAndTime time point
DateAndTimeTimePoint extends S ? ReturnType<React.FC> : never {
  const { form, children, insert, timePoints } = props;

  const [values, setValues] = useState<(DateTime | Duration | undefined)[]>([]);

  /**
   * This structure is useful to optimize calculations executed at onChange.
   */
  const operationMap = {} as Record<
    number | string, // dateAndTime index
    {
      plus: number[]; // Duration indexes to sum to dateAndTime
      minus: number[]; // Duration indexes to subtract to dateAndTime
    }
  >;

  let dateAndTimeEncountered = false;

  const minus: typeof operationMap[keyof typeof operationMap]["minus"] = [];

  timePoints?.forEach(({ type }, index) => {
    if (type === "dateAndTime") {
      //? Useful for multi duration calculations
      if (!dateAndTimeEncountered) minus.reverse();

      operationMap[index] = {
        minus: dateAndTimeEncountered ? [] : minus,
        plus: [],
      };

      dateAndTimeEncountered = true;
    } else if (dateAndTimeEncountered) {
      const currentOperationMapKeys = Object.keys(operationMap);

      operationMap[
        currentOperationMapKeys[currentOperationMapKeys.length - 1]
      ].plus.push(index);
    } else {
      minus.push(index);
    }
  });

  const operationMapKeys = Object.keys(operationMap);

  const renderedComponents =
    timePoints?.map(({ type, ...timePointProps }, index) => {
      //? If correspondingDateTime is undefined: inconsistent state, must be calculated when correspondingDateTime is inserted
      let correspondingDateTime:
        | {
            valuesIndex: number;
            operation: keyof typeof operationMap[keyof typeof operationMap];
          }
        | undefined;

      if (type === "duration") {
        for (const dateAndTime in operationMap) {
          if (
            operationMap[dateAndTime].minus.some(
              (searchIndex) => searchIndex === index
            )
          ) {
            correspondingDateTime = {
              valuesIndex: parseInt(dateAndTime),
              operation: "minus",
            };
            break;
          } else if (
            operationMap[dateAndTime].plus.some(
              (searchIndex) => searchIndex === index
            )
          ) {
            correspondingDateTime = {
              valuesIndex: parseInt(dateAndTime),
              operation: "plus",
            };
            break;
          }
        }
      }

      function transformInput(input: PeriodInputValue): BaseInputValue {
        // TODO SHOULD CALCULATE CORRECT DURATION BASED ON CORRESPONDING DATE TIMES AND PRECEDING DURATIONS
        return typeof input === "string"
          ? (input.startsWith("P") ? (toDuration as any) : (toDateTime as any))(
              input
            )
          : input;
      }

      function transformOutput(output: BaseOutputValue): PeriodOutputValue {
        // TODO SHOULD CALCULATE ITS DATETIME VALUE BASED ON PREVIOUS DURATIONS, THEN UPDATE FOLLOWING DURATION DATETIMES ACCORDINGLY; THIS IS ESSENTIAL WHEN DEALING WITH MORE THAN ONE DURATION
        const newValues = [...values];

        const durationObject = output ? toDuration(output) : undefined;

        newValues[index] = durationObject;

        if (durationObject?.isValid) {
          newValues[index] = values[correspondingDateTime!.valuesIndex]![
            correspondingDateTime!.operation
          ](newValues[index] as Duration);
        }

        setValues(newValues);

        return newValues[index]?.toISO();
      }

      if (type === "dateAndTime") {
        //? Is undefined if is first dateTime
        const lastDateTimeIndex =
          operationMapKeys[operationMapKeys.indexOf(index.toString()) - 1];

        return (
          <DateAndTime
            {...(timePointProps as any)}
            minDateTime={
              lastDateTimeIndex ? values[parseInt(lastDateTimeIndex)] : getNow()
            }
            onChange={(newValue) => {
              // TODO CHECK SURROUNDING DATETIMES. IF CURRENT POSITION IS HIGHER BUT CURRENT VALUE IS LOWER, SHOULD SET OTHER DATETIME TO CURRENT VALUE.
              // TODO IF CURRENT POSITION IS LOWER BUT CURRENT VALUE IS HIGHER, SHOULD SET DATETIME TO CURRENT VALUE.
              // TODO TRY TO REMOVE STATE, AND ONLY WORK WITH FORM UTILS
              // TODO ADD PERIOD TO DEFINE
              const newValueDateTime = newValue
                ? toDateTime(newValue)
                : undefined;

              const newValues = [...values];
              newValues[index] = newValueDateTime;

              function calculateDurationFields(
                operation: keyof typeof operationMap[keyof typeof operationMap]
              ) {
                const fields = operationMap[index][operation];

                fields.forEach((durationIndex, fieldsIndex) => {
                  const durationField = newValues[durationIndex];

                  function fromDateTimeToDuration() {
                    const dateTime = (
                      fieldsIndex
                        ? values[fields[fieldsIndex - 1]]
                        : values[index]
                    ) as DateTime;

                    const durationAsDateTime = durationField as DateTime;

                    return operation === "minus"
                      ? dateTime.diff(durationAsDateTime)
                      : durationAsDateTime.diff(dateTime);
                  }

                  if (durationField) {
                    //? Duration field defined, so must be calculated

                    if (newValues[index]?.isValid) {
                      //? New value defined, so must update duration field dateTimes

                      newValues[durationIndex] = (
                        fieldsIndex
                          ? (newValues[fields[fieldsIndex - 1]] as DateTime)
                          : newValues[index]
                      )![operation](
                        Duration.isDuration(durationField)
                          ? durationField
                          : fromDateTimeToDuration()
                      );
                    } else if (!Duration.isDuration(durationField)) {
                      //? New value undefined, return to duration format (inconsistent) instead of dateTime

                      newValues[durationIndex] = fromDateTimeToDuration();
                    }
                  }
                });
              }

              calculateDurationFields("minus");
              calculateDurationFields("plus");

              setValues(newValues);
            }}
            form={form}
          />
        );
      } else {
        return (
          <TimeDuration
            {...(timePointProps as any)}
            formatValue={transformInput}
            formatOnChange={transformOutput}
            form={form}
          />
        );
      }
    }) ?? [];

  if (insert) {
    for (const { Component, after } of insert) {
      const index = timePoints?.findIndex(({ id }) => id === after);

      if (index !== undefined)
        renderedComponents.splice(index + 1, 0, <Component />);
    }
  }

  const gridComponent = asGrid([
    {
      section: renderedComponents,
    },
  ]);

  return (
    renderedComponents.length && children
      ? asBordered(gridComponent, { title: children })
      : gridComponent
  ) as any;
}
