import rbac, { CognitoRoles, Permissions } from "helpers/rbac";
import { UserRole } from "models";

let roles: UserRole[] = [];

/**
 * Checks if the current logged user has a permission.
 * @param permission The permission to check
 * @returns True if the user has that permission, false otherwise
 */
export function canUser(permission: Permissions) {
  return roles.some((role) => canRole(role, permission));
}

/**
 * Checks if a role has a permission.
 * @param role The role to check
 * @param permission The permission to check
 * @returns True if the role has the permission
 */
export function canRole(role: UserRole, permission: Permissions) {
  return rbac[CognitoRoles[role]].includes(permission);
}

/**
 * Sets the roles of the current user.
 * @param userRoles The roles to set
 */
export function setUserRoles(userRoles: CognitoRoles[]) {
  roles = userRoles.map(roleToUserRole);
}

/**
 * Retrieves the roles assigned to the current user.
 * @returns The roles of the current user
 */
export function getUserRoles() {
  return roles.map(roleToUserRole);
}

/**
 * Transforms the AWS Cognito role to a backend role.
 * @param cognitoRole The role as described by AWS Cognito
 * @returns The role as described by the backend
 */
export function roleToUserRole(cognitoRole: CognitoRoles | UserRole) {
  return cognitoRole.toUpperCase() as UserRole;
}
