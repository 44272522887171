import IdentityCreation from "logic/identities/elements/IdentityCreation";
import { FinancingType, Identity } from "models";
import { ComponentProps } from "react";
import Form from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import { OutcomeProcedureFormIds } from "../OutcomeProcedure";

export default function MeetingFinancing(props: {
  data: Record<OutcomeProcedureFormIds, any>;
  formProps?: ComponentProps<typeof Form>;
  identity?: Identity;
}) {
  return (
    <Form
      defaultValues={{
        [OutcomeProcedureFormIds.FinancingDuration]: props.data[
          OutcomeProcedureFormIds.FinancingDuration
        ] as string,
        [OutcomeProcedureFormIds.FinancingType]: props.data[
          OutcomeProcedureFormIds.FinancingType
        ] as FinancingType,
        [OutcomeProcedureFormIds.FinancingValue]: props.data[
          OutcomeProcedureFormIds.FinancingValue
        ] as number,
      }}
      {...props.formProps}
    >
      {(form) =>
        asColumn(
          IdentityCreation(
            {
              FinancingDuration: OutcomeProcedureFormIds.FinancingDuration,
              FinancingType: OutcomeProcedureFormIds.FinancingType,
              FinancingValue: OutcomeProcedureFormIds.FinancingValue,
            },
            form
          )
        )
      }
    </Form>
  );
}
