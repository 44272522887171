import { BaseCSSProperties } from "@mui/material/styles/createMixins";
import { styled } from "@mui/system";
import { PALETTE } from "constants/ui";
import { ComponentProps } from "react";
import { darken } from "utils/format";
import Table from "./Table";

export const calendarColors = {
  ...PALETTE,
  gray: { main: "#D5D5D5" },
  late: { main: "#333333" },
  travel: { main: "rgba(230, 230, 230, 0.3)" },
};
export const baseDarken = -0.04;
export const selectedDarken = 0.08;
export const hoverDarken = (selectedDarken + baseDarken) / 2 + 0.05;

const StyledTable = styled(Table)(() => {
  const styles = {} as Record<string, BaseCSSProperties>;

  for (const color in calendarColors) {
    const typedColor = color as keyof typeof calendarColors;

    const cssColor = calendarColors[typedColor].main;

    const baseStyle =
      typedColor === "late"
        ? ({
            color: "#FFFFFF",
          } as BaseCSSProperties)
        : undefined;

    const baseKey = `& .calendar-day--${typedColor}`;

    styles[baseKey] = {
      ...baseStyle,
      backgroundColor: darken(cssColor, baseDarken),
    };

    styles[`${baseKey}:hover`] = {
      ...baseStyle,
      backgroundColor: darken(cssColor, baseDarken + hoverDarken),
    };

    const selectedKey = `${baseKey}Selected`;

    styles[selectedKey] = {
      ...baseStyle,
      backgroundColor: darken(cssColor, selectedDarken),
    };

    styles[`${selectedKey}:hover`] = {
      ...baseStyle,
      backgroundColor: darken(cssColor, selectedDarken + hoverDarken),
    };
  }

  return styles;
});

type TaskRow = { id: string; late?: boolean; color?: string };

export default function TaskTable(
  props: {
    rows: TaskRow[];
    selected?: TaskRow[];
  } & Omit<ComponentProps<typeof Table>, "rows">
) {
  const { selected, ...tableProps } = props;

  return (
    <StyledTable
      getRowClassName={({ id, row: { late, travel, color } }) => {
        const isSelected = selected?.some(({ id: searchID }) =>
          id.toString().startsWith(searchID)
        );

        const baseClass = `calendar-day--${
          late ? "late" : travel ? "travel" : color ?? "primary"
        }`;

        return isSelected ? `${baseClass}Selected` : baseClass;
      }}
      {...(tableProps as any)}
    />
  );
}
