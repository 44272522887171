import {
  Link as MuiLink,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from "@mui/material";
import { Coordinates } from "utils/locate";
import asSpace from "../LAYOUT/asSpace";

export default function Text(
  props: {
    type?:
      | "title"
      | "subtitle"
      | "section"
      | "sectionSubtitle"
      | "description"
      | "fieldTitle"
      | "common"
      | "fieldBody"
      | "chartTitle"
      | "tableBody";
    spaceProps?: Parameters<typeof asSpace>[1];
    mailTo?: `${string}@${string}.${string}`;
    phone?: string;
    toMap?: Coordinates;
    href?: string;
  } & Omit<MuiTypographyProps, "variant">
) {
  const {
    type,
    spaceProps,
    color: propsColor,
    mailTo,
    phone,
    toMap,
    href,
    onClick,
    ...typographyProps
  } = props;

  let variant: MuiTypographyProps["variant"];
  let color: MuiTypographyProps["color"];
  let mb: NonNullable<Parameters<typeof asSpace>[1]>["mb"];

  switch (type) {
    case "title":
      variant = "h4";
      break;

    case "chartTitle":
    case "subtitle":
      variant = "h5";
      color = "textSecondary";
      break;

    case "section":
      variant = "h5";
      break;

    case "fieldBody":
      variant = "h5";
      mb = -3;
      break;

    case "sectionSubtitle":
    case "fieldTitle":
    case "description":
      color = "textSecondary";
      break;

    case "tableBody":
      variant = "body2";
      break;
  }

  const text =
    onClick || mailTo || phone || toMap || href ? (
      <MuiLink
        component={onClick ? "button" : "a"}
        type="button"
        variant={variant}
        color={propsColor ?? color}
        href={
          href ??
          (mailTo
            ? `mailto:${mailTo}`
            : phone
            ? `tel:${phone}`
            : toMap
            ? `https://www.google.com/maps/search/?api=1&query=${toMap[1]},${toMap[0]}`
            : undefined)
        }
        target={toMap ? "_blank" : undefined}
        onClick={onClick}
        {...(typographyProps as any)}
      />
    ) : (
      <MuiTypography
        variant={variant}
        color={propsColor ?? color}
        {...typographyProps}
      />
    );

  return asSpace(text, {
    component: "span",
    mb,
    ...spaceProps,
  });
}
