import {
  ActivityCodename,
  ActivityPhase,
  Bank,
  ConditionFilter,
  FinancingType,
  Gender,
  HouseHunter,
  LastWorkDay,
  LocationName,
  ModalityName,
  ModelType,
  Nationality,
  OutcomeMotivationCodename,
  OutcomeName,
  PaymentService,
  PaymentState,
  PaymentType,
  PlaceType,
  RealEstate,
  Review,
  Source,
  Supplier,
  UserRole,
} from "models";
import { ComponentProps, useState } from "react";
import { getNamespaceForEnumOptions, useTranslation } from "utils/localize";
import { useAsync } from "utils/render";
import { Entity } from "utils/store";
import Radio from "./Radio";
import Select from "./Select";

export type EnumOptions =
  | typeof Source
  | typeof RealEstate
  | typeof FinancingType
  | typeof ModalityName
  | typeof OutcomeName
  | typeof LocationName
  | typeof UserRole
  | typeof Gender
  | typeof Review
  | typeof PlaceType
  | typeof Nationality
  | typeof LastWorkDay
  | typeof HouseHunter
  | typeof PaymentState
  | typeof Supplier
  | typeof Bank
  | typeof PaymentType
  | typeof PaymentService
  | typeof ModelType
  | typeof ActivityCodename
  | typeof OutcomeMotivationCodename
  | typeof ConditionFilter
  | typeof ActivityPhase;

type Option = EnumOptions | Entity | string[] | number[];

type OptionLoader<T extends Option> = () => Promise<T[]>;

export default function Choice<T extends Option, S extends boolean>(
  props: {
    options?: T | OptionLoader<T>;
    select?: S;
    change?: (options: T | T[]) => object | Promise<object>;
    getLabel?: (option: T) => string;
    identifier?: keyof T;
  } & Omit<
    ComponentProps<
      typeof Select &
        (boolean extends S
          ? typeof Radio
          : S extends true
          ? object
          : typeof Radio)
    >,
    "options" | "getOptionLabel" | "values" | "getValueLabel" | "row" | "type"
  >
) {
  const {
    options,
    change,
    select,
    identifier,
    getLabel,
    asField,
    ...baseProps
  } = props as typeof props & Pick<ComponentProps<typeof Radio>, "asField">;

  const interpret =
    typeof options === "function"
      ? "async"
      : Array.isArray(options)
      ? undefined
      : "enum";

  const namespace =
    interpret === "enum"
      ? getNamespaceForEnumOptions(options as EnumOptions)
      : undefined;

  const { t } = useTranslation(namespace);

  const [loadedValues, setLoadedValues] = useState<
    string[] | Record<string, { readonly id: string }> | undefined
  >(
    (interpret === "enum" && change) || interpret === "async"
      ? undefined
      : (interpret === "enum"
          ? options && Object.keys(options)
          : (options as string[])) ?? []
  );

  useAsync(
    {
      condition: !loadedValues,
      action: change
        ? async () =>
            interpret === "async"
              ? change(await (options as OptionLoader<T>)())
              : (Object.keys(await change(options as T)) as
                  | string[]
                  | Record<string, string>
                  | { readonly id: string }[])
        : (options as OptionLoader<T>),
    },
    (data) => {
      if (interpret === "enum") {
        setLoadedValues(data as string[]);
      } else {
        let resolvedValues = {} as Record<string, { readonly id: string }>;

        (data as (string | { readonly id: string })[])?.forEach((value) => {
          if (typeof value === "string") {
            resolvedValues[value] = { id: value };
          } else {
            resolvedValues[value[(identifier as keyof typeof value) ?? "id"]] =
              value;
          }
        });

        setLoadedValues(resolvedValues);
      }
    }
  );

  if (loadedValues) {
    const values: string[] =
      interpret === "async"
        ? Object.keys(loadedValues)
        : (loadedValues as string[]);

    const getValueLabel =
      interpret === "enum"
        ? (key: unknown) => {
            const resolvedKey =
              namespace && options?.[key as keyof typeof options]
                ? t(`${namespace}:${key}`)
                : key;

            return getLabel?.(resolvedKey as T) ?? resolvedKey;
          }
        : interpret === "async"
        ? getLabel &&
          ((id: unknown) =>
            getLabel(
              (loadedValues as Record<string, T>)[
                id as keyof typeof loadedValues
              ]
            ))
        : getLabel;

    return select || values.length > 4 ? (
      <Select
        {...(baseProps as ComponentProps<typeof Select>)}
        options={values}
        getOptionLabel={getValueLabel as any}
      />
    ) : (
      <Radio
        {...(baseProps as ComponentProps<typeof Radio>)}
        asField={asField}
        row={values.length < 3}
        values={values}
        getValueLabel={getValueLabel as any}
      />
    );
  } else {
    return null;
  }
}
