import {
  Badge as MuiBadge,
  BadgeProps as MuiBadgeProps,
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from "@mui/material";
import { PALETTE } from "constants/ui";
import { ComponentProps, MouseEvent, ReactNode } from "react";
import { IconBaseProps, IconType as ReactIcon } from "react-icons/lib";
import { Complete, useClickable } from "view/inputs/Button";
import asSpace from "../LAYOUT/asSpace";
import Progress from "./Progress";

export default function Icon(
  props: {
    children?: ReactIcon;
    badge?: ReactNode;
    noMargin?: boolean;
  } & Omit<ComponentProps<typeof IconButton>, "children">
) {
  const {
    onClick,
    children: ViewIcon,
    badge,
    noMargin,
    ...iconButtonProps
  } = props;

  const basicIconProps: IconBaseProps = {
    size: 22,
    color: iconButtonProps.color
      ? PALETTE[iconButtonProps.color as keyof typeof PALETTE]?.main
      : undefined,
  };

  const icon = ViewIcon ? (
    onClick ? (
      <ViewIcon />
    ) : noMargin ? (
      <ViewIcon {...basicIconProps} />
    ) : (
      asSpace(<ViewIcon {...basicIconProps} />, { mt: 0.6 })
    )
  ) : null;

  const badgeProps: MuiBadgeProps | undefined = badge
    ? badge === true
      ? { variant: "dot" }
      : { badgeContent: badge }
    : undefined;

  return onClick ? (
    <IconButton onClick={onClick} {...iconButtonProps}>
      {badgeProps ? (
        <MuiBadge color="primary" {...badgeProps}>
          {icon}
        </MuiBadge>
      ) : (
        icon
      )}
    </IconButton>
  ) : (
    icon
  );
}

function IconButton(
  props: {
    onClick?: (
      complete: Complete,
      e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
    ) => void;
  } & Omit<MuiIconButtonProps, "onClick">
) {
  const { children, disabled, onClick, ...iconButtonProps } = props;

  const [loading, setClickFunction] = useClickable();

  return (
    <MuiIconButton
      disabled={disabled ?? loading}
      onClick={
        onClick &&
        ((e) => {
          const event = { ...e };

          setClickFunction(
            () => (complete: Complete) => onClick(complete, event)
          );
        })
      }
      {...(iconButtonProps as any)}
    >
      <Progress spinner disabled={!loading}>
        {children}
      </Progress>
    </MuiIconButton>
  );
}
