export const CONTENT_MARGIN_DESKTOP = 4;

export const CONTENT_MARGIN_MOBILE = 2;

export const BIG_COMPONENTS_HEIGHT = 45;
export const SMALL_COMPONENTS_HEIGHT = 25;

export const LOADING_TIMEOUT = 1200;

export const PALETTE = {
  primary: {
    main: "#20D0D6",
  },
  secondary: {
    main: "#FF918D",
  },
  success: {
    main: "#98D268",
  },
  warning: {
    main: "#F4BE52",
  },
  error: {
    main: "#FF918D",
  },
};
