// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const UserRole = {
  "ADMIN": "ADMIN",
  "MANAGEMENT": "MANAGEMENT",
  "CREDITBROKER": "CREDITBROKER",
  "CALLCENTER": "CALLCENTER",
  "HOUSEHUNTER": "HOUSEHUNTER",
  "ACCOUNTING": "ACCOUNTING",
  "AGREEMENTS": "AGREEMENTS",
  "REALESTATE": "REALESTATE",
  "BANKREALESTATE": "BANKREALESTATE",
  "MARKETING": "MARKETING",
  "COMMUNICATION": "COMMUNICATION",
  "CONTRACTSANDPOLICIES": "CONTRACTSANDPOLICIES",
  "AUDIT": "AUDIT",
  "APPRAISAL": "APPRAISAL",
  "FILE": "FILE",
  "CREDIT": "CREDIT",
  "INCOME": "INCOME",
  "BUSINESSINTELLIGENCE": "BUSINESSINTELLIGENCE",
  "HUMANRESOURCES": "HUMANRESOURCES",
  "LEGAL": "LEGAL",
  "FUND": "FUND",
  "ARCHIVE": "ARCHIVE",
  "CUSTOMERSERVICE": "CUSTOMERSERVICE",
  "PHONESELLER": "PHONESELLER",
  "DOCUMENTREQUESTER": "DOCUMENTREQUESTER"
};

const ModelType = {
  "TASK": "TASK",
  "IDENTITY": "IDENTITY",
  "USER": "USER",
  "ACTIVITY": "ACTIVITY",
  "NOTIFICATION": "NOTIFICATION",
  "PAYMENT": "PAYMENT",
  "CONFIGURATION": "CONFIGURATION"
};

const ModalityName = {
  "PHYSICAL": "PHYSICAL",
  "VOCAL": "VOCAL",
  "VIDEO": "VIDEO",
  "MAIL": "MAIL"
};

const LocationName = {
  "HEADQUARTERS": "HEADQUARTERS",
  "BANKBRANCH": "BANKBRANCH",
  "IDENTITYHOME": "IDENTITYHOME",
  "IDENTITYDOMICILE": "IDENTITYDOMICILE",
  "IDENTITYWORKPLACE": "IDENTITYWORKPLACE",
  "VENDORHOME": "VENDORHOME",
  "VENDORWORKPLACE": "VENDORWORKPLACE",
  "PLACEOFBUSINESS": "PLACEOFBUSINESS",
  "ESTATEAGENCY": "ESTATEAGENCY",
  "NOTARYOFFICE": "NOTARYOFFICE",
  "PROFESSIONALSTUDIO": "PROFESSIONALSTUDIO",
  "PROPERTYUNDERWARRANT": "PROPERTYUNDERWARRANT",
  "RELATIVESHOME": "RELATIVESHOME"
};

const Review = {
  "NOREVIEW": "NOREVIEW",
  "GOOGLE": "GOOGLE",
  "FACEBOOK": "FACEBOOK"
};

const OutcomeName = {
  "POSITIVE": "POSITIVE",
  "WEAK": "WEAK",
  "NEGATIVE": "NEGATIVE",
  "NOTEXECUTED": "NOTEXECUTED"
};

const MutationAction = {
  "CREATE": "CREATE",
  "UPDATE": "UPDATE",
  "DELETE": "DELETE"
};

const NotificationState = {
  "QUEUED": "QUEUED",
  "VIEWED": "VIEWED",
  "READ": "READ"
};

const Nationality = {
  "AFGHAN": "AFGHAN",
  "ALBANIAN": "ALBANIAN",
  "ALGERIAN": "ALGERIAN",
  "AMERICAN": "AMERICAN",
  "ANDORRAN": "ANDORRAN",
  "ANGOLAN": "ANGOLAN",
  "ANTIGUANS": "ANTIGUANS",
  "ARGENTINEAN": "ARGENTINEAN",
  "ARMENIAN": "ARMENIAN",
  "ARUBAN": "ARUBAN",
  "AUSTRALIAN": "AUSTRALIAN",
  "AUSTRIAN": "AUSTRIAN",
  "AZERBAIJANI": "AZERBAIJANI",
  "BAHAMIAN": "BAHAMIAN",
  "BAHRAINI": "BAHRAINI",
  "BANGLADESHI": "BANGLADESHI",
  "BARBADIAN": "BARBADIAN",
  "BARBUDANS": "BARBUDANS",
  "BASQUE": "BASQUE",
  "BELARUSIAN": "BELARUSIAN",
  "BELGIAN": "BELGIAN",
  "BELIZEAN": "BELIZEAN",
  "BENINESE": "BENINESE",
  "BERMUDIAN": "BERMUDIAN",
  "BHUTANESE": "BHUTANESE",
  "BIRMANIAN": "BIRMANIAN",
  "BOLIVIAN": "BOLIVIAN",
  "BOSNIAN": "BOSNIAN",
  "BOTSWANA": "BOTSWANA",
  "BRAZILIAN": "BRAZILIAN",
  "BRITISH": "BRITISH",
  "BRUNEIAN": "BRUNEIAN",
  "BULGARIAN": "BULGARIAN",
  "BURKINABE": "BURKINABE",
  "BURUNDIAN": "BURUNDIAN",
  "CAMBODIAN": "CAMBODIAN",
  "CAMEROONIAN": "CAMEROONIAN",
  "CANADESE": "CANADESE",
  "CAPEVERDEAN": "CAPEVERDEAN",
  "CENTRALAFRICAN": "CENTRALAFRICAN",
  "CHADIAN": "CHADIAN",
  "CHILEAN": "CHILEAN",
  "CHINESE": "CHINESE",
  "CINGALESE": "CINGALESE",
  "COLOMBIAN": "COLOMBIAN",
  "COMORAN": "COMORAN",
  "CONGOLESE": "CONGOLESE",
  "COSTARICAN": "COSTARICAN",
  "CROATIAN": "CROATIAN",
  "CUBAN": "CUBAN",
  "CYPRIOT": "CYPRIOT",
  "CZECH": "CZECH",
  "DANISH": "DANISH",
  "DOMINICAN": "DOMINICAN",
  "EASTTIMORESE": "EASTTIMORESE",
  "ECUADOREAN": "ECUADOREAN",
  "EGYPTIAN": "EGYPTIAN",
  "EMIRIAN": "EMIRIAN",
  "EQUATOGUINEAN": "EQUATOGUINEAN",
  "ERITREAN": "ERITREAN",
  "ESTONIAN": "ESTONIAN",
  "ETHIOPIAN": "ETHIOPIAN",
  "FALKLANDISLANDER": "FALKLANDISLANDER",
  "FAROESE": "FAROESE",
  "FIJIAN": "FIJIAN",
  "FILIPINO": "FILIPINO",
  "FINNISH": "FINNISH",
  "FRENCH": "FRENCH",
  "GABONESE": "GABONESE",
  "GAMBIAN": "GAMBIAN",
  "GEORGIAN": "GEORGIAN",
  "GERMAN": "GERMAN",
  "GHANAIAN": "GHANAIAN",
  "GIBRALTARIAN": "GIBRALTARIAN",
  "GIBUTIANI": "GIBUTIANI",
  "GREEK": "GREEK",
  "GRENADIAN": "GRENADIAN",
  "GUATEMALAN": "GUATEMALAN",
  "GUINEABISSAU": "GUINEABISSAU",
  "GUINEAN": "GUINEAN",
  "GUYANESE": "GUYANESE",
  "HAITIAN": "HAITIAN",
  "HONDURAN": "HONDURAN",
  "HONGKONGERS": "HONGKONGERS",
  "HUNGARIAN": "HUNGARIAN",
  "ICELANDER": "ICELANDER",
  "KIRIBATI": "KIRIBATI",
  "INDIAN": "INDIAN",
  "INDONESIAN": "INDONESIAN",
  "IRANIAN": "IRANIAN",
  "IRAQI": "IRAQI",
  "IRISH": "IRISH",
  "ISRAELI": "ISRAELI",
  "ITALIAN": "ITALIAN",
  "IVORIAN": "IVORIAN",
  "JAMAICAN": "JAMAICAN",
  "JAPANESE": "JAPANESE",
  "JORDANIAN": "JORDANIAN",
  "KAZAKHSTANI": "KAZAKHSTANI",
  "KENYAN": "KENYAN",
  "KOSOVARIAN": "KOSOVARIAN",
  "KUWAITI": "KUWAITI",
  "KYRGYZ": "KYRGYZ",
  "LAOTIAN": "LAOTIAN",
  "LATVIAN": "LATVIAN",
  "LEBANESE": "LEBANESE",
  "LIBERIAN": "LIBERIAN",
  "LIBYAN": "LIBYAN",
  "LIECHTENSTEINER": "LIECHTENSTEINER",
  "LITHUANIAN": "LITHUANIAN",
  "LOTHIAN": "LOTHIAN",
  "LUXEMBOURGER": "LUXEMBOURGER",
  "MACAO": "MACAO",
  "MACEDONIAN": "MACEDONIAN",
  "MALAGASY": "MALAGASY",
  "MALAWIAN": "MALAWIAN",
  "MALAYSIAN": "MALAYSIAN",
  "MALDIVAN": "MALDIVAN",
  "MALIAN": "MALIAN",
  "MALTESE": "MALTESE",
  "MANX": "MANX",
  "MARSHALLESE": "MARSHALLESE",
  "MAURITANIAN": "MAURITANIAN",
  "MAURITIAN": "MAURITIAN",
  "MEXICAN": "MEXICAN",
  "MICRONESIAN": "MICRONESIAN",
  "MOLDOVAN": "MOLDOVAN",
  "MONACAN": "MONACAN",
  "MONGOLIAN": "MONGOLIAN",
  "MONTENEGRINE": "MONTENEGRINE",
  "MOROCCAN": "MOROCCAN",
  "MOZAMBICAN": "MOZAMBICAN",
  "NAMIBIAN": "NAMIBIAN",
  "NAURUAN": "NAURUAN",
  "NEPALESE": "NEPALESE",
  "NETHERLANDER": "NETHERLANDER",
  "NEWZEALANDER": "NEWZEALANDER",
  "NICARAGUAN": "NICARAGUAN",
  "NIGERIAN": "NIGERIAN",
  "NIGERIEN": "NIGERIEN",
  "NORTHKOREAN": "NORTHKOREAN",
  "NORVEGIAN": "NORVEGIAN",
  "OMANI": "OMANI",
  "PAKISTANI": "PAKISTANI",
  "PALAUAN": "PALAUAN",
  "PALESTINESE": "PALESTINESE",
  "PANAMANIAN": "PANAMANIAN",
  "PAPUANEWGUINEAN": "PAPUANEWGUINEAN",
  "PARAGUAYAN": "PARAGUAYAN",
  "PERUVIAN": "PERUVIAN",
  "POLISH": "POLISH",
  "PORTUGUESE": "PORTUGUESE",
  "PUERTORICAN": "PUERTORICAN",
  "QATARI": "QATARI",
  "QUEBECER": "QUEBECER",
  "REUNION": "REUNION",
  "ROMANIAN": "ROMANIAN",
  "RUSSIAN": "RUSSIAN",
  "RWANDAN": "RWANDAN",
  "SAINTKITTS": "SAINTKITTS",
  "SAINTLUCIAN": "SAINTLUCIAN",
  "SALVADOREAN": "SALVADOREAN",
  "SAMOAN": "SAMOAN",
  "SANMARINESE": "SANMARINESE",
  "SAOTOME": "SAOTOME",
  "SAUDI": "SAUDI",
  "SENEGALESE": "SENEGALESE",
  "SERBIAN": "SERBIAN",
  "SEYCHELLES": "SEYCHELLES",
  "SIERRALEONESE": "SIERRALEONESE",
  "SINGAPOREAN": "SINGAPOREAN",
  "SLOVAKIAN": "SLOVAKIAN",
  "SLOVENIAN": "SLOVENIAN",
  "SOLOMONISLANDER": "SOLOMONISLANDER",
  "SOMALI": "SOMALI",
  "SOMALILANDER": "SOMALILANDER",
  "SOUTHAFRICAN": "SOUTHAFRICAN",
  "SOUTHKOREAN": "SOUTHKOREAN",
  "SPANISH": "SPANISH",
  "SRILANKAN": "SRILANKAN",
  "SUDANESE": "SUDANESE",
  "SURINAMESE": "SURINAMESE",
  "SWAZI": "SWAZI",
  "SWEDISH": "SWEDISH",
  "SWISS": "SWISS",
  "SYRIAN": "SYRIAN",
  "TAIWANESE": "TAIWANESE",
  "TAJIK": "TAJIK",
  "TANZANIAN": "TANZANIAN",
  "THAI": "THAI",
  "TOBAGONIAN": "TOBAGONIAN",
  "TOGOLESE": "TOGOLESE",
  "TONGAN": "TONGAN",
  "TRINIDADIAN": "TRINIDADIAN",
  "TUNISIAN": "TUNISIAN",
  "TURKISH": "TURKISH",
  "TURKMENIAN": "TURKMENIAN",
  "TUVALUAN": "TUVALUAN",
  "UGANDAN": "UGANDAN",
  "UKRAINIAN": "UKRAINIAN",
  "URUGUAYAN": "URUGUAYAN",
  "UZBEKISTANI": "UZBEKISTANI",
  "VANAUTANI": "VANAUTANI",
  "VATICANESE": "VATICANESE",
  "VENEZUELAN": "VENEZUELAN",
  "VIETNAMESE": "VIETNAMESE",
  "VINCENTIAN": "VINCENTIAN",
  "VIRGINISLANDER": "VIRGINISLANDER",
  "YEMENITE": "YEMENITE",
  "ZAMBIAN": "ZAMBIAN",
  "ZIMBABWEAN": "ZIMBABWEAN"
};

const Source = {
  "MUTUOPOSITIVO": "MUTUOPOSITIVO",
  "BEREFERRAL": "BEREFERRAL",
  "LINKSOLUZIONI": "LINKSOLUZIONI",
  "FACILEIT": "FACILEIT",
  "MPREFERRAL": "MPREFERRAL",
  "FCREFERRAL": "FCREFERRAL",
  "AGREFERRAL": "AGREFERRAL",
  "DIREFERRAL": "DIREFERRAL",
  "OFFICEREFERRAL": "OFFICEREFERRAL",
  "REALESTATEAGENCY": "REALESTATEAGENCY",
  "MANAGEMENT": "MANAGEMENT",
  "CREDITBROKER": "CREDITBROKER",
  "REFERRAL": "REFERRAL",
  "FACEBOOKCHATBOT": "FACEBOOKCHATBOT"
};

const RealEstate = {
  "PURCHASEPROPOSALSIGNED": "PURCHASEPROPOSALSIGNED",
  "NEGOTIATIONSTARTED": "NEGOTIATIONSTARTED",
  "IDENTIFIED": "IDENTIFIED",
  "PROACTIVERESEARCH": "PROACTIVERESEARCH",
  "AVAILABLETOSIGNHHP": "AVAILABLETOSIGNHHP",
  "SELLINGOWNHOUSE": "SELLINGOWNHOUSE",
  "OWNREALESTATE": "OWNREALESTATE"
};

const PlaceType = {
  "RESIDENCE": "RESIDENCE",
  "DOMICILE": "DOMICILE"
};

const FinancingType = {
  "FIRSTHOUSEPURCHASEMORTGAGE": "FIRSTHOUSEPURCHASEMORTGAGE",
  "FIRSTHOUSEPURCHASEMORTGAGEAUCTION": "FIRSTHOUSEPURCHASEMORTGAGEAUCTION",
  "SECONDHOUSEPURCHASEMORTGAGE": "SECONDHOUSEPURCHASEMORTGAGE",
  "SURROGATEMORTGAGE": "SURROGATEMORTGAGE",
  "SHOPPURCHASEMORTGAGE": "SHOPPURCHASEMORTGAGE",
  "CONSOLIDATEDMORTGAGE": "CONSOLIDATEDMORTGAGE",
  "SUBSTITUTIONMORTGAGE": "SUBSTITUTIONMORTGAGE",
  "LIQUIDITYMORTGAGE": "LIQUIDITYMORTGAGE",
  "CONSOLIDATEDMORTGAGELIQUIDITY": "CONSOLIDATEDMORTGAGELIQUIDITY",
  "SUBSTITUTIONMORTGAGELIQUIDITY": "SUBSTITUTIONMORTGAGELIQUIDITY",
  "SALCONSTRUCTIONMORTGAGE": "SALCONSTRUCTIONMORTGAGE",
  "ONETRANCHECONSTRUCTIONMORTGAGE": "ONETRANCHECONSTRUCTIONMORTGAGE",
  "SALRENOVATIONMORTGAGE": "SALRENOVATIONMORTGAGE",
  "ONETRANCHERENOVATIONMORTGAGE": "ONETRANCHERENOVATIONMORTGAGE",
  "PURCHASEMORTGAGESALRENOVATION": "PURCHASEMORTGAGESALRENOVATION",
  "PURCHASEMORTGAGEONETRANCHERENOVATION": "PURCHASEMORTGAGEONETRANCHERENOVATION",
  "PERSONALLOAN": "PERSONALLOAN",
  "FINALIZEDLOAN": "FINALIZEDLOAN",
  "CONSOLIDATEDLOAN": "CONSOLIDATEDLOAN",
  "SUBSTITUTIONLOAN": "SUBSTITUTIONLOAN",
  "CONSOLIDATEDLOANLIQUIDITY": "CONSOLIDATEDLOANLIQUIDITY",
  "SUBSTITUTIONLOANLIQUIDITY": "SUBSTITUTIONLOANLIQUIDITY",
  "SALARYBACKEDLOAN": "SALARYBACKEDLOAN",
  "SALARYBACKEDLOANDELEGATION": "SALARYBACKEDLOANDELEGATION"
};

const Gender = {
  "FEMALE": "FEMALE",
  "MALE": "MALE",
  "NONBINARY": "NONBINARY"
};

const HouseHunter = {
  "INTERNAL": "INTERNAL",
  "PREMIERCASA": "PREMIERCASA",
  "REALESTATEAGENCY": "REALESTATEAGENCY"
};

const ActivityCodename = {
  "CONVERSIONTOCUSTOMER": "CONVERSIONTOCUSTOMER",
  "SCHEDULEMEETING": "SCHEDULEMEETING",
  "CONFIRMMEETING": "CONFIRMMEETING",
  "MEETING": "MEETING",
  "APPROVEQUOTE": "APPROVEQUOTE",
  "DELIVERPROPOSAL": "DELIVERPROPOSAL",
  "DELIVERCONVERSIONTOCUSTOMER": "DELIVERCONVERSIONTOCUSTOMER",
  "CENSUS": "CENSUS",
  "APPRAISAL": "APPRAISAL",
  "DELIVERFUND": "DELIVERFUND",
  "RECEIVEDELIBERATION": "RECEIVEDELIBERATION",
  "DEED": "DEED",
  "APPROVECUSTOMER": "APPROVECUSTOMER",
  "DELIVERDOSSIER": "DELIVERDOSSIER",
  "RECEIVEDOCUMENTSMEETING": "RECEIVEDOCUMENTSMEETING",
  "PDFONEA": "PDFONEA",
  "CRIFCR": "CRIFCR",
  "QUOTE": "QUOTE",
  "PDFONEB": "PDFONEB",
  "ISSUECONTRACTS": "ISSUECONTRACTS",
  "PDFTWOA": "PDFTWOA",
  "BANKREPORT": "BANKREPORT",
  "NEGOTIATION": "NEGOTIATION",
  "PROPOSALWITHDRAWAL": "PROPOSALWITHDRAWAL",
  "DELIVERBANKREPORT": "DELIVERBANKREPORT",
  "RECEIVEBANKREPORT": "RECEIVEBANKREPORT",
  "DOSSIER": "DOSSIER",
  "SCHEDULECENSUS": "SCHEDULECENSUS",
  "RECEIVEFINANCINGOUTCOME": "RECEIVEFINANCINGOUTCOME",
  "RECEIVEAPPRAISAL": "RECEIVEAPPRAISAL",
  "REQUESTFUND": "REQUESTFUND",
  "RECEIVEFUND": "RECEIVEFUND",
  "PLANDEED": "PLANDEED",
  "SCHEDULEDEED": "SCHEDULEDEED",
  "VERIFICATIONS": "VERIFICATIONS",
  "PROPOSALSIGNATURE": "PROPOSALSIGNATURE",
  "REQUESTDOCUMENTS": "REQUESTDOCUMENTS"
};

const OutcomeMotivationCodename = {
  "WRONGPERSONALMANAGEMENT": "WRONGPERSONALMANAGEMENT",
  "LACKOFEXPERTISE": "LACKOFEXPERTISE",
  "LACKOFREPLYFROMCOLLEAGUE": "LACKOFREPLYFROMCOLLEAGUE",
  "LACKOFREPLYFROMEXTERNAL": "LACKOFREPLYFROMEXTERNAL",
  "LACKOFOUTCOME": "LACKOFOUTCOME",
  "CANCELLED": "CANCELLED",
  "OTHER": "OTHER",
  "QUOTESCHEDULED": "QUOTESCHEDULED",
  "HOUSEHUNTERSCHEDULED": "HOUSEHUNTERSCHEDULED",
  "DDDQUOTESCHEDULED": "DDDQUOTESCHEDULED",
  "OKIFPROPOSALSIGNATURE": "OKIFPROPOSALSIGNATURE",
  "PHYSICALMEETINGSCHEDULED": "PHYSICALMEETINGSCHEDULED",
  "CRIFCRSTART": "CRIFCRSTART",
  "QUOTESTART": "QUOTESTART",
  "HOUSEHUNTERSTART": "HOUSEHUNTERSTART",
  "DEFINEREALESTATE": "DEFINEREALESTATE",
  "SCHEDULEPHYSICALMEETING": "SCHEDULEPHYSICALMEETING",
  "SCHEDULEVIRTUALMEETING": "SCHEDULEVIRTUALMEETING",
  "CRIF": "CRIF",
  "CR": "CR",
  "INJURIOUS": "INJURIOUS",
  "CIG": "CIG",
  "OVERINDEBTED": "OVERINDEBTED",
  "LACKOFINCOMES": "LACKOFINCOMES",
  "DEPENDENTS": "DEPENDENTS",
  "LACKOFWARRANTIES": "LACKOFWARRANTIES",
  "FARFROMDECISIONTOPURCHASE": "FARFROMDECISIONTOPURCHASE",
  "WILLLETUSKNOW": "WILLLETUSKNOW",
  "RESOURCEDIDNOTUNDERSTANDBUYERPERSONA": "RESOURCEDIDNOTUNDERSTANDBUYERPERSONA",
  "RESOURCEWASUNABLEFORVIRTUALMEETING": "RESOURCEWASUNABLEFORVIRTUALMEETING",
  "RESOURCEWASUNABLE": "RESOURCEWASUNABLE",
  "POSTPONESPURCHASE": "POSTPONESPURCHASE",
  "RENTTOBUY": "RENTTOBUY",
  "MORTGAGEWITHOWNBANK": "MORTGAGEWITHOWNBANK",
  "NOTINTERESTEDINSERVICES": "NOTINTERESTEDINSERVICES",
  "LACKOFCONDITIONS": "LACKOFCONDITIONS",
  "NOTCOLLABORATIVE": "NOTCOLLABORATIVE",
  "COMPANYREPUTATIONNOTPROTECTED": "COMPANYREPUTATIONNOTPROTECTED",
  "LACKOFDOCUMENTS": "LACKOFDOCUMENTS"
};

const ConditionFilter = {
  "ONLY": "ONLY",
  "REMOVE": "REMOVE"
};

const ActivityPhase = {
  "LEGALDISPUTE": "LEGALDISPUTE",
  "NOTINTERESTED": "NOTINTERESTED",
  "ARCHIVE": "ARCHIVE",
  "CONTACTTOSCHEDULE": "CONTACTTOSCHEDULE",
  "CONTACTTOMEET": "CONTACTTOMEET",
  "LEADMET": "LEADMET",
  "LEADWEAK": "LEADWEAK",
  "LEADLOW": "LEADLOW",
  "LEADMEDIUM": "LEADMEDIUM",
  "LEADHIGH": "LEADHIGH",
  "PROSPECTMEDIUM": "PROSPECTMEDIUM",
  "PROSPECTHIGH": "PROSPECTHIGH",
  "CUSTOMER": "CUSTOMER",
  "CUSTOMERCONVERSIONMANAGEMENT": "CUSTOMERCONVERSIONMANAGEMENT",
  "CUSTOMERREALESTATESEARCH": "CUSTOMERREALESTATESEARCH",
  "CUSTOMERNEGOTIATION": "CUSTOMERNEGOTIATION",
  "CUSTOMERPROPOSAL": "CUSTOMERPROPOSAL",
  "CUSTOMERBANKOUTCOME": "CUSTOMERBANKOUTCOME",
  "CUSTOMERREVENUESEVENTYFIVEDAYS": "CUSTOMERREVENUESEVENTYFIVEDAYS",
  "CUSTOMERREVENUESIXTYDAYS": "CUSTOMERREVENUESIXTYDAYS",
  "CUSTOMERREVENUEFORTYFIVEDAYS": "CUSTOMERREVENUEFORTYFIVEDAYS",
  "CUSTOMERREVENUETHIRTYFIVEDAYS": "CUSTOMERREVENUETHIRTYFIVEDAYS",
  "CUSTOMERREVENUETWENTYFIVEDAYS": "CUSTOMERREVENUETWENTYFIVEDAYS",
  "CUSTOMERREVENUETWENTYDAYS": "CUSTOMERREVENUETWENTYDAYS",
  "CUSTOMERREVENUEFIFTEENDAYS": "CUSTOMERREVENUEFIFTEENDAYS",
  "CUSTOMERREVENUETENDAYS": "CUSTOMERREVENUETENDAYS",
  "CUSTOMERREVENUETWODAYS": "CUSTOMERREVENUETWODAYS",
  "CUSTOMERREVENUE": "CUSTOMERREVENUE"
};

const LastWorkDay = {
  "FRIDAY": "FRIDAY",
  "SATURDAY": "SATURDAY"
};

const PaymentState = {
  "VALIDATED": "VALIDATED",
  "TOPAY": "TOPAY",
  "PAID": "PAID"
};

const Supplier = {
  "BACKOFFICEEUROPESHPK": "BACKOFFICEEUROPESHPK"
};

const PaymentService = {
  "MEETINGS": "MEETINGS"
};

const PaymentType = {
  "TRANSFER": "TRANSFER"
};

const Bank = {
  "CREDITOEMILIANOSPA": "CREDITOEMILIANOSPA"
};

const { Task, Audit, Notification, Identity, Activity, User, Payment, Configuration, Place, BuyerPersona, Modality, Location, Outcome, OutcomeMotivation, Condition, ActivityChild, Scheduler, PlanningParameters, PrioritizationParameters, Break } = initSchema(schema);

export {
  Task,
  Audit,
  Notification,
  Identity,
  Activity,
  User,
  Payment,
  Configuration,
  UserRole,
  ModelType,
  ModalityName,
  LocationName,
  Review,
  OutcomeName,
  MutationAction,
  NotificationState,
  Nationality,
  Source,
  RealEstate,
  PlaceType,
  FinancingType,
  Gender,
  HouseHunter,
  ActivityCodename,
  OutcomeMotivationCodename,
  ConditionFilter,
  ActivityPhase,
  LastWorkDay,
  PaymentState,
  Supplier,
  PaymentService,
  PaymentType,
  Bank,
  Place,
  BuyerPersona,
  Modality,
  Location,
  Outcome,
  OutcomeMotivation,
  Condition,
  ActivityChild,
  Scheduler,
  PlanningParameters,
  PrioritizationParameters,
  Break
};