import {
  PersistentModelConstructor,
  ProducerModelPredicate,
} from "@aws-amplify/datastore";
import { Permissions } from "helpers/rbac";
import {
  Activity,
  Audit,
  Configuration,
  Identity,
  Notification,
  Payment,
  Task,
  User,
  UserRole,
} from "models";
import { getProcessActivity } from "./advance";
import { getLoggedUser } from "./authenticate";
import { canUser } from "./authorize";
import { Entity, storeGet, storeList, storeQuery } from "./store";

export type QueryOptions<
  T extends Entity,
  S extends Subscription<T> = undefined,
  O extends Owner<T> = undefined
> = {
  includeDeleted?: boolean;
  subscribe?: S;
} & (undefined extends O
  ? object
  : {
      ownerType?: O;
    });

export type QueryReturn<
  T extends Entity,
  S extends Subscription<any>
> = Promise<undefined extends S ? T[] : undefined>;

export type Subscription<T extends Entity> =
  | ((entities: T[]) => void)
  | undefined;
export type Owner<T extends Entity> = keyof T | unknown;

type GetSearch<T extends Entity, C extends string, K extends keyof T> =
  | Record<C, T["id"]>
  | T["id"]
  | [K, NonNullable<T[K]>];

type GetOptions = { includeDeleted?: boolean };

//! Tasks

export type TaskModel = Task;
type TaskOwners = keyof Pick<TaskModel, "assigner" | "assignee">;
type TaskConnection = "taskID";

export async function getTasks<S extends Subscription<TaskModel>>(
  options?: QueryOptions<TaskModel, S, TaskOwners>
) {
  return query(Task, () => "readAll", options);
}

export async function getActiveTaskChildren<S extends Subscription<TaskModel>>(
  parent: TaskModel,
  options?: QueryOptions<TaskModel, S, TaskOwners>
) {
  const filter: Parameters<TaskModel[]["filter"]>[0] = ({
    taskChildrenId,
    outcome,
  }) => !outcome && taskChildrenId === parent.id;

  return (
    await getTasks(
      options?.subscribe
        ? ({
            ...options,
            subscribe: (tasks) => options.subscribe!(tasks.filter(filter)),
          } as QueryOptions<TaskModel, S, TaskOwners>)
        : options
    )
  )?.filter(filter);
}

export async function getTask<K extends keyof TaskModel>(
  search?: GetSearch<TaskModel, TaskConnection, K>,
  options?: GetOptions
) {
  return get<TaskModel, TaskConnection>(Task, "taskID", search, options);
}

//! Identities

export type IdentityModel = Identity;
type IdentityOwners = undefined;
type IdentityConnection = "identityTasksId";

export async function getIdentities<S extends Subscription<IdentityModel>>(
  options?: QueryOptions<IdentityModel, S, IdentityOwners>
) {
  return query(
    Identity,
    () =>
      (canUser(Permissions.HandleIdentities) ||
        canUser(Permissions.HandleTasks) ||
        canUser(Permissions.HandleMeetings) ||
        canUser(Permissions.ViewIdentities)) &&
      "readAll",
    options
  );
}

export async function getIdentity<K extends keyof IdentityModel>(
  search?: GetSearch<IdentityModel, IdentityConnection, K>,
  options?: GetOptions
) {
  return get<IdentityModel, IdentityConnection>(
    Identity,
    "identityTasksId",
    search,
    options
  );
}

//! Activities

export type ActivityModel = Activity;
type ActivityOwners = undefined;
type ActivityConnection = "activityTasksId";

export async function getActivities<S extends Subscription<ActivityModel>>(
  options?: QueryOptions<ActivityModel, S, ActivityOwners>,
  identity?: Identity
) {
  async function filterActivities(rawActivities: Activity[]) {
    return Promise.all(
      rawActivities.map(async (activity) =>
        getProcessActivity(activity, identity!)
      )
    );
  }

  const activities = await query(Activity, () => "readAll", {
    ...options,
    subscribe: identity
      ? options?.subscribe &&
        (async (rawActivities: Activity[]) =>
          (options.subscribe as Function)(
            await filterActivities(rawActivities)
          ))
      : options?.subscribe,
  });

  return (
    activities && identity && !isSubscription(activities)
      ? filterActivities(activities)
      : activities
  ) as QueryReturn<ActivityModel, S>;
}

/**
 * Always call with identity, except to get the pure activity.
 */
export async function getActivity<K extends keyof ActivityModel>(
  search?: GetSearch<ActivityModel, ActivityConnection, K>,
  identity?: Identity,
  options?: GetOptions
) {
  const activity = await get<ActivityModel, ActivityConnection>(
    Activity,
    "activityTasksId",
    search,
    options
  );

  return identity
    ? activity && getProcessActivity(activity, identity)
    : activity;
}

//! Payments

export type PaymentModel = Payment;
type PaymentOwners = undefined;
type PaymentConnection = "paymentIdentitiesId";

export async function getPayments<S extends Subscription<PaymentModel>>(
  options?: QueryOptions<PaymentModel, S, PaymentOwners>
) {
  return query(
    Payment,
    () =>
      (canUser(Permissions.PayMeetings) ||
        canUser(Permissions.ValidateMeetings)) &&
      "readAll",
    options
  );
}

export async function getPayment<K extends keyof PaymentModel>(
  search?: GetSearch<PaymentModel, PaymentConnection, K>,
  options?: GetOptions
) {
  return get<PaymentModel, PaymentConnection>(
    Payment,
    "paymentIdentitiesId",
    search,
    options
  );
}

//! Users

export type UserModel = User;
type UserOwners = keyof Pick<UserModel, "username">;
type UserConnection = "userID";

export async function getUsers<S extends Subscription<UserModel>>(
  options?: QueryOptions<UserModel, S, UserOwners>
) {
  return query(User, () => "readAll", options);
}

export async function getUser<K extends keyof UserModel>(
  search?: GetSearch<UserModel, UserConnection, K>,
  options?: GetOptions
) {
  return get<UserModel, UserConnection>(User, "userID", search, options);
}

export async function getRandomUserInRole(role: UserRole, users?: UserModel[]) {
  const resolvedUsers =
    users?.filter(({ roles }) => roles.includes(role)) ??
    (await getUsers())?.filter(({ roles }) => roles.includes(role));

  return resolvedUsers?.[Math.floor(Math.random() * resolvedUsers.length)]
    ?.username;
}

//! Audits

export type AuditModel = Audit;
type AuditOwners = keyof Pick<AuditModel, "user">;
type AuditConnection = "auditNotificationsId";

export async function getAudits<S extends Subscription<AuditModel>>(
  options?: QueryOptions<AuditModel, S, AuditOwners>
) {
  return query(
    Audit,
    () => canUser(Permissions.ValidateMeetings) && "readAll",
    options
  );
}

export async function getAudit<K extends keyof AuditModel>(
  search?: GetSearch<AuditModel, AuditConnection, K>,
  options?: GetOptions
) {
  return get<AuditModel, AuditConnection>(
    Audit,
    "auditNotificationsId",
    search,
    options
  );
}

//! Notifications

export type NotificationModel = Notification;
type NotificationOwners = keyof Pick<NotificationModel, "reader">;

export async function getNotifications<
  S extends Subscription<NotificationModel>
>(options?: QueryOptions<NotificationModel, S, NotificationOwners>) {
  return query(Notification, () => "readOwn", options);
}

//! Configurations

export type ConfigurationModel = Configuration;
type ConfigurationOwners = undefined;

export async function getScheduler<S extends Subscription<ConfigurationModel>>(
  options?: QueryOptions<ConfigurationModel, S, ConfigurationOwners>
) {
  return (
    await query(Configuration, () => "readAll", {
      limit: 1,
      ...(options as any),
    })
  )?.find(({ scheduler }) => !!scheduler);
}

//? Utils

type JoinResolver = Entity | string | (Entity | string)[];

export function join<J extends Entity>(
  joined?: JoinResolver | null,
  on?: keyof J,
  filter?: ProducerModelPredicate<J>
): ProducerModelPredicate<J> {
  const resolvedOn = on ?? "id";

  const joiner: ProducerModelPredicate<J> | undefined = Array.isArray(joined)
    ? joined.length
      ? ({ or }) =>
          or(
            joined.reduce(
              (previous, current) => (entity) =>
                (previous(entity)[resolvedOn] as any)(
                  "eq",
                  (current as Extract<JoinResolver, Entity>).id ?? current
                ),
              (entity) => entity
            )
          )
      : undefined
    : joined
    ? (entity) =>
        (entity[resolvedOn] as any)(
          "eq",
          (joined as Extract<JoinResolver, Entity>).id ?? joined
        )
    : undefined;

  return joiner
    ? filter
      ? (entity) => filter(joiner(entity))
      : joiner
    : ({ id }) => id("eq", null as any);
}

async function query<
  T extends Entity,
  S extends Subscription<T>,
  O extends Owner<T>
>(
  type: PersistentModelConstructor<T>,
  getPermission: () => "readAll" | "readOwn" | false,
  options?: QueryOptions<T, S, O>
) {
  const permission = getPermission();

  if (!permission) return;

  let filter: Parameters<T[]["filter"]>[0] | undefined;

  const owner = (options as { ownerType?: keyof T } | undefined)?.ownerType;

  if (owner) {
    const username = getLoggedUser();

    if (username) filter = (entity) => entity[owner] === username;
  }

  if (options?.subscribe) {
    return storeList(
      type,
      (entities) =>
        options.subscribe!(filter ? entities.filter(filter) : entities) as any,
      options.includeDeleted
    ) as unknown as QueryReturn<T, S>;
  } else {
    const entities = await storeQuery(type, options?.includeDeleted);

    return (filter
      ? entities.filter(filter)
      : entities) as unknown as QueryReturn<T, S>;
  }
}

async function get<T extends Entity, C extends string>(
  type: PersistentModelConstructor<T>,
  connection: C,
  search?: GetSearch<T, C, keyof T>,
  options?: GetOptions
) {
  if (search) {
    if (Array.isArray(search)) {
      return (await query(type, () => "readAll", options))?.find(
        (entity) => entity[search[0]] === search[1]
      );
    } else {
      return storeGet(
        type,
        typeof search === "object" ? search[connection] : search,
        options?.includeDeleted
      );
    }
  }
}

function isSubscription<T extends Entity>(
  result: T[] | ReturnType<typeof storeList> | undefined
): result is ReturnType<typeof storeList> {
  return (result as any)?.subscribe;
}
