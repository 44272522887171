import { IIR_BOUNDS } from "constants/business";
import IdentityCreation from "logic/identities/elements/IdentityCreation";
import { Identity, RealEstate } from "models";
import { ComponentProps } from "react";
import Form from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import { OutcomeProcedureFormIds } from "../OutcomeProcedure";

export default function MeetingBuyerPersonas(props: {
  data: Record<OutcomeProcedureFormIds, any>;
  formProps?: ComponentProps<typeof Form>;
  identity?: Identity;
  financingValue?: Identity["financing"];
}) {
  return (
    <Form
      defaultValues={{
        [OutcomeProcedureFormIds.RealEstate]:
          (props.data[OutcomeProcedureFormIds.RealEstate] as RealEstate) ??
          null,
        [OutcomeProcedureFormIds.RealEstatePrice]: props.data[
          OutcomeProcedureFormIds.RealEstatePrice
        ] as number,
        [OutcomeProcedureFormIds.Savings]: props.data[
          OutcomeProcedureFormIds.Savings
        ] as number,
        [OutcomeProcedureFormIds.LoanToValue]: props.data[
          OutcomeProcedureFormIds.LoanToValue
        ] as number,
      }}
      {...props.formProps}
    >
      {(form) =>
        asColumn(
          IdentityCreation(
            {
              RealEstate: OutcomeProcedureFormIds.RealEstate,
              RealEstatePrice: OutcomeProcedureFormIds.RealEstatePrice,
              Savings: OutcomeProcedureFormIds.Savings,
              LoanToValue: OutcomeProcedureFormIds.LoanToValue,
            },
            form,
            {
              RealEstatePrice: {
                min:
                  (props.financingValue ?? undefined) &&
                  props.financingValue! / IIR_BOUNDS.max,
                max:
                  (props.financingValue ?? undefined) &&
                  props.financingValue! / IIR_BOUNDS.min,
              },
            }
          )
        )
      }
    </Form>
  );
}
