import { NavigationEvent } from "constants/code";
import { Permissions } from "helpers/rbac";
import IdentityView from "logic/identities/IdentityView";
import {
  Activity,
  ActivityCodename,
  LocationName,
  ModalityName,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
  Task,
  User,
  UserRole,
} from "models";
import { ComponentProps, useState } from "react";
import { getPath, isAssignerResponsibility } from "utils/advance";
import { getLoggedUser } from "utils/authenticate";
import { canUser } from "utils/authorize";
import { displayPerson } from "utils/format";
import {
  areTheHumanSame,
  sortDates,
  toLocalizedDateTime,
  useTranslation,
} from "utils/localize";
import { isTaskWithoutLocation } from "utils/locate";
import { updateIdentity, updateTask } from "utils/mutate";
import {
  getActivities,
  getIdentity,
  getTask,
  getTasks,
  getUser,
  IdentityModel,
  TaskModel,
} from "utils/query";
import { useLoader } from "utils/render";
import schedule from "utils/schedule";
import { getLargeScreen, useModal } from "utils/show";
import { getNow, toDateTime, toDuration } from "utils/time";
import Entity, { EntityProps, Exclude } from "view/Entity";
import Button from "view/inputs/Button";
import Choice from "view/inputs/Choice";
import asColumn from "view/LAYOUT/asColumn";
import asRow from "view/LAYOUT/asRow";
import Text from "view/outputs/Text";
import Progress from "view/symbols/Progress";
import OutcomeProcedure from "../OutcomeProcedure";
import PostponeTask from "../PostponeTask";

const meetingActivityCodename = ActivityCodename.MEETING;

const scheduleActivityCodename = ActivityCodename.SCHEDULEMEETING;

// TODO REFACTOR
export default function TaskView(props: {
  entityProps?: EntityProps<TaskModel>;
  taskID?: TaskModel["id"];
  task?: TaskModel;
  identity?: IdentityModel;
  showOutcome?: boolean;
  onTaskSubmitted?: NavigationEvent;
}) {
  const { t } = useTranslation("tasks");

  const openModal = useModal();

  const taskID = props.task?.id ?? props.taskID;

  const [showPlace, setShowPlace] = useState(true);

  const taskData = useLoader(async () => {
    const task =
      props.task ??
      (props.entityProps?.defaultValues as Partial<TaskModel> | undefined) ??
      (await getTask(taskID));

    const identity =
      props.identity ?? (await getIdentity(task?.identityTasksId));

    const activities = await getActivities(undefined, identity);

    const activity =
      task && activities?.find(({ id }) => id === task.activityTasksId);

    if (task?.location === LocationName.HEADQUARTERS) setShowPlace(false);

    if (task?.taskChildrenId) {
      const parent = await getTask(task.taskChildrenId);

      return {
        task,
        activities,
        activity,
        identity,
        parent,
        parentActivity: activities?.find(
          ({ id }) => id === parent?.activityTasksId
        ),
      };
    }

    return {
      task,
      activities,
      activity,
      identity,
    };
  });

  const largeScreen = getLargeScreen();

  const isMeeting = taskData?.activity?.codename === meetingActivityCodename;

  const isGod = canUser(Permissions.HandleTasks);

  const isViewer = canUser(Permissions.ViewTasks);

  const isAssignee = !isGod && taskData?.task?.assignee === getLoggedUser();

  const isAssigner =
    !isGod &&
    !isAssignee &&
    (taskData?.task?.assigner === getLoggedUser() ||
      (isMeeting && canUser(Permissions.HandleMeetings)));

  const hideActivity = !isGod && !isViewer && !isAssignee && !isAssigner;

  const taskEnd = taskData?.task?.endDateTime
    ? toDateTime(taskData.task.endDateTime)
    : undefined;

  const adjustedTaskEnd = taskData?.activity?.marginDuration
    ? taskEnd?.plus(toDuration(taskData.activity.marginDuration))
    : taskEnd;

  const now = getNow();

  const isLate = adjustedTaskEnd && adjustedTaskEnd <= now;

  //? False means assignee responsibility (default)
  const assignerResponsibility =
    taskData?.task?.id && taskData?.activity
      ? isAssignerResponsibility(taskData.task as TaskModel, taskData.activity)
      : false;

  const operation = taskID
    ? isGod ||
      (!taskData?.task?.outcome &&
        ((isAssigner && assignerResponsibility) ||
          (isAssignee && !assignerResponsibility && !isLate)))
      ? "update"
      : "view"
    : "create";

  let assignableEntityProps;

  if (props.entityProps) {
    const {
      exclude: removedExclude,
      dependencies: removedDependencies,
      propertyProps: removedPropertyProps,
      ...entityProps
    } = props.entityProps;

    assignableEntityProps = entityProps;
  }

  const goals: Set<string> | undefined =
    !isAssigner && (operation === "update" || operation === "view")
      ? new Set(
          taskData?.activity?.outcomes
            .find(({ name }) => name === OutcomeName.POSITIVE)
            ?.outcomeMotivations.map(({ name }) => name)
        )
      : undefined;

  const identityLink = taskData?.identity && (
    <Text
      type="title"
      spaceProps={{ mb: 0.4 }}
      onClick={() =>
        openModal((close) => (
          <IdentityView
            identityID={taskData?.identity?.id}
            onIdentitySubmitted={close}
          />
        ))
      }
    >
      {displayPerson(taskData.identity)}
    </Text>
  );

  const isMeetingToExecute = isMeeting && !taskData?.task?.outcome;

  const showCancel = isAssigner && assignerResponsibility && isMeetingToExecute;

  const showPostpone =
    isAssignee &&
    !assignerResponsibility &&
    isMeetingToExecute &&
    taskData.task &&
    !isLate;

  async function cancel(data: NonNullable<typeof taskData>) {
    if (!data.identity) return;

    const scheduleMeetingActivity = data.activities?.find(
      ({ codename }) => codename === scheduleActivityCodename
    );

    const lastScheduleMeeting = (await getTasks())
      ?.filter(
        ({ identityTasksId, activityTasksId }) =>
          identityTasksId === data.identity!.id &&
          activityTasksId === scheduleMeetingActivity?.id
      )
      .sort((aTask, bTask) => sortDates(bTask, aTask, "startDateTime"))[0];

    if (lastScheduleMeeting) {
      const updatedScheduleMeeting = await updateTask(
        {
          outcome: OutcomeName.NEGATIVE,
          outcomeMotivation: OutcomeMotivationCodename.CANCELLED,
        },
        lastScheduleMeeting
      );

      if (!updatedScheduleMeeting) return;
    }

    return data.task?.id
      ? updateTask(
          {
            outcome: OutcomeName.NOTEXECUTED,
            outcomeMotivation: OutcomeMotivationCodename.CANCELLED,
          },
          data.task as TaskModel
        )
      : undefined;
  }

  async function postpone() {
    return taskData?.task?.id
      ? new Promise<Task | undefined>((resolve) =>
          openModal((close) => (
            <PostponeTask
              task={taskData.task as TaskModel}
              activity={taskData?.activity}
              identity={taskData?.identity}
              onTaskPostponed={(task) => {
                close();
                resolve(task);
              }}
              onPostponeFailed={() => {
                resolve(undefined);
              }}
            />
          ))
        )
      : undefined;
  }

  const activityName = hideActivity
    ? t("tasks:internalActivity")
    : taskData?.activity && taskData.activity.name;

  const parentDateString =
    taskData?.parent &&
    taskData.parentActivity &&
    `${taskData.parentActivity.name} ${t("tasks:onThe")} ${toLocalizedDateTime(
      taskData.parent.startDateTime
    )}`;

  const defaultExclude: EntityProps<TaskModel>["exclude"] = {
    outcome: !taskData?.task?.outcome,
    outcomeMotivation: hideActivity || !taskData?.task?.outcome,
    review: !(taskData?.task?.review ?? isMeeting) || !taskData?.task?.outcome,
    travelDuration: !taskData?.task?.travelDuration,
    returnDuration: !taskData?.task?.returnDuration,
    location: operation === "view" ? !taskData?.task?.location : undefined,
    placeInfo:
      operation === "view"
        ? !taskData?.task?.placeInfo || !taskData?.task?.location
        : undefined,
  };

  const conditionalExclude: typeof defaultExclude =
    operation === "create" && isGod
      ? {
          assignee: true,
          startDateTime: true,
          endDateTime: true,
        }
      : isAssigner
      ? {
          identityTasksId: true,
          activityTasksId: true,
        }
      : isAssignee
      ? {
          assignee: true,
          identityTasksId: true,
          activityTasksId: true,
        }
      : {
          identityTasksId: true,
          activityTasksId: !isGod,
        };

  const exclude: Exclude<Task> = {
    ...defaultExclude,
    ...conditionalExclude,
    activityTasksId:
      !!taskData?.task?.taskChildrenId ||
      (conditionalExclude.activityTasksId ?? defaultExclude?.activityTasksId),
    placeInfo: defaultExclude.placeInfo ?? !showPlace,
    suggestedOutcomeMotivation: true,
  };

  const defaultPropertyProps: EntityProps<Task>["propertyProps"] = {};

  const conditionalPropertyProps: typeof defaultPropertyProps | undefined =
    operation === "create"
      ? undefined
      : operation === "update"
      ? {
          startDateTime: { required: true },
          endDateTime: { required: true },
          activityTasksId: { required: true },
          identityTasksId: { required: true },
          assignee: { required: true },
          modality: { required: true },
          location: { required: true },
          placeInfo: { required: true },
        }
      : undefined;

  const propertyProps = {
    ...defaultPropertyProps,
    ...conditionalPropertyProps,
  };

  const defaultMessages = isMeeting
    ? {
        creationMessage: t("tasks:meetingCreated"),
        creationFailedMessage: t("tasks:meetingCreationFailed"),
        updateMessage: t("tasks:meetingUpdated"),
        updateFailedMessage: t("tasks:meetingUpdateFailed"),
      }
    : {
        creationMessage: t("tasks:taskCreated"),
        creationFailedMessage: t("tasks:taskCreationFailed"),
        updateMessage: t("tasks:taskUpdated"),
        updateFailedMessage: t("tasks:taskUpdateFailed"),
      };

  const deleteMessages = isGod
    ? {
        deletionMessage: t("tasks:taskDeleted"),
        deletionFailedMessage: t("tasks:taskDeletionFailed"),
      }
    : showCancel
    ? isMeeting
      ? {
          deletionMessage: t("tasks:meetingCancelled"),
          deletionFailedMessage: t("tasks:meetingCancellationFailed"),
        }
      : {
          deletionMessage: t("tasks:taskCancelled"),
          deletionFailedMessage: t("tasks:taskCancellationFailed"),
        }
    : isMeeting
    ? {
        deletionMessage: t("tasks:meetingPostponed"),
        deletionFailedMessage: t("tasks:meetingPostponeFailed"),
      }
    : {
        deletionMessage: t("tasks:taskPostponed"),
        deletionFailedMessage: t("tasks:taskPostponeFailed"),
      };

  const dependencies: NonNullable<EntityProps<TaskModel>["dependencies"]> = [
    {
      value: "modality",
      dependsOn: "activityTasksId",
      relationship: (activityTasksId: Activity["id"], Modality) => {
        const activity = taskData!.activities?.find(
          ({ id }) => id === activityTasksId
        );

        const assignedProps = {
          change: async (options) => {
            const assignedModalities = {} as Record<ModalityName, ModalityName>;

            activity?.modalities.forEach(
              ({ name }) =>
                (assignedModalities[name] =
                  options[name as keyof typeof options])
            );

            return assignedModalities;
          },
          ...props.entityProps?.propertyProps?.modality,
        } as ComponentProps<typeof Choice>;

        return <Modality {...assignedProps} />;
      },
    },
    {
      value: "location",
      dependsOn: "modality",
      relationship: (modality: ModalityName, Location, { getValueRender }) => {
        const activityTasksId =
          getValueRender<Activity["id"]>("activityTasksId");

        const activity = taskData!.activities?.find(
          ({ id }) => id === activityTasksId
        );

        const locationProps = {
          change: (options) => {
            const locations = {} as Record<LocationName, LocationName>;

            activity?.modalities
              .find(({ name }) => name === modality)
              ?.locations.forEach(
                ({ name }) =>
                  (locations[name] = options[name as keyof typeof options])
              );

            return locations;
          },
        } as ComponentProps<typeof Choice>;

        return modality ? <Location {...locationProps} /> : null;
      },
    },
    {
      dependsOn: "location",
      relationship: (location: LocationName) =>
        setShowPlace(!!location && location !== LocationName.HEADQUARTERS),
    },
    {
      value: "outcomeMotivation",
      dependsOn: "outcome",
      relationship: (outcome, OutcomeMotivationName, { getValueRender }) => {
        const activityTasksId =
          getValueRender<Activity["id"]>("activityTasksId");

        const activity = taskData!.activities?.find(
          ({ id }) => id === activityTasksId
        );

        const motivationProps = {
          change: (options) => {
            const outcomeMotivations = {} as Record<
              OutcomeMotivation["name"],
              OutcomeMotivation["name"]
            >;

            activity?.outcomes
              .find(({ name }) => outcome === name)
              ?.outcomeMotivations.forEach(
                ({ codename, name }) =>
                  (outcomeMotivations[codename ?? name] =
                    options[name as keyof typeof options])
              );

            const { OTHER, CANCELLED } = OutcomeMotivationCodename;

            if (
              outcome === OutcomeName.NOTEXECUTED ||
              outcome === OutcomeName.NEGATIVE
            ) {
              outcomeMotivations[CANCELLED] = CANCELLED;
            }

            return { OTHER, ...outcomeMotivations };
          },
        } as ComponentProps<typeof Choice>;

        return <OutcomeMotivationName {...motivationProps} />;
      },
    },
    ...(props.entityProps?.dependencies ?? []),
  ];

  if (!isGod) {
    dependencies.push({
      value: "assignee",
      dependsOn: "activityTasksId",
      relationship: (_, Assignee, { getValueRender }) => {
        const activityTasksId =
          getValueRender<Activity["id"]>("activityTasksId");

        const activity = taskData!.activities?.find(
          ({ id }) => id === activityTasksId
        );

        const assigneeProps = {
          change: (users: User[]) =>
            activity
              ? users.filter(({ roles }) =>
                  roles.includes(activity.role as UserRole)
                )
              : [],
        } as ComponentProps<typeof Choice>;

        return <Assignee {...assigneeProps} />;
      },
    });
  }

  const messages = {
    ...defaultMessages,
    ...deleteMessages,
  };

  return taskData ? (
    <Entity
      model={Task}
      operation={operation}
      defaultValues={taskData.task}
      titleProps={{
        children: isAssigner
          ? operation === "create"
            ? t("tasks:createMeeting")
            : operation === "update"
            ? t("tasks:updateMeeting")
            : t("tasks:viewMeeting")
          : operation === "create"
          ? t("tasks:createTask")
          : operation === "update"
          ? t("tasks:updateTask")
          : t("tasks:viewTask"),
        subtitle: taskData
          ? identityLink && activityName
            ? largeScreen
              ? asRow([identityLink, `- ${activityName}`], {
                  disableResponsive: true,
                  spacing: 1,
                })
              : asColumn([identityLink, activityName], { spacing: 0 })
            : identityLink ?? activityName
          : undefined,
        details:
          (parentDateString || goals?.size) && !hideActivity
            ? `${
                parentDateString || goals?.size === 1
                  ? t("tasks:goal")
                  : t("tasks:goals")
              }${`: ${parentDateString ?? Array.from(goals!).join(", ")}`}`
            : undefined,
      }}
      buttons={
        taskData.task?.id &&
        !taskData.task.outcome &&
        ((isAssignee &&
          taskData.task.startDateTime &&
          toDateTime(taskData.task.startDateTime) <= now) ||
          isGod) &&
        (props.showOutcome === undefined || props.showOutcome)
          ? [
              <Button
                onClick={(complete) => {
                  complete();
                  openModal((close) => (
                    <OutcomeProcedure
                      task={taskData.task as TaskModel}
                      onOutcomeFinished={() => {
                        close();
                        props.onTaskSubmitted?.();
                      }}
                    />
                  ));
                }}
              >
                {t("tasks:outcomeAction")}
              </Button>,
            ]
          : undefined
      }
      formProps={
        operation === "update"
          ? {
              required: false,
            }
          : undefined
      }
      hideDelete={!isGod && !showCancel && !showPostpone}
      customDelete={
        isGod ? undefined : showCancel ? async () => cancel(taskData) : postpone
      }
      deleteButtonProps={
        isGod
          ? undefined
          : {
              children: showCancel ? t("tasks:cancel") : t("tasks:postpone"),
            }
      }
      exclude={{ ...exclude, ...props.entityProps?.exclude }}
      propertyProps={{
        ...propertyProps,
        ...props.entityProps?.propertyProps,
      }}
      asOutput={{
        startDateTime: isAssignee,
        endDateTime: isAssignee,
        activityTasksId: isAssignee,
        identityTasksId: isAssignee,
        outcome: isAssigner,
        outcomeMotivation: isAssigner,
        travelDuration: true,
        returnDuration: true,
      }}
      dependencies={dependencies}
      customCreate={async (data) => {
        const activityTasksId = data["activityTasksId"];

        const activity = taskData.activities?.find(
          ({ id }) => id === activityTasksId
        );

        return activity && schedule(data, activity.role);
      }}
      customValidator={async (data) => {
        if (
          !data.startDateTime ||
          toDateTime(data.startDateTime) < toDateTime(data.endDateTime)
        ) {
          if (taskData.identity && taskData.activity) {
            if (operation === "update") {
              const path =
                getPath(
                  taskData.activity,
                  data.outcome as OutcomeName,
                  data.outcomeMotivation as OutcomeMotivation["name"]
                ) ?? taskData.identity.path;

              const updatedIdentity =
                path && areTheHumanSame(path, taskData.identity.path)
                  ? await updateIdentity(
                      {
                        path,
                      },
                      taskData.identity
                    )
                  : taskData.identity;

              return !!updatedIdentity;
            }
          }

          return true;
        } else {
          return t("tasks:endDateTimeIsBeforeStartTime");
        }
      }}
      customFormatter={async (data) => {
        if (isTaskWithoutLocation(data.modality)) {
          data.location = null;
          data.placeInfo = null;
        } else if (data.location === LocationName.HEADQUARTERS) {
          data.placeInfo = (
            await getUser(["username", data.assignee])
          )?.headquarters;
        }
      }}
      {...messages}
      onOperationExecuted={props.onTaskSubmitted}
      {...assignableEntityProps}
    />
  ) : (
    <Progress />
  );
}
