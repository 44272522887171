import {
  Activity,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
} from "models";
import { ComponentProps } from "react";
import Form from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import TaskEffective from "../elements/TaskEffective";
import { OutcomeProcedureFormIds } from "../OutcomeProcedure";

export default function TaskOutcome(props: {
  data: Record<OutcomeProcedureFormIds, any>;
  formProps?: ComponentProps<typeof Form>;
  activity?: Activity;
}) {
  return (
    <Form
      defaultValues={{
        [OutcomeProcedureFormIds.Outcome]:
          (props.data[OutcomeProcedureFormIds.Outcome] as
            | OutcomeName
            | undefined) ?? null,
        [OutcomeProcedureFormIds.OutcomeMotivationValue]:
          (props.data[
            OutcomeProcedureFormIds.OutcomeMotivationValue
          ] as OutcomeMotivation["name"]) ?? null,
        [OutcomeProcedureFormIds.OutcomeMotivationDescription]: props.data[
          OutcomeProcedureFormIds.OutcomeMotivationDescription
        ] as string,
      }}
      {...props.formProps}
    >
      {(form, { getValueRender, setValue }) => {
        const outcome = getValueRender<OutcomeName>(
          OutcomeProcedureFormIds.Outcome
        );

        return asColumn(
          TaskEffective(
            {
              Outcome: OutcomeProcedureFormIds.Outcome,
              OutcomeMotivation: outcome
                ? OutcomeProcedureFormIds.OutcomeMotivationValue
                : undefined,
              FreeMotivation:
                OutcomeProcedureFormIds.OutcomeMotivationDescription,
            },
            form,
            {
              Outcome: {
                change: () => {
                  const { [OutcomeName.NOTEXECUTED]: removed, ...types } =
                    OutcomeName;

                  return types;
                },
                onChange: () =>
                  setValue(
                    OutcomeProcedureFormIds.OutcomeMotivationValue,
                    null
                  ),
              },
              OutcomeMotivation: {
                key: outcome,
                change: () => {
                  const types = {} as Partial<
                    Record<OutcomeMotivation["name"], OutcomeMotivation["name"]>
                  >;

                  props.activity?.outcomes
                    ?.find(({ name }) => name === outcome)
                    ?.outcomeMotivations?.forEach(
                      ({ codename, name }) => (types[codename ?? name] = name)
                    );

                  const { OTHER } = OutcomeMotivationCodename;

                  const motivations = { ...types, OTHER };

                  const motivationsKeys = Object.keys(motivations);

                  if (motivationsKeys.length === 1) {
                    setValue(
                      OutcomeProcedureFormIds.OutcomeMotivationValue,
                      motivationsKeys[0]
                    );

                    return {};
                  } else {
                    return motivations;
                  }
                },
              },
            }
          )
        );
      }}
    </Form>
  );
}
