import { OmitFirstArg } from "constants/code";
import {
  ComponentProps,
  DetailedHTMLProps,
  FormHTMLAttributes,
  ReactNode,
  useState,
} from "react";
import {
  Control,
  DeepPartial,
  FieldValues,
  Path,
  RegisterOptions,
  UnpackNestedValue,
  useForm,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { useTranslation } from "utils/localize";
import asColumn from "view/LAYOUT/asColumn";
import asHorizontallyCentered from "../LAYOUT/asHorizontallyCentered";
import asRow from "../LAYOUT/asRow";
import asSpace from "../LAYOUT/asSpace";
import Text from "../outputs/Text";
import Title from "../outputs/Title";
import Button, { Complete } from "./Button";

export type FormControl<T = any> = {
  required?: boolean;
  disabled?: boolean;
  control: Control<T>;
  utils: FormUtils;
};

export type FormProps<T = any> = {
  form: FormControl<T>;
  rules?: ValidationRules;
};

export type FormUtils = {
  setValue: (
    id: keyof any,
    ...params: Parameters<OmitFirstArg<UseFormSetValue<any>>>
  ) => void;
  getValueCallback: <T>(id: keyof any) => T | undefined;
  getValueRender: <T>(id: keyof any) => T | undefined;
};

export type FormChildren<T extends FieldValues> = (
  formControl: FormControl<T>,
  utils: FormUtils
) => ReactNode;

type ValidationRules = Omit<
  RegisterOptions,
  "valueAsNumber" | "valueAsDate" | "setValueAs"
>;

type FormElement<T extends Record<string, any>, C extends boolean> = [
  ids: Partial<Record<keyof T, string | number>>,
  form: FormControl,
  props?: {
    [I in keyof T]?: { key?: string } & Omit<ComponentProps<T[I]>, "form">;
  },
  compose?: C
];

export function createFormElement<T extends Record<string, any>>(
  component: (...compose: FormElement<any, true>) => {
    [K in keyof T]: (props: T[K]) => ReactNode;
  }
) {
  return <C extends boolean>(
    ...params: FormElement<T, C>
  ): boolean extends C
    ? ReactNode[]
    : C extends true
    ? ReturnType<typeof component>
    : ReactNode[] => {
    const ret = [] as ReactNode[];

    const [ids, form, props, compose] = params;

    const components = component(ids, form, props, true);

    if (compose) return components as any;

    for (const key in components) {
      ids[key] !== undefined &&
        ret.push(
          components[key]({
            ...(props?.[key] as any),
            id: ids[key],
            form,
          })
        );
    }

    return ret as any;
  };
}

export type FormElementComponent<
  T extends (...args: any) => any,
  K extends keyof NonNullable<Parameters<T>[2]>
> = React.FC<NonNullable<Parameters<T>[2]>[K]>;

type SubmitProps = Pick<
  ComponentProps<typeof Button>,
  "type" | "disabled" | "children" | "simulateClick"
>;

export default function Form<T extends FieldValues>(
  props: {
    title?: ReactNode;
    titleColor?: ComponentProps<typeof Text>["color"];
    subtitle?: ReactNode;
    details?: ReactNode;
    titleProps?: ComponentProps<typeof Title>;
    buttons?: ReactNode[];
  } & BaseFormProps<T>
) {
  const {
    titleColor,
    subtitle,
    details,
    titleProps,
    title,
    buttons,
    ...formProps
  } = props;

  return (
    <>
      <Title
        color={titleColor}
        subtitle={subtitle}
        details={details}
        {...titleProps}
      >
        {title}
      </Title>
      {buttons &&
        asSpace(
          asRow(buttons, {
            disableResponsive: true,
          }),
          {
            mb: 2,
          }
        )}
      <BaseForm {...formProps} />
    </>
  );
}

type BaseFormProps<T extends FieldValues> = {
  submitText?: ReactNode;
  update?: boolean;
  required?: boolean;
  disabled?: boolean;
  defaultValues?: UnpackNestedValue<DeepPartial<T>>;
  children?: FormChildren<T>;
  SubmitButton?: React.FC<SubmitProps>;
  inline?: boolean;
  onSubmit?: (
    data: UnpackNestedValue<T>,
    complete: Complete,
    reset: UseFormReset<T>
  ) => void;
} & Omit<
  DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>,
  "children" | "title" | "onSubmit"
>;

function BaseForm<T extends FieldValues>(props: BaseFormProps<T>) {
  const {
    onSubmit,
    children,
    submitText,
    required,
    SubmitButton,
    disabled,
    style,
    defaultValues,
    inline,
    update,
    ...formProps
  } = props;

  const { t } = useTranslation("view");

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    watch,
    formState: { dirtyFields },
  } = useForm<T>({
    defaultValues,
  });

  const [clickSimulation, setClickSimulation] =
    useState<ComponentProps<typeof Button>["simulateClick"]>();

  //? Required by default
  const isRequired = required ?? true;

  const utils: FormUtils = {
    setValue: (id, ...rest) => setValue(id.toString() as Path<T>, ...rest),
    getValueCallback: (id) => getValues(id.toString() as Path<T>) as any,
    getValueRender: (id) => watch(id.toString() as Path<T>) as any,
  };

  const submitProps: SubmitProps = {
    type: "submit",
    disabled:
      disabled || (update && !Object.keys(dirtyFields).length) || undefined,
    children: submitText ?? t("view:confirm"),
    simulateClick: clickSimulation,
  };

  const submitButton = SubmitButton ? (
    <SubmitButton {...submitProps} />
  ) : (
    <Button {...submitProps} />
  );

  const showSubmit = (onSubmit || submitText) && !disabled;

  const formElements = [
    children?.({ required: isRequired, disabled, control, utils }, utils),
    showSubmit &&
      (inline
        ? submitButton
        : asSpace(asHorizontallyCentered(submitButton), {
            mt: 3,
          })),
  ];

  return (
    <form
      {...formProps}
      style={{
        ...style,
        width: "100%",
      }}
      onSubmit={
        onSubmit &&
        handleSubmit((data: UnpackNestedValue<T>) =>
          setClickSimulation(
            () => (complete: Complete) =>
              onSubmit(
                data,
                (...params) => {
                  setClickSimulation(undefined);
                  complete(...params);
                },
                reset
              )
          )
        )
      }
    >
      {inline
        ? asRow(formElements, { rightShifted: 1.5 })
        : asColumn(formElements, { spacing: 0 })}
    </form>
  );
}
