import {
  OutcomeProcedureFormIds,
  OutcomeProcedureStepIDs,
} from "logic/tasks/OutcomeProcedure";
import { isPlaceVisible } from "logic/tasks/TaskDetails";
import {
  Activity,
  ActivityCodename,
  Audit,
  ConditionFilter,
  FinancingType,
  Identity,
  LocationName,
  ModalityName,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
  Place,
  Task,
  UserRole,
} from "models";
import { getLoggedUser } from "./authenticate";
import { areTheHumanSame, sortDates } from "./localize";
import { isTaskWithoutLocation } from "./locate";
import {
  createNotification,
  createTask,
  updateIdentity,
  updateTask,
} from "./mutate";
import {
  ActivityModel,
  getActivities,
  getActivity,
  getRandomUserInRole,
  getTask,
  getTasks,
  getUser,
  getUsers,
  IdentityModel,
  TaskModel,
} from "./query";
import schedule, { getCandidateAssignees } from "./schedule";
import { MotivationEntity, UpdateEntity } from "./store";
import { getNow, toDateTime, toDuration } from "./time";

export type OutcomeEntities = {
  task: Task;
  identity: Identity;
  nextTask?: Task;
  nextActivity?: Activity;
};

/**
 * Checks if immediate realization applies to the current financing type.
 * @param financingType The financing type to check
 * @returns True if immediate realization applies
 */
export function isImmediateRealizationActive(
  financingType?: FinancingType | ""
) {
  return financingType?.includes("MORTGAGE");
}

/**
 * Checks if a task is currently editable only by its assigner, and not its assignee.
 * @param task The task to check
 * @param activity The activity to check with
 * @returns True if the task should currently be editable by its assigner
 */
export function isAssignerResponsibility(task: Task, activity: Activity) {
  //? False means assignee responsibility (default)
  return task.startDateTime && activity.responsibilityChangeDuration
    ? getNow() <
        toDateTime(task.startDateTime).minus(
          toDuration(activity.responsibilityChangeDuration)
        )
    : false;
}

/**
 * Contextualizes an activity based on the state in the process of an identity.
 * @param activity The activity to contextualize
 * @param identity The identity to check with
 * @returns The contextualized activity
 */
export async function getProcessActivity(
  activity: Activity,
  identity: Identity
) {
  return {
    ...activity,
    outcomes: activity.outcomes.map((outcome) => {
      let outcomeMotivations: ActivityModel["outcomes"][number]["outcomeMotivations"] =
        [];

      for (const motivation of outcome.outcomeMotivations) {
        const { condition } = motivation;

        if (condition) {
          const pathToCheck = identity.path ?? "Nuova anagrafica";

          const conditionValue = condition.oneOfPaths.some((path) =>
            areTheHumanSame(path, pathToCheck)
          );

          if (condition.invertCondition ? !conditionValue : conditionValue) {
            if (condition.filter === ConditionFilter.ONLY) {
              outcomeMotivations = [motivation];
              break;
            } else if (condition.filter === ConditionFilter.REMOVE) {
              continue;
            }
          } else if (condition.invertFilterIfFalse) {
            if (condition.filter === ConditionFilter.ONLY) {
              continue;
            } else if (condition.filter === ConditionFilter.REMOVE) {
              outcomeMotivations = [motivation];
              break;
            }
          }
        }

        outcomeMotivations.push(motivation);
      }

      return {
        ...outcome,
        outcomeMotivations,
      };
    }),
  };
}

/**
 * Evaluates the path of an outcome motivation.
 * @param activity The activity where to find the path
 * @param outcome The outcome to look for
 * @param outcomeMotivation The outcome motivation to look for
 * @returns The path of the outcome motivation
 */
export function getPath(
  activity: ActivityModel,
  outcome: ActivityModel["outcomes"][number]["name"],
  outcomeMotivation: OutcomeMotivation["name"]
) {
  let path: IdentityModel["path"];

  for (const { name, outcomeMotivations } of activity.outcomes) {
    if (name === outcome) {
      path = outcomeMotivations.find(
        ({ codename, name: searchMotivation }) =>
          codename === outcomeMotivation ||
          searchMotivation === outcomeMotivation
      )?.path;
      break;
    }
  }

  return path;
}

export async function getNextProcessActivity(
  outcome: TaskModel["outcome"],
  motivation: TaskModel["outcomeMotivation"],
  activity: ActivityModel,
  identity: IdentityModel,
  parentID?: TaskModel["taskChildrenId"],
  nextActivityID?: TaskModel["activityTasksId"]
) {
  if (motivation === OutcomeMotivationCodename.OTHER) return;

  let nextActivity = nextActivityID;

  if (outcome === OutcomeName.POSITIVE) {
    if (parentID) {
      //? If is relative to a parent task, checks if the next activity is the parent activity or the next activities parent is the parent activity
      const parent = await getTask(parentID);

      if (!parent || nextActivityID === parent.activityTasksId) return;

      const nextTaskParentActivity = (
        await getActivities(undefined, identity)
      )?.find(({ children }) =>
        children?.some(({ childrenId }) => childrenId === nextActivityID)
      );

      if (
        nextTaskParentActivity &&
        nextTaskParentActivity.id === parent.activityTasksId
      ) {
        return;
      }
    } else if (activity.codename === ActivityCodename.RECEIVEDOCUMENTSMEETING) {
      //? Is coming from a lack of documents flow

      const outcomeTasks = (await getTasks())
        ?.filter(
          ({ outcome: taskOutcome, identityTasksId }) =>
            !!taskOutcome && identityTasksId === identity.id
        )
        .sort((aTask, bTask) => sortDates(bTask, aTask));

      if (!outcomeTasks) return;

      const taskActivities = (await getActivities(undefined, identity)).filter(
        ({ id }) =>
          outcomeTasks.some(({ activityTasksId }) => activityTasksId === id)
      );

      let lackOfDocumentsActivityOutcomes: Activity["outcomes"] | undefined;

      for (const {
        activityTasksId,
        outcome: taskOutcome,
        outcomeMotivation,
      } of outcomeTasks) {
        const taskActivityOutcomes = taskActivities?.find(
          ({ id, codename }) =>
            id === activityTasksId &&
            codename !== ActivityCodename.RECEIVEDOCUMENTSMEETING
        )?.outcomes;

        if (
          taskActivityOutcomes
            ?.find(({ name }) => name === taskOutcome)
            ?.outcomeMotivations.find(
              ({ codename, name }) =>
                codename === outcomeMotivation || name === outcomeMotivation
            )?.codename === OutcomeMotivationCodename.LACKOFDOCUMENTS
        ) {
          lackOfDocumentsActivityOutcomes = taskActivityOutcomes;
          break;
        }
      }

      //? Should go to the activity the original task should have gone to when normal not executed.
      const originalNotExecutedActivity = lackOfDocumentsActivityOutcomes
        ?.find(({ name }) => name === OutcomeName.NOTEXECUTED)
        ?.outcomeMotivations.find(
          ({ codename }) =>
            codename === OutcomeMotivationCodename.LACKOFEXPERTISE
        )?.nextActivityIds[0];

      if (originalNotExecutedActivity)
        nextActivity = originalNotExecutedActivity;
    }
  } else if (outcome === OutcomeName.NOTEXECUTED && !nextActivity) {
    const notExecutedActivity = activity.outcomes
      .find(({ name }) => name === outcome)
      ?.outcomeMotivations.find(
        ({ codename, name }) => codename === motivation || name === motivation
      )?.nextActivityIds[0];

    if (notExecutedActivity) nextActivity = notExecutedActivity;
  }

  return getActivity(nextActivity, identity);
}

/**
 * Executes an outcome procedure on the current task.
 * @param task The task to outcome
 * @param activity The activity relative to the task
 * @param taskMutation The mutation to apply to the task
 * @param nextActivity The activity of the next task to schedule
 * @param nextTaskCreationData The creation data for the next task, which already accounts for different situations
 * @returns True if the outcome procedure went well
 */
export async function outcomeTask(
  task: TaskModel,
  activity: ActivityModel,
  identity: IdentityModel,
  taskMutation: [UpdateEntity<TaskModel>, MotivationEntity<TaskModel>],
  nextTaskCreationData?: TaskModel,
  nextActivity?: ActivityModel
): Promise<OutcomeEntities | undefined> {
  const outcome = taskMutation[0].outcome;
  const isNotExecuted = outcome === OutcomeName.NOTEXECUTED;
  const outcomeMotivation = taskMutation[0].outcomeMotivation;

  const isChild = task.taskChildrenId;

  let isBreakChildFlow: boolean | undefined;

  let parentTask: Task | undefined;

  //? If child is negative or is weak confirmation, should cancel active parent and other children
  if (isChild) {
    if (outcome === OutcomeName.NEGATIVE) {
      isBreakChildFlow = true;
    } else if (outcome === OutcomeName.WEAK) {
      parentTask = await getTask(task.taskChildrenId);

      if (
        (await getActivity(parentTask))?.children?.find(
          ({ childrenId }) => childrenId === activity.id
        )?.isConfirmation
      ) {
        isBreakChildFlow = true;
      }
    }
  }

  let nextTask: Task | undefined;

  if (nextTaskCreationData && nextActivity) {
    nextTask = await scheduleNextTask(
      isChild && !isBreakChildFlow
        ? { ...nextTaskCreationData, taskChildrenId: task.taskChildrenId }
        : nextTaskCreationData,
      task.assignee,
      isNotExecuted,
      outcomeMotivation,
      activity.role,
      nextActivity.role
    );

    if (!nextTask) return;
  }

  if (task.taskChildrenId && isBreakChildFlow) {
    const parentAndOtherChildren = (await getTasks())?.filter(
      ({ id, outcome: taskOutcome, taskChildrenId }) =>
        !taskOutcome &&
        id !== task.id &&
        (taskChildrenId === task.taskChildrenId ||
          (!parentTask && id === task.taskChildrenId))
    );

    if (parentAndOtherChildren?.length) {
      const updatedTasks = await updateTask(
        {
          outcome: OutcomeName.NOTEXECUTED,
          outcomeMotivation: OutcomeMotivationCodename.CANCELLED,
        },
        parentTask
          ? [parentTask, ...parentAndOtherChildren]
          : parentAndOtherChildren
      );

      if (!updatedTasks?.filter((updateResult) => !!updateResult).length)
        return;
    }
  }

  let updateAudit: Audit | undefined;

  const updatedTask = await updateTask(taskMutation[0], task, {
    motivation: taskMutation[1],
    onAuditCreated: (audit) => (updateAudit = audit),
  });

  if (!updatedTask) return;

  let newIdentityPath =
    outcome && outcomeMotivation
      ? getPath(activity, outcome, outcomeMotivation)
      : undefined;

  const updatedIdentity =
    !newIdentityPath || areTheHumanSame(newIdentityPath, identity.path)
      ? identity
      : await updateIdentity(
          {
            path: newIdentityPath,
          },
          identity
        );

  if (!updatedIdentity) return;

  if (outcomeMotivation === OutcomeMotivationCodename.OTHER && updateAudit) {
    const managers = (await getUsers())
      ?.filter(({ roles }) => roles.includes(UserRole.MANAGEMENT))
      .map(({ username }) => username);

    if (!managers?.length) return;

    const notifications = await Promise.all(
      managers.map(async (manager) =>
        createNotification({
          reader: manager,
          audit: updateAudit,
        })
      )
    );

    if (!notifications) return;

    if (!isNotExecuted) return { task: updatedTask, identity: updatedIdentity };
  }

  return {
    task: updatedTask,
    identity: updatedIdentity,
    nextActivity,
    nextTask,
  };
}

/**
 * Transforms raw data from a stepper to data usable by outcome procedure.
 * @param param0 The data derived from the stepper
 * @param task The task to outcome
 * @param identity The identity to outcome
 * @param nextActivity The next activity to create
 * @returns The formatted data, ready for the outcome procedure
 */
export async function extractOutcomeData(
  {
    modality,
    outcome,
    details,
    nextDetails,
  }: Record<OutcomeProcedureStepIDs, Record<OutcomeProcedureFormIds, unknown>>,
  task: Task,
  identity: Identity,
  activity: Activity,
  nextActivity: Activity | undefined
) {
  const taskModality = modality[OutcomeProcedureFormIds.Modality] as
    | ModalityName
    | OutcomeName.NOTEXECUTED;

  const isNotExecuted = taskModality === OutcomeName.NOTEXECUTED;

  const processedModality = isNotExecuted ? undefined : taskModality;

  const isWithoutLocation = isNotExecuted
    ? false
    : isTaskWithoutLocation(processedModality!);

  const location = isNotExecuted
    ? undefined
    : isWithoutLocation
    ? null
    : (details[OutcomeProcedureFormIds.Location] as LocationName);

  let taskPlace: Place | null | undefined = isWithoutLocation
    ? null
    : undefined;

  if (taskPlace === undefined && isPlaceVisible(location)) {
    const taskPlaceValue = details[OutcomeProcedureFormIds.Place] as Place;

    if (taskPlaceValue) taskPlace = taskPlaceValue;
  }

  const nextTaskModality = nextDetails?.[
    OutcomeProcedureFormIds.NextTaskModality
  ] as ModalityName | undefined;

  const isNextWithoutLocation =
    !!nextTaskModality && isTaskWithoutLocation(nextTaskModality);

  const nextTaskLocation = isNextWithoutLocation
    ? null
    : (nextDetails?.[OutcomeProcedureFormIds.NextTaskLocation] as
        | LocationName
        | undefined);

  let nextPlace: Place | null | undefined = isNextWithoutLocation
    ? null
    : undefined;

  if (nextPlace === undefined) {
    if (isPlaceVisible(nextTaskLocation)) {
      const nextPlaceValue = nextDetails[
        OutcomeProcedureFormIds.NextTaskPlaceValue
      ] as Place;

      if (nextPlaceValue) nextPlace = nextPlaceValue;
    } else if (nextTaskLocation === LocationName.IDENTITYHOME) {
      nextPlace = identity.placeInfo;
    }
  }

  const processedOutcome = isNotExecuted
    ? taskModality
    : (outcome[OutcomeProcedureFormIds.Outcome] as OutcomeName);

  const processedOutcomeMotivation = isNotExecuted
    ? (modality[
        OutcomeProcedureFormIds.OutcomeMotivationValue
      ] as OutcomeMotivation["name"])
    : (outcome[
        OutcomeProcedureFormIds.OutcomeMotivationValue
      ] as OutcomeMotivation["name"]);

  const nextProcessActivity = await getNextProcessActivity(
    processedOutcome,
    processedOutcomeMotivation,
    activity,
    identity,
    task.taskChildrenId,
    nextActivity?.id
  );

  const modalityChangeMotivation = modality[
    OutcomeProcedureFormIds.ModalityChangeMotivation
  ] as string | undefined;

  return {
    taskMutation: (isNotExecuted
      ? [
          {
            outcome: processedOutcome,
            outcomeMotivation: processedOutcomeMotivation,
          },
          {
            outcome:
              processedOutcomeMotivation ===
                OutcomeMotivationCodename.LACKOFREPLYFROMEXTERNAL ||
              processedOutcomeMotivation ===
                OutcomeMotivationCodename.LACKOFREPLYFROMCOLLEAGUE
                ? `Subject: ${
                    modality[OutcomeProcedureFormIds.Subject] as string
                  }`
                : undefined,
            outcomeMotivation: modalityChangeMotivation,
          },
        ]
      : [
          {
            modality: processedModality,
            startDateTime: details[OutcomeProcedureFormIds.StartTime] as string,
            endDateTime: details[OutcomeProcedureFormIds.EndTime] as string,
            location,
            placeInfo: taskPlace,
            outcome: processedOutcome,
            outcomeMotivation: processedOutcomeMotivation,
          },
          {
            modality: modalityChangeMotivation,
            outcomeMotivation: outcome[
              OutcomeProcedureFormIds.OutcomeMotivationDescription
            ] as string,
          },
        ]) as Parameters<typeof outcomeTask>[3],
    nextTaskCreationData:
      nextProcessActivity &&
      (isNotExecuted
        ? ({
            identityTasksId: task.identityTasksId,
            activityTasksId: nextProcessActivity.id,
            ...(nextProcessActivity?.id === task.activityTasksId
              ? {
                  modality: task.modality,
                  location: task.location,
                  placeInfo: task.placeInfo,
                }
              : {
                  modality: nextProcessActivity.modalities[0].name,
                  location:
                    nextProcessActivity.modalities[0].locations[0]?.name,
                  placeInfo:
                    nextProcessActivity.modalities[0].locations[0]?.name ===
                    LocationName.IDENTITYHOME
                      ? identity.placeInfo
                      : undefined,
                }),
            startDateTime: modality[
              OutcomeProcedureFormIds.NextTaskStartDateTime
            ] as string,
            endDateTime: modality[
              OutcomeProcedureFormIds.NextTaskEndDateTime
            ] as string,
          } as TaskModel)
        : ({
            identityTasksId: task.identityTasksId,
            activityTasksId: nextProcessActivity.id,
            modality: nextTaskModality,
            location: nextTaskLocation,
            placeInfo: nextPlace,
            startDateTime: nextDetails[
              OutcomeProcedureFormIds.NextTaskStartDateTime
            ] as string,
            endDateTime: nextDetails[
              OutcomeProcedureFormIds.NextTaskEndDateTime
            ] as string,
          } as TaskModel)),
    nextProcessActivity,
  };
}

/**
 * Schedules a task based on a previous task in the process.
 * @param creationData Data used to schedule the task
 * @param pastAssignee Username of the assignee of the past task
 * @param isNotExecuted True if outcome is not executed
 * @param pastOutcomeMotivation Outcome motivation of the past task
 * @param pastRole Role of the past task
 * @param nextRole Role of the task to schedule
 * @returns The scheduled task or undefined
 */
async function scheduleNextTask(
  creationData: Omit<TaskModel, "assignee">,
  pastAssignee: TaskModel["assignee"],
  isNotExecuted: boolean,
  pastOutcomeMotivation: TaskModel["outcomeMotivation"],
  pastRole: ActivityModel["role"],
  nextRole: ActivityModel["role"]
) {
  let scheduleOptions: Parameters<typeof schedule>[2] | undefined;

  if (isNotExecuted) {
    const lackOfExpertise =
      pastOutcomeMotivation === OutcomeMotivationCodename.LACKOFEXPERTISE;

    const currentAssignee = lackOfExpertise
      ? await getUser(["username", pastAssignee])
      : undefined;

    scheduleOptions = {
      planning: {
        strategy:
          //? If call center has no response, schedule normally; else, asap
          pastRole === UserRole.CALLCENTER &&
          nextRole === UserRole.CALLCENTER &&
          pastOutcomeMotivation ===
            OutcomeMotivationCodename.LACKOFREPLYFROMEXTERNAL
            ? undefined
            : "wrongScheduling",
      },
      assignees: {
        outcomeUsername: pastAssignee,
        exclude:
          lackOfExpertise && currentAssignee ? [currentAssignee.id] : undefined,
      },
    };
  }

  if (creationData.startDateTime) {
    const candidates = await getCandidateAssignees(
      nextRole,
      creationData.identityTasksId,
      scheduleOptions?.assignees?.exclude,
      undefined,
      pastAssignee
    );

    const assignee =
      (await getRandomUserInRole(nextRole as UserRole, candidates)) ??
      getLoggedUser()!;

    return createTask({
      ...creationData,
      assignee,
      placeInfo:
        creationData.location === LocationName.HEADQUARTERS
          ? (
              candidates?.find(({ username }) => username === assignee) ??
              (await getUser(["username", assignee]))
            )?.headquarters
          : creationData.placeInfo,
    });
  }

  return schedule(creationData, nextRole, scheduleOptions);
}
