/**
 * Represents a navigation event, to navigate to other pages; used in logic components to comunicate with page components.
 */
export type NavigationEvent<T extends object = object> = (state?: T) => void;

/**
 * Omits first argument from a function.
 */
export type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never;

/**
 * Modifier component type. Takes as arguments: Base props, Used props, Sealed props (cannot be assigned manually or by other modifiers), New props.
 * Used props and Sealed props are "Required props". Used props and New props are "Processed props".
 * To replace a prop's type definition, mark it as a Sealed prop and declare the new definition in New props.
 */
export type Modifier<
  B extends object,
  U extends keyof B | undefined = undefined,
  S extends keyof B | undefined = undefined,
  N extends object = object
> = <
  P extends Pick<
    B,
    U | S extends keyof B //? Are both U and S defined?
      ? U | S
      : NonNullable<U | S> extends keyof B //? Is one between U and S defined?
      ? NonNullable<U | S>
      : keyof B
  >
>(
  Component: React.FC<P>
) => React.FC<N & (S extends keyof B ? Omit<P, S> : P)>;

/**
 * Filters the values of an array asynchronously.
 * @param array The array to filter
 * @param predicate The async predicate to apply
 * @returns The filtered array
 */
export async function filterAsync<T>(
  array: T[] | undefined,
  predicate: (value: T, index: number, array: T[]) => Promise<boolean | void>
) {
  const filteredValues = [] as T[];

  if (array) {
    await Promise.all(
      array.map(
        async (...args) =>
          (await predicate(...args)) && filteredValues.push(args[0])
      )
    );
  }

  return filteredValues;
}

/**
 * Checks if an async predicate is true on the values of an array.
 * @param array The array to filter
 * @param predicate The async predicate to apply
 * @returns True if some predicate is true
 */
export async function someAsync<T>(
  array: T[] | undefined,
  predicate: (value: T, index: number, array: T[]) => Promise<boolean | void>
) {
  return array?.length
    ? new Promise<boolean>(async (resolve) => {
        for (let index = 0; index < array.length; index++)
          if (await predicate(array[index], index, array)) return resolve(true);

        resolve(false);
      })
    : false;
}

/**
 * Returns the first value where an async predicate is true.
 * @param array The array to filter
 * @param predicate The async predicate to apply
 * @returns The found value
 */
export async function findAsync<T>(
  array: T[] | undefined,
  predicate: (value: T, index: number, array: T[]) => Promise<boolean | void>
) {
  return array?.length
    ? new Promise<T | undefined>(async (resolve) => {
        for (let index = 0; index < array.length; index++) {
          const value = array[index];

          if (await predicate(value, index, array)) return resolve(value);
        }

        resolve(undefined);
      })
    : undefined;
}
