import TaskEffective from "logic/tasks/elements/TaskEffective";
import MeetingBuyerPersonas from "logic/tasks/MeetingBuyerPersonas";
import MeetingFinancing from "logic/tasks/MeetingFinancing";
import {
  OutcomeProcedureFormIds,
  OutcomeProcedureStepIDs,
} from "logic/tasks/OutcomeProcedure";
import {
  ActivityCodename,
  FinancingType,
  Identity,
  RealEstate,
  Review,
  Task,
} from "models";
import { ComponentProps } from "react";
import { isImmediateRealizationActive, OutcomeEntities } from "utils/advance";
import { TranslationFunction } from "utils/localize";
import { updateIdentity, updateTask } from "utils/mutate";
import { toISODurationFromObject } from "utils/time";
import Form from "view/inputs/Form";
import Stepper, { Steps } from "view/outputs/Stepper";

export type NewStep<I extends string> = Omit<Steps<I>[number], "Content"> & {
  after: I | OutcomeProcedureStepIDs;
  Content: (props: {
    data: Record<OutcomeProcedureFormIds, any>;
    identity: Identity;
    formProps?: ComponentProps<typeof Form>;
    stepperData: Record<
      OutcomeProcedureStepIDs | I,
      Record<OutcomeProcedureFormIds, any>
    >;
  }) => ReturnType<NonNullable<Steps<I>[number]["Content"]>>;
};

export type BaseStepExtension = {
  id: OutcomeProcedureStepIDs;
  Content: (props: {
    data: Record<OutcomeProcedureFormIds, any>;
  }) => ComponentProps<typeof Form>;
};

export type OutcomeProcedureStepInsert<I extends string = string> = Omit<
  ComponentProps<typeof Stepper>,
  "steps" | "onComplete"
> & {
  steps: OutcomeProcedureStep<I>[];
  onComplete?: (
    data: Record<
      I | OutcomeProcedureStepIDs,
      Record<OutcomeProcedureFormIds, unknown>
    >,
    outcome: OutcomeEntities
  ) => Promise<OutcomeEntities | undefined>;
};

type OutcomeProcedureStep<I extends string> = BaseStepExtension | NewStep<I>;

export function isNewStep<I extends string>(
  step: OutcomeProcedureStep<I>
): step is NewStep<I> {
  return !!(step as NewStep<I>).after;
}

/**
 * These procedures customize the outcome procedure for specific activities.
 */
const procedures: Partial<
  Record<
    ActivityCodename,
    (props: { t: TranslationFunction<"tasks"> }) => OutcomeProcedureStepInsert
  >
> = {
  [ActivityCodename.MEETING]({
    t,
  }): OutcomeProcedureStepInsert<"financing" | "buyerPersona"> {
    let financingValue: number | undefined;

    return {
      steps: [
        {
          id: "details",
          Content: ({ data }) => ({
            defaultValues: {
              [OutcomeProcedureFormIds.Review]:
                (data[OutcomeProcedureFormIds.Review] as Review) ?? null,
              [OutcomeProcedureFormIds.ReviewMotivation]: data[
                OutcomeProcedureFormIds.ReviewMotivation
              ] as string,
            },
            children: (form, { getValueRender }) => {
              const review = getValueRender<Review>(
                OutcomeProcedureFormIds.Review
              );

              return TaskEffective(
                {
                  Review: OutcomeProcedureFormIds.Review,
                  ReviewMotivation:
                    review === Review.NOREVIEW
                      ? OutcomeProcedureFormIds.ReviewMotivation
                      : undefined,
                },
                form
              );
            },
          }),
        },
        {
          id: "financing",
          label: t("tasks:financing"),
          after: "details",
          Content: ({ formProps, ...financingProps }) => (
            <MeetingFinancing
              {...financingProps}
              formProps={{
                ...formProps,
                onSubmit: (data, ...args) => {
                  financingValue = isImmediateRealizationActive(
                    data[OutcomeProcedureFormIds.FinancingType] as FinancingType
                  )
                    ? (data[OutcomeProcedureFormIds.FinancingValue] as number)
                    : undefined;

                  formProps?.onSubmit?.(data, ...args);
                },
              }}
            />
          ),
        },
        {
          id: "buyerPersona",
          label: t("tasks:buyerPersonas"),
          after: "financing",
          Content: (props) => (
            <MeetingBuyerPersonas {...props} financingValue={financingValue} />
          ),
        },
      ],
      onComplete: async ({ details, financing, buyerPersona }, outcome) => {
        let task: Task | undefined;
        let identity: Identity | undefined;

        if (details) {
          task = await updateTask(
            {
              review: details[OutcomeProcedureFormIds.Review] as Review,
            },
            outcome.task,
            {
              motivation: {
                review: details[
                  OutcomeProcedureFormIds.ReviewMotivation
                ] as string,
              },
            }
          );

          if (!task) return;
        }

        if (financing) {
          identity = await updateIdentity(
            {
              financingDuration: toISODurationFromObject({
                months: financing[
                  OutcomeProcedureFormIds.FinancingDuration
                ] as number,
              }),
              financingType: financing[
                OutcomeProcedureFormIds.FinancingType
              ] as FinancingType,
              financing: financing[
                OutcomeProcedureFormIds.FinancingValue
              ] as number,
              buyerPersona: {
                realEstate: buyerPersona[
                  OutcomeProcedureFormIds.RealEstate
                ] as RealEstate,
                realEstatePrice: buyerPersona[
                  OutcomeProcedureFormIds.RealEstatePrice
                ] as number,
                savings: buyerPersona[
                  OutcomeProcedureFormIds.Savings
                ] as number,
                loanToValue: buyerPersona[
                  OutcomeProcedureFormIds.LoanToValue
                ] as number,
              },
            },
            outcome.identity
          );

          if (!identity) return;
        }

        return {
          ...outcome,
          task: task ?? outcome.task,
          identity: identity ?? outcome.identity,
        };
      },
    };
  },
  [ActivityCodename.DEED](): OutcomeProcedureStepInsert<
    "financing" | "buyerPersona"
  > {
    return {
      steps: [
        {
          id: "details",
          Content: ({ data }) => ({
            defaultValues: {
              [OutcomeProcedureFormIds.Review]:
                (data[OutcomeProcedureFormIds.Review] as Review) ?? null,
              [OutcomeProcedureFormIds.ReviewMotivation]: data[
                OutcomeProcedureFormIds.ReviewMotivation
              ] as string,
            },
            children: (form, { getValueRender }) => {
              const review = getValueRender<Review>(
                OutcomeProcedureFormIds.Review
              );

              return TaskEffective(
                {
                  Review: OutcomeProcedureFormIds.Review,
                  ReviewMotivation:
                    review === Review.NOREVIEW
                      ? OutcomeProcedureFormIds.ReviewMotivation
                      : undefined,
                },
                form
              );
            },
          }),
        },
      ],
      onComplete: async ({ details }, outcome) => {
        let task: Task | undefined;

        if (details) {
          task = await updateTask(
            {
              review: details[OutcomeProcedureFormIds.Review] as Review,
            },
            outcome.task,
            {
              motivation: {
                review: details[
                  OutcomeProcedureFormIds.ReviewMotivation
                ] as string,
              },
            }
          );

          if (!task) return;
        }

        return {
          ...outcome,
          task: task ?? outcome.task,
        };
      },
    };
  },
};

export default procedures;
