import { NavigationEvent } from "constants/code";
import procedures, {
  BaseStepExtension,
  isNewStep,
  NewStep,
  OutcomeProcedureStepInsert,
} from "helpers/process/procedures";
import IdentityView from "logic/identities/IdentityView";
import {
  Activity,
  ModalityName,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
  Task,
} from "models";
import { ComponentProps, useState } from "react";
import {
  extractOutcomeData,
  getNextProcessActivity,
  outcomeTask,
} from "utils/advance";
import { displayPerson } from "utils/format";
import { toLocalizedDateTime, useTranslation } from "utils/localize";
import { getActivity, getIdentity } from "utils/query";
import { useLoader } from "utils/render";
import { getLargeScreen, useModal } from "utils/show";
import { Complete } from "view/inputs/Button";
import Form from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import asRow from "view/LAYOUT/asRow";
import Stepper, { ContentProps, Steps } from "view/outputs/Stepper";
import Text from "view/outputs/Text";
import Progress from "view/symbols/Progress";
import NextTask from "../NextTask";
import TaskDetails from "../TaskDetails";
import TaskModality from "../TaskModality";
import TaskOutcome from "../TaskOutcome";

export enum OutcomeProcedureFormIds {
  Modality,
  ModalityChangeMotivation,
  StartTime,
  EndTime,
  Location,
  Place,
  Subject,
  FinancingType,
  FinancingValue,
  FinancingDuration,
  RealEstate,
  RealEstatePrice,
  Savings,
  LoanToValue,
  HouseHunterInterest,
  Outcome,
  OutcomeMotivationValue,
  OutcomeMotivationDescription,
  NextTaskLocation,
  NextTaskModality,
  NextTaskPlaceValue,
  Review,
  ReviewMotivation,
  NextTaskStartDateTime,
  NextTaskEndDateTime,
}

export type OutcomeProcedureStepIDs =
  | "modality"
  | "details"
  | "outcome"
  | "nextDetails";

function createFormProps({
  Buttons,
}: Pick<ContentProps, "Buttons">): ComponentProps<typeof Form> {
  return {
    SubmitButton: (submitProps) => <Buttons next={submitProps} />,
  };
}

// TODO USE ENTITY INSTEAD OF ELEMENTS
export default function OutcomeProcedure(props: {
  task: Task;
  onOutcomeFinished?: NavigationEvent;
}) {
  const { t } = useTranslation("tasks");

  const openModal = useModal();

  const [nextActivity, setNextActivity] = useState<Activity>();

  const task = props.task;

  const dataForStepper = useLoader(async () => {
    const identity = await getIdentity(task);

    return {
      identity,
      activity: await getActivity(task, identity),
    };
  });

  if (dataForStepper) {
    if (dataForStepper.identity && dataForStepper.activity) {
      const { identity, activity } = dataForStepper;

      const identityLink = identity && (
        <Text
          type="title"
          spaceProps={{ mb: 0.4 }}
          onClick={() =>
            openModal((close) => (
              <IdentityView
                identityID={identity?.id}
                onIdentitySubmitted={close}
              />
            ))
          }
        >
          {displayPerson(identity)}
        </Text>
      );

      const largeScreen = getLargeScreen();

      let stepperProps: Omit<ComponentProps<typeof Stepper>, "onComplete"> &
        Pick<OutcomeProcedureStepInsert, "onComplete"> = {
        title: t("tasks:outcome"),
        subtitle: largeScreen
          ? asRow([identityLink, activity && ` - ${activity.name}`], {
              disableResponsive: true,
              spacing: 1,
            })
          : asColumn([identityLink, activity.name], {
              spacing: 0,
            }),
      };

      const newSteps: NewStep<OutcomeProcedureStepIDs>[] = [];
      const extendSteps: Partial<
        Record<BaseStepExtension["id"], BaseStepExtension["Content"]>
      > = {};

      const stepInserts = activity.codename
        ? [procedures[activity.codename]?.({ t } as any)]
        : [];

      for (const insert of stepInserts) {
        if (insert) {
          const {
            steps: stepsToAdd,
            onComplete,
            ...stepperPropsToAdd
          } = insert;

          const previousFunction = stepperProps.onComplete?.bind({});

          stepperProps = {
            ...stepperProps,
            ...stepperPropsToAdd,
            onComplete: async (data, outcome) => {
              let mostRecentOutcome = outcome;

              if (previousFunction) {
                const previousOutcome = await previousFunction(data, outcome);

                if (previousOutcome) mostRecentOutcome = previousOutcome;
                else return;
              }

              return onComplete
                ? onComplete(data, mostRecentOutcome)
                : mostRecentOutcome;
            },
          };

          for (const step of stepsToAdd) {
            if (isNewStep(step)) newSteps.push(step as typeof newSteps[number]);
            else extendSteps[step.id] = step.Content;
          }
        }
      }

      const steps: Steps<OutcomeProcedureStepIDs> = [
        {
          //? Can be last
          id: "modality",
          label: t("tasks:modality"),
          Content: ({
            data,
            complete: completeStepper,
            next,
            ...contentProps
          }) => (
            <TaskModality
              data={data as Record<OutcomeProcedureFormIds, any>}
              formProps={{
                ...createFormProps(contentProps),
                onSubmit: (formData, complete) => {
                  if (
                    formData[OutcomeProcedureFormIds.Modality] ===
                    OutcomeName.NOTEXECUTED
                  ) {
                    completeStepper({ complete, ...formData });
                  } else {
                    complete();
                    next(formData);
                  }
                },
              }}
              activity={activity}
              task={task}
              onTaskPostponed={props.onOutcomeFinished}
            />
          ),
        },
        {
          id: "details",
          label: t("tasks:details"),
          Content: ({ data: stepData, stepperData, next, ...contentProps }) => (
            <TaskDetails
              modalityName={
                (
                  stepperData as Record<
                    OutcomeProcedureStepIDs,
                    { [OutcomeProcedureFormIds.Modality]: ModalityName }
                  >
                ).modality[OutcomeProcedureFormIds.Modality]
              }
              data={stepData as Record<OutcomeProcedureFormIds, any>}
              formProps={{
                ...createFormProps(contentProps),
                onSubmit: (formData, complete) => {
                  complete();
                  next(formData);
                },
                ...extendSteps["details"]?.({
                  data: stepData as Record<OutcomeProcedureFormIds, any>,
                }),
              }}
              task={task}
              activity={activity}
              identity={identity}
            />
          ),
        },
        {
          //? Can be last
          id: "outcome",
          label: t("tasks:outcome"),
          Content: ({
            data,
            next,
            complete: completeStepper,
            ...contentProps
          }) => (
            <TaskOutcome
              data={data as Record<OutcomeProcedureFormIds, any>}
              formProps={{
                ...createFormProps(contentProps),
                onSubmit: async (formData, complete) => {
                  const outcome = formData[
                    OutcomeProcedureFormIds.Outcome
                  ] as OutcomeName;

                  const outcomeMotivation = formData[
                    OutcomeProcedureFormIds.OutcomeMotivationValue
                  ] as OutcomeMotivation["name"];

                  if (outcomeMotivation === OutcomeMotivationCodename.OTHER) {
                    completeStepper({ complete, ...formData });
                  } else {
                    const nextProcessActivity = await getNextProcessActivity(
                      outcome,
                      outcomeMotivation,
                      activity,
                      identity,
                      task.taskChildrenId,
                      activity.outcomes
                        .find(
                          ({ name }) =>
                            name ===
                            (formData[
                              OutcomeProcedureFormIds.Outcome
                            ] as OutcomeName)
                        )
                        ?.outcomeMotivations.find(
                          ({ codename, name }) =>
                            codename === outcomeMotivation ||
                            name === outcomeMotivation
                        )?.nextActivityIds[0]
                    );

                    if (nextProcessActivity) {
                      setNextActivity(nextProcessActivity);
                      complete();
                      next(formData);
                    } else {
                      completeStepper({ complete, ...formData });
                    }
                  }
                },
              }}
              activity={activity}
            />
          ),
        },
        {
          id: "nextDetails", //? Can be last
          label: t("tasks:next"),
          Content: ({ data, next, stepperData, ...contentProps }) =>
            nextActivity ? (
              <NextTask
                data={data as Record<OutcomeProcedureFormIds, any>}
                formProps={{
                  ...createFormProps(contentProps),
                  onSubmit: (formData, complete) =>
                    next({ complete, ...formData }),
                }}
                nextActivity={nextActivity}
                task={task}
                identity={identity}
                activity={activity}
                outcome={
                  stepperData.outcome[
                    OutcomeProcedureFormIds.Outcome
                  ] as OutcomeName
                }
                outcomeMotivation={
                  stepperData.outcome[
                    OutcomeProcedureFormIds.OutcomeMotivationValue
                  ] as OutcomeMotivation["name"]
                }
              />
            ) : null,
        },
      ];

      for (const { after, Content, ...newStep } of newSteps) {
        steps.splice(steps.findIndex(({ id }) => id === after) + 1, 0, {
          ...newStep,
          Content: ({ data, next, stepperData, ...contentProps }) => (
            <Content
              data={data as Record<OutcomeProcedureFormIds, any>}
              formProps={{
                ...createFormProps(contentProps),
                onSubmit: (formData, complete) => {
                  if (newStep.id === steps[steps.length - 1].id) {
                    next({ ...formData, complete });
                  } else {
                    complete();
                    next(formData);
                  }
                },
              }}
              identity={identity}
              stepperData={stepperData as any}
            />
          ),
        });
      }

      return (
        <Stepper
          steps={steps}
          {...stepperProps}
          onComplete={async (data) => {
            let complete: Complete | undefined;

            for (const step in data) {
              complete = data[step].complete;
              if (complete) break;
            }

            const { taskMutation, nextTaskCreationData, nextProcessActivity } =
              await extractOutcomeData(
                data,
                task,
                identity,
                activity,
                nextActivity
              );

            const outcome = await outcomeTask(
              task,
              activity,
              identity,
              taskMutation,
              nextTaskCreationData,
              nextProcessActivity
            );

            if (
              outcome &&
              (!stepperProps.onComplete ||
                (await stepperProps.onComplete?.(data, outcome)))
            ) {
              complete?.(
                outcome.nextTask && outcome.nextActivity
                  ? `${outcome.nextActivity.name} ${t(
                      "tasks:programmedFor"
                    )} ${toLocalizedDateTime(outcome.nextTask.startDateTime)}`
                  : t("tasks:outcomeDone"),
                "success"
              );

              props.onOutcomeFinished?.();
            } else {
              complete?.(t("tasks:outcomeFailed"), "error");
            }
          }}
        />
      );
    } else {
      return null;
    }
  } else {
    return <Progress />;
  }
}
