import { IIR_BOUNDS } from "constants/business";
import { NavigationEvent } from "constants/code";
import { Permissions } from "helpers/rbac";
import TaskList from "logic/tasks/TaskList";
import { BuyerPersona, FinancingType, Identity } from "models";
import {
  isAssignerResponsibility,
  isImmediateRealizationActive,
} from "utils/advance";
import { getLoggedUser } from "utils/authenticate";
import { canUser } from "utils/authorize";
import { displayPerson } from "utils/format";
import { useTranslation } from "utils/localize";
import { getActivity, getIdentity, getTasks } from "utils/query";
import { useLoader } from "utils/render";
import { getLargeScreen } from "utils/show";
import { isValidPhoneNumber } from "utils/validate";
import Entity, { EntityProps } from "view/Entity";
import asSpace from "view/LAYOUT/asSpace";
import Title from "view/outputs/Title";
import Progress from "view/symbols/Progress";

export default function IdentityView(props: {
  entityProps?: Partial<EntityProps<Identity>>;
  identityID?: Identity["id"];
  onIdentitySubmitted?: NavigationEvent;
}) {
  const { t } = useTranslation(["identities", "enumRealEstates"]);

  const largeScreen = getLargeScreen();

  const identityData = useLoader(async () => {
    const identity =
      (props.entityProps?.defaultValues as Identity | undefined) ??
      (await getIdentity(props.identityID));

    let canUpdate = canUser(Permissions.HandleIdentities);

    async function getActiveIdentityTasksAs(
      owner: NonNullable<
        NonNullable<Parameters<typeof getTasks>[0]>["ownerType"]
      >
    ) {
      if (!identity) return;

      const username = getLoggedUser();

      if (!username) return;

      return (
        await getTasks({
          ownerType: owner,
        })
      )?.filter(
        ({ identityTasksId, outcome, ...task }) =>
          !outcome &&
          identityTasksId === identity.id &&
          task[owner] === username
      );
    }

    if (!canUpdate) {
      //? Check as assignee

      const assigneeTasks = await getActiveIdentityTasksAs("assignee");

      if (assigneeTasks) {
        for (const task of assigneeTasks) {
          const activity = await getActivity(task, identity);

          if (activity) {
            if (!isAssignerResponsibility(task, activity)) {
              //? Assignee responsibility
              canUpdate = true;
              break;
            }
          }
        }
      }

      if (!canUpdate) {
        //? Check as assigner

        const assignerTasks = await getActiveIdentityTasksAs("assigner");

        if (assignerTasks) {
          for (const task of assignerTasks) {
            const activity = await getActivity(task, identity);

            if (activity) {
              if (isAssignerResponsibility(task, activity)) {
                //? Assigner responsibility
                canUpdate = true;
                break;
              }
            }
          }
        }
      }
    }

    return {
      identity,
      canUpdate,
    };
  });

  const isCallCenter = canUser(Permissions.HandleMeetings);

  let financingType: FinancingType | undefined;
  let financingValue: number | undefined;
  let realEstatePrice: number | undefined;

  const operation = props.identityID
    ? identityData?.canUpdate
      ? "update"
      : "view"
    : "create";

  const exclude = {
    tasks: true,
    houseHunter: true,
    price: true,
    toPay: true,
    paymentIdentitiesId: true,
    callCenterId: !canUser(Permissions.HandleMeetings),
    ...props.entityProps?.exclude,
  };

  return identityData ? (
    <>
      {
        <Entity
          {...props.entityProps}
          model={Identity}
          operation={operation}
          hideDelete={!canUser(Permissions.HandleIdentities)}
          defaultValues={{
            ...identityData.identity,
            ...props.entityProps?.defaultValues,
          }}
          titleProps={{
            children:
              operation === "create"
                ? t("identities:createIdentity")
                : operation === "update"
                ? t("identities:updateIdentity")
                : t("identities:viewIdentity"),
            subtitle: displayPerson(identityData.identity),
            details:
              identityData.identity &&
              [
                t(
                  `enumRealEstates:${identityData.identity.buyerPersona.realEstate}`
                ),
                identityData.identity.buyerPersona.realEstatePrice,
                identityData.identity.buyerPersona.savings,
                identityData.identity.buyerPersona.loanToValue,
              ]
                .filter((buyerPersona) => buyerPersona !== undefined)
                .join(", "),
          }}
          dependencies={[
            {
              dependsOn: "financingType",
              relationship: (value: FinancingType) => {
                financingType = value;
              },
            },
            {
              dependsOn: "financing",
              relationship: (value: number) => {
                financingValue = value;
              },
            },
            {
              dependsOn: "buyerPersona",
              relationship: (value: BuyerPersona) => {
                realEstatePrice = value.realEstatePrice;
              },
            },
          ]}
          insert={[
            {
              Component: () => {
                const iir =
                  isImmediateRealizationActive(financingType) &&
                  financingValue &&
                  realEstatePrice &&
                  financingValue / realEstatePrice;

                return iir && iir > IIR_BOUNDS.min && iir < IIR_BOUNDS.max
                  ? asSpace(
                      <Title field subtitle={iir.toFixed(3)}>
                        {t("identities:immediateRealizationIndex")}
                      </Title>,
                      {
                        mt: largeScreen ? 1 : undefined,
                        mb: largeScreen ? -6 : 3,
                      }
                    )
                  : null;
              },
              after: "financingDuration",
            },
          ]}
          customValidator={async (data) => {
            if (
              !exclude.mobilePhone &&
              (!data.mobilePhone ||
                !(await isValidPhoneNumber(data.mobilePhone)))
            ) {
              return t("identities:invalidPhoneNumber");
            }

            if (
              isImmediateRealizationActive(data.financingType as FinancingType)
            ) {
              const min = IIR_BOUNDS.min * data.buyerPersona.realEstatePrice;
              const higherThanMin = data.financing && data.financing > min;
              const max = IIR_BOUNDS.max * data.buyerPersona.realEstatePrice;
              const lowerThanMax = data.financing && data.financing < max;

              return (
                (higherThanMin && lowerThanMax) ||
                (lowerThanMax
                  ? `${t("identities:financingShouldBeHigherThan")} ${min}`
                  : `${t("identities:financingShouldBeLowerThan")} ${max}`)
              );
            } else {
              return true;
            }
          }}
          exclude={exclude}
          creationMessage={t("identities:identityCreated")}
          creationFailedMessage={t("identities:identityCreationFailed")}
          updateMessage={t("identities:identityUpdated")}
          updateFailedMessage={t("identities:identityUpdateFailed")}
          deletionMessage={t("identities:identityDeleted")}
          deletionFailedMessage={t("identities:identityDeleteFailed")}
          onOperationExecuted={props.onIdentitySubmitted}
        />
      }
      {identityData.identity && !isCallCenter && (
        <TaskList identityID={identityData.identity.id} />
      )}
    </>
  ) : (
    <Progress />
  );
}
