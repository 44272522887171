import { InputAdornment } from "@mui/material";
import { ReactNode } from "react";
import { parseFloating, parseInteger } from "utils/format";
import Controller from "./Controller";
import { FormProps } from "./Form";
import Input, { FormElementProps } from "./Input";

export default function Number(
  props: {
    unit?: ReactNode;
    precise?: boolean;
    min?: number;
    max?: number;
    allowNegative?: boolean;
    normalized?: boolean;
    getHelperText?: (value: number | "") => ReactNode;
    onChange?: (value: number | "") => void;
  } & FormProps &
    Omit<FormElementProps, "onChange">
) {
  const {
    InputProps,
    unit,
    precise,
    min,
    max,
    normalized,
    allowNegative,
    helperText,
    getHelperText,
    onChange: propsOnChange,
    defaultValue,
    ...inputProps
  } = props;

  return (
    <Controller defaultValue={defaultValue} defaultFallback="" {...inputProps}>
      {({ onChange, innerRef, value, ...field }) => (
        <Input
          {...inputProps}
          type="number"
          inputRef={innerRef}
          value={value ?? ""}
          InputProps={{
            ...InputProps,
            inputProps: {
              ...InputProps?.inputProps,
              step: precise ? "any" : undefined,
              min: min
                ? precise
                  ? min
                  : Math.round(min)
                : allowNegative
                ? undefined
                : 0,
              max: max && !precise ? Math.round(max) : max,
            },
            startAdornment: unit ? (
              <InputAdornment position="start">{unit}</InputAdornment>
            ) : undefined,
          }}
          helperText={
            helperText !== undefined || getHelperText
              ? (helperText ?? getHelperText?.(value)) || " "
              : undefined
          }
          onChange={(e) => {
            const parsedValue =
              (precise
                ? parseFloating(e.currentTarget.value)
                : parseInteger(e.currentTarget.value)) ?? "";

            onChange(parsedValue);
            propsOnChange?.(parsedValue);
          }}
          {...field}
        />
      )}
    </Controller>
  );
}
