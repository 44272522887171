import { DataGrid } from "@mui/x-data-grid/DataGrid";
import * as translations from "@mui/x-data-grid/locales";
import { GridRowId } from "@mui/x-data-grid/models";
import { BIG_COMPONENTS_HEIGHT, LOADING_TIMEOUT } from "constants/ui";
import {
  ComponentProps,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
} from "react";
import { toMuiLocaleFromi18Next, useTranslation } from "utils/localize";
import { useAsync } from "utils/render";
import { getLargeScreen, useModal } from "utils/show";
import asSpace from "view/LAYOUT/asSpace";
import Button, { Complete } from "../inputs/Button";
import asRow from "../LAYOUT/asRow";
import Confirm from "./Confirm";
import Title from "./Title";

export default function Table(
  props: {
    title?: ReactNode;
    subtitle?: ReactNode;
    details?: ReactNode;
    height?: number;
    Buttons?: (props: {
      selected: GridRowId[];
      setSelected: Dispatch<SetStateAction<GridRowId[]>>;
    }) => ReactNode;
    RightButton?: (props: { selected: GridRowId[] }) => ReactNode;
    createText?: ReactNode;
    deleteText?: ReactNode;
    deleteButtonProps?: ComponentProps<typeof Button>;
    deleteConfirmProps?: ComponentProps<typeof Confirm>;
    disableFlexColumns?: boolean;
    selectionModel?: GridRowId[];
    onCreate?: (complete: () => void) => ReactNode;
    onDelete?: (param: GridRowId[], complete: Complete) => void;
  } & Omit<ComponentProps<typeof DataGrid>, "localeText" | "selectionModel">
) {
  const {
    title,
    rows,
    loading,
    subtitle,
    details,
    columns,
    height,
    selectionModel,
    onCreate,
    onDelete,
    onSelectionModelChange,
    createText,
    deleteText,
    deleteConfirmProps,
    deleteButtonProps,
    Buttons,
    RightButton,
    disableFlexColumns,
    page,
    pageSize,
    onRowClick,
    ...dataGridProps
  } = props;

  const {
    t,
    i18n: { language },
  } = useTranslation("view");

  const [selected, setSelected] = useState<NonNullable<typeof selectionModel>>(
    selectionModel ?? []
  );

  const [showLoader, setShowLoader] = useState(false);

  let timeout: NodeJS.Timeout | undefined;

  useAsync(
    {
      condition: loading && !showLoader,
      action: () => setTimeout(() => setShowLoader(true), LOADING_TIMEOUT),
      cleanup: () => timeout && clearTimeout(timeout),
    },
    (loadingTimeout) => (timeout = loadingTimeout)
  );

  const openModal = useModal();

  const largeScreen = getLargeScreen();

  const rightButton = RightButton?.({ selected });

  return (
    <>
      <Title subtitle={subtitle} details={details}>
        {title}
      </Title>
      {asRow(
        [
          asRow(
            [
              onCreate && (
                <Button
                  onClick={(complete) => {
                    complete();

                    openModal(onCreate);
                  }}
                >
                  {createText ?? t("view:create")}
                </Button>
              ),
              onDelete && (
                <Button
                  delete
                  disabled={!selected.length}
                  onClick={(complete) => {
                    complete();

                    openModal((close) => (
                      <Confirm
                        title={t("view:confirmDeletion")}
                        confirm={deleteText ?? t("view:delete")}
                        onCancel={close}
                        onConfirm={(completeConfirm) =>
                          onDelete(selected, (...params) => {
                            completeConfirm(...params);
                            setSelected([]);
                            close();
                          })
                        }
                        confirmButtonProps={deleteButtonProps}
                        {...deleteConfirmProps}
                      />
                    ));
                  }}
                  children={deleteText}
                  {...deleteButtonProps}
                />
              ),
              Buttons?.({ selected, setSelected }),
            ],
            {
              disableResponsive: true,
            }
          ),
          rightButton,
        ],
        {
          rightShifted: !!rightButton,
          mb: 2,
        }
      )}
      {asSpace(
        <DataGrid
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading && showLoader}
          pagination
          hideFooter={rows.length < 101 && page === undefined}
          page={page}
          pageSize={pageSize}
          rowsPerPageOptions={pageSize ? [pageSize] : [25, 50, 100]}
          rows={rows}
          disableSelectionOnClick
          localeText={{
            ...(
              translations[
                toMuiLocaleFromi18Next(language) as keyof typeof translations
              ] as {
                components: {
                  MuiDataGrid: {
                    defaultProps: { localeText: Record<string, any> };
                  };
                };
              }
            ).components.MuiDataGrid.defaultProps.localeText,
            noRowsLabel: t("view:noRows"),
            noResultsOverlayLabel: t("view:noRowsFound"),
            columnsPanelTextFieldPlaceholder: t("view:columnTitle"),
            columnsPanelDragIconLabel: t("view:reorderColumn"),
            columnsPanelShowAllButton: t("view:showAll"),
            columnsPanelHideAllButton: t("view:hideAll"),
            filterPanelAddFilter: t("view:addFilter"),
            filterPanelDeleteIconLabel: t("view:delete"),
            filterPanelOperators: t("view:operators"),
            filterPanelOperatorAnd: t("view:and"),
            filterPanelOperatorOr: t("view:or"),
            filterPanelColumns: t("view:columns"),
            filterPanelInputPlaceholder: t("view:filterValue"),
            filterOperatorContains: t("view:contains"),
            filterOperatorEquals: t("view:equals"),
            filterOperatorStartsWith: t("view:startsWith"),
            filterOperatorEndsWith: t("view:endsWith"),
            filterOperatorIs: t("view:is"),
            filterOperatorNot: t("view:isNot"),
            filterOperatorAfter: t("view:isAfter"),
            filterOperatorOnOrAfter: t("view:isOnOrAfter"),
            filterOperatorBefore: t("view:isBefore"),
            filterOperatorOnOrBefore: t("view:isOnOrBefore"),
            filterOperatorIsEmpty: t("view:isEmpty"),
            filterOperatorIsNotEmpty: t("view:isNotEmpty"),
            filterOperatorIsAnyOf: t("view:isOneOf"),
            filterValueAny: t("view:any"),
            filterValueTrue: t("view:true"),
            filterValueFalse: t("view:false"),
            columnMenuLabel: t("view:menu"),
            columnMenuShowColumns: t("view:showColumns"),
            columnMenuUnsort: t("view:unsort"),
            columnMenuSortAsc: t("view:sortByAsc"),
            columnMenuSortDesc: t("view:sortByDesc"),
            columnHeaderFiltersLabel: t("view:showFilters"),
            footerRowSelected: (count) =>
              count !== 1
                ? `${count.toLocaleString()} ${t("view:rowsSelected")}`
                : `${count.toLocaleString()} ${t("view:rowSelected")}`,
            checkboxSelectionHeaderName: t("view:checkboxSelection"),
          }}
          checkboxSelection={!!onDelete}
          columns={(largeScreen ? columns : [columns[0], columns[1]]).map(
            (column) =>
              ({
                flex: (disableFlexColumns ? largeScreen : column.minWidth)
                  ? undefined
                  : 1,
                ...column,
              } as typeof columns[number])
          )}
          selectionModel={selected}
          onSelectionModelChange={
            onSelectionModelChange
              ? (selection, selectionDetails) => {
                  onSelectionModelChange?.(selection, selectionDetails);
                  setSelected(selection);
                }
              : onDelete && ((selection) => setSelected(selection))
          }
          onRowClick={
            onRowClick &&
            ((...params) =>
              (params[1].target as { checked?: boolean }).checked ===
                undefined && onRowClick(...params))
          }
          {...dataGridProps}
        />,
        {
          height: `${
            (height ?? BIG_COMPONENTS_HEIGHT) * (largeScreen ? 1 : 0.8)
          }em`,
        }
      )}
    </>
  );
}
