import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  createFilterOptions,
  FilterOptionsState,
  FormControl as MuiFormControl,
  InputLabel as MuiLabel,
  MenuItem as MuiSelectItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { cleanUserInput } from "utils/format";
import { useTranslation } from "utils/localize";
import { useAsync } from "utils/render";
import { getLargeScreen } from "utils/show";
import Progress from "../symbols/Progress";
import Controller from "./Controller";
import { FormProps } from "./Form";
import Input from "./Input";

const filter = createFilterOptions<any>();

export default function Select<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: {
    options?: T[] | (() => Promise<T[]>);
    id?: string | number;
    children?: ReactNode;
    helperText?: ReactNode;
    getHelperText?: (option: T) => ReactNode;
    defaultValue?: T | null;
    onChange?: (data: T) => void;
    freeSolo?: boolean;
    getOptionLabel?: (option: T) => ReactNode;
  } & Pick<MuiTextFieldProps, "required"> &
    FormProps &
    Omit<
      MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> &
        MuiSelectProps,
      | "id"
      | "renderInput"
      | "variant"
      | "label"
      | "options"
      | "onOpen"
      | "onClose"
      | "loading"
      | "defaultValue"
      | "onChange"
      | "freeSolo"
      | "getOptionLabel"
    >
) {
  type Loaded = T[] | null;

  type ToLoad = (() => Promise<T[]>) | null;

  const {
    form,
    id,
    children,
    disabled,
    required,
    options,
    helperText,
    getHelperText,
    defaultValue,
    multiple,
    freeSolo,
    getOptionLabel,
    onChange: propsOnChange,
    ...selectProps
  } = props;

  const { t } = useTranslation("view");

  const [open, setOpen] = useState(false);
  const [loadedOptions, setLoadedOptions] = useState<Loaded | ToLoad>(
    typeof options !== "function" ? options ?? null : () => options
  );

  const loading =
    typeof options === "function" &&
    (typeof loadedOptions === "function" || (open && !loadedOptions));

  useAsync(
    {
      condition: loading,
      action: options as NonNullable<ToLoad>,
    },
    setLoadedOptions
  );

  const largeScreen = getLargeScreen();

  const showSelect =
    !freeSolo && !largeScreen && loadedOptions && loadedOptions?.length < 25;

  const freeSoloAdd = `${t("view:add")} "`;

  const adjustedOptions = loading ? [] : (loadedOptions as Loaded) ?? [];

  const isRequired = required ?? form.required;

  const selectOptions = adjustedOptions.map((option) => (
    <MuiSelectItem
      key={(option as unknown as string).toString()}
      value={option as unknown as string}
    >
      {getOptionLabel?.(option) ?? (option as unknown as ReactNode)}
    </MuiSelectItem>
  ));

  const assignedOptions = showSelect
    ? isRequired || multiple
      ? selectOptions
      : [
          <MuiSelectItem key="empty" value={null as any}>
            <em>{t("view:none")}</em>
          </MuiSelectItem>,
          ...selectOptions,
        ]
    : (adjustedOptions as unknown as JSX.Element[]);

  return (
    loadedOptions && (
      <Controller
        form={form}
        id={id}
        defaultValue={
          defaultValue === undefined
            ? loadedOptions?.length === 1 && !freeSolo
              ? multiple
                ? []
                : (loadedOptions as NonNullable<Loaded>)[0]
              : undefined
            : defaultValue
        }
        defaultFallback={multiple ? [] : null}
        {...selectProps}
      >
        {({ onChange, innerRef, value, ...field }) =>
          showSelect ? (
            <MuiFormControl fullWidth variant="filled">
              <MuiLabel id={`${id?.toString()}-label`}>{children}</MuiLabel>
              <MuiSelect
                {...selectProps}
                sx={{ textAlign: "left" }}
                labelId={`${id?.toString()}-label`}
                ref={innerRef}
                id={id?.toString()}
                disabled={disabled ?? form.disabled}
                required={isRequired}
                multiple={multiple}
                label={children}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={value ?? ""}
                onChange={({ target: { value: data } }) => {
                  onChange(data);
                  propsOnChange?.(data);
                }}
                {...field}
              >
                {assignedOptions}
              </MuiSelect>
            </MuiFormControl>
          ) : (
            <MuiAutocomplete
              {...(selectProps as any)}
              ref={innerRef}
              id={id?.toString()}
              disabled={disabled ?? form.disabled}
              loading={loading}
              multiple={multiple}
              freeSolo={freeSolo}
              handleHomeEndKeys={freeSolo}
              selectOnFocus={freeSolo}
              clearOnBlur={freeSolo}
              filterOptions={
                freeSolo
                  ? (options: T[], params: FilterOptionsState<string>) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;

                      if (
                        inputValue &&
                        !(loadedOptions as Loaded)?.some(
                          (option) =>
                            inputValue === (option as unknown as string)
                        )
                      ) {
                        filtered.push(
                          `${freeSoloAdd}${cleanUserInput(inputValue)}"`
                        );
                      }

                      return filtered;
                    }
                  : undefined
              }
              options={assignedOptions}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              value={value}
              onChange={(_: any, data: string | null) => {
                let processedData = data;

                if (typeof data === "string" && data.startsWith(freeSoloAdd))
                  processedData = data.slice(freeSoloAdd.length, -1);

                onChange(processedData);
                propsOnChange?.(processedData as any);
              }}
              getOptionLabel={getOptionLabel}
              renderInput={(inputProps) => (
                <Input
                  {...inputProps}
                  helperText={
                    helperText !== undefined || getHelperText
                      ? (helperText ?? getHelperText?.(value)) || " "
                      : undefined
                  }
                  InputProps={{
                    ...inputProps.InputProps,
                    endAdornment: (
                      <>
                        {open && loading && <Progress spinner />}
                        {inputProps.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  InputLabelProps={{
                    required: required && !form.required,
                  }}
                  required={multiple ? isRequired && !value.length : isRequired}
                  form={form}
                >
                  {children}
                </Input>
              )}
              {...field}
            />
          )
        }
      </Controller>
    )
  );
}
