import { ActivityCodename, LocationName, ModalityName, Place } from "models";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import { ActivityModel, IdentityModel, TaskModel } from "utils/query";
import { getNow, toDateTime } from "utils/time";
import Form from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import TaskEffective from "../elements/TaskEffective";
import { OutcomeProcedureFormIds } from "../OutcomeProcedure";

export function isPlaceVisible(location?: TaskModel["location"]) {
  return !!location && location !== LocationName.HEADQUARTERS;
}

export default function TaskDetails(props: {
  modalityName?: ModalityName;
  data: Record<OutcomeProcedureFormIds, any>;
  formProps?: ComponentProps<typeof Form>;
  task?: TaskModel;
  activity?: ActivityModel;
  identity?: IdentityModel;
}) {
  const { t } = useTranslation("tasks");

  const endDateTime =
    (props.task?.endDateTime && toDateTime(props.task.endDateTime)) ||
    undefined;

  const startDateTime =
    (props.task?.startDateTime && toDateTime(props.task.startDateTime)) ||
    undefined;

  const now = getNow();

  //? If false, shows date time
  const showTime = startDateTime?.toISODate() === now.toISODate();

  const endIsBeforeNow = endDateTime && endDateTime < now;

  const startIsBeforeNow = startDateTime && startDateTime < now;

  const locations = props.activity?.modalities.find(
    ({ name }) => name === props.modalityName
  )?.locations;

  const isSingleLocation = locations?.length === 1;

  const isIdentityMeeting =
    props.activity?.codename === ActivityCodename.MEETING;

  return (
    <Form
      {...props.formProps}
      defaultValues={{
        [OutcomeProcedureFormIds.StartTime]:
          (props.data[OutcomeProcedureFormIds.StartTime] as string) ??
          (isIdentityMeeting
            ? null
            : (startIsBeforeNow ? startDateTime : now).toISO()),
        [OutcomeProcedureFormIds.EndTime]:
          (props.data[OutcomeProcedureFormIds.EndTime] as string) ??
          (isIdentityMeeting
            ? null
            : (endIsBeforeNow ? endDateTime : now).toISO()),
        [OutcomeProcedureFormIds.Location]:
          (props.data[OutcomeProcedureFormIds.Location] as string) ??
          (isSingleLocation || !isIdentityMeeting
            ? props.task?.location
            : null),
        [OutcomeProcedureFormIds.Place]:
          (props.data[OutcomeProcedureFormIds.Place] as Place) ??
          isIdentityMeeting
            ? null
            : props.task?.placeInfo,
        ...props.formProps?.defaultValues,
      }}
      onSubmit={(data, complete, reset) => {
        if (
          toDateTime(data[OutcomeProcedureFormIds.StartTime]) <
          toDateTime(data[OutcomeProcedureFormIds.EndTime])
        ) {
          props.formProps?.onSubmit?.(data, complete, reset as any);
        } else {
          complete(
            showTime
              ? t("tasks:endTimeIsBeforeStartTime")
              : t("tasks:endDateTimeIsBeforeStartTime"),
            "error"
          );
        }
      }}
    >
      {(...formProps) => {
        const [form, { getValueRender, setValue }] = formProps;

        const location = getValueRender<LocationName>(
          OutcomeProcedureFormIds.Location
        );

        const extendedChildren = props.formProps?.children?.(
          ...(formProps as [any, any])
        );

        return asColumn([
          ...TaskEffective(
            {
              StartTime: showTime
                ? OutcomeProcedureFormIds.StartTime
                : undefined,
              EndTime: showTime ? OutcomeProcedureFormIds.EndTime : undefined,
              StartDateTime: showTime
                ? undefined
                : OutcomeProcedureFormIds.StartTime,
              EndDateTime: showTime
                ? undefined
                : OutcomeProcedureFormIds.EndTime,
              Location: isSingleLocation
                ? undefined
                : OutcomeProcedureFormIds.Location,
              Place: isPlaceVisible(location)
                ? OutcomeProcedureFormIds.Place
                : undefined,
            },
            form,
            {
              StartTime: {
                maxTime: now,
              },
              EndTime: {
                maxTime: now,
              },
              StartDateTime: {
                maxDateTime: now,
              },
              EndDateTime: {
                maxDateTime: now,
              },
              Location: {
                change: (options) => {
                  const choiceLocations = {} as Record<
                    LocationName,
                    LocationName
                  >;

                  locations?.forEach(
                    ({ name }) =>
                      (choiceLocations[name] =
                        options[name as keyof typeof options])
                  );

                  return choiceLocations;
                },
                onChange:
                  props.identity &&
                  ((value) => {
                    if (value === LocationName.IDENTITYHOME) {
                      setValue(
                        OutcomeProcedureFormIds.Place,
                        props.identity!.placeInfo
                      );
                    }
                  }),
              },
            }
          ),
          ...(Array.isArray(extendedChildren)
            ? extendedChildren
            : [extendedChildren]),
        ]);
      }}
    </Form>
  );
}
