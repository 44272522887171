import { FinancingType, RealEstate } from "models";
import { ComponentProps, forwardRef, useState } from "react";
import { useTranslation } from "utils/localize";
import Choice from "view/inputs/Choice";
import { createFormElement } from "view/inputs/Form";
import Money from "view/inputs/Money";
import Number from "view/inputs/Number";
import Percentage from "view/inputs/Percentage";

export default createFormElement(() => {
  const { t } = useTranslation([
    "identities",
    "enumRealEstatePrices",
    "enumSavings",
    "enumLoanToValues",
  ]);

  return {
    FinancingType: (props: typeof Choice) => {
      const CustomChoice = forwardRef((refProps, ref) => (
        <Choice
          options={FinancingType}
          {...(props as any)}
          {...refProps}
          ref={ref}
        >
          {t("identities:financeType")}
        </Choice>
      ));

      return <CustomChoice />;
    },
    FinancingValue: (props: typeof Money) => (
      <Money {...(props as any)}>{t("identities:financingValue")}</Money>
    ),
    FinancingDuration: (props: typeof Number) => (
      <Number
        unit={t("identities:monthsUnit")}
        min={36}
        max={360}
        {...(props as any)}
      >
        {t("identities:financingDuration")}
      </Number>
    ),
    RealEstate: (props: typeof Choice) => {
      const [realEstate, setRealEstate] = useState(
        (props as unknown as ComponentProps<typeof props> | undefined)
          ?.defaultValue as RealEstate | undefined
      );

      return (
        <Choice
          options={RealEstate}
          helperText={
            realEstate
              ? realEstate === RealEstate.PURCHASEPROPOSALSIGNED
                ? "1"
                : realEstate === RealEstate.NEGOTIATIONSTARTED
                ? "2"
                : realEstate === RealEstate.IDENTIFIED
                ? "3"
                : realEstate === RealEstate.PROACTIVERESEARCH
                ? "4"
                : realEstate === RealEstate.AVAILABLETOSIGNHHP
                ? "5"
                : "6"
              : " "
          }
          onChange={(data: RealEstate) => setRealEstate(data)}
          {...(props as any)}
        >
          {t("identities:realEstate")}
        </Choice>
      );
    },
    RealEstatePrice: (props: typeof Money) => {
      const { onChange, ...moneyProps } = props as unknown as ComponentProps<
        typeof Money
      >;

      const [realEstatePrice, setRealEstatePrice] = useState(
        (props as unknown as ComponentProps<typeof props> | undefined)
          ?.defaultValue as number | undefined
      );

      return (
        <Money
          helperText={
            realEstatePrice !== undefined
              ? realEstatePrice > 200000
                ? t("enumRealEstatePrices:MERIT")
                : realEstatePrice > 150000
                ? t("enumRealEstatePrices:ELEGANT")
                : realEstatePrice > 125000
                ? t("enumRealEstatePrices:BOURGEOIS")
                : realEstatePrice > 100000
                ? t("enumRealEstatePrices:ECONOMIC")
                : t("enumRealEstatePrices:POPULAR")
              : " "
          }
          onChange={(value) => {
            onChange?.(value);
            setRealEstatePrice(value !== "" ? value : undefined);
          }}
          {...(moneyProps as any)}
        >
          {t("identities:realEstatePrice")}
        </Money>
      );
    },
    Savings: (props: typeof Money) => {
      const [savings, setSavings] = useState(
        (props as unknown as ComponentProps<typeof props> | undefined)
          ?.defaultValue as number | undefined
      );

      return (
        <Money
          helperText={
            savings !== undefined
              ? savings > 40000
                ? t("enumSavings:GREAT")
                : savings > 30000
                ? t("enumSavings:GOOD")
                : savings > 20000
                ? t("enumSavings:DISCRETE")
                : savings > 10000
                ? t("enumSavings:SUFFICIENT")
                : savings > 5000
                ? t("enumSavings:BAD")
                : t("enumSavings:ZERO")
              : " "
          }
          onChange={(value) => setSavings(value !== "" ? value : undefined)}
          {...(props as any)}
        >
          {t("identities:savings")}
        </Money>
      );
    },
    LoanToValue: (props: typeof Percentage) => {
      const [loanToValue, setLoanToValue] = useState(
        (props as unknown as ComponentProps<typeof props> | undefined)
          ?.defaultValue as number | undefined
      );

      return (
        <Percentage
          min={0}
          max={200}
          helperText={
            loanToValue !== undefined
              ? loanToValue > 100
                ? t("enumLoanToValues:GREATERTHANHUNDRED")
                : loanToValue > 95
                ? t("enumLoanToValues:NINETYSIXTOHUNDRED")
                : loanToValue > 94
                ? t("enumLoanToValues:NINETYFIVE")
                : loanToValue > 80
                ? t("enumLoanToValues:EIGHTYONETONINETYFOUR")
                : loanToValue > 79
                ? t("enumLoanToValues:EIGHTY")
                : t("enumLoanToValues:UNDEREIGHTY")
              : " "
          }
          onChange={(value) => setLoanToValue(value !== "" ? value : undefined)}
          {...(props as any)}
        >
          {t("identities:loanToValue")}
        </Percentage>
      );
    },
  };
});
