import { PersistentModelConstructor } from "@aws-amplify/datastore";
import display, { getModelName } from "helpers/display";
import Geohash from "latlon-geohash";
import { Place as PlaceModel } from "models";
import { ComponentProps, FC, lazy, ReactNode } from "react";
import { cleanUserInput } from "utils/format";
import {
  toLocalizedDate,
  toLocalizedDateTime,
  toLocalizedTime,
} from "utils/localize";
import { labelFromPlace } from "utils/locate";
import { Entity } from "utils/store";
import { toDateTime, toDuration } from "utils/time";
import { isValidPhoneNumber } from "utils/validate";
import Choice from "view/inputs/Choice";
import Date from "view/inputs/Date";
import DateAndTime from "view/inputs/DateAndTime";
import Free from "view/inputs/Free";
import Mail from "view/inputs/Mail";
import MainAndOptional from "view/inputs/MainAndOptional";
import Money from "view/inputs/Money";
import Number from "view/inputs/Number";
import Percentage from "view/inputs/Percentage";
import Place from "view/inputs/Place";
import Quicklist from "view/inputs/Quicklist";
import Section from "view/inputs/Section";
import Time from "view/inputs/Time";
import TimeDuration from "view/inputs/TimeDuration";
import Toggle from "view/inputs/Toggle";
import Context from "view/outputs/Context";
import Title from "view/outputs/Title";

export type ViewableProperties<T extends object> = Exclude<
  keyof T,
  "id" | "createdAt" | "updatedAt" | "deletedAt"
>;

// TODO IT WOULD BE BETTER IF THE VALUE WOULD BE A FUNCTION WITH PROPS AS INPUT, AS TOSTORAGE AND TOVIEW COULD DEPEND ON PROPS
export type ViewableEntity<T extends Entity> = {
  [K in ViewableProperties<T>]: ViewableEntityInfo<T[K]> & {
    input: FC<any>;
    output: (dbValue: T[K], props?: any) => ReturnType<FC<any>>;
    toStorage?: (inputValue: any, newEntity: any) => Promise<any> | any;
    toView?: (dbValue: any, existingEntity: any) => any;
  };
};

type ViewableEntityInfo<T extends Entity> = {
  view: View | "custom";
  subtype?: { [K in ViewableProperties<T>]: ViewableEntityInfo<T[K]> };
};

type View =
  | "duration"
  | "exclude"
  | "section"
  | "free"
  | "id"
  | "name"
  | "mail"
  | "phone"
  | "choice"
  | "multichoice"
  | "quicklist"
  | "toggle"
  | "datetime"
  | "date"
  | "period"
  | "time"
  | "number"
  | "money"
  | "percentage"
  | "place"
  | "mainAndOptional";

type ConstructorOptions<V> = {
  view: View;
  label?: ReactNode;
  props?: Record<any, any>;
} & (V extends object | object[]
  ? {
      identifier?: keyof (V extends object[]
        ? Constructor<V[number]>
        : V extends object
        ? Constructor<V>
        : never);
      subtype?: V extends object[]
        ? Constructor<V[number]>
        : V extends object
        ? Constructor<V>
        : never;
      relationship?: PersistentModelConstructor<any>;
    }
  : {}) &
  (V extends string
    ? {
        group?: string;
      }
    : {});

type Constructor<T extends object> = {
  [K in ViewableProperties<T>]: ConstructorOptions<T[K]>;
};

const Phone = lazy(() => import("view/inputs/Phone"));

/**
 * Defines data conversions of entities, to use them for create, update and conversions.
 * @param construct Definition data
 * @returns Object that contains the definitions
 */
export default function define<T extends Entity>(
  construct: () => Constructor<T>,
  groupOptions?: { group: string; props?: Record<any, any> }[]
): () => ViewableEntity<T> {
  return () => {
    const viewableEntity = {} as ViewableEntity<T>;

    const constructor = construct();

    for (const key in constructor) {
      const {
        view,
        label: children,
        props: defProps,
        group,
        identifier,
        ...compose
      } = constructor[key] as ConstructorOptions<object[] & string>;

      if (view !== "exclude") {
        switch (view) {
          case "toggle":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "toggle",
              input: (props) => (
                <Toggle {...defProps} {...props}>
                  {children}
                </Toggle>
              ),
              output: (dbValue, props) => (
                <Title
                  field
                  subtitle={dbValue ? "yes" : "no"}
                  resolver="view"
                  {...props}
                >
                  {children}
                </Title>
              ),
              toView: (dbValue) => !!dbValue,
            };
            break;

          case "number":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "number",
              input: (props) => (
                <Number
                  min={defProps?.normalized ? 0 : undefined}
                  max={defProps?.normalized ? 100 : undefined}
                  {...defProps}
                  {...props}
                >
                  {children}
                </Number>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={dbValue} {...props}>
                  {children}
                </Title>
              ),
              toView: (dbValue) =>
                defProps?.normalized ? Math.round(dbValue * 100) : dbValue,
              toStorage: (inputValue) =>
                defProps?.normalized ? inputValue / 100 : inputValue,
            };
            break;

          case "percentage":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "percentage",
              input: (props) => (
                <Percentage {...defProps} {...props}>
                  {children}
                </Percentage>
              ),
              output: (dbValue, props) => (
                <Title field unit="percentage" subtitle={dbValue} {...props}>
                  {children}
                </Title>
              ),
            };
            break;

          case "money":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "money",
              input: (props) => (
                <Money {...defProps} {...props}>
                  {children}
                </Money>
              ),
              output: (dbValue, props) => (
                <Title field unit="euro" subtitle={dbValue} {...props}>
                  {children}
                </Title>
              ),
            };
            break;

          case "place":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "place",
              input: (props) => (
                <Place {...defProps} {...props}>
                  {children}
                </Place>
              ),
              output: (dbValue, props) => {
                const typedValue = dbValue as PlaceModel | null;

                const coordinates =
                  typedValue?.geohash && Geohash.decode(typedValue.geohash);

                return (
                  <Title
                    field
                    subtitle={labelFromPlace(typedValue)}
                    typographyProps={{
                      subtitle: {
                        toMap: coordinates
                          ? [coordinates.lon, coordinates.lat]
                          : undefined,
                      },
                    }}
                    {...props}
                  >
                    {children}
                  </Title>
                );
              },
            };
            break;

          case "mail":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "mail",
              input: (props) => (
                <Mail {...defProps} {...props}>
                  {children}
                </Mail>
              ),
              output: (dbValue, props) => (
                <Title
                  field
                  subtitle={dbValue}
                  typographyProps={{ subtitle: { mailTo: dbValue } }}
                  {...props}
                >
                  {children}
                </Title>
              ),
            };
            break;

          case "phone":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "phone",
              input: (props) => (
                <Phone {...defProps} {...props}>
                  {children}
                </Phone>
              ),
              output: (dbValue, props) => (
                <Title
                  field
                  subtitle={dbValue}
                  typographyProps={{ subtitle: { phone: dbValue } }}
                  {...props}
                >
                  {children}
                </Title>
              ),
              toStorage: async (inputValue) =>
                (await isValidPhoneNumber(inputValue)) ? inputValue : null,
            };
            break;

          case "datetime":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "datetime",
              input: (props) => (
                <DateAndTime {...defProps} {...props}>
                  {children}
                </DateAndTime>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={toLocalizedDateTime(dbValue)} {...props}>
                  {children}
                </Title>
              ),
            };
            break;

          case "date":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "date",
              input: (props) => (
                <Date {...defProps} {...props}>
                  {children}
                </Date>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={toLocalizedDate(dbValue)} {...props}>
                  {children}
                </Title>
              ),
            };
            break;

          case "time":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "time",
              input: (props) => (
                <Time {...defProps} {...props}>
                  {children}
                </Time>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={toLocalizedTime(dbValue)} {...props}>
                  {children}
                </Title>
              ),
              toStorage: (inputValue) => toDateTime(inputValue)?.toISOTime(),
              toView: (dbValue) => toDateTime(dbValue)?.toISO(),
            };
            break;

          case "id":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "id",
              input: (props) => (
                <Free {...defProps} {...props}>
                  {children}
                </Free>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={dbValue} {...props}>
                  {children}
                </Title>
              ),
              toStorage: (inputValue) =>
                inputValue && cleanUserInput(inputValue, "none"),
              toView: (dbValue) => dbValue ?? "",
            };
            break;

          case "name":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "name",
              input: (props) => (
                <Free {...defProps} {...props}>
                  {children}
                </Free>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={dbValue} {...props}>
                  {children}
                </Title>
              ),
              toStorage: (inputValue) =>
                inputValue && cleanUserInput(inputValue, "titleCase"),
              toView: (dbValue) => dbValue ?? "",
            };
            break;

          case "free":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "free",
              input: (props) => (
                <Free {...defProps} {...props}>
                  {children}
                </Free>
              ),
              output: (dbValue, props) => (
                <Title field subtitle={dbValue} {...props}>
                  {children}
                </Title>
              ),
              toStorage: (inputValue) =>
                inputValue && cleanUserInput(inputValue),
              toView: (dbValue) => dbValue ?? "",
            };
            break;

          case "duration":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "duration",
              input: (props) => (
                <TimeDuration {...defProps} {...props}>
                  {children}
                </TimeDuration>
              ),
              output: (dbValue, props) => (
                <Title
                  field
                  unit={defProps?.unit}
                  subtitle={dbValue}
                  {...props}
                >
                  {children}
                </Title>
              ),
              toStorage: (inputValue) =>
                inputValue
                  ? defProps?.unit && defProps.unit !== "time"
                    ? toDuration({
                        [defProps.unit]: inputValue,
                      })?.toISO()
                    : inputValue
                  : null,
              toView: (dbValue) =>
                dbValue
                  ? typeof dbValue === "string" &&
                    defProps?.unit &&
                    defProps.unit !== "time"
                    ? toDuration(dbValue)?.as(defProps.unit)
                    : dbValue
                  : null,
            };
            break;

          case "choice":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "choice",
              input: (props) => (
                <Choice asField {...defProps} {...props}>
                  {children}
                </Choice>
              ),
              output: (dbValue, props) => (
                <Title
                  field
                  subtitle={dbValue}
                  resolver={defProps?.options}
                  getLabel={defProps?.getLabel}
                  identifier={defProps?.identifier}
                  {...props}
                >
                  {children}
                </Title>
              ),
              toStorage: (inputValue) => inputValue ?? null,
            };
            break;

          case "multichoice":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "multichoice",
              input: (props) => (
                <Choice multiple children={children} {...defProps} {...props} />
              ),
              output: (dbValue, props) => (
                <Title
                  field
                  subtitle={dbValue}
                  resolver={defProps?.options}
                  getLabel={defProps?.getLabel}
                  children={children}
                  {...props}
                />
              ),
              toView: (dbValue) => dbValue ?? [],
            };
            break;

          case "section":
            {
              const resolvedViewableEntity = compose.relationship
                ? (display[
                    getModelName(compose.relationship)
                  ]() as unknown as ViewableEntity<Entity>)
                : define(() => compose.subtype as Constructor<Entity>)();

              const subtype = {} as NonNullable<
                ViewableEntityInfo<any>["subtype"]
              >;

              for (const id in resolvedViewableEntity) {
                subtype[id] = {
                  view: resolvedViewableEntity[id].view,
                  subtype: resolvedViewableEntity[id].subtype,
                };
              }

              viewableEntity[key as keyof typeof viewableEntity] = {
                view: "section",
                subtype: subtype as any,
                input: (props) => (
                  <Section
                    Components={({ values, inputProps }) =>
                      Object.keys(resolvedViewableEntity).map((id) =>
                        resolvedViewableEntity[id].input(
                          props && {
                            defaultValue: values[id],
                            id: `${props.id}.${id}`,
                            form: props.form,
                            ...inputProps,
                            ...props[id],
                          }
                        )
                      )
                    }
                    {...defProps}
                    {...props}
                  >
                    {children}
                  </Section>
                ),
                output: (dbValue, props) => (
                  <Context
                    values={
                      dbValue
                        ? Object.keys(dbValue).map((id) =>
                            resolvedViewableEntity?.[id].output(
                              dbValue?.[id],
                              props?.[id]
                            )
                          )
                        : null
                    }
                    children={children}
                    {...props}
                  />
                ),
                toStorage: async (inputValue) => {
                  const formattedValue = {} as typeof inputValue;

                  for (const id in inputValue) {
                    const resolvedValue =
                      (await resolvedViewableEntity[id].toStorage?.(
                        inputValue[id],
                        formattedValue
                      )) ?? inputValue[id];

                    if (resolvedValue || resolvedValue === 0)
                      formattedValue[id] = resolvedValue;
                  }

                  return Object.keys(formattedValue).length
                    ? formattedValue
                    : null;
                },
                toView: (dbValue) => {
                  const formattedValue = {} as typeof dbValue;

                  for (const id in dbValue) {
                    formattedValue[id] =
                      resolvedViewableEntity[id].toView?.(
                        dbValue[id],
                        dbValue
                      ) ?? dbValue[id];
                  }

                  return formattedValue;
                },
              };
            }
            break;

          case "mainAndOptional":
            {
              const resolvedViewableEntity = compose.relationship
                ? (display[
                    getModelName(compose.relationship)
                  ]() as unknown as ViewableEntity<Entity>)
                : define(() => compose.subtype as Constructor<Entity>)();

              const subtype = {} as NonNullable<
                ViewableEntityInfo<any>["subtype"]
              >;

              for (const id in resolvedViewableEntity) {
                subtype[id] = {
                  view: resolvedViewableEntity[id].view,
                  subtype: resolvedViewableEntity[id].subtype,
                };
              }

              const properties = Object.keys(resolvedViewableEntity);

              const mainEntity = defProps && properties[defProps.main];

              viewableEntity[key as keyof typeof viewableEntity] = {
                view: "mainAndOptional",
                subtype: subtype as any,
                input: (props) => (
                  <MainAndOptional
                    showOptionalButtonText={children}
                    components={properties.map((id) =>
                      resolvedViewableEntity[id].input(
                        props && {
                          id: `${props.id}.${id}`,
                          form: props.form,
                          children: id === mainEntity ? children : undefined,
                          ...props[id],
                        }
                      )
                    )}
                    {...defProps}
                    {...props}
                  />
                ),
                output: (dbValue, props) =>
                  mainEntity
                    ? resolvedViewableEntity[mainEntity].output(
                        (dbValue as any[]).map((value) => value[mainEntity]),
                        { children, ...props }
                      )
                    : null,
                toStorage: (inputValue) =>
                  (mainEntity &&
                    (inputValue[mainEntity] as any[])?.map((main) => ({
                      [mainEntity]: main,
                    }))) ??
                  inputValue,
              };
            }
            break;

          case "quicklist":
            {
              const resolvedViewableEntity = compose.relationship
                ? (display[
                    getModelName(compose.relationship)
                  ]() as unknown as ViewableEntity<Entity>)
                : define(() => compose.subtype as Constructor<Entity>)();

              const subtype = {} as NonNullable<
                ViewableEntityInfo<any>["subtype"]
              >;

              for (const id in resolvedViewableEntity) {
                subtype[id] = {
                  view: resolvedViewableEntity[id].view,
                  subtype: resolvedViewableEntity[id].subtype,
                };
              }

              viewableEntity[key as keyof typeof viewableEntity] = {
                view: "quicklist",
                subtype: subtype as any,
                input: (props) => (
                  <Quicklist
                    Edit={({ form, props: editProps }) => {
                      let elements = [] as ReactNode[];

                      for (const id in resolvedViewableEntity) {
                        elements.push(
                          resolvedViewableEntity[id].input({
                            id,
                            form,
                            ...editProps?.[id],
                            defaultValue:
                              resolvedViewableEntity[id].toView?.(
                                editProps?.defaultValue?.[id],
                                editProps?.defaultValue
                              ) ?? editProps?.defaultValue?.[id],
                          })
                        );
                      }

                      return elements;
                    }}
                    View={({ data, props: viewProps }) => {
                      let elements = [] as ReactNode[];

                      for (const id in resolvedViewableEntity) {
                        elements.push(
                          resolvedViewableEntity[id].output(
                            resolvedViewableEntity[id].toView
                              ? resolvedViewableEntity[id].toView!(
                                  data[id],
                                  data
                                )
                              : data[id],
                            viewProps?.[id]
                          )
                        );
                      }

                      return elements;
                    }}
                    onSubmit={async (data, complete) => {
                      for (const id in resolvedViewableEntity) {
                        if (resolvedViewableEntity[id].toStorage) {
                          data[id] = await resolvedViewableEntity[id]
                            .toStorage!(data[id], data);
                        }
                      }

                      if (props.onSubmit) props.onSubmit(data, complete);
                      else complete(data);
                    }}
                    {...defProps}
                    {...props}
                  >
                    {children}
                  </Quicklist>
                ),
                output: (dbValue, props) => {
                  const elements: ReactNode[] =
                    (dbValue as Record<any, any>[] | undefined)?.map(
                      (item, index) => {
                        const currentIdentifier =
                          identifier?.toString() ?? Object.keys(item)[0];

                        return resolvedViewableEntity[currentIdentifier].output(
                          resolvedViewableEntity[currentIdentifier].toView
                            ? resolvedViewableEntity[currentIdentifier].toView!(
                                item[currentIdentifier],
                                item
                              )
                            : item[currentIdentifier],
                          {
                            ...props?.[currentIdentifier],
                            noTitle: true,
                            noMargin: true,
                            key: index,
                          } as ComponentProps<typeof Title>
                        );
                      }
                    ) ?? [];

                  return (
                    <Title field subtitle={elements} {...props}>
                      {children}
                    </Title>
                  );
                },
                toStorage: async (inputValue) =>
                  inputValue
                    ? Promise.all(
                        inputValue?.map(async (value: Record<any, any>) => {
                          const formattedValue = {} as typeof value;

                          for (const id in value) {
                            formattedValue[id] = resolvedViewableEntity[id]
                              .toStorage
                              ? await resolvedViewableEntity[id].toStorage?.(
                                  value[id],
                                  formattedValue
                                )
                              : value[id];
                          }

                          return formattedValue;
                        })
                      )
                    : [],
                toView: (dbValue) =>
                  dbValue?.length
                    ? dbValue.map((value: Record<any, any>) => {
                        const formattedValue = {} as typeof value;

                        for (const id in value) {
                          formattedValue[id] =
                            resolvedViewableEntity[id]?.toView?.(
                              value[id],
                              value
                            ) ?? value[id];
                        }

                        return formattedValue;
                      })
                    : null,
              };
            }
            break;

          case "period":
            viewableEntity[key as keyof typeof viewableEntity] = {
              view: "period",
              input: () => <></>,
              output: () => <></>,
            };
            break;
        }
      }
    }

    return viewableEntity;
  };
}
