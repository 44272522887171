export const schema = {
    "models": {
        "Task": {
            "name": "Task",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "assigner": {
                    "name": "assigner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "assignee": {
                    "name": "assignee",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "startDateTime": {
                    "name": "startDateTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "endDateTime": {
                    "name": "endDateTime",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "travelDuration": {
                    "name": "travelDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "returnDuration": {
                    "name": "returnDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "modality": {
                    "name": "modality",
                    "isArray": false,
                    "type": {
                        "enum": "ModalityName"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "location": {
                    "name": "location",
                    "isArray": false,
                    "type": {
                        "enum": "LocationName"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "moved": {
                    "name": "moved",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "placeInfo": {
                    "name": "placeInfo",
                    "isArray": false,
                    "type": {
                        "nonModel": "Place"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "review": {
                    "name": "review",
                    "isArray": false,
                    "type": {
                        "enum": "Review"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "outcome": {
                    "name": "outcome",
                    "isArray": false,
                    "type": {
                        "enum": "OutcomeName"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "outcomeMotivation": {
                    "name": "outcomeMotivation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "suggestedOutcomeMotivation": {
                    "name": "suggestedOutcomeMotivation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "activityTasksId": {
                    "name": "activityTasksId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "identityTasksId": {
                    "name": "identityTasksId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "children": {
                    "name": "children",
                    "isArray": true,
                    "type": {
                        "model": "Task"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "taskChildrenId"
                    }
                },
                "audits": {
                    "name": "audits",
                    "isArray": true,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "taskAuditsId"
                    }
                },
                "taskChildrenId": {
                    "name": "taskChildrenId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Tasks",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDeleted",
                        "fields": [
                            "deletedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAssignee",
                        "fields": [
                            "assignee"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOutcome",
                        "fields": [
                            "outcome"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOutcomeMotivation",
                        "fields": [
                            "outcomeMotivation"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "assigner",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "assignee",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Management"
                                ],
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Audit": {
            "name": "Audit",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "modelAuditsId": {
                    "name": "modelAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "user": {
                    "name": "user",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "modelType": {
                    "name": "modelType",
                    "isArray": false,
                    "type": {
                        "enum": "ModelType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "action": {
                    "name": "action",
                    "isArray": false,
                    "type": {
                        "enum": "MutationAction"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "mutation": {
                    "name": "mutation",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "motivation": {
                    "name": "motivation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "detailedMotivation": {
                    "name": "detailedMotivation",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "notifications": {
                    "name": "notifications",
                    "isArray": true,
                    "type": {
                        "model": "Notification"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "auditNotificationsId"
                    }
                },
                "taskAuditsId": {
                    "name": "taskAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "identityAuditsId": {
                    "name": "identityAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "activityAuditsId": {
                    "name": "activityAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userAuditsId": {
                    "name": "userAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentAuditsId": {
                    "name": "paymentAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "configurationAuditsId": {
                    "name": "configurationAuditsId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Audits",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byModel",
                        "fields": [
                            "modelAuditsId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byModelType",
                        "fields": [
                            "modelType"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAction",
                        "fields": [
                            "action"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "user",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Notification": {
            "name": "Notification",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "reader": {
                    "name": "reader",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": {
                        "enum": "NotificationState"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "audit": {
                    "name": "audit",
                    "isArray": false,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "auditNotificationsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Notifications",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byState",
                        "fields": [
                            "state"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "userPools",
                                "ownerField": "reader",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "create",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Identity": {
            "name": "Identity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "surname": {
                    "name": "surname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "nationality": {
                    "name": "nationality",
                    "isArray": false,
                    "type": {
                        "enum": "Nationality"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "source": {
                    "name": "source",
                    "isArray": false,
                    "type": {
                        "enum": "Source"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "buyerPersona": {
                    "name": "buyerPersona",
                    "isArray": false,
                    "type": {
                        "nonModel": "BuyerPersona"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "placeInfo": {
                    "name": "placeInfo",
                    "isArray": false,
                    "type": {
                        "nonModel": "Place"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "placeType": {
                    "name": "placeType",
                    "isArray": false,
                    "type": {
                        "enum": "PlaceType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "financingType": {
                    "name": "financingType",
                    "isArray": false,
                    "type": {
                        "enum": "FinancingType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "financingDuration": {
                    "name": "financingDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "financing": {
                    "name": "financing",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "callCenterId": {
                    "name": "callCenterId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "gender": {
                    "name": "gender",
                    "isArray": false,
                    "type": {
                        "enum": "Gender"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "mobilePhone": {
                    "name": "mobilePhone",
                    "isArray": false,
                    "type": "AWSPhone",
                    "isRequired": false,
                    "attributes": []
                },
                "houseHunter": {
                    "name": "houseHunter",
                    "isArray": false,
                    "type": {
                        "enum": "HouseHunter"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "path": {
                    "name": "path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "price": {
                    "name": "price",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "toPay": {
                    "name": "toPay",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "tasks": {
                    "name": "tasks",
                    "isArray": true,
                    "type": {
                        "model": "Task"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "identityTasksId"
                    }
                },
                "audits": {
                    "name": "audits",
                    "isArray": true,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "identityAuditsId"
                    }
                },
                "paymentIdentitiesId": {
                    "name": "paymentIdentitiesId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Identities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDeleted",
                        "fields": [
                            "deletedAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Management",
                                    "CallCenter",
                                    "Marketing"
                                ],
                                "operations": [
                                    "create"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "update",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Activity": {
            "name": "Activity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "codename": {
                    "name": "codename",
                    "isArray": false,
                    "type": {
                        "enum": "ActivityCodename"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "expectedDuration": {
                    "name": "expectedDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "modalities": {
                    "name": "modalities",
                    "isArray": true,
                    "type": {
                        "nonModel": "Modality"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "outcomes": {
                    "name": "outcomes",
                    "isArray": true,
                    "type": {
                        "nonModel": "Outcome"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "role": {
                    "name": "role",
                    "isArray": false,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "phase": {
                    "name": "phase",
                    "isArray": false,
                    "type": {
                        "enum": "ActivityPhase"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "favoriteStartTime": {
                    "name": "favoriteStartTime",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": false,
                    "attributes": []
                },
                "favoriteEndTime": {
                    "name": "favoriteEndTime",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": false,
                    "attributes": []
                },
                "minDurationBeforeExecution": {
                    "name": "minDurationBeforeExecution",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "responsibilityChangeDuration": {
                    "name": "responsibilityChangeDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "marginDuration": {
                    "name": "marginDuration",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "manualScheduling": {
                    "name": "manualScheduling",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "children": {
                    "name": "children",
                    "isArray": true,
                    "type": {
                        "nonModel": "ActivityChild"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "tasks": {
                    "name": "tasks",
                    "isArray": true,
                    "type": {
                        "model": "Task"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "activityTasksId"
                    }
                },
                "audits": {
                    "name": "audits",
                    "isArray": true,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "activityAuditsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Activities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDeleted",
                        "fields": [
                            "deletedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCodename",
                        "fields": [
                            "codename"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Management"
                                ],
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "username": {
                    "name": "username",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "surname": {
                    "name": "surname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": true,
                    "attributes": []
                },
                "roles": {
                    "name": "roles",
                    "isArray": true,
                    "type": {
                        "enum": "UserRole"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "preferredBuyerPersona": {
                    "name": "preferredBuyerPersona",
                    "isArray": false,
                    "type": {
                        "nonModel": "BuyerPersona"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "workTimeStart": {
                    "name": "workTimeStart",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": true,
                    "attributes": []
                },
                "workTimeEnd": {
                    "name": "workTimeEnd",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": true,
                    "attributes": []
                },
                "lunchBreakStart": {
                    "name": "lunchBreakStart",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": true,
                    "attributes": []
                },
                "lunchBreakEnd": {
                    "name": "lunchBreakEnd",
                    "isArray": false,
                    "type": "AWSTime",
                    "isRequired": true,
                    "attributes": []
                },
                "lastWorkDay": {
                    "name": "lastWorkDay",
                    "isArray": false,
                    "type": {
                        "enum": "LastWorkDay"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "headquarters": {
                    "name": "headquarters",
                    "isArray": false,
                    "type": {
                        "nonModel": "Place"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "audits": {
                    "name": "audits",
                    "isArray": true,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "userAuditsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDeleted",
                        "fields": [
                            "deletedAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUsername",
                        "fields": [
                            "username"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Management",
                                    "HumanResources"
                                ],
                                "operations": [
                                    "create",
                                    "update"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Payment": {
            "name": "Payment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "deletedAt": {
                    "name": "deletedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": {
                        "enum": "PaymentState"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "supplier": {
                    "name": "supplier",
                    "isArray": false,
                    "type": {
                        "enum": "Supplier"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "service": {
                    "name": "service",
                    "isArray": false,
                    "type": {
                        "enum": "PaymentService"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "billNumber": {
                    "name": "billNumber",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "billDate": {
                    "name": "billDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "reason": {
                    "name": "reason",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dueDate": {
                    "name": "dueDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "PaymentType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "bank": {
                    "name": "bank",
                    "isArray": false,
                    "type": {
                        "enum": "Bank"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "identities": {
                    "name": "identities",
                    "isArray": true,
                    "type": {
                        "model": "Identity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "paymentIdentitiesId"
                    }
                },
                "audits": {
                    "name": "audits",
                    "isArray": true,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "paymentAuditsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Payments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byDeleted",
                        "fields": [
                            "deletedAt"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Management"
                                ],
                                "operations": [
                                    "create",
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Accounting"
                                ],
                                "operations": [
                                    "read",
                                    "update"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Configuration": {
            "name": "Configuration",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "scheduler": {
                    "name": "scheduler",
                    "isArray": false,
                    "type": {
                        "nonModel": "Scheduler"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "audits": {
                    "name": "audits",
                    "isArray": true,
                    "type": {
                        "model": "Audit"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "configurationAuditsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Configurations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Management"
                                ],
                                "operations": [
                                    "update"
                                ]
                            },
                            {
                                "allow": "private",
                                "operations": [
                                    "read",
                                    "delete"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "UserRole": {
            "name": "UserRole",
            "values": [
                "ADMIN",
                "MANAGEMENT",
                "CREDITBROKER",
                "CALLCENTER",
                "HOUSEHUNTER",
                "ACCOUNTING",
                "AGREEMENTS",
                "REALESTATE",
                "BANKREALESTATE",
                "MARKETING",
                "COMMUNICATION",
                "CONTRACTSANDPOLICIES",
                "AUDIT",
                "APPRAISAL",
                "FILE",
                "CREDIT",
                "INCOME",
                "BUSINESSINTELLIGENCE",
                "HUMANRESOURCES",
                "LEGAL",
                "FUND",
                "ARCHIVE",
                "CUSTOMERSERVICE",
                "PHONESELLER",
                "DOCUMENTREQUESTER"
            ]
        },
        "ModelType": {
            "name": "ModelType",
            "values": [
                "TASK",
                "IDENTITY",
                "USER",
                "ACTIVITY",
                "NOTIFICATION",
                "PAYMENT",
                "CONFIGURATION"
            ]
        },
        "ModalityName": {
            "name": "ModalityName",
            "values": [
                "PHYSICAL",
                "VOCAL",
                "VIDEO",
                "MAIL"
            ]
        },
        "LocationName": {
            "name": "LocationName",
            "values": [
                "HEADQUARTERS",
                "BANKBRANCH",
                "IDENTITYHOME",
                "IDENTITYDOMICILE",
                "IDENTITYWORKPLACE",
                "VENDORHOME",
                "VENDORWORKPLACE",
                "PLACEOFBUSINESS",
                "ESTATEAGENCY",
                "NOTARYOFFICE",
                "PROFESSIONALSTUDIO",
                "PROPERTYUNDERWARRANT",
                "RELATIVESHOME"
            ]
        },
        "Review": {
            "name": "Review",
            "values": [
                "NOREVIEW",
                "GOOGLE",
                "FACEBOOK"
            ]
        },
        "OutcomeName": {
            "name": "OutcomeName",
            "values": [
                "POSITIVE",
                "WEAK",
                "NEGATIVE",
                "NOTEXECUTED"
            ]
        },
        "MutationAction": {
            "name": "MutationAction",
            "values": [
                "CREATE",
                "UPDATE",
                "DELETE"
            ]
        },
        "NotificationState": {
            "name": "NotificationState",
            "values": [
                "QUEUED",
                "VIEWED",
                "READ"
            ]
        },
        "Nationality": {
            "name": "Nationality",
            "values": [
                "AFGHAN",
                "ALBANIAN",
                "ALGERIAN",
                "AMERICAN",
                "ANDORRAN",
                "ANGOLAN",
                "ANTIGUANS",
                "ARGENTINEAN",
                "ARMENIAN",
                "ARUBAN",
                "AUSTRALIAN",
                "AUSTRIAN",
                "AZERBAIJANI",
                "BAHAMIAN",
                "BAHRAINI",
                "BANGLADESHI",
                "BARBADIAN",
                "BARBUDANS",
                "BASQUE",
                "BELARUSIAN",
                "BELGIAN",
                "BELIZEAN",
                "BENINESE",
                "BERMUDIAN",
                "BHUTANESE",
                "BIRMANIAN",
                "BOLIVIAN",
                "BOSNIAN",
                "BOTSWANA",
                "BRAZILIAN",
                "BRITISH",
                "BRUNEIAN",
                "BULGARIAN",
                "BURKINABE",
                "BURUNDIAN",
                "CAMBODIAN",
                "CAMEROONIAN",
                "CANADESE",
                "CAPEVERDEAN",
                "CENTRALAFRICAN",
                "CHADIAN",
                "CHILEAN",
                "CHINESE",
                "CINGALESE",
                "COLOMBIAN",
                "COMORAN",
                "CONGOLESE",
                "COSTARICAN",
                "CROATIAN",
                "CUBAN",
                "CYPRIOT",
                "CZECH",
                "DANISH",
                "DOMINICAN",
                "EASTTIMORESE",
                "ECUADOREAN",
                "EGYPTIAN",
                "EMIRIAN",
                "EQUATOGUINEAN",
                "ERITREAN",
                "ESTONIAN",
                "ETHIOPIAN",
                "FALKLANDISLANDER",
                "FAROESE",
                "FIJIAN",
                "FILIPINO",
                "FINNISH",
                "FRENCH",
                "GABONESE",
                "GAMBIAN",
                "GEORGIAN",
                "GERMAN",
                "GHANAIAN",
                "GIBRALTARIAN",
                "GIBUTIANI",
                "GREEK",
                "GRENADIAN",
                "GUATEMALAN",
                "GUINEABISSAU",
                "GUINEAN",
                "GUYANESE",
                "HAITIAN",
                "HONDURAN",
                "HONGKONGERS",
                "HUNGARIAN",
                "ICELANDER",
                "KIRIBATI",
                "INDIAN",
                "INDONESIAN",
                "IRANIAN",
                "IRAQI",
                "IRISH",
                "ISRAELI",
                "ITALIAN",
                "IVORIAN",
                "JAMAICAN",
                "JAPANESE",
                "JORDANIAN",
                "KAZAKHSTANI",
                "KENYAN",
                "KOSOVARIAN",
                "KUWAITI",
                "KYRGYZ",
                "LAOTIAN",
                "LATVIAN",
                "LEBANESE",
                "LIBERIAN",
                "LIBYAN",
                "LIECHTENSTEINER",
                "LITHUANIAN",
                "LOTHIAN",
                "LUXEMBOURGER",
                "MACAO",
                "MACEDONIAN",
                "MALAGASY",
                "MALAWIAN",
                "MALAYSIAN",
                "MALDIVAN",
                "MALIAN",
                "MALTESE",
                "MANX",
                "MARSHALLESE",
                "MAURITANIAN",
                "MAURITIAN",
                "MEXICAN",
                "MICRONESIAN",
                "MOLDOVAN",
                "MONACAN",
                "MONGOLIAN",
                "MONTENEGRINE",
                "MOROCCAN",
                "MOZAMBICAN",
                "NAMIBIAN",
                "NAURUAN",
                "NEPALESE",
                "NETHERLANDER",
                "NEWZEALANDER",
                "NICARAGUAN",
                "NIGERIAN",
                "NIGERIEN",
                "NORTHKOREAN",
                "NORVEGIAN",
                "OMANI",
                "PAKISTANI",
                "PALAUAN",
                "PALESTINESE",
                "PANAMANIAN",
                "PAPUANEWGUINEAN",
                "PARAGUAYAN",
                "PERUVIAN",
                "POLISH",
                "PORTUGUESE",
                "PUERTORICAN",
                "QATARI",
                "QUEBECER",
                "REUNION",
                "ROMANIAN",
                "RUSSIAN",
                "RWANDAN",
                "SAINTKITTS",
                "SAINTLUCIAN",
                "SALVADOREAN",
                "SAMOAN",
                "SANMARINESE",
                "SAOTOME",
                "SAUDI",
                "SENEGALESE",
                "SERBIAN",
                "SEYCHELLES",
                "SIERRALEONESE",
                "SINGAPOREAN",
                "SLOVAKIAN",
                "SLOVENIAN",
                "SOLOMONISLANDER",
                "SOMALI",
                "SOMALILANDER",
                "SOUTHAFRICAN",
                "SOUTHKOREAN",
                "SPANISH",
                "SRILANKAN",
                "SUDANESE",
                "SURINAMESE",
                "SWAZI",
                "SWEDISH",
                "SWISS",
                "SYRIAN",
                "TAIWANESE",
                "TAJIK",
                "TANZANIAN",
                "THAI",
                "TOBAGONIAN",
                "TOGOLESE",
                "TONGAN",
                "TRINIDADIAN",
                "TUNISIAN",
                "TURKISH",
                "TURKMENIAN",
                "TUVALUAN",
                "UGANDAN",
                "UKRAINIAN",
                "URUGUAYAN",
                "UZBEKISTANI",
                "VANAUTANI",
                "VATICANESE",
                "VENEZUELAN",
                "VIETNAMESE",
                "VINCENTIAN",
                "VIRGINISLANDER",
                "YEMENITE",
                "ZAMBIAN",
                "ZIMBABWEAN"
            ]
        },
        "Source": {
            "name": "Source",
            "values": [
                "MUTUOPOSITIVO",
                "BEREFERRAL",
                "LINKSOLUZIONI",
                "FACILEIT",
                "MPREFERRAL",
                "FCREFERRAL",
                "AGREFERRAL",
                "DIREFERRAL",
                "OFFICEREFERRAL",
                "REALESTATEAGENCY",
                "MANAGEMENT",
                "CREDITBROKER",
                "REFERRAL",
                "FACEBOOKCHATBOT"
            ]
        },
        "RealEstate": {
            "name": "RealEstate",
            "values": [
                "PURCHASEPROPOSALSIGNED",
                "NEGOTIATIONSTARTED",
                "IDENTIFIED",
                "PROACTIVERESEARCH",
                "AVAILABLETOSIGNHHP",
                "SELLINGOWNHOUSE",
                "OWNREALESTATE"
            ]
        },
        "PlaceType": {
            "name": "PlaceType",
            "values": [
                "RESIDENCE",
                "DOMICILE"
            ]
        },
        "FinancingType": {
            "name": "FinancingType",
            "values": [
                "FIRSTHOUSEPURCHASEMORTGAGE",
                "FIRSTHOUSEPURCHASEMORTGAGEAUCTION",
                "SECONDHOUSEPURCHASEMORTGAGE",
                "SURROGATEMORTGAGE",
                "SHOPPURCHASEMORTGAGE",
                "CONSOLIDATEDMORTGAGE",
                "SUBSTITUTIONMORTGAGE",
                "LIQUIDITYMORTGAGE",
                "CONSOLIDATEDMORTGAGELIQUIDITY",
                "SUBSTITUTIONMORTGAGELIQUIDITY",
                "SALCONSTRUCTIONMORTGAGE",
                "ONETRANCHECONSTRUCTIONMORTGAGE",
                "SALRENOVATIONMORTGAGE",
                "ONETRANCHERENOVATIONMORTGAGE",
                "PURCHASEMORTGAGESALRENOVATION",
                "PURCHASEMORTGAGEONETRANCHERENOVATION",
                "PERSONALLOAN",
                "FINALIZEDLOAN",
                "CONSOLIDATEDLOAN",
                "SUBSTITUTIONLOAN",
                "CONSOLIDATEDLOANLIQUIDITY",
                "SUBSTITUTIONLOANLIQUIDITY",
                "SALARYBACKEDLOAN",
                "SALARYBACKEDLOANDELEGATION"
            ]
        },
        "Gender": {
            "name": "Gender",
            "values": [
                "FEMALE",
                "MALE",
                "NONBINARY"
            ]
        },
        "HouseHunter": {
            "name": "HouseHunter",
            "values": [
                "INTERNAL",
                "PREMIERCASA",
                "REALESTATEAGENCY"
            ]
        },
        "ActivityCodename": {
            "name": "ActivityCodename",
            "values": [
                "CONVERSIONTOCUSTOMER",
                "SCHEDULEMEETING",
                "CONFIRMMEETING",
                "MEETING",
                "APPROVEQUOTE",
                "DELIVERPROPOSAL",
                "DELIVERCONVERSIONTOCUSTOMER",
                "CENSUS",
                "APPRAISAL",
                "DELIVERFUND",
                "RECEIVEDELIBERATION",
                "DEED",
                "APPROVECUSTOMER",
                "DELIVERDOSSIER",
                "RECEIVEDOCUMENTSMEETING",
                "PDFONEA",
                "CRIFCR",
                "QUOTE",
                "PDFONEB",
                "ISSUECONTRACTS",
                "PDFTWOA",
                "BANKREPORT",
                "NEGOTIATION",
                "PROPOSALWITHDRAWAL",
                "DELIVERBANKREPORT",
                "RECEIVEBANKREPORT",
                "DOSSIER",
                "SCHEDULECENSUS",
                "RECEIVEFINANCINGOUTCOME",
                "RECEIVEAPPRAISAL",
                "REQUESTFUND",
                "RECEIVEFUND",
                "PLANDEED",
                "SCHEDULEDEED",
                "VERIFICATIONS",
                "PROPOSALSIGNATURE",
                "REQUESTDOCUMENTS"
            ]
        },
        "OutcomeMotivationCodename": {
            "name": "OutcomeMotivationCodename",
            "values": [
                "WRONGPERSONALMANAGEMENT",
                "LACKOFEXPERTISE",
                "LACKOFREPLYFROMCOLLEAGUE",
                "LACKOFREPLYFROMEXTERNAL",
                "LACKOFOUTCOME",
                "CANCELLED",
                "OTHER",
                "QUOTESCHEDULED",
                "HOUSEHUNTERSCHEDULED",
                "DDDQUOTESCHEDULED",
                "OKIFPROPOSALSIGNATURE",
                "PHYSICALMEETINGSCHEDULED",
                "CRIFCRSTART",
                "QUOTESTART",
                "HOUSEHUNTERSTART",
                "DEFINEREALESTATE",
                "SCHEDULEPHYSICALMEETING",
                "SCHEDULEVIRTUALMEETING",
                "CRIF",
                "CR",
                "INJURIOUS",
                "CIG",
                "OVERINDEBTED",
                "LACKOFINCOMES",
                "DEPENDENTS",
                "LACKOFWARRANTIES",
                "FARFROMDECISIONTOPURCHASE",
                "WILLLETUSKNOW",
                "RESOURCEDIDNOTUNDERSTANDBUYERPERSONA",
                "RESOURCEWASUNABLEFORVIRTUALMEETING",
                "RESOURCEWASUNABLE",
                "POSTPONESPURCHASE",
                "RENTTOBUY",
                "MORTGAGEWITHOWNBANK",
                "NOTINTERESTEDINSERVICES",
                "LACKOFCONDITIONS",
                "NOTCOLLABORATIVE",
                "COMPANYREPUTATIONNOTPROTECTED",
                "LACKOFDOCUMENTS"
            ]
        },
        "ConditionFilter": {
            "name": "ConditionFilter",
            "values": [
                "ONLY",
                "REMOVE"
            ]
        },
        "ActivityPhase": {
            "name": "ActivityPhase",
            "values": [
                "LEGALDISPUTE",
                "NOTINTERESTED",
                "ARCHIVE",
                "CONTACTTOSCHEDULE",
                "CONTACTTOMEET",
                "LEADMET",
                "LEADWEAK",
                "LEADLOW",
                "LEADMEDIUM",
                "LEADHIGH",
                "PROSPECTMEDIUM",
                "PROSPECTHIGH",
                "CUSTOMER",
                "CUSTOMERCONVERSIONMANAGEMENT",
                "CUSTOMERREALESTATESEARCH",
                "CUSTOMERNEGOTIATION",
                "CUSTOMERPROPOSAL",
                "CUSTOMERBANKOUTCOME",
                "CUSTOMERREVENUESEVENTYFIVEDAYS",
                "CUSTOMERREVENUESIXTYDAYS",
                "CUSTOMERREVENUEFORTYFIVEDAYS",
                "CUSTOMERREVENUETHIRTYFIVEDAYS",
                "CUSTOMERREVENUETWENTYFIVEDAYS",
                "CUSTOMERREVENUETWENTYDAYS",
                "CUSTOMERREVENUEFIFTEENDAYS",
                "CUSTOMERREVENUETENDAYS",
                "CUSTOMERREVENUETWODAYS",
                "CUSTOMERREVENUE"
            ]
        },
        "LastWorkDay": {
            "name": "LastWorkDay",
            "values": [
                "FRIDAY",
                "SATURDAY"
            ]
        },
        "PaymentState": {
            "name": "PaymentState",
            "values": [
                "VALIDATED",
                "TOPAY",
                "PAID"
            ]
        },
        "Supplier": {
            "name": "Supplier",
            "values": [
                "BACKOFFICEEUROPESHPK"
            ]
        },
        "PaymentService": {
            "name": "PaymentService",
            "values": [
                "MEETINGS"
            ]
        },
        "PaymentType": {
            "name": "PaymentType",
            "values": [
                "TRANSFER"
            ]
        },
        "Bank": {
            "name": "Bank",
            "values": [
                "CREDITOEMILIANOSPA"
            ]
        }
    },
    "nonModels": {
        "Place": {
            "name": "Place",
            "fields": {
                "geohash": {
                    "name": "geohash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "label": {
                    "name": "label",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "addressNumber": {
                    "name": "addressNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "street": {
                    "name": "street",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "neighborhood": {
                    "name": "neighborhood",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "municipality": {
                    "name": "municipality",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "postalCode": {
                    "name": "postalCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "subRegion": {
                    "name": "subRegion",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "region": {
                    "name": "region",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "country": {
                    "name": "country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "BuyerPersona": {
            "name": "BuyerPersona",
            "fields": {
                "realEstate": {
                    "name": "realEstate",
                    "isArray": false,
                    "type": {
                        "enum": "RealEstate"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "realEstatePrice": {
                    "name": "realEstatePrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "savings": {
                    "name": "savings",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "loanToValue": {
                    "name": "loanToValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Modality": {
            "name": "Modality",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "ModalityName"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "locations": {
                    "name": "locations",
                    "isArray": true,
                    "type": {
                        "nonModel": "Location"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "Location": {
            "name": "Location",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "LocationName"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Outcome": {
            "name": "Outcome",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": {
                        "enum": "OutcomeName"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "outcomeMotivations": {
                    "name": "outcomeMotivations",
                    "isArray": true,
                    "type": {
                        "nonModel": "OutcomeMotivation"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "OutcomeMotivation": {
            "name": "OutcomeMotivation",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "nextActivityIds": {
                    "name": "nextActivityIds",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "path": {
                    "name": "path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "codename": {
                    "name": "codename",
                    "isArray": false,
                    "type": {
                        "enum": "OutcomeMotivationCodename"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "condition": {
                    "name": "condition",
                    "isArray": false,
                    "type": {
                        "nonModel": "Condition"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "toNotPay": {
                    "name": "toNotPay",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Condition": {
            "name": "Condition",
            "fields": {
                "oneOfPaths": {
                    "name": "oneOfPaths",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "filter": {
                    "name": "filter",
                    "isArray": false,
                    "type": {
                        "enum": "ConditionFilter"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "invertCondition": {
                    "name": "invertCondition",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "invertFilterIfFalse": {
                    "name": "invertFilterIfFalse",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ActivityChild": {
            "name": "ActivityChild",
            "fields": {
                "childrenId": {
                    "name": "childrenId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "minDurationBeforeParent": {
                    "name": "minDurationBeforeParent",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "isConfirmation": {
                    "name": "isConfirmation",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Scheduler": {
            "name": "Scheduler",
            "fields": {
                "timeInterval": {
                    "name": "timeInterval",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "minDurationBeforeMoveAllowed": {
                    "name": "minDurationBeforeMoveAllowed",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "maxAllowedMoves": {
                    "name": "maxAllowedMoves",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "planningParameters": {
                    "name": "planningParameters",
                    "isArray": false,
                    "type": {
                        "nonModel": "PlanningParameters"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "prioritizationParameters": {
                    "name": "prioritizationParameters",
                    "isArray": false,
                    "type": {
                        "nonModel": "PrioritizationParameters"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PlanningParameters": {
            "name": "PlanningParameters",
            "fields": {
                "doNotMove": {
                    "name": "doNotMove",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "favoriteTimeRange": {
                    "name": "favoriteTimeRange",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "compatibility": {
                    "name": "compatibility",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "nearLocations": {
                    "name": "nearLocations",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "asSoonAsPossible": {
                    "name": "asSoonAsPossible",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "similarActivities": {
                    "name": "similarActivities",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "PrioritizationParameters": {
            "name": "PrioritizationParameters",
            "fields": {
                "conversionToCustomer": {
                    "name": "conversionToCustomer",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                },
                "identityValue": {
                    "name": "identityValue",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Break": {
            "name": "Break",
            "fields": {
                "start": {
                    "name": "start",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                },
                "end": {
                    "name": "end",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": true,
                    "attributes": []
                }
            }
        }
    },
    "version": "5b28d5111f04d1be2d0abb52b36550fc"
};