import {
  MenuItem as MuiSelectItem,
  SelectProps as MuiSelectProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { ComponentProps, ReactNode } from "react";
import asSpace from "../LAYOUT/asSpace";
import { FormProps } from "./Form";

export type FormElementProps = Omit<
  ComponentProps<typeof Input>,
  "formRequired" | "formDisabled"
>;

export default function Input<S extends boolean, T>(
  props: {
    id?: string | number;
    pattern?: {
      regex: RegExp | string;
      description: string;
    };
    showHelperText?: boolean;
    options?: readonly T[];
    getOptionLabel?: (option: T) => ReactNode;
    select?: S;
    form: Omit<FormProps["form"], "control">;
  } & Omit<
    boolean extends S
      ? MuiTextFieldProps
      : S extends true
      ? MuiSelectProps
      : MuiTextFieldProps,
    "id" | "variant" | "fullWidth" | "select"
  >
) {
  const {
    children,
    label,
    id,
    form,
    defaultValue,
    inputProps,
    required,
    pattern,
    helperText,
    showHelperText,
    disabled,
    options,
    getOptionLabel,
    InputLabelProps,
    ...textFieldProps
  } = props as typeof props & MuiTextFieldProps;

  const processedDisabled = disabled ?? form.disabled;

  const processedHelperText =
    showHelperText || !processedDisabled
      ? helperText ?? pattern?.description
      : undefined;

  const renderedTextField = (
    <MuiTextField
      {...textFieldProps}
      inputProps={{
        ...inputProps,
        pattern: inputProps?.pattern ?? pattern?.regex.toString(),
      }}
      helperText={processedHelperText}
      required={required ?? form.required}
      disabled={processedDisabled}
      InputLabelProps={{
        ...InputLabelProps,
        required: !form.required && InputLabelProps?.required,
      }}
      fullWidth
      variant="filled"
      label={label ?? children}
    >
      {options?.map((option) => (
        <MuiSelectItem
          key={(option as unknown as string).toString()}
          value={option as unknown as string}
        >
          {getOptionLabel?.(option) ?? (option as unknown as ReactNode)}
        </MuiSelectItem>
      ))}
    </MuiTextField>
  );

  return processedHelperText
    ? asSpace(renderedTextField, { mb: -2 })
    : renderedTextField;
}
