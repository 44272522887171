import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogProps as MuiDialogProps,
  DialogTitle as MuiDialogTitle,
} from "@mui/material";
import { CONTENT_MARGIN_DESKTOP, CONTENT_MARGIN_MOBILE } from "constants/ui";
import { ReactNode } from "react";
import { MdClose } from "react-icons/md";
import { getLargeScreen } from "utils/show";
import Icon from "../symbols/Icon";
import asSpace from "./asSpace";

export default function asModal(
  component: ReactNode,
  options?: { small?: boolean } & Omit<MuiDialogProps, "open">
) {
  let props: typeof options = undefined;

  if (options) {
    const { small, ...dialogProps } = options;
    props = dialogProps;
  }

  const largeScreen = getLargeScreen();

  const contentMargin = largeScreen
    ? CONTENT_MARGIN_DESKTOP - 2
    : CONTENT_MARGIN_MOBILE;

  return component ? (
    <MuiDialog
      maxWidth={options?.small ? undefined : "lg"}
      fullWidth={largeScreen && !options?.small}
      fullScreen={!largeScreen}
      open={true}
      {...props}
    >
      {asSpace(
        <>
          {asSpace(
            <MuiDialogTitle>
              <Icon
                aria-label="close"
                onClick={(complete, e) => {
                  complete();
                  options?.onClose?.(e, "backdropClick");
                }}
              >
                {MdClose}
              </Icon>
            </MuiDialogTitle>,
            {
              display: "flex",
              flexDirection: "row-reverse",
              mb: largeScreen ? -9 : -4,
            }
          )}
          <MuiDialogContent>{component}</MuiDialogContent>
        </>,
        {
          m: contentMargin,
          mb: contentMargin + 1,
        }
      )}
    </MuiDialog>
  ) : null;
}
