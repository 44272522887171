import { Card as MuiCard, CardProps as MuiCardProps } from "@mui/material";
import { CONTENT_MARGIN_DESKTOP, CONTENT_MARGIN_MOBILE } from "constants/ui";
import { ReactNode } from "react";
import { getLargeScreen } from "utils/show";
import asBordered from "./asBordered";
import asSpace from "./asSpace";

export default function asCard(
  component: ReactNode,
  options?: {
    noMargin?: boolean;
  } & MuiCardProps
) {
  let assignedCardProps: MuiCardProps | undefined;

  if (options) {
    const { noMargin, ...cardProps } = options;

    assignedCardProps = cardProps;
  }

  const largeScreen = getLargeScreen();

  return asSpace(
    largeScreen
      ? asBordered(
          <MuiCard elevation={0} {...assignedCardProps}>
            {asSpace(component, {
              m: options?.noMargin ? undefined : CONTENT_MARGIN_DESKTOP,
            })}
          </MuiCard>,
          {
            noPadding: true,
          }
        )
      : asSpace(component, {
          m: options?.noMargin ? undefined : CONTENT_MARGIN_MOBILE,
        }),
    { m: 2 }
  );
}
