import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
} from "@mui/material";
import { ComponentProps, ReactNode } from "react";
import asSpace from "../LAYOUT/asSpace";
import Controller from "./Controller";
import { FormProps } from "./Form";

export default function Toggle<S extends boolean, I extends boolean>(
  props: {
    id?: number | string;
    switch?: S;
    children?: ReactNode;
    defaultValue?: boolean;
    boundToAbove?: boolean;
    asInline?: I;
    onChange?: (value: boolean) => void;
  } & (boolean extends I ? FormProps : I extends true ? object : FormProps) &
    Omit<
      boolean extends S
        ? MuiCheckboxProps
        : S extends true
        ? MuiSwitchProps
        : MuiCheckboxProps,
      | "children"
      | "checked"
      | "defaultChecked"
      | "defaultValue"
      | "onChange"
      | "id"
      | "form"
    >
) {
  const {
    switch: asSwitch,
    id,
    children,
    defaultValue,
    boundToAbove,
    asInline,
    value,
    onChange: propsOnChange,
    ...toggleProps
  } = props;

  const BaseToggle = ({
    onChange,
    innerRef,
    value: checked,
    ...field
  }: Partial<Parameters<ComponentProps<typeof Controller>["children"]>[0]> &
    MuiSwitchProps &
    MuiCheckboxProps) => {
    const baseToggleProps = {
      ...toggleProps,
      color: "primary",
      checked: !!checked,
      value: !!checked,
      onChange: (_, isChecked) => {
        propsOnChange?.(isChecked);
        onChange?.(isChecked);
      },
      inputRef: innerRef,
      ...field,
    } as MuiSwitchProps & MuiCheckboxProps;

    return asSwitch ? (
      <MuiSwitch {...baseToggleProps} />
    ) : (
      <MuiCheckbox {...baseToggleProps} />
    );
  };

  const toggle = (
    <MuiFormControlLabel
      control={
        asInline ? (
          <BaseToggle id={id?.toString()} value={value} />
        ) : (
          <Controller
            id={id}
            defaultValue={!!defaultValue}
            {...(toggleProps as any)}
          >
            {BaseToggle}
          </Controller>
        )
      }
      label={children as ComponentProps<typeof MuiFormControlLabel>["label"]}
    />
  );

  return boundToAbove ? asSpace(toggle, { mt: -2 }) : toggle;
}
