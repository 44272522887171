import { DateTime } from "luxon";
import {
  ActivityCodename,
  Identity,
  OutcomeMotivationCodename,
  OutcomeName,
  Source,
} from "models";
import { sortDates } from "utils/localize";
import {
  ActivityModel,
  getActivity,
  getTasks,
  IdentityModel,
} from "utils/query";
import { toDateTime, toInterval } from "utils/time";

export const IIR_BOUNDS = {
  min: 0.3,
  max: 1.4,
};

export const MEETING_PAYMENT_MAX_DATE = 15;

const callCenterNegativeMotivations = [
  OutcomeMotivationCodename.CRIF,
  OutcomeMotivationCodename.CR,
  OutcomeMotivationCodename.INJURIOUS,
  OutcomeMotivationCodename.CIG,
  OutcomeMotivationCodename.OVERINDEBTED,
  OutcomeMotivationCodename.LACKOFINCOMES,
  OutcomeMotivationCodename.DEPENDENTS,
  OutcomeMotivationCodename.LACKOFWARRANTIES,
];

const SOURCE_TO_PRICE: Record<Source, number> = {
  [Source.AGREFERRAL]: 28,
  [Source.BEREFERRAL]: 28,
  [Source.CREDITBROKER]: 28,
  [Source.DIREFERRAL]: 28,
  [Source.FACEBOOKCHATBOT]: 28,
  [Source.FACILEIT]: 28,
  [Source.FCREFERRAL]: 28,
  [Source.LINKSOLUZIONI]: 28,
  [Source.MANAGEMENT]: 28,
  [Source.MPREFERRAL]: 28,
  [Source.MUTUOPOSITIVO]: 28,
  [Source.OFFICEREFERRAL]: 28,
  [Source.REALESTATEAGENCY]: 28,
  [Source.REFERRAL]: 28,
};

export async function isSuspended(
  identity: IdentityModel,
  paymentDate: DateTime,
  scheduleActivity?: ActivityModel,
  meetingActivity?: ActivityModel
) {
  //? If identity has first executed schedule meeting inside last month

  const scheduleIdentityMeetingActivity =
    scheduleActivity ??
    (await getActivity(["codename", ActivityCodename.SCHEDULEMEETING]));

  if (scheduleIdentityMeetingActivity) {
    const firstScheduleIdentityMeeting = (await getTasks())
      ?.filter(
        ({ identityTasksId, activityTasksId, outcome }) =>
          !!outcome &&
          outcome !== OutcomeName.NOTEXECUTED &&
          identityTasksId === identity.id &&
          activityTasksId === scheduleIdentityMeetingActivity.id
      )
      .sort(sortDates)[0];

    const paymentMonth = paymentDate.minus({ months: 1 });

    const paymentLastMonth = toInterval(
      paymentMonth.startOf("month"),
      paymentMonth.endOf("month")
    );

    if (
      firstScheduleIdentityMeeting &&
      paymentLastMonth.contains(
        toDateTime(firstScheduleIdentityMeeting.endDateTime)
      )
    ) {
      //? And if identity meeting does not have executed meetings in payment period

      const identityMeetingActivity =
        meetingActivity ??
        (await getActivity(["codename", ActivityCodename.MEETING]));

      if (!identityMeetingActivity) return false;

      const meetingInterval = evaluateMeetingInterval(paymentDate);

      const identityMeeting = (await getTasks())?.some(
        ({ identityTasksId, activityTasksId, outcome, startDateTime }) =>
          !!outcome &&
          outcome !== OutcomeName.NOTEXECUTED &&
          identityTasksId === identity.id &&
          activityTasksId === identityMeetingActivity.id &&
          meetingInterval.contains(toDateTime(startDateTime))
      );

      if (!identityMeeting) return true;
    }
  }

  return false;
}

async function getMeetings(
  identity: IdentityModel,
  paymentDate: DateTime,
  meetingActivity?: ActivityModel
) {
  const identityMeetingActivity =
    meetingActivity ??
    (await getActivity(["codename", ActivityCodename.MEETING]));

  if (!identityMeetingActivity) return;

  const meetingInterval = evaluateMeetingInterval(paymentDate);

  return (await getTasks())
    ?.filter(
      ({ identityTasksId, activityTasksId, outcome, startDateTime }) =>
        !!outcome &&
        outcome !== OutcomeName.NOTEXECUTED &&
        identityTasksId === identity.id &&
        activityTasksId === identityMeetingActivity.id &&
        meetingInterval.contains(toDateTime(startDateTime))
    )
    .sort(sortDates);
}

export async function isToPay(
  identity: IdentityModel,
  paymentDate: DateTime,
  meetingActivity?: ActivityModel
) {
  //? If identity has an executed meeting inside meeting interval, and its first meeting is not negative for call center
  const firstMeeting = (
    await getMeetings(identity, paymentDate, meetingActivity)
  )?.[0];

  if (
    firstMeeting &&
    !callCenterNegativeMotivations.includes(
      firstMeeting.outcomeMotivation as OutcomeMotivationCodename
    )
  ) {
    return firstMeeting;
  }
}

export async function isToNotPay(
  notSuspendedIdentity: IdentityModel,
  paymentDate: DateTime,
  meetingActivity?: ActivityModel
) {
  //? If identity has an executed meeting inside meeting interval, and its first meeting is not negative for call center
  const meetings = await getMeetings(
    notSuspendedIdentity,
    paymentDate,
    meetingActivity
  );

  if (
    meetings &&
    (!meetings.length ||
      callCenterNegativeMotivations.includes(
        meetings[0].outcomeMotivation as OutcomeMotivationCodename
      ))
  ) {
    return meetings.length ? meetings[0] : true;
  }

  return false;
}

export function calculateIdentityAmount(identities?: Identity[] | Identity) {
  if (Array.isArray(identities)) {
    return identities.reduce(
      (previous, { price, source }) =>
        previous + (price ?? SOURCE_TO_PRICE[source]),
      0
    );
  } else {
    return (
      identities && (identities.price ?? SOURCE_TO_PRICE[identities.source])
    );
  }
}

export function evaluateMeetingInterval(paymentDate: DateTime) {
  return toInterval(
    paymentDate.minus({ months: 1 }).startOf("month"),
    paymentDate.set({ day: MEETING_PAYMENT_MAX_DATE }).endOf("day")
  );
}
