import { useEffect, useState } from "react";
import { log } from "./log";
import { storeList } from "./store";

/**
 * Loads data asynchronously, without the boilerplate.
 * @param fetch The object to construct the function
 * @param load Callback executed after fetch has retrieved the data successfully
 */
export function useAsync<T>(
  fetch:
    | {
        action: (isMounted: () => boolean) => T | Promise<T>;
        condition?: boolean;
        cleanup?: () => void;
      }
    | undefined,
  load?: (data: T) => void
) {
  useEffect(() => {
    let isMounted = true;

    async function loadData() {
      let data = undefined;
      let isError = false;

      try {
        data = await fetch!.action(() => isMounted);
      } catch (error) {
        log(error);
        isError = true;
      }

      if (!isError && isMounted) load?.(data!);
    }

    if (fetch) {
      if (fetch.condition !== false) loadData();

      if (fetch.cleanup) {
        return () => {
          isMounted = false;
          fetch.cleanup!();
        };
      }
    }
  });
}

/**
 * Subscribes to a model and returns its values.
 * @param list The list operation to subscribe to
 * @returns Null if the query is still loading, else the results
 */
export function useList<T>(
  list: (
    subscribe: (result: T[]) => void | Promise<void>
  ) => Promise<ReturnType<typeof storeList> | undefined> | undefined
) {
  const [listResult, setListResult] = useState<T[] | null>(null);

  let subscription: ReturnType<typeof storeList> | undefined;

  useAsync(
    {
      action: async (isMounted) =>
        list((entities) => {
          if (
            isMounted() &&
            (listResult?.length !== entities.length ||
              entities.some(
                (entity, index) =>
                  JSON.stringify(listResult?.[index]) !== JSON.stringify(entity)
              ))
          ) {
            //? If entities are added, deleted, or updated, update list
            setListResult([...entities]);
          }
        }),
      cleanup: () => subscription?.unsubscribe(),
    },
    (listSubscription) => (subscription = listSubscription)
  );

  return listResult;
}

/**
 * Awaits a promise or returns null if loading.
 * @param load Promise to load
 * @returns Loaded data or null if loading
 */
export function useLoader<T extends any>(load: () => Promise<T>) {
  const [data, setData] = useState<T | null | undefined>();

  useAsync(
    {
      condition: data === undefined,
      action: load,
    },
    (loadedData) => setData(loadedData ?? null)
  );

  return data ?? null;
}
