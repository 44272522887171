import { ModelInit, MutableModel } from "@aws-amplify/datastore";
import { NavigationEvent } from "constants/code";
import { ViewableEntity } from "helpers/define";
import { getModelName } from "helpers/display";
import { ComponentProps, FC, ReactNode } from "react";
import { useTranslation } from "utils/localize";
import * as Mutators from "utils/mutate";
import { Entity as EntityType } from "utils/store";
import Form, { FormUtils } from "view/inputs/Form";
import Quicklist from "view/inputs/Quicklist";
import asSpace from "view/LAYOUT/asSpace";
import { InternalEntityProps } from "../Entity";
import asGrid from "../LAYOUT/asGrid";
import Button, { Complete } from "./Button";

export type FormEntityProps<T extends EntityType> = {
  formProps?: ComponentProps<typeof Form>;
  buttons?: ComponentProps<typeof Form>["buttons"];
  creationMessage?: ReactNode;
  creationFailedMessage?: ReactNode;
  updateMessage?: ReactNode;
  updateFailedMessage?: ReactNode;
  deletionMessage?: ReactNode;
  deletionFailedMessage?: ReactNode;
  hideDelete?: boolean;
  deleteButtonProps?: ComponentProps<typeof Button>;
  dependencies?: {
    value?: keyof T;
    dependsOn: keyof T;
    relationship: (
      dependValue: any,
      Component: React.FC<Record<any, any>>,
      formUtils: FormUtils
    ) => void | Promise<void> | ReturnType<React.FC>;
  }[];
  insert?: [{ Component: React.FC; after?: keyof T; big?: boolean }];
  asOutput?: Partial<Record<keyof T, boolean>>;
  customCreate?: (data: ModelInit<T>) => Promise<T | undefined>;
  customValidator?: (
    data: T
  ) => true | Parameters<Complete>[0] | Promise<true | Parameters<Complete>[0]>;
  customFormatter?: (data: MutableModel<T>) => void | Promise<void>;
  customDelete?: () => Promise<T | undefined>;
  afterCreate?: (entity: T) => Promise<boolean>;
  afterUpdate?: (entity: T) => Promise<boolean>;
  afterDelete?: (entity: T) => Promise<boolean>;
  onEntitySubmitted?: NavigationEvent;
  onEntityDeleted?: NavigationEvent;
};

export default function FormEntity<T extends EntityType>(
  props: {
    model?: InternalEntityProps<T>["model"];
    instance?: T;
    propertyProps?: InternalEntityProps<T>["propertyProps"];
    propertyInfo?: ViewableEntity<T>;
    defaultValues?: Record<any, any>;
  } & FormEntityProps<T>
) {
  const {
    model,
    instance,
    formProps,
    defaultValues,
    hideDelete,
    customDelete,
    deletionMessage,
    onEntitySubmitted,
    onEntityDeleted,
    deletionFailedMessage,
    deleteButtonProps,
    propertyProps,
    insert,
    updateMessage,
    creationMessage,
    updateFailedMessage,
    creationFailedMessage,
    dependencies,
    propertyInfo,
    asOutput,
    customCreate,
    customFormatter,
    customValidator,
    afterCreate,
    afterUpdate,
    afterDelete,
    buttons,
  } = props;

  const { t } = useTranslation("view");

  const isRequired = formProps?.required ?? true;

  const update = !!formProps?.update;

  let assignableFormProps: typeof formProps;

  if (formProps) {
    const { buttons, onSubmit, title, subtitle, ...processedFormProps } =
      formProps;

    assignableFormProps = onSubmit
      ? processedFormProps
      : { title, subtitle, ...processedFormProps };
  }

  const modelName = model && getModelName(model);

  return (
    <Form
      defaultValues={defaultValues}
      buttons={[
        ...(buttons ?? formProps?.buttons ?? []),
        formProps?.update && !hideDelete ? (
          <Button
            delete
            onClick={
              instance &&
              (async (complete) => {
                if (model) {
                  let deleted: T | undefined;

                  if (customDelete) {
                    deleted = await customDelete();
                  } else {
                    const deleteModel = Mutators[
                      `delete${modelName}` as keyof typeof Mutators
                    ] as any | undefined;

                    if (deleteModel) deleted = await deleteModel(instance);
                  }

                  if (
                    deleted &&
                    (!afterDelete || (await afterDelete(deleted)))
                  ) {
                    complete(deletionMessage ?? t("view:deleted"), "success");

                    onEntityDeleted?.();
                  } else {
                    complete(
                      deletionFailedMessage ?? t("view:deleteFailed"),
                      "error"
                    );
                  }
                }
              })
            }
            {...deleteButtonProps}
          />
        ) : undefined,
      ]}
      onSubmit={async (data, complete, reset) => {
        const formattedData = defaultValues as T &
          ModelInit<T> &
          MutableModel<T>;

        const compoundEntities = [];

        for (const key in propertyInfo) {
          const value = propertyInfo[key];

          const formatter = value.toStorage;

          formattedData[key as keyof typeof formattedData] = formatter
            ? await formatter(data[key], formattedData)
            : data[key];

          if (value.subtype) compoundEntities.push(key);
        }

        let valid = !customValidator || (await customValidator(formattedData));

        if (valid === true) {
          await customFormatter?.(formattedData);

          for (const id of compoundEntities) {
            if (
              (isRequired ||
                (
                  propertyProps?.[
                    id as unknown as keyof typeof propertyProps
                  ] as any
                )?.required) &&
              !(Array.isArray(formattedData[id])
                ? formattedData[id].length
                : formattedData[id] && Object.keys(formattedData[id]).length)
            ) {
              complete(t("view:requiredFieldsMissing"), "error");
              return;
            }
          }

          if (formProps?.onSubmit) {
            formProps?.onSubmit?.(formattedData, complete, reset);
          } else {
            if (model) {
              let submittedEntity: T | undefined;

              if (update) {
                if (instance) {
                  const update = Mutators[
                    `update${modelName}` as keyof typeof Mutators
                  ] as any | undefined;

                  if (update)
                    submittedEntity = await update(formattedData, instance);
                }
              } else {
                if (customCreate) {
                  submittedEntity = await customCreate(formattedData);
                } else {
                  const create = Mutators[
                    `create${modelName}` as keyof typeof Mutators
                  ] as any | undefined;

                  if (create) submittedEntity = await create(formattedData);
                }
              }

              if (
                submittedEntity &&
                ((update &&
                  (!afterUpdate || (await afterUpdate(submittedEntity)))) ||
                  (!update &&
                    (!afterCreate || (await afterCreate(submittedEntity)))))
              ) {
                complete(
                  update
                    ? updateMessage ?? t("view:updated")
                    : creationMessage ?? t("view:created"),
                  "success"
                );

                onEntitySubmitted?.();
              } else {
                complete(
                  update
                    ? updateFailedMessage ?? t("view:updateFailed")
                    : creationFailedMessage ?? t("view:creationFailed"),
                  "error"
                );
              }
            }
          }
        } else {
          complete(valid, "error");
        }
      }}
      {...assignableFormProps}
    >
      {(form, formUtils) => {
        function evaluateInput(
          id: string,
          Input: ViewableEntity<T>[keyof ViewableEntity<T>]["input"],
          Output: ViewableEntity<T>[keyof ViewableEntity<T>]["output"],
          additionalProps?: Record<any, any>
        ) {
          const assignedProps = {
            id,
            form,
            ...additionalProps,
            ...propertyProps?.[id],
          };

          const currentValue =
            dependencies && formUtils.getValueRender<T[keyof T]>(id);

          const renderDependencies = [] as NonNullable<typeof dependencies>;

          dependencies?.forEach((dependency) => {
            if (dependency.value) {
              if (dependency.value === id) renderDependencies.push(dependency);
            } else if (dependency.dependsOn === id) {
              dependency.relationship(currentValue, () => null, formUtils);
            }
          });

          const BaseComponent: FC<any> = asOutput?.[id]
            ? (finalProps: any) =>
                asSpace(Output(currentValue!, finalProps), { mb: -4 })
            : Input;

          let renderedComponent: ReactNode;

          if (renderDependencies.length) {
            // TODO THIS CODE ONLY RENDERS ONE DEPENDENCY, SHOULD INSTEAD COMPOSE THE DEPENDENCIES
            renderDependencies.forEach(({ dependsOn, relationship }) => {
              renderedComponent = relationship(
                formUtils.getValueRender<T[keyof T]>(dependsOn),
                (dependencyProps) => (
                  <BaseComponent {...assignedProps} {...dependencyProps} />
                ),
                formUtils
              ) as ReturnType<React.FC>;
            });
          } else {
            renderedComponent = <BaseComponent {...assignedProps} />;
          }

          return renderedComponent;
        }

        const sections = [] as Parameters<typeof asGrid>[0];

        if (propertyInfo) {
          const properties = Object.keys(propertyInfo);
          const entities = Object.values(
            propertyInfo
          ) as typeof propertyInfo[any][];

          if (insert) {
            for (const { Component, after, big } of insert) {
              let index: number | undefined;

              if (after) index = properties.indexOf(after.toString()) + 1;

              if (index) {
                properties.splice(index, 0, index.toString());
                entities.splice(index, 0, {
                  input: Component,
                  output: Component,
                  view: "custom",
                  subtype: big ? ({} as any) : undefined,
                });
              }
            }
          }

          let splitter = 0;

          properties.forEach((id, index) => {
            if (entities[index].subtype) {
              const { input, output, subtype } = entities[index];

              const inNewLine = [] as NonNullable<
                ComponentProps<typeof Quicklist>["inNewLine"]
              >;

              Object.values(subtype!).forEach(
                ({ subtype: child }, subtypeIndex) =>
                  child && inNewLine.push(subtypeIndex, subtypeIndex + 1)
              );

              sections.push({
                section: [
                  evaluateInput(id, input, output, {
                    inNewLine,
                  }),
                ],
                options: {
                  fullWidth: true,
                },
              });

              splitter++;
            } else {
              const nextEntity = entities[index + 1];

              if (!nextEntity || nextEntity.subtype) {
                sections.push({
                  section: entities
                    .slice(splitter, index + 1)
                    .map(({ input, output }, entityIndex) =>
                      evaluateInput(
                        properties[splitter + entityIndex],
                        input,
                        output
                      )
                    ),
                });

                splitter = index + 1;
              }
            }
          });
        }

        return asGrid(sections);
      }}
    </Form>
  );
}
