import { ViewableEntity } from "helpers/define";
import { ComponentProps, FC } from "react";
import { Entity as EntityType } from "utils/store";
import Form from "view/inputs/Form";
import asRow from "view/LAYOUT/asRow";
import asSpace from "view/LAYOUT/asSpace";
import { InternalEntityProps } from "../Entity";
import Quicklist from "../inputs/Quicklist";
import asGrid from "../LAYOUT/asGrid";
import Title from "./Title";

export type ViewEntityProps<T> = {
  buttons?: ComponentProps<typeof Form>["buttons"];
  titleProps?: ComponentProps<typeof Title>;
  insert?: [{ Component: FC; after?: keyof T; big?: boolean }];
};

export default function ViewEntity<T extends EntityType>(
  props: {
    propertyProps?: InternalEntityProps<T>["propertyProps"];
    propertyInfo?: ViewableEntity<T>;
    defaultValues?: Record<any, any>;
  } & ViewEntityProps<T>
) {
  const {
    titleProps,
    propertyProps,
    propertyInfo,
    defaultValues,
    insert,
    buttons,
  } = props;

  const sections = [] as Parameters<typeof asGrid>[0];

  if (propertyInfo) {
    const properties = Object.keys(propertyInfo);
    const entities = Object.values(propertyInfo) as typeof propertyInfo[any][];

    if (insert) {
      for (const { Component, after, big } of insert) {
        let index: number | undefined;

        if (after) index = properties.indexOf(after.toString()) + 1;

        if (index) {
          properties.splice(index, 0, index.toString());
          entities.splice(index, 0, {
            input: Component,
            output: Component,
            view: "custom",
            subtype: big ? ({} as any) : undefined,
          });
        }
      }
    }

    let splitter = 0;

    properties.forEach((id, index) => {
      const { output, subtype } = entities[index];
      const nextEntity = entities[index + 1];

      if (subtype) {
        const inNewLine = [] as NonNullable<
          ComponentProps<typeof Quicklist>["inNewLine"]
        >;

        Object.values(subtype).forEach(
          ({ subtype }, index) => subtype && inNewLine.push(index, index + 1)
        );

        sections.push({
          section: [
            output(defaultValues?.[id], { inNewLine, ...propertyProps?.[id] }),
          ],
          options: {
            fullWidth: true,
          },
        });

        splitter++;
      } else if (!nextEntity || nextEntity.subtype) {
        sections.push({
          section: entities
            .slice(splitter, index + 1)
            .map(({ output }, index) => {
              const key = properties[splitter + index];

              return output(defaultValues?.[key], propertyProps?.[key]);
            }),
        });

        splitter = index + 1;
      }
    });
  }

  return (
    <>
      <Title {...titleProps} />
      {buttons &&
        asSpace(
          asRow(buttons, {
            disableResponsive: true,
          }),
          {
            mb: 2,
          }
        )}
      {asGrid(sections)}
    </>
  );
}
