import { NavigationEvent } from "constants/code";
import {
  Activity,
  Identity,
  OutcomeMotivation,
  OutcomeMotivationCodename,
  OutcomeName,
  Task,
} from "models";
import { useTranslation } from "utils/localize";
import { updateTask } from "utils/mutate";
import { getActivity } from "utils/query";
import schedule from "utils/schedule";
import { toDateTime } from "utils/time";
import Choice from "view/inputs/Choice";
import Form from "view/inputs/Form";
import Free from "view/inputs/Free";
import asRow from "view/LAYOUT/asRow";

enum PostponeFormIds {
  Delay,
  Motivation,
  Description,
}

export default function PostponeTask(props: {
  task?: Task;
  activity?: Activity;
  identity?: Identity;
  onTaskPostponed?: NavigationEvent<Task>;
  onPostponeFailed?: NavigationEvent;
}) {
  const { t } = useTranslation("tasks");

  const delayOptions = [15, 30, 45, 60, 0];

  return (
    <Form
      title={t("tasks:postpone")}
      onSubmit={
        props.task &&
        (async (data: Record<PostponeFormIds, any>, complete) => {
          complete();

          const delay = data[
            PostponeFormIds.Delay
          ] as typeof delayOptions[number];
          const motivation = data[
            PostponeFormIds.Motivation
          ] as OutcomeMotivation["name"];
          const description = data[PostponeFormIds.Description] as string;

          const task = props.task!;

          if (delay) {
            //? Move task
            const updatedTask = await updateTask(
              {
                startDateTime: toDateTime(task.startDateTime)
                  .plus({ minutes: delay })
                  .toISO(),
                endDateTime: toDateTime(task.endDateTime)
                  .plus({ minutes: delay })
                  .toISO(),
              },
              task,
              motivation === OutcomeMotivationCodename.OTHER
                ? {
                    motivation: {
                      startDateTime: motivation,
                      endDateTime: description,
                    },
                  }
                : undefined
            );

            if (updatedTask) props.onTaskPostponed?.(updatedTask);
          } else {
            //? Task is not executed
            const updatedTask = await updateTask(
              {
                outcome: OutcomeName.NOTEXECUTED,
                outcomeMotivation: motivation,
              },
              task,
              motivation === OutcomeMotivationCodename.OTHER
                ? { motivation: { outcome: description } }
                : undefined
            );

            if (updatedTask) {
              const outcomeMotivations = props.activity?.outcomes.find(
                ({ name }) => name === OutcomeName.NOTEXECUTED
              )?.outcomeMotivations;

              //? Reschedule
              const nextActivity = await getActivity(
                (motivation === OutcomeMotivationCodename.OTHER
                  ? outcomeMotivations?.[0]
                  : outcomeMotivations?.find(
                      ({ codename, name }) =>
                        codename === motivation || name === motivation
                    )
                )?.nextActivityIds[0],
                props.identity
              );

              if (nextActivity) {
                const newTask = await schedule(
                  {
                    activityTasksId: nextActivity.id,
                    identityTasksId: task.identityTasksId,
                    modality: task.modality,
                    location: task.location,
                    placeInfo: task.placeInfo,
                    taskChildrenId: task.taskChildrenId,
                  },
                  nextActivity.role,
                  {
                    planning: {
                      strategy: "wrongScheduling",
                    },
                  }
                );

                if (newTask) props.onTaskPostponed?.(updatedTask);
              }
            }
          }

          props.onPostponeFailed?.();
        })
      }
    >
      {(form, { getValueRender }) => {
        const motivation = getValueRender<OutcomeMotivation["name"]>(
          PostponeFormIds.Motivation
        );

        return asRow([
          <Choice
            id={PostponeFormIds.Delay}
            form={form}
            options={delayOptions}
            getLabel={(option) =>
              option ? `${option} ${t("tasks:minutes")}` : t("tasks:reschedule")
            }
          >
            {t("tasks:delay")}
          </Choice>,
          <Choice
            id={PostponeFormIds.Motivation}
            form={form}
            options={OutcomeMotivationCodename}
            change={() => {
              const types = {} as Record<
                OutcomeMotivation["name"],
                OutcomeMotivation["name"]
              >;

              if (props.activity) {
                props.activity.outcomes
                  ?.find(({ name }) => name === OutcomeName.NOTEXECUTED)
                  ?.outcomeMotivations?.forEach(
                    ({ codename, name }) => (types[codename ?? name] = name)
                  );
              }

              return {
                ...types,
                [OutcomeMotivationCodename.OTHER]:
                  OutcomeMotivationCodename.OTHER,
              };
            }}
          >
            {t("tasks:motivation")}
          </Choice>,
          motivation === OutcomeMotivationCodename.OTHER ? (
            <Free multiline id={PostponeFormIds.Description} form={form}>
              {t("tasks:description")}
            </Free>
          ) : undefined,
        ]);
      }}
    </Form>
  );
}
