import type { NationalNumber } from "libphonenumber-js";

/**
 * Checks if a phone number is valid.
 * @param number The phone number to check
 * @returns True if the number is valid
 */
export async function isValidPhoneNumber(number: string) {
  const { isValidNumber } = await import("libphonenumber-js");

  return isValidNumber(number as NationalNumber);
}
