import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
} from "@mui/material";
import { ReactNode } from "react";
import { getLargeScreen } from "utils/show";
import Text from "../outputs/Text";
import Progress from "../symbols/Progress";
import asSpace from "./asSpace";

export type ListItem = {
  component: ReactNode;
  options: MuiListItemProps;
};

export default function asList(
  components: (ReactNode | ListItem)[],
  options?: {
    button?: boolean;
    loading?: boolean;
    noMargin?: boolean;
    listSymbol?: "number";
    emptyLabel?: string;
  } & Omit<MuiListItemProps, "button">
) {
  let assignedItemOptions: typeof options;

  if (options) {
    const {
      button,
      loading,
      noMargin,
      listSymbol,
      emptyLabel,
      ...itemOptions
    } = options;

    assignedItemOptions = itemOptions;
  }

  function isListItem(
    component: typeof components[number]
  ): component is ListItem {
    return !!(component as ListItem).component;
  }

  return options?.loading ? (
    <Progress spinner />
  ) : components?.length ? (
    <MuiList>
      {components.map((component, index) => {
        let assignedComponent = component as ListItem["component"];
        let assignedOptions: Omit<ListItem["options"], "button"> = {
          key: index,
          button: options?.button as any,
          alignItems: "flex-start",
          ...assignedItemOptions,
        };

        if (isListItem(component)) {
          assignedComponent = component.component;
          assignedOptions = { ...assignedOptions, ...component.options };
        }

        const largeScreen = getLargeScreen();

        return (
          <MuiListItem {...assignedOptions}>
            {options?.noMargin
              ? assignedComponent
              : asSpace(
                  <>
                    {asSpace(
                      options?.listSymbol === "number" ? (
                        <Text type="section">{index + 1}.</Text>
                      ) : undefined,
                      {
                        ml: -2,
                        mr: largeScreen ? 2 : 1,
                      }
                    )}
                    {asSpace(assignedComponent, {
                      width: 1,
                    })}
                  </>,
                  {
                    display: "flex",
                    flexDirection: "row",
                    width: 1,
                  }
                )}
          </MuiListItem>
        );
      })}
    </MuiList>
  ) : (
    <>{options?.emptyLabel ?? "No rows"}</>
  );
}
