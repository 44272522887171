import { ReactNode } from "react";
import { toLabelCase } from "utils/format";
import { getLargeScreen } from "utils/show";
import Text from "view/outputs/Text";
import asSpace from "./asSpace";

export default function asBordered(
  component: ReactNode,
  options?: {
    title?: ReactNode;
    noPadding?: boolean;
    onlyBorder?: "bottom" | "top" | "left" | "right";
  }
) {
  const largeScreen = getLargeScreen();

  const additionalSpaceProps = options?.noPadding
    ? undefined
    : ({
        paddingX: largeScreen ? 2 : 1,
        paddingTop: 2,
        paddingBottom: 2,
      } as Parameters<typeof asSpace>[1]);

  const borderedContent = asSpace(component, {
    [`border${
      (options?.onlyBorder && toLabelCase(options?.onlyBorder)) ?? ""
    }`]: 1,
    borderRadius: options?.onlyBorder ? undefined : 1,
    borderColor: "rgba(0, 0, 0, 0.12)",
    mt: options?.title ? -1.8 : undefined,
    ...additionalSpaceProps,
  });

  return options?.title
    ? asSpace(
        <>
          <Text
            type="fieldBody"
            spaceProps={{
              display: "inline-block",
              ml: 2,
              px: 0.5,
              position: "relative",
              bgcolor: "#FFFFFF",
            }}
          >
            {options?.title}
          </Text>
          {borderedContent}
        </>,
        {
          mt: -1,
        }
      )
    : borderedContent;
}
