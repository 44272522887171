/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:7d527ce3-1bf7-47c2-bef8-649fefbf3575",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_u1vhfyL8R",
    "aws_user_pools_web_client_id": "5n9k88rd55jmif8bi99epvjq1o",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://75ttnzt6gvdnfk3wmdhdve6eae.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://kv9c9wwmti.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_mobile_analytics_app_id": "b28cba2808f84d64847217eaee4534e2",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "aws_user_files_s3_bucket": "insync5253971305134bf88d0c22230fe1d486114526-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "search_indices": {
                "items": [
                    "placeindexecdbd4b8-prod"
                ],
                "default": "placeindexecdbd4b8-prod"
            },
            "maps": {
                "items": {
                    "map33571acf-prod": {
                        "style": "VectorEsriNavigation"
                    }
                },
                "default": "map33571acf-prod"
            }
        }
    }
};


export default awsmobile;
