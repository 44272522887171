import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import Number from "./Number";

export type PercentageUnit = "percentage";

export default function Percentage(
  props: Omit<ComponentProps<typeof Number>, "unit">
) {
  const { t } = useTranslation("view");

  return <Number {...props} unit={t(`view:percentageUnit`)} />;
}
