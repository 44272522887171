import define from "helpers/define";
import { Configuration } from "models";
import { ComponentProps } from "react";
import { useTranslation } from "utils/localize";
import Number from "view/inputs/Number";
import TimeDuration from "view/inputs/TimeDuration";

export default define<Configuration>(() => {
  const { t } = useTranslation("configurations");

  return {
    scheduler: {
      view: "section",
      label: t("configurations:scheduler"),
      subtype: {
        maxAllowedMoves: {
          view: "number",
          label: t("configurations:maxAllowedMoves"),
        },
        minDurationBeforeMoveAllowed: {
          view: "duration",
          label: t("configurations:minDurationBeforeMoveAllowed"),
          props: {
            unit: "days",
            helperText: t("configurations:minimumTimeToMoveATask"),
          } as ComponentProps<typeof TimeDuration>,
        },
        timeInterval: {
          view: "duration",
          label: t("configurations:timeInterval"),
        },
        planningParameters: {
          view: "section",
          label: t("configurations:planningParameters"),
          subtype: {
            asSoonAsPossible: {
              view: "number",
              label: t("configurations:asSoonAsPossible"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
            compatibility: {
              view: "number",
              label: t("configurations:compatibility"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
            doNotMove: {
              view: "number",
              label: t("configurations:doNotMove"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
            favoriteTimeRange: {
              view: "number",
              label: t("configurations:favoriteTimeRange"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
            nearLocations: {
              view: "number",
              label: t("configurations:nearLocations"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
            similarActivities: {
              view: "number",
              label: t("configurations:similarActivities"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
          },
        },
        prioritizationParameters: {
          view: "section",
          label: t("configurations:prioritizationParameters"),
          subtype: {
            identityValue: {
              view: "number",
              label: t("configurations:identityValue"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
            conversionToCustomer: {
              view: "number",
              label: t("configurations:conversionToCustomer"),
              props: {
                normalized: true,
              } as ComponentProps<typeof Number>,
            },
          },
        },
      },
    },
    audits: {
      view: "exclude",
    },
  };
});
