import { PersistentModelConstructor } from "@aws-amplify/datastore";
import { Activity, Configuration, Identity, Payment, Task, User } from "models";
import activity from "./activity";
import configuration from "./configuration";
import identity from "./identity";
import payment from "./payment";
import task from "./task";
import user from "./user";

export function getModelName(model: PersistentModelConstructor<any, any>) {
  return new model({} as any).constructor.name as string;
}

const activityName = getModelName(Activity);
const configurationName = getModelName(Configuration);
const identityName = getModelName(Identity);
const paymentName = getModelName(Payment);
const taskName = getModelName(Task);
const userName = getModelName(User);

export const deleteable = {
  [activityName]: true,
  [identityName]: true,
  [paymentName]: true,
  [taskName]: true,
  [userName]: true,
};

const display = {
  [activityName]: activity,
  [configurationName]: configuration,
  [identityName]: identity,
  [paymentName]: payment,
  [taskName]: task,
  [userName]: user,
};

export default display;
