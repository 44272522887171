import { Dispatch, SetStateAction, useState } from "react";

export let setServiceWorkerStatus:
  | Dispatch<SetStateAction<ServiceWorkerRegistration | undefined>>
  | undefined;

export function useServiceWorkerStatus() {
  const [status, setStatus] = useState<ServiceWorkerRegistration>();

  setServiceWorkerStatus = setStatus;

  return status;
}
