import { displayPerson, toColorFromOutcome } from "utils/format";
import { sortDates, toLocalizedDateTime, useTranslation } from "utils/localize";
import {
  ActivityModel,
  getActivities,
  getTasks,
  getUsers,
  TaskModel,
} from "utils/query";
import { useList } from "utils/render";
import { useModal } from "utils/show";
import TaskTable from "view/outputs/TaskTable";
import TaskView from "../TaskView";

export default function TaskList(props: {
  identityID?: TaskModel["identityTasksId"];
}) {
  const { t } = useTranslation(["tasks", "enumMotivations", "enumRoles"]);

  const tasks = useList<TaskModel & { role?: ActivityModel["role"] }>(
    async (subscribe) => {
      const activities = await getActivities();
      const users = await getUsers({ includeDeleted: true });

      return getTasks({
        subscribe: (items) => {
          subscribe(
            (props.identityID
              ? items.filter(
                  ({ identityTasksId }) => identityTasksId === props.identityID
                )
              : items
            )
              .map(({ activityTasksId, assignee, ...item }) => {
                const activity = activities?.find(
                  ({ id }) => id === activityTasksId
                );

                return {
                  ...item,
                  activityTasksId: activity?.name ?? "",
                  assignee: displayPerson(
                    users?.find(({ username }) => username === assignee)
                  ),
                  role: activity?.role,
                };
              })
              .sort((aTask, bTask) => sortDates(bTask, aTask))
          );
        },
      });
    }
  );

  const openModal = useModal();

  const taskList =
    tasks?.map(
      ({ endDateTime, activityTasksId, outcomeMotivation, role, ...task }) => ({
        ...task,
        name: activityTasksId,
        date: endDateTime,
        outcomeMotivation: outcomeMotivation
          ? t(`enumMotivations:${outcomeMotivation}`)
          : t("tasks:toExecute"),
        color: toColorFromOutcome(task.outcome),
        role: role && t(`enumRoles:${role}`),
      })
    ) ?? [];

  return (
    <TaskTable
      loading={!tasks}
      rows={taskList}
      columns={[
        {
          field: "date",
          headerName: t("tasks:date"),
          valueFormatter: ({ value }) =>
            toLocalizedDateTime(value as typeof taskList[number]["date"]),
        },
        {
          field: "name",
          headerName: t("tasks:activity"),
        },
        {
          field: "outcomeMotivation",
          headerName: t("tasks:outcomeMotivation"),
        },
        {
          field: "role",
          headerName: t("tasks:role"),
        },
        {
          field: "assignee",
          headerName: t("tasks:assignee"),
        },
      ]}
      onRowClick={({ id }) =>
        openModal((close) => (
          <TaskView taskID={id.toString()} onTaskSubmitted={close} />
        ))
      }
    />
  );
}
