import { Duration } from "luxon";
import { ComponentProps } from "react";
import { getNow, toDuration, toDurationFromTimespan } from "utils/time";
import Time, { TimeInputValue, TimeOutputValue } from "./Time";

type BaseInputValue = TimeInputValue;

type BaseOutputValue = TimeOutputValue;

export type TimespanOutputValue = string | undefined;

export type TimespanInputValue =
  | BaseInputValue
  | TimespanOutputValue
  | Duration;

export default function Timespan<F>(
  props: {
    defaultValue?: TimespanInputValue;
    formatValue?: (value: F) => TimespanInputValue;
    formatOnChange?: (value: TimespanOutputValue) => F;
    onChange?: (value: unknown extends F ? TimespanOutputValue : F) => void;
  } & Omit<
    ComponentProps<typeof Time>,
    "defaultValue" | "ampm" | "onChange" | "formatValue" | "formatOnChange"
  >
) {
  const { defaultValue, formatValue, formatOnChange, ...timeProps } = props;

  const date = getNow().toISODate();

  function transformInput(input: TimespanInputValue): BaseInputValue {
    return (typeof input === "string" ? toDuration(input) : input)?.toISOTime();
  }

  function transformOutput(output: BaseOutputValue): TimespanOutputValue {
    return output && toDurationFromTimespan(output).toISO();
  }

  return (
    <Time
      ampm={false}
      date={date}
      formatValue={(value) =>
        transformInput(
          formatValue
            ? formatValue(value)
            : (value as unknown as TimespanInputValue)
        )
      }
      formatOnChange={(value) => {
        const transformedOutput = transformOutput(value);

        return formatOnChange
          ? formatOnChange(transformedOutput)
          : (transformedOutput as unknown as F);
      }}
      defaultValue={transformInput(defaultValue)}
      {...timeProps}
    />
  );
}
