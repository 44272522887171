import { ReactNode } from "react";
import asColumn from "./asColumn";
import asRow from "./asRow";

export default function asGrid(
  sections: (
    | {
        section: Parameters<typeof asRow>[0];
        options?: { fullWidth?: boolean } & NonNullable<
          Parameters<typeof asRow>[1]
        >;
      }
    | undefined
  )[],
  options?: {
    maxColumns?: number | "auto";
  } & Parameters<typeof asColumn>[1]
) {
  let props = undefined;

  if (options) {
    const { maxColumns, ...columnOptions } = options;

    props = columnOptions;
  }

  const processedMaxColumns =
    options?.maxColumns === "auto" ? 0 : options?.maxColumns ?? 4;

  let columns = 0;

  for (const sectionObject of sections) {
    if (sectionObject) {
      const {
        section: { length },
      } = sectionObject;

      if (processedMaxColumns && length >= processedMaxColumns) {
        columns = processedMaxColumns;
        break;
      }

      if (length > columns) columns = length;
    }
  }

  const rows = [] as ReactNode[];

  sections.forEach((sectionObject) => {
    if (sectionObject) {
      const { section, options: sectionOptions } = sectionObject;

      const sectionLength = section.length;

      for (let i = 0; i * columns < sectionLength; ++i) {
        const rowToAssign = [];

        for (let j = 0; j < columns; ++j) {
          const field = section[i * columns + j];

          if (field) rowToAssign.push(field);
          else if (!sectionOptions?.fullWidth) rowToAssign.push(<></>);
        }

        let assignedRowOptions = {};

        if (sectionOptions && !i) {
          const { fullWidth, ...rowOptions } = sectionOptions;
          assignedRowOptions = { ...rowOptions, ...assignedRowOptions };
        }

        rows.push(asRow(rowToAssign, assignedRowOptions));
      }
    }
  });

  return asColumn(rows, props);
}
