import { Grid as MuiGrid, GridProps as MuiGridProps } from "@mui/material";
import { ReactNode } from "react";
import Title from "../outputs/Title";
import asSpace from "./asSpace";

export default function asColumn(
  components: ReactNode[],
  options?: { spacing?: number } & Omit<
    MuiGridProps,
    "container" | "direction" | "spacing"
  >
) {
  const spacing = options?.spacing ?? 2;

  return (
    <>
      {options?.title &&
        asSpace(<Title>{options.title}</Title>, {
          mb: spacing / 2,
        })}
      <MuiGrid {...options} spacing={spacing} container direction="column">
        {components.map((component, index) => (
          <MuiGrid key={index} item xs>
            {component}
          </MuiGrid>
        ))}
      </MuiGrid>
    </>
  );
}
