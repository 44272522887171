import { ComponentProps, ReactNode, useState } from "react";
import { FieldValues, Path, useForm } from "react-hook-form";
import { useTranslation } from "utils/localize";
import { FormProps, FormUtils } from "view/inputs/Form";
import asColumn from "view/LAYOUT/asColumn";
import asBordered from "../LAYOUT/asBordered";
import asGrid from "../LAYOUT/asGrid";
import asList from "../LAYOUT/asList";
import asRow from "../LAYOUT/asRow";
import asSpace from "../LAYOUT/asSpace";
import Button, { Complete } from "./Button";
import Controller from "./Controller";

export default function Quicklist(
  props: {
    id?: string | number;
    defaultValue?: ComponentProps<typeof QuicklistBase>["value"];
  } & Omit<ComponentProps<typeof QuicklistBase>, "value"> &
    FormProps
) {
  const {
    form,
    defaultValue,
    disabled,
    required,
    children,
    ...quicklistProps
  } = props;

  return (
    <Controller
      form={form}
      defaultValue={defaultValue}
      defaultFallback={null}
      {...quicklistProps}
    >
      {(field) => (
        <QuicklistBase
          {...quicklistProps}
          disabled={disabled ?? form.disabled}
          required={required ?? form.required}
          {...field}
        >
          {required && !form.required ? `${children} *` : children}
        </QuicklistBase>
      )}
    </Controller>
  );
}

const adderIndex = -1;

function QuicklistBase(props: {
  children?: ReactNode;
  required?: boolean;
  disabled?: boolean;
  value?: Record<any, any>[];
  getEditProps?: (utils: FormUtils) => Record<any, Record<any, any>>;
  getViewProps?: (utils: FormUtils) => Record<any, Record<any, any>>;
  Edit?: (props: {
    form: FormProps["form"];
    props?: Record<any, Record<any, any>>;
  }) => ReactNode[];
  View?: (props: {
    data: Record<any, any>;
    props?: Record<any, Record<any, any>>;
  }) => ReactNode[];
  inNewLine?: number[];
  onChange?: (data: Record<any, any>[] | null) => void;
  onSubmit?: (
    data: Record<any, any>,
    complete: (
      newData: typeof data,
      ...completeProps: Parameters<Complete>
    ) => void
  ) => void;
}) {
  const {
    children,
    Edit,
    View,
    required,
    disabled,
    value,
    inNewLine,
    getEditProps,
    getViewProps,
    onChange,
    onSubmit,
  } = props;

  const { t } = useTranslation("view");

  const [values, setValues] = useState<Record<any, any>[] | null>(
    value ?? null
  );

  const [update, setUpdate] = useState<number[]>([]);

  const { control, getValues, reset, setValue, watch } = useForm();

  function updateValues(newValues: typeof values) {
    const valuesToSet = newValues?.length ? newValues : null;

    setValues(valuesToSet);
    onChange?.(valuesToSet);
  }

  function SubmitButton(submitProps: {
    formIndex: typeof update[number];
    editLength?: number;
  }) {
    const { formIndex, editLength } = submitProps;

    return (
      <Button
        onClick={(complete) => {
          function endForm(
            ...params: Parameters<
              Parameters<NonNullable<typeof props["onSubmit"]>>[1]
            >
          ) {
            const [updateValue, ...completeProps] = params;

            complete(...completeProps);
            setUpdate(
              update.filter((updateIndex) => updateIndex !== formIndex)
            );

            if (formIndex === adderIndex) {
              updateValues(values ? [...values, updateValue] : [updateValue]);
            } else {
              const updatedValues = [...values!];

              updatedValues.splice(formIndex, 1, updateValue);

              updateValues(updatedValues);
            }

            reset();
          }

          const newValues = getValues();

          if (
            required &&
            Object.values(newValues).filter(
              (newValue) => newValue === false || !!newValue
            ).length !== editLength
          ) {
            complete(t("view:allFieldsAreRequired"), "error");
          } else {
            if (onSubmit) {
              onSubmit(newValues, (data, ...params) =>
                params[1] === "error"
                  ? complete(...params)
                  : endForm(data, ...params)
              );
            } else {
              endForm(newValues);
            }
          }
        }}
      />
    );
  }

  function EditButton(editProps: { formIndex: number; active?: boolean }) {
    const { formIndex, active } = editProps;

    return (
      <Button
        back
        disabled={disabled}
        onClick={(complete) => {
          complete();
          setUpdate(
            active
              ? update.filter((updateIndex) => updateIndex !== formIndex)
              : [...update, formIndex]
          );
        }}
      >
        {active ? t("view:cancel") : t("view:add")}
      </Button>
    );
  }

  function processEdit(edit: ReactNode[]) {
    const sections = [] as Parameters<typeof asGrid>[0];

    if (inNewLine) {
      let splitter = 0;

      inNewLine.forEach((currentValue) => {
        sections.push({
          section: edit.slice(splitter, currentValue),
          options: {
            fullWidth: true,
          },
        });

        splitter = currentValue;
      });

      sections.push({
        section: edit.slice(splitter),
        options: {
          fullWidth: true,
        },
      });
    } else {
      sections.push({
        section: edit,
      });
    }

    return asGrid(sections);
  }

  function FormattedEdit(
    editProps: { content: ReactNode; active?: boolean } & ComponentProps<
      typeof SubmitButton
    >
  ) {
    const { content, formIndex, active, ...submitProps } = editProps;

    return (
      <>
        {content}
        {active ? (
          asRow(
            [
              <EditButton formIndex={formIndex} active />,
              <SubmitButton formIndex={formIndex} {...submitProps} />,
            ],
            {
              disableResponsive: true,
            }
          )
        ) : (
          <EditButton formIndex={formIndex} active={false} />
        )}
      </>
    );
  }

  const utils: FormUtils = {
    setValue: (id, ...rest) =>
      setValue(id.toString() as Path<FieldValues>, ...rest),
    getValueCallback: (id) => getValues(id.toString() as Path<FieldValues>),
    getValueRender: (id) => watch(id.toString() as Path<FieldValues>),
  };

  const list = values
    ? [
        ...values.map((valueToView, index) => {
          if (update.includes(index)) {
            const edit = Edit?.({
              props: {
                defaultValue: values[index],
                ...getEditProps?.(utils),
              },
              form: { disabled, required, control, utils },
            });

            return edit ? (
              <FormattedEdit
                active
                content={processEdit(edit)}
                formIndex={index}
                editLength={edit.length}
              />
            ) : null;
          } else {
            const visualizer = View?.({
              data: valueToView,
              props: getViewProps?.(utils),
            });

            const visualized = visualizer && asGrid([{ section: visualizer }]);

            return asRow(
              [
                visualized,
                asColumn(
                  [
                    <Button
                      lowEmphasisUpdate
                      onClick={(complete) => {
                        complete();
                        setUpdate(
                          update.includes(index)
                            ? update.filter(
                                (updateIndex) => updateIndex !== index
                              )
                            : [...update, index]
                        );
                      }}
                    >
                      {t("view:update")}
                    </Button>,
                    <Button
                      lowEmphasisDelete
                      onClick={(complete) => {
                        complete();
                        updateValues(
                          values.filter(
                            (searchValue) => searchValue !== valueToView
                          )
                        );
                      }}
                    >
                      {t("view:delete")}
                    </Button>,
                  ],
                  {
                    spacing: 0,
                  }
                ),
              ],
              {
                key: index,
                rightShifted: 1,
              }
            );
          }
        }),
      ]
    : [];

  const adder = Edit?.({
    props: {
      ...getEditProps?.(utils),
    },
    form: { disabled, required, control, utils },
  });

  const adderVisible = update.includes(adderIndex);

  if (adder && adderVisible) list.push(processEdit(adder));

  return asBordered(
    <FormattedEdit
      content={
        list.length
          ? asSpace(
              asList(list, {
                listSymbol: "number",
                emptyLabel: t("view:noRows"),
              }),
              {
                mb: 1,
              }
            )
          : null
      }
      active={adderVisible}
      formIndex={adderIndex}
      editLength={adder?.length}
    />,
    {
      title: children,
    }
  );
}
