import {
  FormControl as MuiFormControl,
  FormControlLabel as MuiFormControlLabel,
  FormGroup as MuiFormGroup,
  FormGroupProps as MuiFormGroupProps,
  FormLabel as MuiFormLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from "@mui/material";
import { ComponentProps, ReactNode, useState } from "react";
import { useAsync } from "utils/render";
import asSpace from "../LAYOUT/asSpace";
import Controller from "./Controller";
import { FormProps } from "./Form";
import Toggle from "./Toggle";

type Value = string;

type Loaded = Value[] | null;

type ToLoad = (() => Promise<Value[]>) | null;

export default function Radio<M extends boolean>(
  props: {
    id?: string | number;
    multiple?: M;
    values?: Value[] | (() => Promise<Value[]>);
    getValueLabel?: (
      value: Value
    ) => ComponentProps<typeof MuiFormControlLabel>["label"];
    disabled?: boolean;
    required?: boolean;
    helperText?: ReactNode;
    getHelperText?: (option: Value) => ReactNode;
    asField?: boolean;
    defaultValue?: MuiRadioGroupProps["defaultValue"] | null;
    onChange?: (
      data: boolean extends M
        ? Parameters<NonNullable<MuiRadioGroupProps["onChange"]>>[1]
        : M extends true
        ? Parameters<NonNullable<MuiRadioGroupProps["onChange"]>>[1][]
        : Parameters<NonNullable<MuiRadioGroupProps["onChange"]>>[1]
    ) => void;
  } & FormProps &
    Omit<
      MuiRadioGroupProps & MuiFormGroupProps,
      "id" | "defaultValue" | "onChange"
    >
) {
  const {
    form,
    id,
    children,
    onChange: propsOnChange,
    values,
    disabled,
    required,
    helperText,
    getHelperText,
    multiple,
    asField,
    getValueLabel,
    defaultValue,
    ...radioProps
  } = props;

  const [loadedValues, setLoadedValues] = useState<Loaded | ToLoad>(
    typeof values !== "function" ? values ?? null : () => values
  );

  const loading =
    typeof values === "function" &&
    (typeof loadedValues === "function" || !loadedValues!.length);

  useAsync(
    {
      condition: loading,
      action: values as NonNullable<ToLoad>,
    },
    setLoadedValues
  );

  const isRequired = required ?? form.required;

  return loadedValues?.length ? (
    <MuiFormControl>
      <MuiFormLabel>
        {required && !form.required ? `${children} *` : children}
      </MuiFormLabel>
      {!loading && (
        <Controller
          form={form}
          id={id}
          defaultValue={defaultValue}
          defaultFallback={multiple ? "" : null}
          {...radioProps}
        >
          {({ onChange, innerRef, value: currentValue, ...field }) => {
            const typedValue = currentValue as string[] | "" | null;

            const radio = multiple ? (
              <MuiFormGroup {...(radioProps as any)}>
                {(loadedValues as Loaded)?.map((value, index) => (
                  <Toggle
                    key={index}
                    asInline
                    disabled={disabled ?? form.disabled}
                    required={isRequired}
                    onChange={(data) => {
                      let newValue: typeof typedValue;

                      if (data) {
                        newValue =
                          typedValue === ""
                            ? [value]
                            : [...(typedValue ?? []), value];
                      } else {
                        const filteredValues = (typedValue || [])?.filter(
                          (filterValue) => filterValue !== value
                        );

                        newValue = filteredValues.length ? filteredValues : "";
                      }

                      onChange(newValue);
                      propsOnChange?.(newValue as any);
                    }}
                    inputRef={innerRef}
                    value={typedValue?.includes(value)}
                    {...field}
                  >
                    {getValueLabel?.(value) ?? value}
                  </Toggle>
                ))}
              </MuiFormGroup>
            ) : (
              <MuiRadioGroup
                {...radioProps}
                value={typedValue}
                onClick={
                  isRequired
                    ? undefined
                    : ({ target: { value: data } }: any) => {
                        const valueToSet = data === typedValue ? null : data;

                        onChange(valueToSet);
                        propsOnChange?.(valueToSet);
                      }
                }
                onChange={
                  isRequired
                    ? ({ target: { value: data } }) => {
                        onChange(data);
                        propsOnChange?.(data as any);
                      }
                    : undefined
                }
                ref={innerRef}
                {...field}
              >
                {(loadedValues as Loaded)?.map((value, index) => (
                  <MuiFormControlLabel
                    key={index}
                    value={value}
                    label={!!getValueLabel ? getValueLabel(value) : value}
                    control={
                      <MuiRadio
                        color="primary"
                        required={required ?? form.required}
                      />
                    }
                    disabled={disabled ?? form.disabled}
                  />
                ))}
              </MuiRadioGroup>
            );

            return (
              <>
                {asField && radioProps.row
                  ? asSpace(radio, {
                      mb: -2,
                    })
                  : radio}
                {helperText !== undefined || getHelperText
                  ? (helperText ??
                      getHelperText?.(typedValue?.toString() ?? "")) ||
                    " "
                  : undefined}
              </>
            );
          }}
        </Controller>
      )}
    </MuiFormControl>
  ) : null;
}
