import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
} from "@mui/material";
import { LOADING_TIMEOUT } from "constants/ui";
import { ReactNode, useState } from "react";
import { useAsync } from "utils/render";

export default function Progress<S extends boolean>(
  props: { children?: ReactNode; disabled?: boolean; spinner?: S } & Omit<
    boolean extends S
      ? MuiLinearProgressProps
      : S extends true
      ? MuiCircularProgressProps
      : MuiLinearProgressProps,
    "children"
  >
) {
  const { spinner, disabled, children, ...progressProps } = props;

  const [showLoader, setShowLoader] = useState(false);

  let timeout: NodeJS.Timeout | undefined;

  useAsync(
    {
      condition: !showLoader && !disabled,
      action: (isMounted) =>
        setTimeout(() => isMounted() && setShowLoader(true), LOADING_TIMEOUT),
      cleanup: () => timeout && clearTimeout(timeout),
    },
    (loadingTimeout) => (timeout = loadingTimeout)
  );

  return disabled || !showLoader ? (
    <>{children}</>
  ) : spinner ? (
    <MuiCircularProgress {...(progressProps as any)} />
  ) : (
    <MuiLinearProgress {...(progressProps as any)} />
  );
}
